export const enum PaymentLinkSendingTypeEnum {
  Email = 'Email',
  Sms = 'Sms',
}

export const enum PaymentLinkReminderChannelEnum {
  sms = 1,
  email = 2,
  both = 3,
}
