export enum CommonHelpersTriggerGTMEventsNamesEnum {
  pageView = 'Pageview',
  prospect = 'prospect',
  registrationFormSubmit = 'registration_form_submit',
  otpSuccess = 'otp_success',
  timeStamp = 'time_stamp',
  storeSetupUserLevel = 'store_setup_level',
  storeSetupUserInventoryCity = 'store_setup_user_inventory_city',
  storeSetupUserBusinessType = 'store_setup_user_business_type',
  storeSetupUserStoreCategory = 'store_setup_user_store_category',
  storeSetupCompleted = 'store_setup_completed',
  addToCart = 'add_to_cart',
  startCheckout = 'start_checkout',
  purchase = 'purchase',
  productRemoved = 'product_removed',
  addExtraService = 'add_extra_service',
  linkClick = 'link_click',
  subscriptions = 'subscriptions',
  appPurchased = 'purchases',
  storeSetupDomain = 'storeSetupDomain',
  otpSuccessPhone = 'otp_success_phone',
  firstPurchase = 'first_purchase',
}
