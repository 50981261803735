import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AnchorButtonTypeEnum } from './type.enum';
import { AnchorButtonSizeEnum } from './size.enum';
import styles from './AnchorButton.scss';

@Component
export class AnchorButton extends Vue {
  private readonly typeToClassNameMap: Record<AnchorButtonTypeEnum, string> = {
    [AnchorButtonTypeEnum.primary]: styles['anchorbutton--type-primary'],
    [AnchorButtonTypeEnum.secondary]: styles['anchorbutton--type-secondary'],
    [AnchorButtonTypeEnum.success]: styles['anchorbutton--type-success'],
    [AnchorButtonTypeEnum.warning]: styles['anchorbutton--type-warning'],
    [AnchorButtonTypeEnum.danger]: styles['anchorbutton--type-danger'],
    [AnchorButtonTypeEnum.info]: styles['anchorbutton--type-info'],
    [AnchorButtonTypeEnum.link]: styles['anchorbutton--type-link'],
  };

  private readonly sizeToClassNameMap: Record<AnchorButtonSizeEnum, string> = {
    [AnchorButtonSizeEnum.small]: styles['anchorbutton--size-small'],
    [AnchorButtonSizeEnum.default]: styles['anchorbutton--size-default'],
    [AnchorButtonSizeEnum.large]: styles['anchorbutton--size-large'],
  };

  @Prop({
    default: AnchorButtonTypeEnum.primary,
  })
  readonly type!: AnchorButtonTypeEnum;

  @Prop({
    default: AnchorButtonSizeEnum.default,
  })
  readonly size!: AnchorButtonSizeEnum;

  @Prop({
    default: false,
  })
  private readonly outlined!: boolean;

  get classes(): Record<string, boolean> {
    return {
      [styles.anchorbutton]: true,
      [styles['anchorbutton--outlined']]: this.outlined,
      // [styles['button--loading']]: this.isLoading,
      [this.typeToClassNameMap[this.type]]: !!this.type,
      [this.sizeToClassNameMap[this.size]]: !!this.size,
    };
  }

  render(h: CreateElement): VNode {
    return <a class={this.classes}>{this.$slots.default}</a>;
  }
}
