import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import isFeatureAvailable from '../../common/helpers/is-feature-available';

export function hasGroupedProducts(to: Route, from: Route, next: Function) {
  const hasFeatureKey = isFeatureAvailable('products.grouped_products');

  if (hasFeatureKey) {
    next();
  } else {
    next({
      name: RoutingRouteEnum.products,
    });
  }
}
