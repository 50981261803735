import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IconPrefix, library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { FontAwesomeIconTypeEnum } from './type.enum';

// Unfortunately FontAwesomeIcon type doesn't allow to render it using JSX without doing type hacks
Vue.component('font-awesome-icon', FontAwesomeIcon);

@Component
export class FontAwesomeIconComponent extends Vue {
  @Prop({
    required: true,
  })
  private readonly icon!: IconDefinition;

  @Prop({
    default: FontAwesomeIconTypeEnum.regular,
  })
  private readonly type!: FontAwesomeIconTypeEnum;

  private readonly typeToPrefixMap: Record<FontAwesomeIconTypeEnum, IconPrefix> = {
    [FontAwesomeIconTypeEnum.regular]: 'far',
    [FontAwesomeIconTypeEnum.solid]: 'fas',
  };

  created(): void {
    // Add the icon to FA's library
    // At this point we don't have to worry about tree shaking and bundle optimizations.
    // The final bundle will contain only a single copy of icons that are being used.
    library.add(this.icon);
  }

  render(h: CreateElement): VNode {
    let icon;
    if (this.type) {
      icon = [this.typeToPrefixMap[this.type], this.icon.iconName];
    } else {
      icon = this.icon.iconName;
    }

    return <font-awesome-icon icon={icon} />;
  }

  @Watch('icon')
  protected onChangeIcon(icon: IconDefinition): void {
    library.add(icon);
  }
}
