import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import styles from './StoreAvailabilityBar.scss';
import { I18nMessages } from '../../../i18n/messages';
import { ButtonComponent } from '../../../common/components/Button';
import lockedStoreImgUrl from './locked_Store.svg';
import shoppingCartImgUrl from './shopping_cart.svg';
import lockedOrdersImgUrl from './locked_orders.svg';
import UserStoreModule from '../../../user/store/module';
import { ZidText } from '@zidsa/ui';

@Component
export class StoreAvailabilityBarComponent extends Vue {
  render(h: CreateElement): VNode {
    return (
      <div class={styles.storeavailabilitybarcontainer}>
        {this.user?.store.availability.is_store_closed &&
        this.user?.store.availability.closing_type === 'store_closed' &&
        this.user?.store.availability.closed_now ? (
          <nav class={styles.storeavailabilitybar}>
            <img class={styles.storeavailabilitybar__img} src={lockedStoreImgUrl} alt='Store locked' />
            <span class={styles.storeavailabilitybartitle}>
              {this.$t(I18nMessages['store.availability.close.title'])}
            </span>
            <span class={styles.storeavailabilitybarwarning}>
              {this.$t(I18nMessages['store.availability.open.store'])}
            </span>
            <ButtonComponent class={styles.storesettingbtn} nativeOnClick={this.goToSettings}>
              {this.$t(I18nMessages['store.availability.store.settings'])}
            </ButtonComponent>
            <div class={styles.remaining__btn}>
              {this.user?.store.availability.activating_data &&
              (this.user?.store.availability.activating_data.remaining_months ||
                this.user?.store.availability.activating_data.remaining_days ||
                this.user?.store.availability.activating_data.remaining_hours) ? (
                <strong class={styles.storeavailabilitybarcounter}>
                  <ZidText class={styles.text_alert} weight='bold'>
                    {this.$t(I18nMessages['store.availability.counter'])}
                  </ZidText>
                  {this.user?.store.availability.activating_data.remaining_months ? (
                    <span>
                      <span>{this.user?.store.availability.activating_data.remaining_months} </span>
                      <span>{this.$t(I18nMessages['common.month'])}</span>
                      {this.user?.store.availability.activating_data.remaining_days ? (
                        <span>
                          <span> {this.$t(I18nMessages['common.and'])} </span>
                          <span>{this.user?.store.availability.activating_data.remaining_days} </span>
                          <span>{this.$t(I18nMessages['store.availability.days'])}</span>
                        </span>
                      ) : null}
                    </span>
                  ) : this.user?.store.availability.activating_data.remaining_days ? (
                    <span>
                      <span>{this.user?.store.availability.activating_data.remaining_days} </span>
                      <span>{this.$t(I18nMessages['store.availability.days'])}</span>
                    </span>
                  ) : this.user?.store.availability.activating_data.remaining_hours ? (
                    <span>
                      <span>{this.user?.store.availability.activating_data.remaining_hours} </span>
                      <span>{this.$t(I18nMessages['store.availability.hour'])}</span>
                    </span>
                  ) : null}
                </strong>
              ) : null}
            </div>
          </nav>
        ) : null}

        {this.user?.store.availability.is_store_closed &&
        !this.user?.store.availability.closed_now &&
        this.user?.store.availability.closing_type === 'orders_hold' ? (
          <div id='navbar-remaining' class={styles.storeavailabilitybar__shoppingcart}>
            <img class={styles.storeavailabilitybar__img} src={shoppingCartImgUrl} alt='Shopping cart' />
            <span class={styles.storeavailabilitybartitle}>
              {this.$t(I18nMessages['store.availability.orders.enable'])}
            </span>
            <span class={styles.storeavailabilitybarwarning}>
              {this.$t(I18nMessages['store.availability.close.orders'])}
            </span>
            <ButtonComponent class={styles.openordersettingbtn} nativeOnClick={this.goToSettings}>
              {this.$t(I18nMessages['store.availability.store.settings'])}
            </ButtonComponent>
            <div class={styles.remaining__btn}>
              {this.user?.store.availability.closing_time_type === 'recurring' &&
              this.user?.store.availability.closing_data &&
              this.user?.store.availability.closing_data.day_name ? (
                <strong class={styles.storeavailabilitybarcounter}>
                  <ZidText class={styles.text_alert} weight='bold'>
                    {this.$t(I18nMessages['store.availability.orders.notification'])}
                  </ZidText>
                  {this.user?.store.availability.closing_data.day_name === 'Sunday' ? (
                    <span>{this.$t(I18nMessages['common.days.sunday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Monday' ? (
                    <span>{this.$t(I18nMessages['common.days.monday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Tuesday' ? (
                    <span>{this.$t(I18nMessages['common.days.tuesday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Wednesday' ? (
                    <span>{this.$t(I18nMessages['common.days.wednesday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Thursday' ? (
                    <span>{this.$t(I18nMessages['common.days.thursday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Friday' ? (
                    <span>{this.$t(I18nMessages['common.days.friday'])}</span>
                  ) : this.user?.store.availability.closing_data.day_name === 'Saturday' ? (
                    <span>{this.$t(I18nMessages['common.days.saturday'])}</span>
                  ) : null}
                  <span class={styles.availability__time}>
                    {this.$t(I18nMessages['store.availability.orders.hour'])}
                  </span>
                  {this.user?.store.availability.closing_data.time}
                </strong>
              ) : null}
              <button class={styles.close__btn} onClick={this.dismissNavBar}>
                {' '}
                x
              </button>
            </div>
          </div>
        ) : null}

        {this.user?.store.availability.is_store_closed &&
        this.user?.store.availability.closed_now &&
        this.user?.store.availability.closing_type === 'orders_hold' ? (
          <div class={styles.storeavailabilitybar}>
            <img class={styles.storeavailabilitybar__img} src={lockedOrdersImgUrl} alt='locked orders' />
            <span class={styles.storeavailabilitybartitle}>
              {this.$t(I18nMessages['store.availability.orders.disabled'])}
            </span>
            <span class={styles.storeavailabilitybarwarning}>
              {this.$t(I18nMessages['store.availability.open.orders'])}
            </span>
            <ButtonComponent class={styles.storesettingbtn} nativeOnClick={this.goToSettings}>
              {this.$t(I18nMessages['store.availability.store.settings'])}
            </ButtonComponent>
            <div class={styles.remaining__btn}>
              {this.user?.store.availability.closing_time_type === 'recurring' &&
              this.user?.store.availability.activating_data &&
              this.user?.store.availability.activating_data.day_name ? (
                <strong class={styles.storeavailabilitybarcounter}>
                  <ZidText class={styles.text_alert} weight='bold'>
                    {this.$t(I18nMessages['store.availability.orders.notification.opening'])}
                  </ZidText>
                  {this.user?.store.availability.activating_data.day_name === 'Sunday' ? (
                    <span>{this.$t(I18nMessages['common.days.sunday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Monday' ? (
                    <span>{this.$t(I18nMessages['common.days.monday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Tuesday' ? (
                    <span>{this.$t(I18nMessages['common.days.tuesday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Wednesday' ? (
                    <span>{this.$t(I18nMessages['common.days.wednesday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Thursday' ? (
                    <span>{this.$t(I18nMessages['common.days.thursday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Friday' ? (
                    <span>{this.$t(I18nMessages['common.days.friday'])}</span>
                  ) : this.user?.store.availability.activating_data.day_name === 'Saturday' ? (
                    <span>{this.$t(I18nMessages['common.days.saturday'])}</span>
                  ) : null}
                  <span> {this.$t(I18nMessages['store.availability.orders.hour'])} </span>
                  <span class={styles.availability__time}>{this.user?.store.availability.activating_data.time}</span>
                </strong>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  private get user() {
    return UserStoreModule?.data;
  }

  private goToSettings() {
    window.location.href = '/account/settings/worktime';
  }

  private dismissNavBar() {
    const e = document.getElementById('navbar-remaining');
    // @ts-ignore
    e.setAttribute('style', 'display:none;');
  }
}
