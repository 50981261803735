import { ZidActionGroup, ZidChip } from '@zidsa/ui';

//Styles & Assets
import styles from './SuggestedValueChips.module.scss';

import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { I18nMessages } from '../../../../../i18n/messages';
import { WALLET_SUGGESTED_VALUES } from '../../../../constants/wallet_constants';

@Component
export class WalletComponentsAddFundsActionsMoneyChipSelectComponent extends Vue {
  @Prop()
  private readonly isUserInput!: boolean;

  private suggestedMoneyValues = WALLET_SUGGESTED_VALUES;

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidActionGroup class={styles.funds__chips}>
          {this.suggestedMoneyValues.map((amount, index) => (
            <ZidChip
              position='start'
              name='suggested_money_values'
              value={amount.value}
              selected={this.suggestedMoneyValues[index].selected}
              onClick={(): void => this.toggleMoneySelect(index, amount.value)}
            >
              {amount.value} {this.$t(I18nMessages['zidship.sar'])}
            </ZidChip>
          ))}
        </ZidActionGroup>
      </div>
    );
  }

  private toggleMoneySelect(index: number, value: string): void {
    for (let i = 0; i < this.suggestedMoneyValues.length; i++) {
      if (i === index) {
        this.suggestedMoneyValues[i].selected = !this.suggestedMoneyValues[i].selected;
      } else {
        this.suggestedMoneyValues[i].selected = false;
      }
    }
    this.$emit('moneyChipSelect', value);
  }

  @Watch('isUserInput')
  async onMoneyInput(): Promise<void> {
    if (this.isUserInput)
      for (let i = 0; i < this.suggestedMoneyValues.length; i++) {
        this.suggestedMoneyValues[i].selected = false;
      }
  }
}
