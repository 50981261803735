/* eslint-disable @typescript-eslint/camelcase */
import { ApiSearchParamsType } from '../../api/types';
import { objectFilter } from '../../common/helpers/object/filter';
import { ZidShipTypesWalletFiltersInterface } from './wallet-filters.interface';

export function ZidShipTypesWalletFiltersToSearchParams(
  filters: ZidShipTypesWalletFiltersInterface,
): ApiSearchParamsType {
  const params = {
    limit: filters.limit !== undefined ? String(filters.limit) : undefined,
    page: filters.page !== undefined ? String(filters.page) : undefined,
    type: filters.type !== undefined ? String(filters.type) : undefined,
    orderBy: filters.orderBy !== undefined ? String(filters.orderBy) : undefined,
    search: filters.search !== undefined ? String(filters.search) : undefined,
    service_levels: filters.service_levels !== undefined ? String(filters.service_levels) : undefined,
    status: filters.status !== undefined ? String(filters.status) : undefined,
    created_at_from: filters.created_at_from !== undefined ? String(filters.created_at_from) : undefined,
    created_at_to: filters.created_at_to !== undefined ? String(filters.created_at_to) : undefined,
  };
  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
