import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { routingRoutes } from './routes';
import { userPermissionGuard } from './guards/user-permission.guard';
import { RoutingRouteEnum } from './route.enum';
import { draftSessionGuard } from './guards/draft-session.guard';

import appI18nFactory from '../app/i18n/factory';
import { LocaleMessage } from 'vue-i18n';
import { AppStoreModule } from '../app/store/module';
import UserStoreModule from '../user/store/module';
import { redirectNewRegisterToSubscription } from './guards/redirect-new-register-to-subscription.guard';
import { suspendedGuard } from './guards/suspended.guard';
import isFreemiumSubscription from './guards/is-freemium-subscription';

function changeTitleTag(to: Route): void {
  const i18n = appI18nFactory();

  let pageTitle: LocaleMessage = i18n.messages[i18n.locale]['zid.title'];

  if (to.meta?.title) {
    pageTitle = `${to.meta.title} | ${pageTitle}`;
  }
  document.title = pageTitle as string;
}

function closeNavbarOnMobile(): void {
  if (window.matchMedia('(max-width: 768px)').matches) {
    AppStoreModule.closeNavbar();
  }
}

export function appRouterCreate(): VueRouter {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    routes: routingRoutes,
  });

  router.beforeEach((to: Route, from: Route, next: Function) => {
    if (
      [
        RoutingRouteEnum.login,
        RoutingRouteEnum.register,
        RoutingRouteEnum.loginBtns,
        RoutingRouteEnum.loginMobile,
        RoutingRouteEnum.resetPass,
        RoutingRouteEnum.otp,
        RoutingRouteEnum.loginWithPassword,
        RoutingRouteEnum.loginBtns,
        RoutingRouteEnum.registerWizard,
        RoutingRouteEnum.changeMobile,
        RoutingRouteEnum.subscriptionsSuspended,
        RoutingRouteEnum.subscriptionsCheckout,
        RoutingRouteEnum.subscriptionPackages,
        RoutingRouteEnum.completeStoreInfo,
        RoutingRouteEnum.newPassword,
        RoutingRouteEnum.thankYou,
      ].includes(to.name as RoutingRouteEnum)
    ) {
      /**
       * Any user can access these routes!
       */
      next();
    } else if (isFreemiumSubscription()) {
      /**
       * If the user is freemium then redirect him to subscribe (select some package)!
       */
      redirectNewRegisterToSubscription(to, next);
    } else if (
      UserStoreModule.data &&
      UserStoreModule.data.store &&
      UserStoreModule.data.store.subscription &&
      UserStoreModule.data.store.subscription.is_suspended
    ) {
      /**
       * If the subscription is suspended
       */
      suspendedGuard(to, next);
    } else {
      /**
       * Finally, this is the user we want :)
       * Premium and not suspended
       */
      userPermissionGuard(to, next, router);

      if (to.name === RoutingRouteEnum.orderCreation) {
        draftSessionGuard(router, to);
      }
    }
  });

  router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
      changeTitleTag(to);
      closeNavbarOnMobile();
    });
  });

  return router;
}
