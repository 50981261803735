import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidIconComponent, ZidIconTypeEnum } from '../../ZidIcon';
import { LoaderComponent, LoaderSizeEnum } from '../../Loader';
import {
  DropdownBaseComponent,
  DropdownBaseBodyComponent,
  DropdownBaseHeaderComponent,
  DropdownBaseToggleExpandedComponent,
  DropdownBaseChangeEventInterface,
} from '../Base';
import { DropdownListTypeEnum } from './type.enum';
import { DropdownListOptionInterface } from './option.interface';
import { DropdownListChangeEventInterface } from './change-event.interface';
import styles from './DropdownList.scss';
import { DropdownListTypesHeaderValueRendererType } from './types';

@Component
export class DropdownListComponent<TValue = string> extends Vue {
  private isExpanded = false;

  @Prop({
    default: DropdownListTypeEnum.primary,
  })
  private readonly type!: DropdownListTypeEnum;

  @Prop({
    required: true,
  })
  private readonly value!: TValue | null;

  @Prop({
    required: true,
    default: () => [],
  })
  private readonly options!: Array<DropdownListOptionInterface<TValue>>;

  @Prop({
    default: false,
  })
  private readonly isDisabled!: boolean;

  @Prop({
    default: false,
  })
  private readonly isLoading!: boolean;

  @Prop()
  private readonly headerValueRenderer!: DropdownListTypesHeaderValueRendererType<TValue>;

  @Prop()
  private readonly disabledClass!: string;

  @Prop()
  private readonly headerClass!: string;

  @Prop()
  private readonly headerButtonClass!: string;

  @Prop()
  private readonly bodyClass!: string;

  private readonly typeToClassNameMap: Record<DropdownListTypeEnum, string> = {
    [DropdownListTypeEnum.primary]: styles['dropdownlist--type-primary'],
    [DropdownListTypeEnum.secondary]: styles['dropdownlist--type-secondary'],
    [DropdownListTypeEnum.success]: styles['dropdownlist--type-success'],
    [DropdownListTypeEnum.warning]: styles['dropdownlist--type-warning'],
    [DropdownListTypeEnum.danger]: styles['dropdownlist--type-danger'],
    [DropdownListTypeEnum.info]: styles['dropdownlist--type-info'],
    [DropdownListTypeEnum.light]: styles['dropdownlist--type-light'],
  };

  render(h: CreateElement): VNode {
    return (
      <DropdownBaseComponent
        isExpanded={this.isExpanded}
        isDisabled={this.isDisabled}
        isExpandable={this.options.length > 0}
        onChange={this.onChangeDropdownBaseState}
        class={{
          [styles.dropdownlist]: true,
          [this.typeToClassNameMap[this.type]]: true,
          [styles['dropdownlist--disabled']]: this.isDisabled,
          [styles['dropdownlist--loading']]: this.isLoading,
          [this.disabledClass]: this.isDisabled,
        }}
      >
        {this.renderHeader(h)}
        <DropdownBaseBodyComponent class={[styles.dropdownlist__body, this.bodyClass]}>
          {this.options.map((option) => this.renderOption(h, option))}
        </DropdownBaseBodyComponent>
      </DropdownBaseComponent>
    );
  }

  private renderHeader(h: CreateElement): VNode {
    const activeOption = this.options.find((option) => option.value === this.value);

    const valueRenderer = this.headerValueRenderer || this.defaultHeaderValueRenderer;

    return (
      <DropdownBaseHeaderComponent
        class={[styles.dropdownlist__header, this.headerClass]}
        expandedClass={styles['dropdownlist__header--expanded']}
      >
        <DropdownBaseToggleExpandedComponent class={[styles.dropdownlist__button, this.headerButtonClass]}>
          <span class={styles.dropdownlist__value}>{valueRenderer(h, activeOption)}</span>
          {this.options.length ? (
            <ZidIconComponent type={ZidIconTypeEnum.arrow} class={styles.dropdownlist__buttonicon} />
          ) : null}
          {this.isLoading && <LoaderComponent size={LoaderSizeEnum.small} class={styles.dropdownlist__loader} />}
        </DropdownBaseToggleExpandedComponent>
      </DropdownBaseHeaderComponent>
    );
  }

  private defaultHeaderValueRenderer: DropdownListTypesHeaderValueRendererType = (h, option) => option?.label;

  private renderOption(h: CreateElement, option: DropdownListOptionInterface<TValue>): VNode {
    const classes: Record<string, boolean> = {
      [styles.dropdownlist__listitem]: true,
      [styles['dropdownlist__listitem--active']]: option.value === this.value,
      [styles['dropdownlist__listitem--disabled']]: !!option.disabled,
    };

    return (
      <div class={classes} onClick={() => this.onClickOption(option)}>
        {option.label}
      </div>
    );
  }

  private onClickOption(option: DropdownListOptionInterface<TValue>): void {
    if (this.isDisabled || this.isLoading || option.disabled) {
      return;
    }

    const event: DropdownListChangeEventInterface<TValue> = {
      option,
    };

    this.isExpanded = false;
    this.$emit('change', event);
  }

  private onChangeDropdownBaseState(event: DropdownBaseChangeEventInterface): void {
    this.isExpanded = event.state.isExpanded;
  }
}
