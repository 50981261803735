<template>
  <div>
    <div :class="styles['search-footer-wrapper']"
      v-if="selectedSearchCategories && isCategorySearchCompleted && isAllowedCategory">
      <a :href="handleSearchRedirectUrl(selectedSearchCategories)" :class="styles['recommended-category-results']">
        <div :class="styles['results-wrapper']">
          <ZidText size="sm">
            {{ $t(I18nMessages['common.search_on']) }}
          </ZidText>
          <ZidText :class="styles['result']" size="sm" weight="semibold">
            {{ searchQuery }}
          </ZidText>
          <ZidText size="sm">
            {{ $t(I18nMessages['common.in']) }}
          </ZidText>
          <ZidBadge :class="styles['category-box']" type="neutral">
            <div :class="styles['category']">
              <ZidIcon v-if="selectedSearchCategories.icon" :icon="selectedSearchCategories.icon" size="10px" />
              <component v-else :is="selectedSearchCategories.img" />
              <span>{{ $t(I18nMessages[selectedSearchCategories.name]) }}</span>
            </div>
          </ZidBadge>
        </div>
        <div>
          <ZidIcon icon="arrow_left_line" size="8px" />
        </div>
      </a>
    </div>
    <div :class="styles['search-footer-wrapper']" v-else-if="!selectedSearchCategories && isGlobalSearchCompleted">
      <a v-for="(item, index) in globalSearchRecommendedResults" :key="index" :href="`${item.url}${searchQuery}`"
        :class="styles['recommended-category-results']" :target="item.target">
        <div :class="styles['results-wrapper']">
          <ZidText size="sm">
            {{ $t(I18nMessages['common.search_on']) }}
          </ZidText>
          <ZidText :class="styles['result']" size="sm" weight="semibold">
            {{ searchQuery }}
          </ZidText>
          <ZidText size="sm">
            {{ $t(I18nMessages['common.in']) }}
          </ZidText>
          <ZidBadge :class="styles['category-box']" type="neutral">
            <div :class="styles['category']">
              <ZidIcon v-if="typeof item.icon === 'string'" :icon="item.icon" size="10px" />
              <component v-else :is="item.icon"></component>
              <span>{{ $t(item.name) }}</span>
            </div>
          </ZidBadge>
        </div>
        <div :class="styles['icon-box']">
          <ZidIcon icon="arrow_left_line" size="8px" />
        </div>
      </a>
    </div>
    <div v-else-if="searchHistory.length && !selectedSearchCategories" :class="styles['search-footer-wrapper']">
      <div :class="styles['title-box']">
        <ZidText size="sm" weight="semibold">{{ $t(I18nMessages['global_search.categories_search_history']) }}</ZidText>
        <button @click="removeSearchHistory">
          {{ $t(I18nMessages['global_search.categories_remove_search_history']) }}
        </button>
      </div>
      <div :class="styles['search-history-box']">
        <template v-for="(history, index) in searchHistory">
          <button @click="() => performSearchHistory(history)">
            <ZidBadge :key="index" :class="styles['history-badge']" type="neutral">
              <div :class="styles['content']">
                <img :src="clockIcon" alt="clock-icon" />
                <span>{{ history }}</span>
              </div>
            </ZidBadge>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { ZidBadge, ZidText, ZidIcon } from '@zidsa/ui';
import clockIcon from '../../../../../common/assets/icons/clockIcon.svg';
import { globalSearchModule } from '../../../../store/GlobalSearch/module';
import { useGlobalSearch } from '../../../../composables/useGlobalSearch';
import useI18nMessages from '../../../../../common/composables/useI18nMessages';
import { GlobalSearchCategoriesInterface } from '../../../../types/global-search.interface';
import { GlobalSearchCategoriesEnum } from '../../../../types/global-search.enum';
import PuzzlePieceIcon from "../../../../../common/assets/icons/PuzzlePieceIcon.vue";

export default defineComponent({
  components: { ZidText, ZidBadge, ZidIcon },
  setup() {
    const I18nMessages = useI18nMessages();
    const { handleSearch, searchQuery } = useGlobalSearch();
    const globalSearchRecommendedResults = ref([
      {
        url: `https://help.zid.sa/?s=`,
        name: I18nMessages['statusbar.help_center'],
        icon: 'question_circle_outline',
        target: "_blank"
      },
      {
        url: `/market/applications?term=`,
        name: I18nMessages['global_search.categories_apps'],
        icon: PuzzlePieceIcon,
        target: ''
      },
    ]);
    const searchHistory = computed((): string[] => globalSearchModule.searchHistory);
    const selectedSearchCategories = computed(
      (): GlobalSearchCategoriesInterface | null => globalSearchModule.selectedSearchCategories,
    );
    const isCategorySearchCompleted = computed((): boolean => globalSearchModule.isCategorySearchCompleted);
    const isGlobalSearchCompleted = computed((): boolean => globalSearchModule.isGlobalSearchCompleted);
    const isAllowedCategory = computed((): boolean => {
      return (
        !!selectedSearchCategories.value && selectedSearchCategories.value.code !== GlobalSearchCategoriesEnum.features
      );
    });

    const removeSearchHistory = (): void => {
      globalSearchModule.RESET_SEARCH_HISTORY();
    };

    const performSearchHistory = async (history: string): Promise<void> => {
      if (searchQuery.value === history) return;
      globalSearchModule.SET_SEARCH_QUERY(history);
      await handleSearch(history);
    };

    const handleSearchRedirectUrl = (category: GlobalSearchCategoriesInterface): string => {
      if (category.code === GlobalSearchCategoriesEnum.appMarket) {
        return `${category.route_path}?term=${searchQuery.value}`;
      }

      return `${category.route_path}?search=${searchQuery.value}`;
    };

    return {
      clockIcon,
      searchHistory,
      removeSearchHistory,
      performSearchHistory,
      I18nMessages,
      selectedSearchCategories,
      isCategorySearchCompleted,
      isGlobalSearchCompleted,
      searchQuery,
      isAllowedCategory,
      handleSearchRedirectUrl,
      globalSearchRecommendedResults,
    };
  },
});
</script>
<style lang="scss" module="styles" src="./SearchFooter.module.scss"></style>
