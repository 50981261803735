import { PurchaseHistoryApiFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../../api/types';
import { objectFilter } from '../../../../common/helpers/object/filter';

export function purchaseHistoryApiFiltersToSearchParams(
  filters: PurchaseHistoryApiFiltersInterface,
): ApiSearchParamsType {
  /* eslint-disable @typescript-eslint/camelcase */
  const params: PurchaseHistoryApiFiltersInterface = {
    page: filters.page,
    per_page: filters.per_page,
    status_code: filters.status_code,
    payment_method_code: filters.payment_method_code,
    purchase_type: filters.purchase_type,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
