import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';

function isRouteAllowed(to: Route): boolean {
  return [
    RoutingRouteEnum.subscriptionsSuspended,
    RoutingRouteEnum.subscriptionsCheckout,
    RoutingRouteEnum.subscriptionPackages,
    RoutingRouteEnum.completeStoreInfo,
    RoutingRouteEnum.subscriptionsCheckoutThank,
    RoutingRouteEnum.registerWizard,
    RoutingRouteEnum.thankYou,
    RoutingRouteEnum.presetup,
  ].includes(to.name as RoutingRouteEnum);
}

export function redirectNewRegisterToSubscription(to: Route, next: Function): void {
  if (!isRouteAllowed(to)) next({ name: RoutingRouteEnum.subscriptionPackages });
  else next();
}
