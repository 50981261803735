<template>
  <div :class="styles['search-body-wrapper']">
    <div :class="styles['loader']" v-if="isSearchLoading"><ZidLoader /></div>
    <div v-else>
      <div :class="styles['empty-state']" v-if="isEmptySearch">{{ $t(I18nMessages['common.no_search_results']) }}</div>
      <div v-else-if="hasNotSearched && selectedCategory" :class="styles['initial-search']">
        <div :class="styles['content-wrapper']">
          <ZidIcon icon="search" size="xl" />
          <ZidText> {{ $t(I18nMessages['common.search_on']) }} {{ $t(I18nMessages[selectedCategory.name]) }} </ZidText>
        </div>
      </div>
      <div v-else>
        <div v-if="searchResults.orders.length" v-for="order in searchResults.orders" :key="order.id">
          <GlobalSearchComponentsSearchResultComponent
            icon="basket_outline"
            :result="order.id.toString()"
            :subResult="order.order_status.name"
            :url="handleOrderUrl(order)"
            :source="GlobalSearchCategoriesEnum.orders"
          />
        </div>
        <div v-if="searchResults.products.length" v-for="product in searchResults.products" :key="product.id">
          <GlobalSearchComponentsSearchResultComponent
            icon="product_outline"
            :result="getLocalizedValue(product.name)"
            :subResult="formatProductSubResult(product)"
            :url="handleProductUrl(product)"
            :source="GlobalSearchCategoriesEnum.products"
          />
        </div>
        <div
          v-if="searchResults.customers.length && selectedCategory?.code === GlobalSearchCategoriesEnum.customers"
          v-for="customer in searchResults.customers"
          :key="customer.id"
        >
          <GlobalSearchComponentsSearchResultComponent
            :customIcon="CustomerOutlineIcon"
            :result="customer.name"
            :subResult="$t(handleCustomerSubtitle(customer))"
            :url="handleCustomerUrl(customer)"
            :source="GlobalSearchCategoriesEnum.customers"
          />
        </div>
        <div
          v-if="searchResults.appMarket.length && selectedCategory?.code === GlobalSearchCategoriesEnum.appMarket"
          v-for="app in searchResults.appMarket"
          :key="app.id"
        >
          <GlobalSearchComponentsSearchResultComponent
            :customIcon="PuzzlePieceIcon"
            :result="app.name"
            :subResult="app.short_description"
            :url="handleAppMarketUrl(app)"
            :source="GlobalSearchCategoriesEnum.appMarket"
          />
        </div>
        <div v-if="searchResults.features.length" v-for="(feature, index) in searchResults.features" :key="index">
          <GlobalSearchComponentsSearchResultComponent
            :result="getLocalizedValue(feature.feature_name)"
            :subResult="getLocalizedValue(feature.module_name)"
            :url="handleFeaturesUrl(feature)"
            icon="settings"
            :source="GlobalSearchCategoriesEnum.features"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, getCurrentInstance } from 'vue';
import { ZidIcon, ZidText, ZidLoader } from '@zidsa/ui';
import { globalSearchModule } from '../../../../store/GlobalSearch/module';
import { GlobalSearchCategoriesInterface, GlobalSearchFeaturesResponseInterface } from '../../../../types/global-search.interface';
import GlobalSearchComponentsSearchResultComponent from './SearchResult/SearchResult.vue';
import { AppStoreModule } from '../../../../store/module';
import I18nLocaleEnum from '../../../../../i18n/locale.enum';
import useI18nMessages from '../../../../../common/composables/useI18nMessages';
import { ApiModelProductInterface } from '../../../../../api/model/product.interface';
import { ApiModelProductClassTypeEnum } from '../../../../../api/model/product/class-type.enum';
import { RoutingRouteEnum } from '../../../../../routing/route.enum';
import { ApiModelOrderInterface } from '../../../../../api/model/order.interface';
import { useGlobalSearch } from '../../../../composables/useGlobalSearch';
import CustomerOutlineIcon from '../../../../../common/assets/icons/CustomerOutlineIcon.vue';
import PuzzlePieceIcon from '../../../../../common/assets/icons/PuzzlePieceIcon.vue';
import { CustomerTypesCustomerModelResponseInterface } from '../../../../../customers/types/customer-model-response.interface';
import { AppMarketTypesAppInterface } from '../../../../../app-market/types/app.interface';
import { GlobalSearchCategoriesEnum } from '../../../../types/global-search.enum';
import { I18nMessages } from '../../../../../i18n/messages';
import { I18nLocalizedStringMapType } from "../../../../../i18n/types";

export default defineComponent({
  components: { ZidIcon, ZidText, ZidLoader, GlobalSearchComponentsSearchResultComponent },
  setup() {
    const { isSearchLoading, searchResults, isEmptySearch, hasNotSearched } = useGlobalSearch();
    const I18nMessages = useI18nMessages();
    const instance = getCurrentInstance();
    const selectedCategory = computed(
      (): GlobalSearchCategoriesInterface | null => globalSearchModule.selectedSearchCategories,
    );
    const local = computed((): I18nLocaleEnum => AppStoreModule.locale);

    const handleProductUrl = (product: ApiModelProductInterface) => {
      return product.product_class === ApiModelProductClassTypeEnum.groupedProduct
        ? { name: RoutingRouteEnum.editGroupedProduct, params: { productId: product.id } }
        : { name: RoutingRouteEnum.editProduct, params: { productId: product.id } };
    };

    const handleOrderUrl = (order: ApiModelOrderInterface) => {
      return { name: RoutingRouteEnum.orderDetails, params: { orderId: order.id } };
    };

    const handleCustomerUrl = (customer: CustomerTypesCustomerModelResponseInterface) => {
      return { name: RoutingRouteEnum.customer, params: { customerId: customer.id } };
    };

    const handleAppMarketUrl = (app: AppMarketTypesAppInterface) => {
      return { name: RoutingRouteEnum.appMarketDetails, params: { id: app.id } };
    };

    const handleFeaturesUrl = (feature: GlobalSearchFeaturesResponseInterface) => {
      if (feature.route_name) {
        return { name: feature.route_name };
      }

      return feature.route_path;
    };

    const formatProductSubResult = (product: ApiModelProductInterface): string => {
      if (product.quantity === null) {
        return `${instance?.proxy.$t(I18nMessages['product.quantity_infinite'])} ∙ ${
          product.price
        } ${instance?.proxy.$t(I18nMessages['zidship.sar'])}`;
      } else {
        return `${product.quantity.toString()} ${instance?.proxy.$t(
          I18nMessages['order_creation.products_and_customer.product_selection.in_stock'],
        )} ∙ ${product.price} ${instance?.proxy.$t(I18nMessages['zidship.sar'])}`;
      }
    };

    const handleCustomerSubtitle = (customer: CustomerTypesCustomerModelResponseInterface): keyof I18nMessages => {
      const key = `common.business_details.type_of_business.${customer.type}` as keyof typeof I18nMessages;
      return I18nMessages[key];
    };

    const getLocalizedValue = (result: I18nLocalizedStringMapType | undefined) => {
       return result ? result[local.value] ?? result.ar ?? "" : "";
    }
    

    return {
      selectedCategory,
      searchResults,
      local,
      I18nMessages,
      handleProductUrl,
      handleOrderUrl,
      isEmptySearch,
      isSearchLoading,
      hasNotSearched,
      CustomerOutlineIcon,
      handleCustomerUrl,
      PuzzlePieceIcon,
      handleAppMarketUrl,
      handleFeaturesUrl,
      GlobalSearchCategoriesEnum,
      handleCustomerSubtitle,
      formatProductSubResult,
      getLocalizedValue
    };
  },
});
</script>
<style lang="scss" module="styles" src="./SearchBody.module.scss"></style>
