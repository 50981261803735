import { I18nMessages } from '../../../../i18n/messages';
import { ZidpayCountriesLocaleBase } from '../../shared/locale/locale';

export class ZidpayCountriesLocaleKW extends ZidpayCountriesLocaleBase {
  static titles: Record<string, string> = {
    individualBankCertificateTitle: I18nMessages['zidpay.activation.account.banckAccount.choose.detailed.corporate'],
    minIbanChar: '30',
    invoiceBtn: I18nMessages['common.deposit.invoice'],
    bulkExportBtn: I18nMessages['zidpay.account.statement.deposits.bulk.export.invoice.KW'],
    activationProcessHeader: I18nMessages['zidpay.marketing.activation.process.header.kw'],
  };
}
