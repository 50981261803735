interface ScriptOptionsInterface {
  async?: boolean;
  defer?: boolean;
}

export function loadScriptDynamically(src: string, options?: ScriptOptionsInterface): void {
  const script = document.createElement('script');
  script.src = src;
  script.defer = options?.defer ?? false;
  script.async = options?.async ?? false;
  document.body.append(script);
}
