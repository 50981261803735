import isRTL from '../../../../common/helpers/is-lang-arabic';
import imgs from '../../../assets/imgs';
import { ActivationProcess, CommonQuestions, FeatureList, Requirements } from '../../../types/MarketingPage.interface';
import { PackagesEnum } from '../../../types/Packages.enum';
import { ZidpayCountriesHelpersBase } from '../../shared/helpers/helpers';

export class ZidpayCountriesHelpersKW extends ZidpayCountriesHelpersBase {
  static setCurrentPackage(packageId: string): PackagesEnum {
    let current: PackagesEnum = PackagesEnum.KwBasic;
    switch (packageId) {
      case '109':
        current = PackagesEnum.KwGrowth;
        break;
      case '110':
        current = PackagesEnum.KwEnterprise;
        break;
      case '111':
        current = PackagesEnum.KwProfessional;
        break;
      default:
        break;
    }
    return current;
  }
  static getActivationProcess(): Array<ActivationProcess> {
    return [
      {
        icon: imgs.CameraHighlighted,
        number: '01',
        title: 'zidpay.marketing.activation.process.header.send.header',
        content: ['zidpay.marketing.activation.process.header.send.body'],
        arrow: imgs.ArrowHighlighted,
      },
      {
        icon: imgs.Payments,
        number: '02',
        title: 'zidpay.marketing.activation.process.header.payments.header',
        content: ['zidpay.marketing.activation.process.header.payments.body'],
        arrow: imgs.Arrow,
      },
      {
        icon: imgs.Transactions,
        number: '03',
        title: 'zidpay.marketing.activation.process.header.transactions.header',
        content: ['zidpay.marketing.activation.process.header.transactions.body'],
      },
    ];
  }
  static getFeatureList(): Array<FeatureList> {
    return [
      {
        icon: imgs.DoubleCheck,
        title: 'zidpay.marketing.features.activate',
        content: [
          'zidpay.marketing.features.activate.click',
          'zidpay.marketing.features.activate.instant',
          'zidpay.marketing.features.activate.networks',
        ],
      },
      {
        icon: imgs.Dollar,
        title: 'zidpay.marketing.features.prices',
        content: [
          'zidpay.marketing.features.prices.no.fees',
          'zidpay.marketing.features.prices.no.fees.monthly',
          'zidpay.marketing.features.prices.no.fees.refund',
        ],
      },
      {
        icon: imgs.PaymentLink,
        title: 'zidpay.marketing.features.paymentlinks',
        content: [
          'zidpay.marketing.features.paymentlinks.share',
          'zidpay.marketing.features.paymentlinks.send',
          'zidpay.marketing.features.paymentlinks.follow',
        ],
      },
      {
        icon: imgs.Monitor,
        title: 'zidpay.marketing.features.dashboard',
        content: [
          'zidpay.marketing.features.dashboard.follow',
          'zidpay.marketing.features.dashboard.balance',
          'zidpay.marketing.features.dashboard.orders',
        ],
      },
      {
        icon: imgs.Phone,
        title: 'zidpay.marketing.features.collect',
        content: [
          'zidpay.marketing.features.collect.interval.kw',
          'zidpay.marketing.features.collect.min.kw',
          'zidpay.marketing.features.collect.fees.kw',
        ],
      },
      {
        icon: imgs.Card,
        title: 'zidpay.marketing.features.networks',
        content: [
          'zidpay.marketing.features.networks.knet',
          'zidpay.marketing.features.networks.visa.mastercard',
          'zidpay.marketing.features.networks.applepay',
          'zidpay.marketing.features.networks.american',
        ],
      },
    ];
  }
  static getRequirements(): Array<Requirements> {
    return [
      {
        icon: imgs.Individuals,
        title: 'zidpay.marketing.requirements.individual',
        content: [
          'zidpay.marketing.requirements.individual.id',
          'zidpay.marketing.requirements.individual.accepted.bank',
        ],
      },
      {
        icon: imgs.Company,
        title: 'zidpay.marketing.requirements.companies',
        content: [
          'zidpay.marketing.requirements.company.bank',
          'zidpay.marketing.requirements.companies.contract',
          'zidpay.marketing.requirements.companies.signature',
        ],
        contentExtra: [
          'zidpay.marketing.requirements.company.id',
          'zidpay.marketing.requirements.company.cr',
          'zidpay.marketing.requirements.companies.license',
        ],
      },
    ];
  }
  static getCommonQuestions(): Array<CommonQuestions> {
    return [
      { title: 'zidpay.marketing.questions.kw.one', answer: 'zidpay.marketing.questions.kw.one.answer' },
      { title: 'zidpay.marketing.questions.kw.two', answer: 'zidpay.marketing.questions.kw.two.answer' },
      { title: 'zidpay.marketing.questions.kw.three', answer: 'zidpay.marketing.questions.kw.three.answer' },
      { title: 'zidpay.marketing.questions.kw.four', answer: 'zidpay.marketing.questions.kw.four.answer' },
      {
        title: 'zidpay.marketing.questions.kw.five',
        answer: 'zidpay.marketing.questions.kw.five.answer',
        answerOne: 'zidpay.marketing.questions.kw.five.answer.one',
        answerTwo: 'zidpay.marketing.questions.kw.five.answer.two',
      },
      { title: 'zidpay.marketing.questions.kw.six', answer: 'zidpay.marketing.questions.kw.six.answer' },
    ];
  }
  static getHeaderImg(): string {
    return isRTL() ? imgs.WomanKWAR : imgs.WomanKWEN;
  }
}
