import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { zidWalletApiUrls } from './urls';
import { ZidWalletInterfaceWalletInterface } from '../types/Wallet/wallet';
import {
  ZidWalletTransactionFiltersRequestInterface,
  ZidWalletTransactionResponseInterface,
} from '../types/Transactions/transactions.interface';
import { ZidWalletVerificationRequestResponseInterface } from '../types/Verification/wallet-verification.interface';
import { ZidWalletUpdateWalletRequestInterface } from '../types/Wallet/update-wallet.interface';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';
import { transactionsApiFiltersToSearchParams } from './filters-to-search-params';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import { ZidWalletConfigurationRequestResponseInterface } from '../types/Configurations/configurations-response.interface';
import { SubscriptionTypesSubscriptionPurchasesCheckoutRequestInterface } from '../../subscription/types/subscription-checkout-purchases-checkout-request.interface';

export class ZidWalletApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getWallet(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletConfigurationRequestResponseInterface>>
  > {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletConfigurationRequestResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.get, zidWalletApiUrls.wallet);

    return response;
  }

  public async getConfigurations(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletConfigurationRequestResponseInterface>>
  > {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletConfigurationRequestResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.get, zidWalletApiUrls.configurations);

    return response;
  }

  public async updateWallet(
    info: ZidWalletUpdateWalletRequestInterface,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletInterfaceWalletInterface>>> {
    const response = this.apiClient.request<ApiBaseDataResponseObjectType<ZidWalletInterfaceWalletInterface>>(
      ApiHttpMethodEnum.put,
      zidWalletApiUrls.updateWallet,
      {
        data: info,
      },
    );

    return response;
  }

  public async listTransactions(
    filters: ZidWalletTransactionFiltersRequestInterface,
  ): Promise<ZidWalletTransactionResponseInterface> {
    const params = urlQueryFormat(transactionsApiFiltersToSearchParams(filters));
    const response = await this.apiClient.request<ZidWalletTransactionResponseInterface>(
      ApiHttpMethodEnum.post,
      zidWalletApiUrls.transactions,
      {
        params,
        data: filters,
      },
    );

    return response.data;
  }

  public async exportTransactions(startDate: string | undefined, endDate: string | undefined): Promise<Blob> {
    return this.apiClient
      .request<Blob>(ApiHttpMethodEnum.post, zidWalletApiUrls.exportTransactions, {
        responseType: 'blob',
        data: { ['start_date']: startDate, ['end_date']: endDate },
      })
      .then((response) => response.data);
  }

  public async initiateWalletVerificationRequest(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>
  > {
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.get, zidWalletApiUrls.verify);

    return response;
  }

  public async validateWalletVerificationOTPRequest(
    id: string,
    otp: string,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>> {
    const response = this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.post, zidWalletApiUrls.verifyOtp, {
      data: {
        id,
        otp,
      },
    });
    return response;
  }

  public async resendWalletVerificationOTPRequest(
    id: string,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.post, zidWalletApiUrls.resendOtp, {
      data: {
        id,
      },
    });

    return response;
  }

  public async withdrawFromWallet(
    amount: number,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletTransactionResponseInterface>>> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<ZidWalletTransactionResponseInterface> & { message: string }
    >(ApiHttpMethodEnum.post, zidWalletApiUrls.withdraw, { data: { amount } });

    return response;
  }

  public async purchaseWalletCheckout(
    data: SubscriptionTypesSubscriptionPurchasesCheckoutRequestInterface,
  ): Promise<any> {
    const response = await this.apiClient.request<any>(ApiHttpMethodEnum.post, zidWalletApiUrls.walletCheckout, {
      data,
    });
    return response.data;
  }
}
