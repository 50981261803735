import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InputTextChangeEventInterface } from './change-event.interface';
import styles from './Text.scss';

@Component
export class InputTextComponent extends Vue {
  @Prop({
    default: 'text',
  })
  private readonly type!: string;
  @Prop()
  private readonly value!: string;

  render(h: CreateElement): VNode {
    return <input type={this.type} value={this.value} onInput={this.onChange} class={styles.inputtext} />;
  }

  private onChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    const changeEvent: InputTextChangeEventInterface = {
      value,
    };

    this.$emit('change', changeEvent);
  }
}
