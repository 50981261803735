import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InputTextAreaChangeEventInterface } from './change-event.interface';
import styles from './TextArea.scss';

@Component
export class InputTextAreaComponent extends Vue {
  @Prop()
  private readonly value!: string;

  @Prop()
  private readonly placeholder!: string;

  @Prop({
    default: true,
  })
  private readonly resize!: boolean;

  render(h: CreateElement): VNode {
    return (
      <textarea
        value={this.value}
        placeholder={this.placeholder}
        onChange={this.onChange}
        onInput={this.onInput}
        class={this.classes}
      />
    );
  }

  private get classes(): Record<string, boolean> {
    return {
      [styles.inputtextarea]: true,
      [styles['inputtextarea--non-resizable']]: !this.resize,
    };
  }

  private onChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    const changeEvent: InputTextAreaChangeEventInterface = {
      value,
    };

    this.$emit('change', changeEvent);
  }

  private onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;

    const changeEvent: InputTextAreaChangeEventInterface = {
      value,
    };

    this.$emit('input', changeEvent);
  }
}
