// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bb15b879{position:fixed;top:0;left:0;right:0;bottom:0;z-index:99;background:rgba(0,0,0,.58);overflow:hidden auto}.fc3c6b4d{margin:32px 10px;display:flex;flex-direction:row;justify-content:center}.e86d7d2b{max-width:500px;width:100%;background:var(--color-white);border-radius:12px;padding:15px;box-shadow:0 3px 15px var(--zid-colors-primary-200)}", "",{"version":3,"sources":["webpack://./src/common/components/Modal/Modal.scss"],"names":[],"mappings":"AAAA,UACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,0BAAA,CACA,oBAAA,CAGF,UACE,gBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,UACE,eAAA,CACA,UAAA,CACA,6BAAA,CACA,kBAAA,CACA,YAAA,CACA,mDAAA","sourcesContent":[".modal {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  z-index: 99;\n  background: rgba(0, 0, 0, 0.58);\n  overflow: hidden auto;\n}\n\n.modal__wrapper {\n  margin: 32px 10px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.modal__content {\n  max-width: 500px;\n  width: 100%;\n  background: var(--color-white);\n  border-radius: 12px;\n  padding: 15px;\n  box-shadow: 0 3px 15px var(--zid-colors-primary-200);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "bb15b879",
	"modal__wrapper": "fc3c6b4d",
	"modal__content": "e86d7d2b"
};
export default ___CSS_LOADER_EXPORT___;
