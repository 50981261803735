import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import AppSidebar from '../../components/Sidebar/Sidebar';
import { StoreAvailabilityBarComponent } from '../../components/StoreAvailabilityControlBar/StoreAvailabilityBar';
import AppStatusBar from '../../components/StatusBar/StatusBar';
import AppNotificationsBar from '../../components/NotificationsBar/NotificationsBar';
import styles from './App.scss';
import AppNotifications from '../../../notifications/components/AppNotifications';
import AppFooter from '../../components/Footer/Footer';
import AppComponentsStoreDemoBarComponent from '../../components/StoreDemoBar/StoreDemoBar';
import { AppStoreModule } from '../../store/module';
import '@zidsa/ui/styles/global.css';
import zidIconRegWizard from '../../../common/assets/images/zid_icon_reg_wizard.svg';
import isFreemiumSubscription from '../../../routing/guards/is-freemium-subscription';
import isMobileView from '../../../common/helpers/is-mobile-view';
import AppComponentsStatusBarMobileComponent from '../../components/StatusBar/StatusBarMobile/StatusBarMobile.vue';

@Component
export default class AppComponent extends Vue {
  private get isStatusBarVisible(): boolean {
    return !isFreemiumSubscription() && !isMobileView();
  }

  render(h: CreateElement): VNode {
    return !window.__INITIAL_STATE__.isLoginOrRegister ? (
      <div id='app' class={styles.app} onClick={this.onCloseSidebarInMobile}>
        <div class={styles.app__wrapper}>
          <AppNotificationsBar />
          <AppNotifications />
          {!isFreemiumSubscription() ? (
            <AppSidebar
              class={styles.app__sidebar}
              isCollapsed={AppStoreModule.settings?.navbarCollapsed}
              onClose={this.onToggleSidebar}
              nativeOnClick={this.onNavbarClick}
            />
          ) : null}

          <div
            class={{
              [styles.app__content]: true,
              [styles['app__content--sidebarcollapsed']]: !isFreemiumSubscription()
                ? AppStoreModule.settings?.navbarCollapsed
                : true,
            }}
          >
            {/* <AppPageLoader /> */}
            <AppComponentsStoreDemoBarComponent />
            {this.isStatusBarVisible && (
              <AppStatusBar
                onToggleSidebar={this.onToggleSidebar}
                isCollapsed={AppStoreModule.settings?.navbarCollapsed}
              />
            )}

            {isMobileView() && (
              <AppComponentsStatusBarMobileComponent
                onToggleSidebar={this.onToggleSidebar}
                isCollapsed={AppStoreModule.settings?.navbarCollapsed}
              />
            )}

            {false && <StoreAvailabilityBarComponent />}
            <router-view key={this.$route.path} class={styles.app__dashboard} />
            {!isFreemiumSubscription() ? <AppFooter /> : null}
          </div>
        </div>
      </div>
    ) : (
      <div id='app' class={styles.app}>
        <div class={[styles.app__wrapper, styles['app--is-login-or-register']]}>
          {/* <AppNotificationsBar /> */}
          <AppNotifications />

          <img class={styles['app__register-wizard']} src={zidIconRegWizard} />

          <div class={[styles.app__content, styles['app__content--no-sidebar']]}>
            <router-view key={this.$route.path} />
          </div>
        </div>
      </div>
    );
  }

  private onNavbarClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  private onCloseSidebarInMobile(): void {
    if (isMobileView()) {
      AppStoreModule.closeNavbar();
    }
  }

  private onToggleSidebar(): void {
    AppStoreModule.toggleNavbar();
  }
}
