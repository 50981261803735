// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e41db3a7{padding:6px;display:flex;flex-direction:column;align-items:center;justify-content:center;border-radius:8px}.b07b7715{width:40px;height:40px;border-radius:50%;background-repeat:no-repeat;background-size:cover;margin-bottom:4px}.d55e61c2{color:var(--zid-colors-neutrals-900);font-size:var(--zid-font-size--xs);font-weight:400;text-align:center}", "",{"version":3,"sources":["webpack://./src/app/components/StatusBar/PinnedApps/PinnedAppComponent/PinnedAppComponent.scss"],"names":[],"mappings":"AAAA,UACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAEA,UACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,2BAAA,CACA,qBAAA,CACA,iBAAA,CAGF,UACE,oCAAA,CACA,kCAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".pinned_app {\n  padding: 6px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  border-radius: 8px;\n\n  &_icon {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    margin-bottom: 4px;\n  }\n\n  &_name {\n    color: var(--zid-colors-neutrals-900);\n    font-size: var(--zid-font-size--xs);\n    font-weight: 400;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pinned_app": "e41db3a7",
	"pinned_app_icon": "b07b7715",
	"pinned_app_name": "d55e61c2"
};
export default ___CSS_LOADER_EXPORT___;
