import { ZidSelect, ZidSelectBody, ZidSelectHeader, ZidSelectOption } from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import styles from './CountryKeySelect.module.scss';
import { InputSelectOptionInterface } from '../../../common/components/Input';
import COUNTRY_CODES from '../../api/country_codes';

@Component
export class VerifyWalletComponentsCountryKeySelectComponent extends Vue {
  @Prop({
    default: '+966',
  })
  private readonly userMobile!: string;

  private get countryKeys(): Array<string> {
    return Array.from(new Set(COUNTRY_CODES.sort((a: any, b: any) => a.key - b.key).map((country) => country.key)));
  }

  private get countryKeysSelectOptions(): Array<Record<string, string>> {
    return this.countryKeys.map((countryCode) => ({
      value: countryCode,
      flagFile: COUNTRY_CODES.find((country) => country.key === countryCode)?.flagFile || '',
    }));
  }

  private get initialCountryKey(): string {
    let value = '';

    if (this.userMobile.startsWith('+20')) {
      value = this.userMobile.substring(0, 3);
    } else {
      value = this.userMobile.substring(0, 4);
    }
    return this.userMobile ? value : this.countryKeys[2];
  }

  private countryKey = this.initialCountryKey;

  created(): void {
    this.$emit('countryKeyChange', this.countryKey);
  }

  render(h: CreateElement): VNode {
    return (
      <ZidSelect
        data-identifier={'country_code_dropdown'}
        onChange={(event: InputSelectOptionInterface): void => this.onCountryKeyChange(event.value)}
        value={{ value: this.countryKey }}
        class={{
          [styles['verify-phone-select']]: true,
        }}
        outlined
      >
        <ZidSelectHeader dir={'ltr'} data-identifier={'dropdown_header'}>
          {this.countryKey || this.countryKeys[2]}
        </ZidSelectHeader>
        <ZidSelectBody data-identifier={'dropdown_body'} class={styles.verify_country_code_select_body}>
          {this.countryKeysSelectOptions.map((code) => (
            <ZidSelectOption dir={'ltr'} data-identifier={`option_${code}`} class={styles.select_options} value={code}>
              <div>
                <img
                  src={require(`../../assets/flags/${code.flagFile}`)}
                  alt={`flag of ${code}`}
                  class={styles.select_flags}
                />
                {code.value}
              </div>
            </ZidSelectOption>
          ))}
        </ZidSelectBody>
      </ZidSelect>
    );
  }

  private onCountryKeyChange(value: string): void {
    this.$emit('countryKeyChange', value);
    this.countryKey = value;
  }
}
