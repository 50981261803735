import { OrdersApiBulkActionOptionsInterface } from './options.interface';
import { ApiSearchParamsType } from '../../../api/types';
import { OrdersApiBulkActionSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../common/helpers/object/filter';

export function ordersApiBulkActionOptionsToSearchParams(
  options: OrdersApiBulkActionOptionsInterface,
): ApiSearchParamsType {
  const params: OrdersApiBulkActionSearchParamsInterface = {
    orders: options.orderIds.map((orderId) => orderId.toString()),
    action: options.action,
    'params[status]': options.params.status,
    'params[inventory_address_id]': options.params.inventory_address_id?.toString(),
    'params[payment_status]': options.params.payment_status?.toString(),
  };

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
