import { ZidpayStoreModule } from '../../../store/module';
import { CountryCode, CountryCodeSource } from '../../shared/CountryValidation/CountryValidation.enum';
import { ZidpayStoreSettingsModule } from '../../../store/settings-module';
import { ZidpayCountriesHelpersBase } from '../../shared/helpers/helpers';
import { ZidpayCountriesHelpersSA } from '../../SA/helpers/helpers';
import { ZidpayCountriesHelpersKW } from '../../KW/helpers/helpers';

export class ZidpayCountriesHelpers {
  static getHelpers(source: CountryCodeSource = CountryCodeSource.zidpayProfile): typeof ZidpayCountriesHelpersBase {
    let countryCode: CountryCode | undefined;
    if (source === CountryCodeSource.zidpayProfile) {
      countryCode = ZidpayStoreModule.merchantProfile.headquarter?.countryCode;
    } else if (source === CountryCodeSource.zidpayDetails) {
      countryCode = ZidpayStoreSettingsModule.data?.headquarter?.countryCode;
    }
    if (!countryCode) return ZidpayCountriesHelpersSA;

    const countryCodeData: Partial<Record<CountryCode, typeof ZidpayCountriesHelpersBase>> = {
      [CountryCode.SA]: ZidpayCountriesHelpersSA,
      [CountryCode.KW]: ZidpayCountriesHelpersKW,
    };
    if (!countryCodeData[countryCode]) return ZidpayCountriesHelpersSA;

    const helpers = countryCodeData[countryCode] as typeof ZidpayCountriesHelpersBase;

    return helpers;
  }
}
