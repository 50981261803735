export const enum ZidIconTypeEnum {
  tick = 'tick',
  truck = 'truck',
  upDown = 'upDown',
  upload = 'upload',
  user = 'user',
  users = 'users',
  viral = 'viral',
  writing = 'writing',
  settings = 'settings',
  print = 'print',
  plus = 'plus',
  plant = 'plant',
  pieChart = 'pieChart',
  pen = 'pen',
  options = 'options',
  logo = 'logo',
  laptop = 'laptop',
  home = 'home',
  find = 'find',
  eye = 'eye',
  exit = 'exit',
  apps = 'apps',
  arrow = 'arrow',
  arrowRight = 'arrow_right',
  arrowLeft = 'arrow_left',
  arrowLine = 'arrowLine',
  basket = 'basket',
  bell = 'bell',
  calendar = 'calendar',
  chart = 'chart',
  clock = 'clock',
  close = 'close',
  cogwheel = 'cogwheel',
  coins = 'coins',
  dartBoard = 'dartBoard',
  eCommerce = 'eCommerce',
  circle = 'circle',
  transfer = 'transfer',
  delivery = 'delivery',
  account = 'account',
  marker = 'marker',
}
