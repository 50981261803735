import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

//Styles
import styles from './CreditSection.module.scss';
import { SubscriptionComponentsEmbeddedCreditCardComponent } from '../../../../subscription/components/Shared/EmbeddedCreditCard/EmbeddedCreditCard';

@Component
export class ZidWalletComponentsAddFundsBankTransferSectionComponent extends Vue {
  @Prop()
  private readonly submitCreditCard!: boolean;

  render(h: CreateElement): VNode {
    return (
      <div>
        <SubscriptionComponentsEmbeddedCreditCardComponent
          class={styles.embeddedcreditcard__cardcontainer}
          onTokenCreated={this.onTokenCreated}
          onDisableSubmit={this.disableSubmit}
          purchaseSource='wallet'
          ref='saveForm'
        />
      </div>
    );
  }

  @Watch('submitCreditCard')
  onSumbitChange(): void {
    if (this.submitCreditCard) {
      const saveForm = this.$refs.saveForm as SubscriptionComponentsEmbeddedCreditCardComponent;
      saveForm.createToken();
    }
  }

  private disableSubmit(event: boolean): void {
    this.$emit('disableSubmitEmbeddedCreditCard', event);
  }

  private onTokenCreated(token: any): void {
    this.$emit('submitWithEmbeddedCreditCard', token);
  }
}
