import Vue, { CreateElement, VNode } from 'vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import styles from './DropdownBaseHeader.scss';
import { DropdownBaseStateInterface } from '../state.interface';
import { dropdownBaseStateSymbol } from '../state-symbol';

@Component
export class DropdownBaseHeaderComponent extends Vue {
  @Inject({
    default: {
      isExpanded: false,
    } as DropdownBaseStateInterface,
    from: dropdownBaseStateSymbol,
  })
  private state!: DropdownBaseStateInterface;

  @Prop()
  private readonly expandedClass!: string;

  render(h: CreateElement): VNode {
    return (
      <div class={{ [styles.dropdownbaseheader]: true, [this.expandedClass]: this.state.isExpanded }}>
        {this.$slots.default}
      </div>
    );
  }
}
