// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e43253b4{width:77% !important;display:inline-block !important}", "",{"version":3,"sources":["webpack://./src/common/components/Input/Tags/Tags.scss"],"names":[],"mappings":"AAAA,UACE,oBAAA,CACA,+BAAA","sourcesContent":[".tags {\n  width: 77% !important;\n  display: inline-block !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tags": "e43253b4"
};
export default ___CSS_LOADER_EXPORT___;
