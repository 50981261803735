import { Route } from 'vue-router';
import isStaging from '../../common/helpers/is-staging';
import isTesting from '../../common/helpers/is-testing';
import UserStoreModule from '../../user/store/module';
import { RoutingRouteEnum } from '../route.enum';

export function pilotsGuard(to: Route, from: Route, next: Function): void {
  const user = UserStoreModule.data;

  const userIsPilot =
    isTesting() || isStaging() || !to.meta?.pilots || (user && to.meta?.pilots.includes(user.store.id));
  if (userIsPilot) {
    next();
  } else {
    next({
      name: RoutingRouteEnum.home,
    });
  }
}
