import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidpayApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { UpdateMerchant } from '../types/UpdateMerchant';

const zidpayService = ZidpayApiServiceFactory();

@Module({
  dynamic: true,
  name: 'zidpay-sms-name',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidpayUpdateMerchantModule extends VuexModule {
  public loading: AppStoreReadyStateEnum = AppStoreReadyStateEnum.loaded;
  public data: UpdateMerchant = {};
  public isEditSmsName = false;
  public error: Error | null = null;

  @Mutation
  private UPDATE_SUCCESS(): void {
    this.loading = AppStoreReadyStateEnum.loaded;
    this.isEditSmsName = false;
  }

  @Mutation
  private UPDATE_ERROR(error: Error): void {
    this.loading = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private UPDATE(): void {
    this.loading = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private SET_EDIT_SMS_NAME(status: boolean): void {
    this.isEditSmsName = status;
  }

  @Mutation
  private SET_SMS_NAME(name: string): void {
    this.data = { ...this.data, smsDisplayName: name };
  }

  @Action
  public setEditSmsName(status: boolean): void {
    this.SET_EDIT_SMS_NAME(status);
  }

  @Action
  public setSmsName(name: string): void {
    this.SET_SMS_NAME(name);
    if (!name) {
      this.SET_EDIT_SMS_NAME(true);
    }
  }

  @Action
  public async updateMerchant(): Promise<void> {
    this.UPDATE();
    try {
      await zidpayService.updateMerchant(this.data);
      this.UPDATE_SUCCESS();
    } catch (error) {
      this.UPDATE_ERROR(error as Error);
    }
  }
}

export const ZidpayStoreUpdateMerchantModule = getModule(ZidpayUpdateMerchantModule);
