// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".af17827b .zid-select .zid-select-body{--zid-select-body-min-width: 100px}.af17827b input{width:100%}.e8bd8489{max-height:200px;width:90px !important;overflow-y:scroll !important;z-index:999}.ff726dee div{display:flex;justify-content:space-between}.c616267a{width:var(--zid-spacing-20);height:auto}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/CountryKeySelect/CountryKeySelect.module.scss"],"names":[],"mappings":"AAIM,uCACE,kCAAA,CAKN,gBACE,UAAA,CAIJ,UACE,gBAAA,CACA,qBAAA,CACA,4BAAA,CACA,WAAA,CAIA,cACE,YAAA,CACA,6BAAA,CAIJ,UACE,2BAAA,CACA,WAAA","sourcesContent":["\n.verify-phone-select {\n  :global {\n    .zid-select {\n      .zid-select-body {\n        --zid-select-body-min-width: 100px;\n      }\n    }\n  }\n\n  input {\n    width: 100%;\n  }\n}\n\n.verify_country_code_select_body {\n  max-height: 200px;\n  width: 90px !important;\n  overflow-y: scroll !important;\n  z-index: 999;\n}\n\n.select_options {\n  div {\n    display: flex;\n    justify-content: space-between;\n  }\n}\n\n.select_flags {\n  width: var(--zid-spacing-20);\n  height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verify-phone-select": "af17827b",
	"verify_country_code_select_body": "e8bd8489",
	"select_options": "ff726dee",
	"select_flags": "c616267a"
};
export default ___CSS_LOADER_EXPORT___;
