// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes f4fc4dfe{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.be9064c4{border-radius:50%;border:2px solid rgba(0,0,0,0);border-top-color:currentColor;transform:translate3d(0, 0, 0);animation:f4fc4dfe 1.25s infinite linear}.aeeee07f{width:14px;height:14px}.e3c6cc97{width:24px;height:24px;border-width:3px}.a346eded{width:48px;height:48px;border-width:4px}", "",{"version":3,"sources":["webpack://./src/common/components/Loader/Loader.css"],"names":[],"mappings":"AAAA,oBACE,GACE,sBAAA,CAEF,KACE,wBAAA,CAAA,CAIJ,UACE,iBAAA,CACA,8BAAA,CACA,6BAAA,CACA,8BAAA,CACA,wCAAA,CAGF,UACE,UAAA,CACA,WAAA,CAGF,UACE,UAAA,CACA,WAAA,CACA,gBAAA,CAGF,UACE,UAAA,CACA,WAAA,CACA,gBAAA","sourcesContent":["@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loader {\n  border-radius: 50%;\n  border: 2px solid transparent;\n  border-top-color: currentColor;\n  transform: translate3d(0, 0, 0);\n  animation: spin 1.25s infinite linear;\n}\n\n.loader--size-small {\n  width: 14px;\n  height: 14px;\n}\n\n.loader--size-medium {\n  width: 24px;\n  height: 24px;\n  border-width: 3px;\n}\n\n.loader--size-large {\n  width: 48px;\n  height: 48px;\n  border-width: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "be9064c4",
	"spin": "f4fc4dfe",
	"loader--size-small": "aeeee07f",
	"loader--size-medium": "e3c6cc97",
	"loader--size-large": "a346eded"
};
export default ___CSS_LOADER_EXPORT___;
