export const enum ButtonTypeEnum {
  link = 'link',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
  secondary = 'secondary',
  primary = 'primary',
  light = 'light',
  neutral = 'neutral',
}
