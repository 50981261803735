export enum PackagesEnum {
  starter = 'starter',
  basic = 'business',
  KwBasic = 'KwBusiness',
  growth = 'growth',
  KwGrowth = 'KwGrowth',
  professional = 'professional',
  KwProfessional = 'KwProfessional',
  enterprise = 'enterprise',
  KwEnterprise = 'KwEnterprise',
  special = 'special',
  ngo = 'ngo',
}
