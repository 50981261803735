import { ApiModelDeliveryOptionInterface } from './../../../api/model/delivery-option.interface';
import { ApiModelOrderDraftShippingMethodsInterface } from './../../../api/model/order/draft/shipping-methods.interface';
import { ApiModelOrderDraftPickupMethodInterface } from './../../../api/model/order/draft/pickup-method.interface';
import { ApiModelOrderDraftCustomerAddressInterface } from './../../../api/model/order/draft/customer-address.interface';
import { ApiModelCustomerPrimaryInterface } from './../../../api/model/customer-primary.interface';
import { ApiModelOrderDraftSessionIdInterface } from '../../../api/model/order/draft/session-id.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../../app/store/factory';
import { OrdersApiServiceFactory } from '../../api/service-factory';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import { ApiModelOrderDraftSessionInterface } from '../../../api/model/order/draft/session.interface';
import { ApiModelOrderPaymentMethodInterface } from '../../../api/model/order/payment-method.interface';
import { ApiModelOrderProductInterface } from '../../../api/model/order/product.interface';
import { ApiModelOrderDraftConsigneeAddressInterface } from '../../../api/model/order/draft/consignee-address.interface';
import { PaymentMethodFormData } from '../../components/OrderCreation/PaymentMethodStep/PaymentMethodFormData.interface';
import { DeliveryOptions } from '../../components/OrderCreation/DeliveryOptionStep/DeliveryOptions.enum';
import UserStoreModule from '../../../user/store/module';
import { PackagesEnum } from '../../../zidpay/types/Packages.enum';
import { ApiModelOrderPaymentMethodCodeEnum } from '../../../api/model/order/payment-method.enum';
import Catch from '../../../common/decorators/catch-error';

const ordersService = OrdersApiServiceFactory();

@Module({
  dynamic: true,
  name: 'ordersDraft',
  store: appStoreFactory(),
  namespaced: true,
})
class OrdersDraftModule extends VuexModule {
  public sessionId: ApiModelOrderDraftSessionIdInterface | null = null;
  public session: ApiModelOrderDraftSessionInterface | null = null;
  public customer: ApiModelCustomerPrimaryInterface | null = null;
  public customerAddresses: Array<ApiModelOrderDraftCustomerAddressInterface> | null = null;
  public deliveryOptions: Array<ApiModelDeliveryOptionInterface> | null = null;
  public pickupMethods: Array<ApiModelOrderDraftPickupMethodInterface> | null = null;
  public paymentMethods: Array<ApiModelOrderPaymentMethodInterface> | null = null;
  public confirmedOrderId: string | null = null;
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public error: Error | null = null;
  public dateFieldError: string | null = null;
  public emailFieldError: string | null = null;

  @Mutation
  private REQUEST(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_SESSION_ID_SUCCESS(draft: ApiModelOrderDraftSessionIdInterface): void {
    this.sessionId = draft;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.error = error;
    this.loadingState = AppStoreReadyStateEnum.error;
  }

  @Mutation
  private SET_DATE_FIELD_ERROR(message: string | null): void {
    this.dateFieldError = message;
  }

  @Mutation
  private SET_EMAIL_FIELD_ERROR(message: string | null): void {
    this.emailFieldError = message;
  }

  @Mutation
  private FETCH_SESSION_SUCCESS(session: ApiModelOrderDraftSessionInterface): void {
    this.session = session;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private SET_LOADING(value: AppStoreReadyStateEnum): void {
    this.loadingState = value;
  }

  @Mutation
  private SET_CUSTOMER_SUCCESS(customer: ApiModelCustomerPrimaryInterface): void {
    this.customer = customer;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_CUSTOMER_ADDRESSES_SUCCESS(customerAddresses: Array<ApiModelOrderDraftCustomerAddressInterface>): void {
    this.customerAddresses = customerAddresses.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_DELIVERY_OPTIONS_SUCCESS(deliveryOptions: ApiModelOrderDraftShippingMethodsInterface): void {
    this.deliveryOptions = deliveryOptions.delivery_methods;
    this.pickupMethods = deliveryOptions.pickup_methods;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_PAYMENT_METHODS_SUCCESS(paymentMethods: Array<ApiModelOrderPaymentMethodInterface>): void {
    // TODO: change it to handled from back-end
    // disable payment link in starter and basic package
    if (
      (UserStoreModule.data?.store.subscription.package_code.split('.')[0] as PackagesEnum) === PackagesEnum.starter
    ) {
      paymentMethods = paymentMethods.filter((p) => p.code !== ApiModelOrderPaymentMethodCodeEnum.paymentLink);
    }
    this.paymentMethods = paymentMethods;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private CONFIRM_ORDER_SUCCESS(orderId: any): void {
    this.confirmedOrderId = orderId;
  }

  @Mutation
  private UPDATE_SESSION_PRODUCTS(products: Array<ApiModelOrderProductInterface>): void {
    if (!this.session) return;
    this.session = Object.assign(this.session, {
      ...this.session,
      products: products,
    });
  }

  @Action
  public async fetchSessionId(): Promise<void> {
    this.REQUEST();

    try {
      const response = await ordersService.getDraftSessionId();

      this.FETCH_SESSION_ID_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetchSession(sessionId: string): Promise<void> {
    this.REQUEST();

    try {
      const response = await ordersService.getDraftSession(sessionId);

      this.FETCH_SESSION_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async setDraftCurrency({ sessionId, currency }: { sessionId: string; currency: string }): Promise<void> {
    this.REQUEST();
    try {
      await ordersService.setDraftCurrency(sessionId, currency);
      this.SET_LOADING(AppStoreReadyStateEnum.loaded);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async setDraftCustomer({ sessionId, customer }: { sessionId: string; customer: string }): Promise<void> {
    this.REQUEST();
    try {
      const response = await ordersService.setDraftCustomer(sessionId, customer);
      this.SET_CUSTOMER_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetchCustomerAddresses(sessionId: string): Promise<void> {
    this.REQUEST();

    try {
      const response = await ordersService.getDraftCustomerAddresses(sessionId);

      this.FETCH_CUSTOMER_ADDRESSES_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetchDeliveryOptions(sessionId: string): Promise<void> {
    this.REQUEST();

    try {
      const response = await ordersService.getDraftDeliveryOptions(sessionId);

      this.FETCH_DELIVERY_OPTIONS_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetchPaymentMethods(sessionId: string): Promise<void> {
    this.REQUEST();

    try {
      const response = await ordersService.getDraftPaymentMethods(sessionId);

      this.FETCH_PAYMENT_METHODS_SUCCESS(response.data);
      this.SET_LOADING(AppStoreReadyStateEnum.loaded);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action({ rawError: true })
  @Catch()
  public async setDraftCustomerAddress({
    sessionId,
    address,
    giftAddress,
  }: {
    sessionId: string;
    address: string;
    giftAddress: ApiModelOrderDraftConsigneeAddressInterface;
  }): Promise<void> {
    this.REQUEST();
    const response = await ordersService.setDraftCustomerAddress(sessionId, address, giftAddress);
    this.FETCH_SESSION_SUCCESS(response.data);
  }

  @Action
  public async setDraftDeliveryOption({
    sessionId,
    type,
    id,
  }: {
    sessionId: string;
    type: DeliveryOptions;
    id: number;
  }): Promise<void> {
    this.REQUEST();
    try {
      const response = await ordersService.setDraftDeliveryOption(sessionId, type, id);
      this.FETCH_SESSION_SUCCESS(response.data);
    } catch (error: any) {
      if (error.response) {
        this.FETCH_ERROR(error.response.data);
      } else {
        this.FETCH_ERROR(error as Error);
      }
    }
  }
  @Action
  public changeDateFieldError(value: string | null): void {
    this.SET_DATE_FIELD_ERROR(value);
  }
  @Action
  public changeEmailFieldError(value: string | null): void {
    this.SET_EMAIL_FIELD_ERROR(value);
  }
  @Action
  public async setDraftPaymentMethod({
    sessionId,
    data,
  }: {
    sessionId: string;
    data: PaymentMethodFormData;
  }): Promise<void> {
    this.REQUEST();
    try {
      const response = await ordersService.setDraftPaymentMethod(sessionId, data);
      this.FETCH_SESSION_SUCCESS(response.data);
      this.SET_DATE_FIELD_ERROR('');
      this.SET_EMAIL_FIELD_ERROR('');
    } catch (error: any) {
      this.FETCH_ERROR(error.response.data);
      if (error.response?.data.errors?.expiryDateTime?.length) {
        this.SET_DATE_FIELD_ERROR(error.response.data.errors.expiryDateTime[0]);
      }
      if (error.response?.data.errors?.customerEmail?.length) {
        this.SET_EMAIL_FIELD_ERROR(error.response.data.errors.customerEmail[0]);
      }
    }
  }

  @Action
  public async verifyDraftSession(sessionId: string): Promise<any> {
    this.REQUEST();
    try {
      const response = await ordersService.verifyDraftSession(sessionId);
      this.FETCH_SESSION_SUCCESS(response.data);
      return response;
    } catch (error) {
      this.FETCH_ERROR(error as Error);
      return (error as any).response;
    }
  }

  @Action
  public async confirmDraft({
    sessionId,
    data,
  }: {
    sessionId: string;
    data: { comment: string; payment_request_token: string };
  }): Promise<void> {
    this.REQUEST();
    try {
      const response = await ordersService.confirmDraft(sessionId, data);
      this.CONFIRM_ORDER_SUCCESS(response.data);
    } catch (error: any) {
      if (error.response) {
        this.FETCH_ERROR(error.response.data);
      } else {
        this.FETCH_ERROR(error as Error);
      }
    }
  }

  @Action
  public async setSession(session: ApiModelOrderDraftSessionInterface): Promise<void> {
    this.FETCH_SESSION_SUCCESS(session);
  }

  @Action
  public async setSessionProducts(products: Array<ApiModelOrderProductInterface>): Promise<void> {
    this.UPDATE_SESSION_PRODUCTS(products);
  }
}

export const OrdersStoreDraftModule = getModule(OrdersDraftModule);
