import { UserPermissionSlugEnum } from './../../api/model/user/permission-slug.enum';
import { I18nMessages } from './../../i18n/messages';
import { NotificationsStoreModule } from './../../notifications/store/module';
import VueRouter, { Route } from 'vue-router';
import NotificationTypeEnum from '../../notifications/components/type.enum';
import { userStoreHasPermission } from '../../user/store/has-permission';

export function userPermissionGuard(to: Route, next: Function, router: VueRouter) {
  const routeNameAsSlugKey = to.name as keyof typeof UserPermissionSlugEnum;
  const isLoginOrRegister = window.__INITIAL_STATE__.isLoginOrRegister;
  const requiredPermissionSlug =
    to.name == 'zidWallet'
      ? 'wallet.topup,wallet.view.history,wallet.view.balance,wallet.withdraw'
      : UserPermissionSlugEnum[routeNameAsSlugKey];
  const strictPermissionRoutes = ['zidWallet'];
  if (
    (routeNameAsSlugKey && userStoreHasPermission(requiredPermissionSlug, strictPermissionRoutes.includes(to.name!))) ||
    isLoginOrRegister
  ) {
    next();
  } else {
    NotificationsStoreModule.notify({
      id: '',
      text: router.app.$t(I18nMessages['common.permission_required']).toString(),
      type: NotificationTypeEnum.danger,
      showBell: false,
      autoDismiss: true,
    });
    next(false);
  }
}
