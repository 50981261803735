import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { ButtonComponent, ButtonSizeEnum, ButtonTypeEnum } from '../../../common/components/Button';
import { FontAwesomeIconComponent, FontAwesomeIconTypeEnum } from '../../../common/components/FontAwesomeIcon';
import { ActivitySelectionComponent } from '../../../home/components/ActivitySelection/ActivitySelection';
import { I18nMessages } from '../../../i18n/messages';
import ApiModelUserInterface from '../../../api/model/user.interface';
import UserStoreModule from '../../../user/store/module';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import styles from './NotificationsBar.scss';

@Component
export default class NotificationsBarComponent extends Vue {
  private inputDate = new Date('7/14/2021');
  private todaysDate = new Date();
  private bool = this.todaysDate <= this.inputDate;

  get user(): ApiModelUserInterface | null {
    return UserStoreModule.data;
  }

  get showStoreSelectedCategoryNotification(): boolean {
    if (UserStoreModule.loadingState !== AppStoreReadyStateEnum.loaded || !this.user) {
      return false;
    }
    return this.user.store.show_store_selected_category_notification;
  }

  private isActivitySelectionComponentModalOpen = false;

  render(h: CreateElement): VNode {
    return (
      <div>
        {this.user && this.bool && this.showStoreSelectedCategoryNotification ? (
          <div class={styles.notificationsbar} id='merchantProfile'>
            <ButtonComponent
              type={ButtonTypeEnum.primary}
              size={ButtonSizeEnum.small}
              class={styles.notificationsbar__helpbtn}
              nativeOnClick={this.showActivitySelectionComponentModal}
            >
              {this.$t(I18nMessages['home.category_select_notification'])}
              <FontAwesomeIconComponent
                type={FontAwesomeIconTypeEnum.solid}
                class={styles.notificationsbar__helpbtn__icon}
                icon={faArrowLeft}
              />
            </ButtonComponent>
            <ActivitySelectionComponent
              isOpen={this.isActivitySelectionComponentModalOpen}
              onClose={(): void => {
                this.isActivitySelectionComponentModalOpen = false;
              }}
            />
          </div>
        ) : (
          <div>
            {this.userCanSeeCategorySelectionModal() && this.showStoreSelectedCategoryNotification ? (
              <ActivitySelectionComponent
                isOpen={this.isActivitySelectionComponentModalOpen}
                onClose={(): void => {
                  this.isActivitySelectionComponentModalOpen = false;
                  sessionStorage.setItem('zid_can_user_see_category_selection_modal', 'false');
                }}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  }

  private showActivitySelectionComponentModal(): void {
    this.isActivitySelectionComponentModalOpen = true;
  }

  private userCanSeeCategorySelectionModal() {
    return sessionStorage.getItem('zid_can_user_see_category_selection_modal') === null;
  }
}
