import { ZidToaster, ZidToasterWrapper } from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AppNotificationInterface from '../store/notification.interface';
@Component
export default class Notification extends Vue {
  @Prop({
    required: true,
  })
  readonly notification!: AppNotificationInterface;

  @Prop({
    default: true,
  })
  readonly showDissmissButton!: boolean;

  render(h: CreateElement): VNode {
    return (
      <ZidToasterWrapper>
        <ZidToaster
          type={this.notification.type}
          message={this.notification.text}
          showBell={this.notification.showBell}
          showDismissButton={this.showDissmissButton}
          title={this.notification.title}
        />
      </ZidToasterWrapper>
    );
  }
}
