import { ApiBaseDataResponseObjectType } from '../../api/types';
import { BankAccount } from './BankAccount.interface';
import { Bank } from './Banks.interface';
import { MerchantDetailsAttachment } from './MerchantDetailsAttachment';
import {
  EnteredFileds,
  MerchantProfileBasic,
  MerchantProfileHeadquarter,
  MerchantProfilePackage,
} from './MerchantProfile.interface';
import { PaymentLink } from './PaymentLink.interface';

export enum MerchantStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Suspended = 'Suspended',
  Dormant = 'Dormant',
  Inactive = 'Inactive',
}

export enum MerchantDetailsAccountType {
  individual = 1,
  corporate,
  charity,
  company,
}

export enum MerchantProvider {
  MyFatoorah = 1,
  Tap = 2,
  Moyasar = 3,
}

export enum KycStatus {
  PrePending = 0, // my fatoorah nafaz
  FirstPending = 1,
  Verified = 2,
  Rejected = 3,
  SecondPending = 4,
}

export interface NafazData {
  nafathAttemptCount?: number;
  lastNafathAttempt?: string;
  nafathCode?: string | null;
}

export interface MerchantDetails extends NafazData {
  basic?: MerchantProfileBasic;
  headquarter?: MerchantProfileHeadquarter;
  commercial?: any;
  package?: MerchantProfilePackage;
  account?: BankAccount;
  attachments?: Array<MerchantDetailsAttachment>;
  payment?: Record<EnteredFileds, number>;
  merchantId?: number;
  paymentLink?: PaymentLink;
  merchantStatus?: MerchantStatus;
  kycStatus?: KycStatus;
  kycRejectionReason?: string;
  kycCustomRejectionReason?: string;
  hasDeposit?: boolean;
  smsName?: string;
  referenceNumber?: string;
  rejectedRefundsNotificationDismissDate?: string;
  lastRejectedRefundDate?: string;
  banks?: Array<Bank>;
  hasRunningFinancing?: boolean;
  paymentProviderId?: MerchantProvider;
  hasRunningSplitting?: boolean;
  zidpayWebsocketUrl?: string;
  hasActiveSplitting?: boolean;
  hasPaymentLinks?: boolean;
}

export type MerchantDetailsApiResponseInterface = ApiBaseDataResponseObjectType<MerchantDetails>;
export type NafazDataApiResponseInterface = ApiBaseDataResponseObjectType<NafazData>;
