// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fe8c3605{color:var(--zid-colors-red-600);margin-top:16px}.a9cf2f05 div#e49c8af3{display:none !important}", "",{"version":3,"sources":["webpack://./src/subscription/components/Shared/EmbeddedCreditCard/EmbeddedCreditCard.scss"],"names":[],"mappings":"AAAA,UACI,+BAAA,CACA,eAAA,CAGJ,uBACI,uBAAA","sourcesContent":[".embeddedcreditcard__errorhanlder {\n    color: var(--zid-colors-red-600);\n    margin-top: 16px;\n}\n\n.brands-container div#icon-AMERICAN_EXPRESS {\n    display: none !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"embeddedcreditcard__errorhanlder": "fe8c3605",
	"brands-container": "a9cf2f05",
	"icon-AMERICAN_EXPRESS": "e49c8af3"
};
export default ___CSS_LOADER_EXPORT___;
