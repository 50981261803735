import ApiClient from './client';

const apiClient = new ApiClient();

/**
 * Get ApiClient instance
 */
export default function ApiClientFactory(): ApiClient {
  return apiClient;
}
