import { render, staticRenderFns } from "./SubscriptionPackage.vue?vue&type=template&id=3585669d"
import script from "./SubscriptionPackage.vue?vue&type=script&lang=ts"
export * from "./SubscriptionPackage.vue?vue&type=script&lang=ts"
import style0 from "./SubscriptionPackage.vue?vue&type=style&index=0&id=3585669d&prod&module=styles&lang=scss"




function injectStyles (context) {
  
  this["styles"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports