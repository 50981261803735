import Catch from '../../../common/decorators/catch-error';
import { OrdersApiFiltersInterface } from '../../../orders/api/filters.interface';
import { OrdersApiResponseInterface } from '../../../orders/api/response.interface';
import { OrdersApiServiceFactory } from '../../../orders/api/service-factory';
import { ProductsApiFiltersInterface } from '../../../products/api/filters.interface';
import { ProductsApiResponseInterface } from '../../../products/api/response.interface';
import { ProductsApiServiceFactory } from '../../../products/api/service-factory';
import {
  GlobalSearchCategoriesInterface,
  GlobalSearchFeaturesResponseInterface,
  GlobalSearchResultsInterface,
} from '../../types/global-search.interface';
import appStoreFactory from '../factory';
import { AppApiServiceFactory } from '../../api/service-factory';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import AppStoreReadyStateEnum from '../ready-state.enum';
import { CustomersApiServiceFactory } from '../../../customers/api/service-factory';
import { CustomersApiFiltersInterface } from '../../../customers/api/filters.interface';
import { CustomersPaginatedApiResponseInterface } from '../../../customers/types/customers-paginated.interface';
import { AppMarketApiServiceFactory } from '../../../app-market/api/service-factory';
import { AppMarketAppParamsInterface } from '../../../app-market/types/app-params.interface';
import { AppMarketTypesAppsListInterface } from '../../../app-market/types/app.interface';
import { GlobalSearchApiFiltersInterface } from '../../api/filters.interface';
import { AxiosError } from 'axios';
import showNotification from '../../../common/helpers/show-notification/show-notification';
import NotificationTypeEnum from '../../../notifications/components/type.enum';

const globalSearchServices = AppApiServiceFactory();
const ordersService = OrdersApiServiceFactory();
const productsService = ProductsApiServiceFactory();
const customersService = CustomersApiServiceFactory();
const appMarketServices = AppMarketApiServiceFactory();

const customErrorhandler = (error: AxiosError) => {
  if (error && error.code === 'ERR_CANCELED') return;
  showNotification(error.message, NotificationTypeEnum.danger);
};

@Module({
  dynamic: true,
  name: 'global-search',
  store: appStoreFactory(),
  namespaced: true,
})
class GlobalSearch extends VuexModule {
  public searchQuery = '';
  public searchCategory = '';
  public loadingState = AppStoreReadyStateEnum.pending;
  public selectedSearchCategories: GlobalSearchCategoriesInterface | null = null;
  public searchHistory: string[] = [];
  public controller: AbortController | null = null;
  public isSearchOpen = false;
  public isCategorySearchCompleted = false;
  public isGlobalSearchCompleted = false;
  public isInputFocused = false;
  private mimSearchLimit = 3;
  public searchResult: GlobalSearchResultsInterface = {
    products: [],
    orders: [],
    features: [],
    customers: [],
    appMarket: [],
  };

  @Mutation
  public FETCH_GLOBAL_SEARCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  public FETCH_GLOBAL_SEARCH_SUCCESS(): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  public FETCH_GLOBAL_SEARCH_ERROR(status: AppStoreReadyStateEnum): void {
    this.loadingState = status;
  }

  @Mutation
  public ADD_SELECTED_SEARCH_CATEGORIES(selected: GlobalSearchCategoriesInterface): void {
    this.selectedSearchCategories = selected;
  }

  @Mutation
  public RESET_SELECTED_SEARCH_CATEGORIES(): void {
    this.selectedSearchCategories = null;
  }

  @Mutation
  public SET_SEARCH_QUERY(value: string): void {
    this.searchQuery = value;
  }

  @Mutation
  public SET_ORDER_SEARCH_RESULT(result: OrdersApiResponseInterface): void {
    if (this.searchQuery.length < this.mimSearchLimit) return;
    this.searchResult.orders = result.orders.data;
  }

  @Mutation
  public SET_PRODUCTS_SEARCH_RESULT(result: ProductsApiResponseInterface): void {
    if (this.searchQuery.length < this.mimSearchLimit) return;
    this.searchResult.products = result.products.data;
  }

  @Mutation
  public SET_CUSTOMERS_SEARCH_RESULT(result: CustomersPaginatedApiResponseInterface): void {
    if (this.searchQuery.length < this.mimSearchLimit) return;
    this.searchResult.customers = result.customers.data;
  }

  @Mutation
  public SET_ZAM_SEARCH_RESULT(result: AppMarketTypesAppsListInterface): void {
    if (this.searchQuery.length < this.mimSearchLimit) return;
    this.searchResult.appMarket = result.applications;
  }

  @Mutation
  public SET_FEATURES_SEARCH_RESULT(result: GlobalSearchFeaturesResponseInterface[]): void {
    if (this.searchQuery.length < this.mimSearchLimit) return;
    this.searchResult.features = result;
  }

  @Mutation
  public RESET_SEARCH_RESULTS(): void {
    this.searchResult = {
      products: [],
      orders: [],
      features: [],
      customers: [],
      appMarket: [],
    };
  }

  @Mutation
  public SET_SEARCH_HISTORY(searchQuery: string): void {
    let history = JSON.parse(localStorage.getItem('searchHistory') || '[]');
    history = history.filter((query: string) => query !== searchQuery);
    history.unshift(searchQuery);

    if (history.length > 3) {
      history = history.slice(0, 3);
    }
    this.searchHistory = history;
    localStorage.setItem('searchHistory', JSON.stringify(history));
  }

  @Mutation
  public LOAD_SEARCH_HISTORY(): void {
    const history = JSON.parse(localStorage.getItem('searchHistory') || '[]');
    this.searchHistory = history;
  }

  @Mutation
  public RESET_SEARCH_HISTORY(): void {
    this.searchHistory = [];
    localStorage.removeItem('searchHistory');
  }

  @Mutation
  public ABORT_BULK_FETCH(): void {
    if (this.controller) {
      this.controller.abort();
    }
  }

  @Mutation
  public SET_CONTROLLER_BULK_FETCH(): void {
    this.controller = new AbortController();
  }

  @Mutation
  public RESET_CONTROLLER_BULK_FETCH(): void {
    this.controller = null;
  }

  @Mutation
  public TOGGLE_SEARCH_VIEW(value: boolean): void {
    this.isSearchOpen = value;
  }

  @Mutation
  public TOGGLE_INPUT_FOCUS(value: boolean): void {
    this.isInputFocused = value;
  }

  @Mutation
  public SET_CATEGORY_SEARCH_RESULT_STATUS(value: boolean): void {
    this.isCategorySearchCompleted = value;
  }

  @Mutation
  public SET_GLOBAL_SEARCH_RESULT_STATUS(value: boolean): void {
    this.isGlobalSearchCompleted = value;
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_GLOBAL_SEARCH_ERROR(error) })
  public async searchOrders(searchQuery: string): Promise<void> {
    const filters: OrdersApiFiltersInterface = {
      search: searchQuery,
      limit: 2,
    };
    this.FETCH_GLOBAL_SEARCH();
    const response = await ordersService.getOrders(filters);
    this.FETCH_GLOBAL_SEARCH_SUCCESS();
    this.SET_CATEGORY_SEARCH_RESULT_STATUS(true);
    this.SET_ORDER_SEARCH_RESULT(response.data.data);
  }

  @Action
  @Catch({ errorHandler: (error: any) => customErrorhandler(error) })
  public async searchProducts(searchQuery: string): Promise<void> {
    const filters: ProductsApiFiltersInterface = {
      search: searchQuery,
      limit: 2,
    };
    this.FETCH_GLOBAL_SEARCH();
    const response = await productsService.getProducts(filters, true, this.controller);
    this.FETCH_GLOBAL_SEARCH_SUCCESS();
    this.SET_CATEGORY_SEARCH_RESULT_STATUS(true);
    this.SET_PRODUCTS_SEARCH_RESULT(response.data.data);
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_GLOBAL_SEARCH_ERROR(error) })
  public async searchCustomers(searchQuery: string): Promise<void> {
    const filters: CustomersApiFiltersInterface = {
      search: searchQuery,
      limit: 2,
    };
    this.FETCH_GLOBAL_SEARCH();
    const response = await customersService.getCustomers(filters);
    this.FETCH_GLOBAL_SEARCH_SUCCESS();
    this.SET_CATEGORY_SEARCH_RESULT_STATUS(true);
    this.SET_CUSTOMERS_SEARCH_RESULT(response.data.data);
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_GLOBAL_SEARCH_ERROR(error) })
  public async searchAppMarket(searchQuery: string): Promise<void> {
    const filters: AppMarketAppParamsInterface = {
      search: searchQuery,
      perPage: 10,
    };
    this.FETCH_GLOBAL_SEARCH();
    const response = await appMarketServices.getApplications(filters);
    this.FETCH_GLOBAL_SEARCH_SUCCESS();
    this.SET_CATEGORY_SEARCH_RESULT_STATUS(true);
    this.SET_ZAM_SEARCH_RESULT(response.data);
  }

  @Action
  @Catch({ errorHandler: (error: any) => customErrorhandler(error) })
  public async searchFeatures(featuresFilters: GlobalSearchApiFiltersInterface): Promise<void> {
    this.FETCH_GLOBAL_SEARCH();
    const response = await globalSearchServices.searchFeatures(featuresFilters, this.controller);
    this.FETCH_GLOBAL_SEARCH_SUCCESS();
    this.SET_CATEGORY_SEARCH_RESULT_STATUS(true);
    this.SET_FEATURES_SEARCH_RESULT(response.data);
  }

  @Action
  @Catch({ onError: (error, ctx) => ctx.FETCH_GLOBAL_SEARCH_ERROR(error) })
  public async globalSearch(searchQuery: string): Promise<void> {
    const featuresFilters: GlobalSearchApiFiltersInterface = {
      page: 1,
      perPage: 2,
      search: searchQuery,
    };
    this.FETCH_GLOBAL_SEARCH();
    this.SET_CONTROLLER_BULK_FETCH();
    try {
      await Promise.all([
        await this.searchOrders(searchQuery),
        await this.searchProducts(searchQuery),
        await this.searchFeatures(featuresFilters),
      ]);
    } catch (error) {
      console.error('Error global search fetching bulk APIs:', error);
    } finally {
      this.SET_SEARCH_HISTORY(searchQuery);
      this.FETCH_GLOBAL_SEARCH_SUCCESS();
      this.RESET_CONTROLLER_BULK_FETCH();
      this.SET_GLOBAL_SEARCH_RESULT_STATUS(true);
    }
  }
}

export const globalSearchModule = getModule(GlobalSearch);
