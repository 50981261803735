import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from './factory';
import AppStoreReadyStateEnum from './ready-state.enum';
import ApiModelAppStatusInterface from '../../api/model/app-status.interface';
import { AppApiServiceFactory } from '../api/service-factory';

const appService = AppApiServiceFactory();

@Module({
  dynamic: true,
  name: 'appStatus',
  store: appStoreFactory(),
  namespaced: true,
})
class StatusModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ApiModelAppStatusInterface | null = null;
  public error: Error | null = null;

  @Mutation
  private FETCH() {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: ApiModelAppStatusInterface) {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error) {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async fetch() {
    this.FETCH();

    try {
      const response = await appService.getAppStatus();

      this.FETCH_SUCCESS(response.data.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action({ rawError: true })
  public async changeLanguage(language: string): Promise<void> {
    await appService.changeLanguage(language);
  }
}

export const AppStoreStatusModule = getModule(StatusModule);
