import { ValidateIbanResponse, ValidateIbanData } from './../types/BankAccount.interface';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidpayApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import {
  MerchantProfile,
  EnteredFileds,
  RequiredFields,
  MerchantProfileEnteredFields,
} from '../types/MerchantProfile.interface';
import { BankAccount } from '../types/BankAccount.interface';
import { ZidpayTypesMerchantStatsInterface } from '../types/merchant-stats.interface';
import AppApiStatusEnum from '../../api/status.enum';
import { ZidpayStoreSettingsModule } from './settings-module';
import { KycStatus } from '../types/MerchantDetails.interface';
import { CountryCode } from '../countries/shared/CountryValidation/CountryValidation.enum';
import { ActivationSteps } from '../types/ActivationSteps.enum';
import { Network } from '../types/Networks.interface';
import { ActivationBankInputError } from '../types/ActivationBankInputError.interface';
import { I18nMessages } from '../../i18n/messages';
import { ZidpayCountriesLocale } from '../countries/exports/locale/locale';
import Catch from '../../common/decorators/catch-error';

const zidpayService = ZidpayApiServiceFactory();
@Module({
  dynamic: true,
  name: 'zidpay',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidpayModule extends VuexModule {
  public readonly english = /^(?:[A-Za-z0-9]+)(?:[A-Za-z0-9 -]*)$/;
  public loadingMerchantState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingBankInfo: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingValidateIban: AppStoreReadyStateEnum = AppStoreReadyStateEnum.idle;
  public loadingActivate: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public merchantProfile: MerchantProfile = {};
  public banckAccounts: BankAccount[] = [];
  public enteredFields: MerchantProfileEnteredFields = {};
  public merchantStats: ZidpayTypesMerchantStatsInterface | null = null;
  public get merchantKYC(): KycStatus | undefined {
    return ZidpayStoreSettingsModule.data?.kycStatus;
  }
  public requiredFields!: RequiredFields;
  public currentStep: ActivationSteps = ActivationSteps.Step1;
  public canActivate = false;

  public isActivated = false;
  public isTermsChecked = false;
  public isEnglishName = false;
  public isIban = false;
  public isIbanFromatValid = false;
  public error: Error | null = null;

  public get requiredFieldsProp(): RequiredFields {
    return this.requiredFields;
  }

  public get isEnglishNameValid(): ActivationBankInputError {
    if (this.isEnglishName)
      return {
        invalid: false,
      };

    if (!this.enteredFields.english_account_holder_name)
      return {
        invalid: true,
        message: I18nMessages['zidpay.activation.account.banckAccount.english.name.error.required'],
      };

    if (!this.english.test(this.enteredFields.english_account_holder_name))
      return {
        invalid: true,
        message: I18nMessages['zidpay.activation.account.banckAccount.english.name.error.format'],
      };

    return {
      invalid: false,
    };
  }

  public get isIbanValid(): ActivationBankInputError {
    if (this.isIban)
      return {
        invalid: false,
      };

    if (!this.enteredFields.iban)
      return {
        invalid: true,
        message: I18nMessages['zidpay.activation.account.banckAccount.iban.error.required'],
      };

    if (this.enteredFields.iban.length !== +ZidpayCountriesLocale.getTitle('minIbanChar'))
      return {
        invalid: true,
        message: I18nMessages['zidpay.activation.account.banckAccount.iban.error.min'],
        subMessage: ZidpayCountriesLocale.getTitle('minIbanChar'),
      };

    if ([AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(this.loadingValidateIban))
      return {
        invalid: true,
        message: '',
      };

    if (!this.isIbanFromatValid)
      return {
        invalid: true,
        message: I18nMessages['zidpay.activation.account.banckAccount.iban.error.format'],
      };

    return {
      invalid: false,
    };
  }

  @Mutation
  private UPDATE_CAN_ACTIVATE(): void {
    const canActivate = {
      [ActivationSteps.Step1]: (): boolean => {
        const basic = this.merchantProfile.basic;

        for (const key of this.requiredFields.basic) {
          if (!basic || !basic[key]) return false;
        }

        const headquarter = this.merchantProfile.headquarter;
        for (const key of this.requiredFields.headquarter) {
          if (!headquarter || !headquarter[key]) return false;
        }

        const commercial = this.merchantProfile.commercial;
        for (const key of this.requiredFields.commercial) {
          if (!commercial || !commercial[key]) return false;
        }

        if (![CountryCode.SA, CountryCode.KW].includes(this.merchantProfile?.headquarter?.countryCode as CountryCode))
          return false;

        return true;
      },
      [ActivationSteps.Step2]: (): boolean => {
        if (!canActivate[ActivationSteps.Step1]()) return false;

        for (const key of this.requiredFields.EnteredFileds) {
          if (!this.enteredFields[key]) return false;
        }

        const packageDetails = this.merchantProfile.package;
        for (const key of this.requiredFields.package) {
          if (!packageDetails || !packageDetails[key]) return false;
        }

        const commercial = this.merchantProfile.commercial;
        for (const key of this.requiredFields.bankCertificate || []) {
          if (!commercial || !commercial[key]) return false;
        }

        if (this.isEnglishNameValid) {
          return false;
        }

        if (this.isIbanValid) {
          return false;
        }

        return true;
      },
      [ActivationSteps.Step3]: (): boolean => {
        if (!canActivate[ActivationSteps.Step2]()) return false;

        if (!this.isTermsChecked) return false;

        return true;
      },
    };
    this.canActivate = canActivate[this.currentStep]();
  }
  @Mutation
  private UPDATE_REQUIRED_FILEDS(requiredFileds: RequiredFields): void {
    this.requiredFields = requiredFileds;
  }

  @Mutation
  private FETCH_SUCCESS_MERCHANT_PROFILE(profile: MerchantProfile): void {
    this.loadingMerchantState = AppStoreReadyStateEnum.loaded;
    this.merchantProfile = profile;
    this.error = null;
  }

  @Mutation
  private FETCH_MERCHANT_STATS_SUCCESS(merchantStats: ZidpayTypesMerchantStatsInterface): void {
    this.loadingMerchantState = AppStoreReadyStateEnum.loaded;
    this.merchantStats = merchantStats;
    this.error = null;
  }

  @Mutation
  private FETCH_MERCHANT_PROFILE(): void {
    this.loadingMerchantState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }
  @Mutation
  private UPDATE_ENTERED_FIELDS(data: { key: EnteredFileds; value: string | Network[] }): void {
    this.enteredFields = { ...this.enteredFields, [data.key]: data.value };
  }

  @Mutation
  private UPDATE_NETWORK(network: Network): void {
    const updatedNetwork = this.enteredFields.networks?.find((n) => n.id === network.id);
    if (!updatedNetwork) return;

    updatedNetwork['is_enabled'] = !updatedNetwork.is_enabled;
  }

  @Mutation
  private UPDATE_CURRENT_STEP(step: ActivationSteps): void {
    this.currentStep = step;
  }

  @Mutation
  private VALIDATE_BANK_INFO(id: string): void {
    const bank = this.banckAccounts.find((b) => b.id === +id);
    this.isEnglishName = false;
    this.isIban = false;

    if (bank?.beneficiary_name && this.english.test(bank.beneficiary_name)) {
      this.isEnglishName = true;
      delete this.enteredFields.english_account_holder_name;
    }

    if (bank?.iban) {
      this.isIban = true;
      delete this.enteredFields.iban;
    }
  }

  @Mutation
  private UPDATE_TERMS_CHECKED(): void {
    this.isTermsChecked = !this.isTermsChecked;
  }

  @Mutation
  private RESET(): void {
    this.enteredFields = {};
    this.isTermsChecked = false;
  }

  @Mutation
  private ACTIVATE_MERCHANT(): void {
    this.loadingActivate = AppStoreReadyStateEnum.loading;
    this.error = null;
  }
  @Mutation
  private SUCCESS_ACTIVATE_MERCHANT(): void {
    this.loadingActivate = AppStoreReadyStateEnum.loaded;
    this.isActivated = true;
    this.error = null;
  }
  @Mutation
  private FETCH_BANK_INFO(): void {
    this.loadingBankInfo = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS_BANK_INFO(data: BankAccount[]): void {
    this.loadingBankInfo = AppStoreReadyStateEnum.loaded;
    this.banckAccounts = data;
    this.error = null;
  }

  @Mutation
  private FETCH_VALIDATE_IBAN(): void {
    this.loadingValidateIban = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS_VALIDATE_IBAN(data: ValidateIbanResponse): void {
    this.loadingValidateIban = AppStoreReadyStateEnum.loaded;
    this.isIbanFromatValid = data.isValid;
    this.error = null;
  }

  @Mutation
  private FETCH_ERROR_MERCHANT_PROFILE(error: Error): void {
    this.loadingMerchantState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private FETCH_ERROR_VALIDATE_IBAN(error: Error): void {
    this.loadingValidateIban = AppStoreReadyStateEnum.error;
    this.error = error;
  }
  @Mutation
  private FETCH_ERROR_BANK_INFO(error: Error): void {
    this.loadingBankInfo = AppStoreReadyStateEnum.error;
    this.error = error;
  }
  @Mutation
  private FETCH_ERROR_ACTIVATE_MERCHANT(error: Error): void {
    this.loadingActivate = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async fetchMerchantProfile(): Promise<void> {
    this.FETCH_MERCHANT_PROFILE();
    try {
      const res = await zidpayService.getMerchantProfile();
      this.FETCH_SUCCESS_MERCHANT_PROFILE(res.data);
      this.UPDATE_CAN_ACTIVATE();
    } catch (error) {
      this.FETCH_ERROR_MERCHANT_PROFILE(error as Error);
    }
  }

  @Action
  public async validateIban(): Promise<void> {
    if (this.enteredFields?.iban?.length !== +ZidpayCountriesLocale.getTitle('minIbanChar')) {
      return;
    }

    this.FETCH_VALIDATE_IBAN();
    const data: ValidateIbanData = {
      countryCode: this.merchantProfile.headquarter?.countryCode as CountryCode,
      iban: this.enteredFields.iban || '',
    };
    try {
      const res = await zidpayService.validateIban(data);
      this.FETCH_SUCCESS_VALIDATE_IBAN(res.data);
    } catch (error) {
      this.FETCH_ERROR_VALIDATE_IBAN(error as Error);
    }
  }

  @Action
  public async fetchBankInfo(): Promise<void> {
    this.FETCH_BANK_INFO();
    try {
      const res = await zidpayService.getBankInfo(this.merchantProfile.headquarter?.countryCode);
      this.FETCH_SUCCESS_BANK_INFO(res.data);
    } catch (error) {
      this.FETCH_ERROR_BANK_INFO(error as Error);
    }
  }

  @Action
  public updateEnteredFields(data: { key: EnteredFileds; value: string | Network[] }): void {
    this.UPDATE_ENTERED_FIELDS(data);
    if (data.key === EnteredFileds.bankId) {
      this.VALIDATE_BANK_INFO(data.value as string);
    }
    this.UPDATE_CAN_ACTIVATE();
  }

  @Action
  public updateTermsChecked(): void {
    this.UPDATE_TERMS_CHECKED();
    this.UPDATE_CAN_ACTIVATE();
  }
  @Action
  public updateNetwork(network: Network): void {
    this.UPDATE_NETWORK(network);
  }

  @Action
  public updateCurrentStep(step: ActivationSteps): void {
    this.UPDATE_CURRENT_STEP(step);
    this.UPDATE_CAN_ACTIVATE();
  }

  @Action({ rawError: true })
  @Catch()
  public async activateMerchant(): Promise<void> {
    this.ACTIVATE_MERCHANT();
    const isReActivate = this.merchantKYC === KycStatus.Rejected;
    const res = await zidpayService.postActivateMerchant(this.enteredFields, isReActivate);
    if (res.status === AppApiStatusEnum.error) {
      if (res.error) {
        this.FETCH_ERROR_ACTIVATE_MERCHANT({
          message: res.error,
        } as Error);
      }
      return;
    }
    ZidpayStoreSettingsModule.fetchMerchantDetails();
    this.SUCCESS_ACTIVATE_MERCHANT();
  }

  @Action
  @Catch()
  public async getMerchantStats(): Promise<void> {
    const response = await zidpayService.getMerchantStats();
    this.FETCH_MERCHANT_STATS_SUCCESS(response.data.data);
  }

  @Action
  public reset(): void {
    this.RESET();
    this.UPDATE_CAN_ACTIVATE();
  }
  @Action
  public updateRequiredFields(requiredFields: RequiredFields): void {
    this.UPDATE_REQUIRED_FILEDS(requiredFields);
    this.UPDATE_CAN_ACTIVATE();
  }
}

export const ZidpayStoreModule = getModule(ZidpayModule);
