import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { SettingsApiLocationServiceFactory } from '../api/location/service-factory';
import ApiModelCountryInterface from '../../api/model/country.interface';

const service = SettingsApiLocationServiceFactory();

@Module({
  dynamic: true,
  name: 'settingsCountries',
  store: appStoreFactory(),
  namespaced: true,
})
class CountriesModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: Array<ApiModelCountryInterface> | null = null;
  public error: Error | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.data = null;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: Array<ApiModelCountryInterface>): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async fetch(): Promise<void> {
    this.FETCH();

    try {
      const response = await service.getCountries();

      this.FETCH_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }
}

export const SettingsStoreCountriesModule = getModule(CountriesModule);
