<template>
  <div :class="styles['search-categories-wrapper']">
    <div v-for="(category, index) in searchCategories" :key="index">
      <button :class="isSearchLoading && styles['search-inprogress']" @click="manageSelectedCategory(category)">
        <ZidBadge :class="styles['category-box']" type="primary" inversed>
          <div :class="styles['category']">
            <ZidIcon v-if="category.icon" :icon="category.icon" size="10px" />
            <component v-else-if="category.img" :is="category.img"></component>
            <span>{{ $t(I18nMessages[category.name]) }}</span>
          </div>
        </ZidBadge>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { ZidIcon, ZidBadge } from '@zidsa/ui';
import { globalSearchModule } from '../../../../store/GlobalSearch/module';
import { GlobalSearchCategoriesInterface } from '../../../../types/global-search.interface';
import PuzzlePieceIcon from '../../../../../common/assets/icons/PuzzlePieceIcon.vue';
import CustomerOutlineIcon from '../../../../../common/assets/icons/CustomerOutlineIcon.vue';
import { useGlobalSearch } from '../../../../composables/useGlobalSearch';
import useI18nMessages from '../../../../../common/composables/useI18nMessages';
import { GlobalSearchCategoriesEnum } from "../../../../types/global-search.enum";
export default defineComponent({
  components: { ZidIcon, ZidBadge },
  setup() {
    const { handleSearch, isSearchLoading } = useGlobalSearch();
    const I18nMessages = useI18nMessages();
    const searchCategories = ref<GlobalSearchCategoriesInterface[]>([
      {
        name: 'sidebar.navigation_section.orders.title',
        route_path: '/orders',
        icon: 'basket_outline',
        code: GlobalSearchCategoriesEnum.orders,
      },
      {
        name: 'sidebar.navigation_section.products.title',
        route_path: '/products',
        icon: 'product_outline',
        code: GlobalSearchCategoriesEnum.products,
      },
      {
        name: 'sidebar.navigation_section.customer.title',
        route_path: '/customers',
        img: CustomerOutlineIcon,
        code: GlobalSearchCategoriesEnum.customers
      },
      {
        name: 'global_search.categories_apps',
        route_path: '/market/applications',
        img: PuzzlePieceIcon,
        code: GlobalSearchCategoriesEnum.appMarket
      },
      {
        name: 'global_search.categories_features',
        icon: 'settings',
        code: GlobalSearchCategoriesEnum.features
      },
    ]);

    const manageSelectedCategory = (selectedCategory: GlobalSearchCategoriesInterface): void => {
      if (isSearchLoading.value) return;
      globalSearchModule.RESET_SEARCH_RESULTS();
      globalSearchModule.ADD_SELECTED_SEARCH_CATEGORIES(selectedCategory);
      handleSearch(globalSearchModule.searchQuery);
    };

    return { manageSelectedCategory, searchCategories, isSearchLoading, I18nMessages };
  },
});
</script>
<style lang="scss" module="styles" scoped src="./SearchCategories.module.scss"></style>
