import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { AccountAuthApiServiceFactory } from '../api/service-factory';
import ApiModelUserInterface from '../../api/model/user.interface';
import { ApiBaseMessageResponseObjectType } from '../../api/types';
import { RegisterMerchantBackgroundEnum } from '../views/Register/step.enum';
import Catch from '../../common/decorators/catch-error';

const registerService = AccountAuthApiServiceFactory();
@Module({
  dynamic: true,
  name: 'authUser',
  store: appStoreFactory(),
  namespaced: true,
})
class UserModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingCreateRecommendedStepsState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: ApiModelUserInterface | null = null;
  public error: Error | null = null;
  public eccommerceExperience: RegisterMerchantBackgroundEnum | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_SUCCESS(data: ApiModelUserInterface): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_EXPERIENCE_SUCCESS(data: RegisterMerchantBackgroundEnum): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.eccommerceExperience = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private FETCH_CREATE_RECOMMENDED_STEPS_SETTINGS(): void {
    this.loadingCreateRecommendedStepsState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_CREATE_RECOMMENDED_STEPS_SETTINGS_SUCCESS(): void {
    this.loadingCreateRecommendedStepsState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  public CREATE_RECOMMENDED_STEPS_SETTINGS_ERROR(status: AppStoreReadyStateEnum): void {
    this.loadingCreateRecommendedStepsState = status;
  }

  @Action
  public async changeMobile(value: string): Promise<any> {
    this.FETCH();
    return registerService
      .updatePhoneNumber(value)
      .then((res) => {
        this.FETCH_SUCCESS(this.data || ({} as ApiModelUserInterface));
        return res;
      })
      .catch((e) => {
        this.FETCH_ERROR(e);
        return e;
      });
  }

  @Action
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_SUCCESS() })
  public async fetch(): Promise<ApiModelUserInterface | ApiBaseMessageResponseObjectType> {
    this.FETCH();
    const response = await registerService.getUser();
    this.FETCH_SUCCESS(response.data);
    return response.data;
  }

  @Action
  public async getStoreEcommerceExperience(): Promise<RegisterMerchantBackgroundEnum> {
    this.FETCH();
    try {
      const response = await registerService.getStoreEcommerceExperience();
      this.FETCH_EXPERIENCE_SUCCESS(response.data);
      return response.data;
    } catch (e) {
      this.FETCH_ERROR(e as Error);
      return (e as any).response.data as any;
    }
  }

  @Action
  @Catch({ onError: (_, ctx) => ctx.CREATE_RECOMMENDED_STEPS_SETTINGS_ERROR(AppStoreReadyStateEnum.error) })
  public async createRecommendedStepsList(): Promise<void> {
    this.FETCH_CREATE_RECOMMENDED_STEPS_SETTINGS();
    await registerService.createRecommendedStepsList();
    this.FETCH_CREATE_RECOMMENDED_STEPS_SETTINGS_SUCCESS();
  }
}

export const AuthUserStoreModule = getModule(UserModule);
