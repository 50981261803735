import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ClickOutsideHandlerComponent } from '../ClickOutsideHandler/ClickOutsideHandler';
import styles from './Modal.scss';

@Component
export class ModalComponent extends Vue {
  @Prop({
    default: false,
    required: true,
  })
  private readonly isOpen!: boolean;

  @Prop()
  private readonly contentClass!: string;

  @Watch('isOpen')
  onIsOpenChanged(value: boolean, oldValue: boolean): void {
    if (value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  render(h: CreateElement): VNode | null {
    if (!this.isOpen) {
      return null;
    }

    return (
      <div class={styles.modal}>
        <div class={styles.modal__wrapper}>
          <ClickOutsideHandlerComponent
            class={[styles.modal__content, this.contentClass]}
            onClickOutside={this.onClickOutside}
          >
            {this.$slots.default}
          </ClickOutsideHandlerComponent>
        </div>
      </div>
    );
  }

  private onClickOutside(): void {
    this.$emit('close');
  }
}
