import { FileDropChangeEventInterface } from './change-event.interface';
import { FileDropFileInterface } from './file.interface';
import { ButtonComponent } from './../../Button/Button';
import { UploadIconComponent } from './../../../assets/icons/UploadIconComponent';
import ZidIconComponent from '../../ZidIcon/ZidIcon';
import { ZidIconTypeEnum } from '../../ZidIcon/type.enum';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import { ApiModelProductImageInterface } from '../../../../api/model/product/image.interface';
import styles from './FileDrop.scss';
import { ButtonTypeEnum } from '../../Button';
import { v4 as uuidv4 } from 'uuid';

@Component({
  data() {
    return { I18nMessages, styles, closeIcon: ZidIconTypeEnum.close, linkButtonType: ButtonTypeEnum.link };
  },
  components: {
    UploadIconComponent,
    ZidIconComponent,
    ButtonComponent,
  },
})
export default class FileDrop extends Vue {
  @Prop()
  readonly images!: Array<FileDropFileInterface> | Array<ApiModelProductImageInterface>;

  @Prop()
  readonly files!: Array<FileDropFileInterface>;

  @Prop()
  readonly value!: FileDropFileInterface;

  @Prop()
  readonly imageLink!: string;

  @Prop()
  readonly getPreviewURL!: Function;

  @Prop({
    default: false,
  })
  readonly isImageUpload!: boolean;

  @Prop({
    default: 10,
  })
  readonly limit!: number;

  @Prop()
  readonly getFileURL!: Function;

  @Prop()
  readonly getAltText!: Function;

  handleDrop(event: DragEvent): void {
    event.stopPropagation();

    if (!event.dataTransfer) return;
    if (this.isImageUpload && event.dataTransfer.files[0].type.split('/')[0] !== 'image') return;

    const changeEvent: FileDropChangeEventInterface = {
      value: {
        id: uuidv4(),
        data: event.dataTransfer.files[0],
      },
    };

    this.$emit('drop', changeEvent);
  }

  handleRemoveFile(id: string) {
    this.$emit('delete', id);
  }

  handleImageZoneClick() {
    this.$emit('imageZoneClick');
  }
}
