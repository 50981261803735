import ApiClient from '../../api/client';
import { ApiBaseDataResponseObjectType, ApiBaseResponseType } from '../../api/types';
import ApiModelAppStatusInterface from '../../api/model/app-status.interface';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { appApiUrls } from './urls';
import { GlobalSearchApiFiltersInterface } from './filters.interface';
import { GlobalSearchFeaturesResponseInterface } from '../types/global-search.interface';

export class AppApiService {
  constructor(private readonly apiClient: ApiClient) {}

  getAppStatus(): Promise<ApiBaseResponseType<{ data: ApiModelAppStatusInterface }>> {
    return this.apiClient.request(ApiHttpMethodEnum.get, appApiUrls.appStatus);
  }

  async changeLanguage(lang: string): Promise<ApiBaseResponseType> {
    const response = await this.apiClient.request<ApiBaseResponseType>(
      ApiHttpMethodEnum.post,
      appApiUrls.changeLanguage,
      { data: { lang } },
    );

    return response.data;
  }

  async searchFeatures(
    filters: GlobalSearchApiFiltersInterface,
    controller?: AbortController | null,
  ): Promise<ApiBaseDataResponseObjectType<GlobalSearchFeaturesResponseInterface[]>> {
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<GlobalSearchFeaturesResponseInterface[]>
    >(ApiHttpMethodEnum.get, appApiUrls.globalSearch, {
      params: filters,
      signal: controller?.signal,
    });

    return response.data;
  }
}
