import I18nLocaleEnum from '../../i18n/locale.enum';
import appI18nCreate from './create';

const locale: string = window.__INITIAL_STATE__.appLocale || 'ar';

const i18n = appI18nCreate(I18nLocaleEnum[locale as keyof typeof I18nLocaleEnum]);

export default function appI18nFactory(): typeof i18n {
  return i18n;
}
