import UserStoreModule from './module';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { UserPermissionSlugEnum } from '../../api/model/user/permission-slug.enum';

export function userStoreHasPermission(slug?: UserPermissionSlugEnum | string, isStrict?: boolean): boolean {
  if (UserStoreModule.loadingState !== AppStoreReadyStateEnum.loaded || !UserStoreModule.data) {
    return false;
  }

  const user = UserStoreModule.data;

  if (!user || !user.roles) return false;

  if (user.roles[0].slug === 'store.owner') return true;

  const { permissions } = user;

  const requiredPermissions: Array<string> = slug?.split(',') || [];

  if (isStrict) return requiredPermissions.every((item) => permissions.map((p) => p.slug.toString()).includes(item));
  return permissions.some((item) => requiredPermissions.includes(item.slug));
}
