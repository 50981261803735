import { ZidButton, ZidIcon } from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { AnchorButtonSizeEnum } from '../../../../common/components/AnchorButton/size.enum';
import Catch from '../../../../common/decorators/catch-error';
import isRTL from '../../../../common/helpers/is-lang-arabic';
import I18nLocaleEnum from '../../../../i18n/locale.enum';
import { I18nMessages } from '../../../../i18n/messages';
import { AppStoreStatusModule } from '../../../store/status-module';
import styles from './LanguageSwitcher.scss';
import { SettingsStoreCountriesModule } from '../../../../settings/store/countries-module';
import { SettingsStoreCitiesModule } from '../../../../settings/store/cities-module';
import UserStoreModule from '../../../../user/store/module';
import ApiModelUserInterface from '../../../../api/model/user.interface';
import { MixpanelService } from '../../../../mixpanel/api/service';
import { MixpanelEvents } from '../../../../mixpanel/api/events.enum';

@Component
export class StatusBarLanguageSwitcherComponent extends Vue {
  @Prop({ default: false }) private readonly isTopNavbar!: boolean;
  private mixpanelService: MixpanelService = new MixpanelService();

  render(h: CreateElement): VNode {
    return this.isTopNavbar ? (
      <div class={styles.languageswitcher_topnavbar_wrapper}>
        <div class={styles.languageswitcher__button_topnavbar}>
          <ZidButton link size={AnchorButtonSizeEnum.small} nativeOnClick={this.changeLanguage}>
            {this.$t(I18nMessages['language_switcher_label_short'])}
          </ZidButton>
        </div>
      </div>
    ) : (
      <div class={styles.languageswitcher_wrapper}>
        <div class={styles.languageswitcher__button}>
          <ZidButton link size={AnchorButtonSizeEnum.small} nativeOnClick={this.changeLanguage}>
            <ZidIcon size={'xxs'} color={'primary'} icon={'language'} />
            <span class={styles.languageswitcher__button__text}>
              {this.$t(I18nMessages['language_switcher_label'])}
            </span>
          </ZidButton>
        </div>
      </div>
    );
  }

  private get user(): ApiModelUserInterface | null {
    return UserStoreModule?.data;
  }

  @Catch()
  private async changeLanguage(): Promise<void> {
    try {
      this.mixpanelService.track(isRTL() ? MixpanelEvents.clickEnglishLanguage : MixpanelEvents.clickArabicLanguage);
      const changeLanguageTo = isRTL() ? I18nLocaleEnum.en : I18nLocaleEnum.ar;
      await AppStoreStatusModule.changeLanguage(changeLanguageTo)
        .then(async () => {
          await SettingsStoreCountriesModule.fetch();
          if (this.user?.business_location?.country) {
            await SettingsStoreCitiesModule.fetch({ countryId: this.user.business_location.country.id });
          }

          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  }
}
