import { AxiosResponse } from 'axios';
import { generateUrlFromAxiosRequest } from '../url/generate-url-from-axios-request';
import { updateCache } from './update-cache';

/**
 * Helper function to revalidate inMemory cache using a web worker
 */

export function revalidate(response: AxiosResponse, callback: (data: any) => any): void {
  if (!response.request.fromCache) return;
  const url = generateUrlFromAxiosRequest(response.config);

  const swrWorker = new Worker('/js/web-workers/swr-worker.js');
  swrWorker.postMessage(url);

  swrWorker.onmessage = (event: MessageEvent): void => {
    const { data, url: _url } = event.data;
    if (url !== _url) {
      /**
       * This is to make sure web worker response/event is received by the right consumer.
       * `swrWorker` instance is considered as a singleton that may send and get simultaneous messages/events.
       */
      return;
    }

    callback(data);

    updateCache(url, data);
  };
}
