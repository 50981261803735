import { AxiosRequestConfig } from 'axios';

export function generateUrlFromAxiosRequest(request: AxiosRequestConfig): string {
  let url = request.url;

  if (request.params) {
    url += '?' + new URLSearchParams(request.params).toString();
  }

  return url as string;
}
