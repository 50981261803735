import { SettingsApiLocationCountriesResponseInterface } from './countries/response.interface';
import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { SettingsApiLocationCitiesFiltersInterface } from './cities/filters.interface';
import { SettingsApiLocationCountriesFiltersInterface } from './countries/filters.interface';
import { settingsApiLocationCitiesFiltersToSearchParams } from './cities/filters-to-search-params';
import { settingsApiLocationCountriesFiltersToSearchParams } from './countries/filters-to-search-params';
import { settingsApiUrls } from '../urls';
import { SettingsApiLocationCitiesResponseInterface } from './cities/response.interface';
import { urlQueryFormat } from '../../../common/helpers/url/query/format';

export class SettingsApiLocationService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getCities(
    filters: SettingsApiLocationCitiesFiltersInterface,
  ): Promise<SettingsApiLocationCitiesResponseInterface> {
    const params = urlQueryFormat(settingsApiLocationCitiesFiltersToSearchParams(filters));
    const response = await this.apiClient.request<SettingsApiLocationCitiesResponseInterface>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.cities,
      {
        params,
      },
    );
    return response.data;
  }
  public async getCountries(
    filters?: SettingsApiLocationCountriesFiltersInterface,
  ): Promise<SettingsApiLocationCountriesResponseInterface> {
    const params = urlQueryFormat(settingsApiLocationCountriesFiltersToSearchParams(filters));
    const response = await this.apiClient.request<SettingsApiLocationCountriesResponseInterface>(
      ApiHttpMethodEnum.get,
      settingsApiUrls.countries,
      {
        params,
      },
    );

    return response.data;
  }
}
