// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c901bf5b{text-align:right !important;margin-inline-end:10px;margin-bottom:3px}.c901bf5b label{padding-top:20px;font-size:16px;display:flex;color:#464646}", "",{"version":3,"sources":["webpack://./src/home/components/ActivitySelection/ActivitySelection.scss"],"names":[],"mappings":"AAAA,UACE,2BAAA,CACA,sBAAA,CACA,iBAAA,CAGF,gBACE,gBAAA,CACA,cAAA,CACA,YAAA,CACA,aAAA","sourcesContent":[".content {\n  text-align: right !important;\n  margin-inline-end: 10px;\n  margin-bottom: 3px;\n}\n\n.content label {\n  padding-top: 20px;\n  font-size: 16px;\n  display: flex;\n  color: rgb(70, 70, 70);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "c901bf5b"
};
export default ___CSS_LOADER_EXPORT___;
