import { Component, Prop, Vue } from 'vue-property-decorator';
import { CreateElement, VNode } from 'vue';
import { ModalComponent } from '../Modal';
import styles from './HelpCenter.scss';
import { AnchorButton } from '../AnchorButton/AnchorButton';
import { AnchorButtonTypeEnum } from '../AnchorButton/type.enum';
import { AnchorButtonSizeEnum } from '../AnchorButton/size.enum';
import { I18nMessages } from '../../../i18n/messages';
import { FontAwesomeIconComponent, FontAwesomeIconTypeEnum } from '../FontAwesomeIcon';
import { faQuestionCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonComponent, ButtonSizeEnum, ButtonTypeEnum } from '../Button';
import { AppStoreModule } from '../../../app/store/module';
import isMobileView from '../../helpers/is-mobile-view';
@Component
export class HelpCenterComponent extends Vue {
  @Prop()
  private readonly isOpen!: boolean;
  render(h: CreateElement): VNode {
    return (
      <ModalComponent
        isOpen={this.isOpen}
        onClose={() => {
          this.$emit('close');
        }}
        contentClass={{
          [styles.helpcenter]: true,
          [styles['helpcenter--sidebarcollapsed']]: !AppStoreModule.settings?.navbarCollapsed && !isMobileView(),
        }}
      >
        <header class={styles.helpcenter__header}>
          <h3>{this.$t(I18nMessages['statusbar.help_center'])}</h3>
          <ButtonComponent
            type={ButtonTypeEnum.link}
            size={ButtonSizeEnum.large}
            nativeOnClick={() => {
              this.$emit('close');
            }}
          >
            <FontAwesomeIconComponent type={FontAwesomeIconTypeEnum.solid} icon={faTimes} />
          </ButtonComponent>
        </header>
        <div class={styles.helpcenter__content}>
          <iframe id='help-center-iframe' src='https://help.zid.sa/' frameborder='0' height='500px' width='100%' />
        </div>
        <footer class={styles.helpcenter__footer}>
          <AnchorButton
            type={AnchorButtonTypeEnum.link}
            size={AnchorButtonSizeEnum.small}
            target='_blank'
            href='https://help.zid.sa/الأسئلة-الشائعة'
          >
            <FontAwesomeIconComponent
              type={FontAwesomeIconTypeEnum.solid}
              class={styles.helpcenter__footer__button__icon}
              icon={faQuestionCircle}
            />
            &nbsp;
            {this.$t(I18nMessages['common.faq'])}
          </AnchorButton>
          <AnchorButton
            type={AnchorButtonTypeEnum.primary}
            size={AnchorButtonSizeEnum.small}
            target='_blank'
            href='https://help.zid.sa/'
          >
            {this.$t(I18nMessages['common.go_to_help_center'])}
          </AnchorButton>
        </footer>
      </ModalComponent>
    );
  }
}
