// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d7b24896{display:flex;height:55px;border:1px solid var(--zid-colors-neutrals-200);border-radius:var(--zid-border-radius-8);align-items:center}.dbe044d2{max-height:fit-content;width:100%;padding:10px}.bbc8b08f{display:flex;justify-content:space-between;pointer-events:none;--zid-text-color: var(--zid-colors-neutrals-500)}.bbc8b08f img{pointer-events:all}.bbc8b08f img:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/Shared/FileSelect/ZidWalletFileSelect.module.scss"],"names":[],"mappings":"AAGA,UACI,YAAA,CACA,WAAA,CACA,+CAAA,CACA,wCAAA,CACA,kBAAA,CAEA,UACI,sBAAA,CACA,UAAA,CACA,YAAA,CAGJ,UACI,YAAA,CACA,6BAAA,CACA,mBAAA,CAEA,gDAAA,CAEA,cACI,kBAAA,CACA,oBACI,cAAA","sourcesContent":["@import '../../../../common/styles/mixins/rtl';\n@import '../../../../common/styles/mixins/responsiveness';\n\n.upload {\n    display: flex;\n    height: 55px;\n    border: 1px solid var(--zid-colors-neutrals-200);\n    border-radius: var(--zid-border-radius-8);\n    align-items: center;\n\n    &__content {\n        max-height: fit-content;\n        width: 100%;\n        padding: 10px;\n    }\n\n    &__file {\n        display: flex;\n        justify-content: space-between;\n        pointer-events: none;\n\n        --zid-text-color: var(--zid-colors-neutrals-500);\n\n        img {\n            pointer-events: all;\n            &:hover {\n                cursor: pointer;\n        }\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": "d7b24896",
	"upload__content": "dbe044d2",
	"upload__file": "bbc8b08f"
};
export default ___CSS_LOADER_EXPORT___;
