import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidAlert, ZidIcon, ZidText, ZidCard, ZidCardHeader, ZidCardBody, ZidBadge, ZidTooltip } from '@zidsa/ui';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidIconTypeEnum } from '../../../../common/components/ZidIcon/type.enum';
import { AnchorButton } from '../../../../common/components/AnchorButton/AnchorButton';
import { AnchorButtonSizeEnum } from '../../../../common/components/AnchorButton/size.enum';
import { AnchorButtonTypeEnum } from '../../../../common/components/AnchorButton/type.enum';
import SubscriptionPackage from '../../../../common/components/SubscriptionPackage/SubscriptionPackage.vue';
import { ButtonTypeEnum, ButtonComponent, ButtonSizeEnum } from '../../../../common/components/Button';
import UserStoreModule from '../../../../user/store/module';
import {
  DropdownBaseBodyComponent,
  DropdownBaseChangeEventInterface,
  DropdownBaseComponent,
  DropdownBaseHeaderComponent,
  DropdownBaseToggleExpandedComponent,
} from '../../../../common/components/Dropdown/Base';
import ZidIconComponent from '../../../../common/components/ZidIcon/ZidIcon';
import UserAvatar from './user-avatar.svg';
import copyIcon from './copy-icon.svg';

import styles from './UserProfile.scss';
import { ApiModelStoreMerchantRelationsInterface } from '../../../../api/model/store/merchant-relations.interface';
import isFreemiumSubscription from '../../../../routing/guards/is-freemium-subscription';
import isFeatureAvailable, { getFeaturePackage } from '../../../../common/helpers/is-feature-available';
import { ApiModelSubscriptionPackageCodeGroupEnum } from '../../../../api/model/subscription/package-code.enum';

@Component
export default class StatusBarUserProfileComponent extends Vue {
  @Prop({ default: false }) private readonly isSideMenu!: boolean;
  private isExpanded = false;
  private isStoreIdCopied = false;
  private isStoreUrlCopied = false;
  public isHovered = false;

  private get storePackageColor(): string {
    const packageCode = UserStoreModule?.data?.store.subscription.package_key ?? '';

    switch (packageCode) {
      case ApiModelSubscriptionPackageCodeGroupEnum.newProfessional:
        return '--zid-colors-blue-600';
      case ApiModelSubscriptionPackageCodeGroupEnum.enterprise:
        return '--zid-colors-neutrals-600';
      case ApiModelSubscriptionPackageCodeGroupEnum.zidLite:
        return '--zid-colors-blue-400';
      default:
        return '--zid-colors-primary-600';
    }
  }

  render(h: CreateElement): VNode {
    return (
      <DropdownBaseComponent
        isExpanded={this.isExpanded}
        onChange={this.onChangeDropdown}
        class={styles.statusbaruserprofile}
      >
        <DropdownBaseHeaderComponent expandedClass={styles['statusbaruserprofile__dropdown__header--expanded']}>
          <DropdownBaseToggleExpandedComponent class={styles.statusbaruserprofile__dropdown__button}>
            <div
              class={styles.statusbaruserprofile__dropdown__button__wrapper}
              onMouseenter={this.handleMouseEnter}
              onMouseleave={this.handleMouseLeave}
            >
              <div class={styles.statusbaruserprofile__dropdown__button__userlogobox}>
                {this.isHovered && !this.isSideMenu ? (
                  <div class={styles.statusbaruserprofile__dropdown__button__userlogobox__storebtn}>
                    <ZidTooltip>
                      <a href={this.storeUrl} target='_blank'>
                        <ZidIcon icon='eye' size='xxs' />
                      </a>
                      <template slot='tooltip'>{this.$t(I18nMessages['statusbar.view_store'])}</template>
                    </ZidTooltip>
                  </div>
                ) : (
                  <img src={this.userLogo} />
                )}
              </div>
              <span class={styles.statusbaruserprofile__dropdown__button__username}>{this.userName}</span>
              <ZidIconComponent
                type={ZidIconTypeEnum.arrow}
                class={styles.statusbaruserprofile__dropdown__header__arrow}
                style={`color: var(${this.storePackageColor})`}
              />
            </div>
          </DropdownBaseToggleExpandedComponent>
        </DropdownBaseHeaderComponent>
        <DropdownBaseBodyComponent class={styles.statusbaruserprofile__dropdown__body}>
          <div>
            <span>{this.userName}</span>
            <div class={styles.statusbaruserprofile__dropdown__body__emailsection}>{this.userEmail}</div>
            <div class={styles.statusbaruserprofile__dropdown__body__storeid}>
              {this.isStoreIdCopied ? (
                <ZidText type='primary' size='sm'>
                  {this.$t(I18nMessages['common.store_copied'])}
                </ZidText>
              ) : (
                <div>
                  {this.$t(I18nMessages['common.store_id'])} : {this.storeId}
                  <img
                    src={copyIcon}
                    alt='copy-icon'
                    onClick={(): void => this.onMakeCopy(this.storeId as number, 'isStoreIdCopied')}
                  />
                </div>
              )}
            </div>
          </div>
          <div class={styles.statusbaruserprofile__dropdown__body_links}>
            <SubscriptionPackage />
            {this.isStoreUrlCopied ? (
              <ZidAlert
                dismissible={false}
                type='primary'
                size='small'
                class={styles.statusbaruserprofile__link__copied}
              >
                <template slot={'icon'}>
                  <ZidIcon icon='link' color='primary-darken' size='12px' />
                </template>
                {this.$t(I18nMessages['common.store_copied'])}
              </ZidAlert>
            ) : (
              <a onClick={(): void => this.onMakeCopy(this.storeUrl as string, 'isStoreUrlCopied')}>
                <ZidAlert dismissible={false} type='primary' size='small' class={styles.statusbaruserprofile__link}>
                  <template slot={'icon'}>
                    <ZidIcon icon='link' color='primary-darken' size='12px' />
                  </template>
                  {this.$t(I18nMessages['sidebar.header.copy_store_url_button'])}
                </ZidAlert>
              </a>
            )}
          </div>
          <div class={styles.statusbaruserprofile__dropdown__body__settingssection}>
            {this.merchantRelations && (
              <ZidCard
                collapsible={isFeatureAvailable('merchants_relations.merchants_relations')}
                collapsed={true}
                class={styles.statusbaruserprofile__dropdown__body__settingssection__card}
              >
                <ZidCardHeader class={styles.statusbaruserprofile__dropdown__body__settingssection__card__header}>
                  <ZidIcon
                    icon='account_circle_outline'
                    color='primary'
                    size='xxs'
                    class={styles.statusbaruserprofile__dropdown__body__icon}
                  />
                  <span class={styles.statusbaruserprofile__dropdown__body__relation__span}>
                    {this.$t(I18nMessages['common.account.merchant_relations'])}
                  </span>
                  {!isFeatureAvailable('merchants_relations.merchants_relations') ? (
                    <ZidBadge isUpgrade style={'margin-inline-start: auto;'}>
                      {getFeaturePackage('merchants_relations.merchants_relations')}
                    </ZidBadge>
                  ) : (
                    <span class={styles.statusbaruserprofile__dropdown__body__settingssection__card__header__name}>
                      {this.merchantRelations.name}
                    </span>
                  )}
                </ZidCardHeader>
                {isFeatureAvailable('merchants_relations.merchants_relations') ? (
                  <ZidCardBody class={styles.statusbaruserprofile__dropdown__body__settingssection__card__body}>
                    <ZidAlert
                      dismissible={false}
                      type='light'
                      size='small'
                      class={styles.statusbaruserprofile__dropdown__body__settingssection__card__body__alert}
                    >
                      <template slot={'icon'}>&nbsp;</template>
                      <a href={`tel:${this.merchantRelations['phone_number']}`} target='_blank'>
                        {this.merchantRelations['phone_number']}
                      </a>
                      <ZidIcon icon='mobile_outline' color='dark' size='xxs' />
                    </ZidAlert>
                    <ZidAlert
                      dismissible={false}
                      type='light'
                      size='small'
                      class={styles.statusbaruserprofile__dropdown__body__settingssection__card__body__alert}
                    >
                      <template slot={'icon'}>&nbsp;</template>
                      <a href={`mailto:${this.merchantRelations.email}`} target='_blank'>
                        {this.merchantRelations.email}
                      </a>
                      <span
                        class={styles.statusbaruserprofile__dropdown__body__settingssection__card__body__alert__icon}
                      >
                        @
                      </span>
                    </ZidAlert>
                  </ZidCardBody>
                ) : (
                  ''
                )}
              </ZidCard>
            )}
            {!isFreemiumSubscription() ? (
              <AnchorButton
                type={AnchorButtonTypeEnum.link}
                size={AnchorButtonSizeEnum.small}
                href='/account/settings/merchant'
              >
                <ZidIcon
                  icon='settings'
                  color='primary'
                  size='xxs'
                  class={styles.statusbaruserprofile__dropdown__body__icon}
                />
                {this.$t(I18nMessages['common.account_settings'])}
              </AnchorButton>
            ) : null}
            {!window.__INITIAL_STATE__.isAppWebViewUnderReview && !isFreemiumSubscription() ? (
              <AnchorButton type={AnchorButtonTypeEnum.link} size={AnchorButtonSizeEnum.small} href='/subscriptions'>
                <ZidIcon
                  icon='subscription'
                  color='primary'
                  size='xxs'
                  class={styles.statusbaruserprofile__dropdown__body__icon}
                />
                {this.$t(I18nMessages['common.manage_subscription'])}
              </AnchorButton>
            ) : null}
          </div>
          <div class={styles.statusbaruserprofile__dropdown__body__logoutsection}>
            <ButtonComponent
              type={ButtonTypeEnum.link}
              size={ButtonSizeEnum.small}
              onClick={this.logout}
              class={styles.statusbaruserprofile__logoutbutton}
            >
              <ZidIcon
                icon='logout'
                color='danger'
                size='xxs'
                class={styles.statusbaruserprofile__dropdown__body__icon}
              />
              {this.$t(I18nMessages['statusbar.logout_button'])}
            </ButtonComponent>
          </div>
        </DropdownBaseBodyComponent>
      </DropdownBaseComponent>
    );
  }

  private get userName() {
    return UserStoreModule?.data?.name;
  }

  private get userEmail() {
    return UserStoreModule?.data?.email;
  }

  private get storeUrl(): string {
    return UserStoreModule?.data?.store.url || '#';
  }

  private get storeId(): number | undefined {
    return UserStoreModule?.data?.store.id;
  }

  private get merchantRelations(): ApiModelStoreMerchantRelationsInterface | null | undefined {
    return UserStoreModule?.data?.store?.merchant_relations;
  }

  private get userLogo(): string | undefined {
    return UserStoreModule.data?.store.logo || UserAvatar;
  }

  private logout(): void {
    UserStoreModule.logout();
  }

  private onChangeDropdown(event: DropdownBaseChangeEventInterface): void {
    this.isExpanded = event.state.isExpanded;
  }

  private onMakeCopy(dataToCopy: string | number, status: string): void {
    navigator.clipboard.writeText(dataToCopy.toString());

    this.$data[status] = true;
    setTimeout(() => {
      this.$data[status] = false;
    }, 1000);
  }

  private handleMouseEnter(): void {
    this.isHovered = true;
  }

  private handleMouseLeave(): void {
    this.isHovered = false;
  }
}
