export enum LoginStepsEnum {
  SelectLoginOptions = 'SELECT_LOGIN_OPTIONS',
  EmailLogin = 'EMAIL_LOGIN',
  OtpVerify = 'OTP_VERIFY',
}

export enum SocialLoginOptionEnum {
  email = `EMAIL`,
  instagram = `INSTAGRAM`,
  google = `GOOGLE`,
}

export enum LoginActionTypeEnum {
  Login = 'login',
  Register = 'register',
}
