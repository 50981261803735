import { OrdersApiSalesReportFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../api/types';
import { OrdersApiSalesReportSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../common/helpers/object/filter';

export function ordersApiSalesReportFiltersToSearchParams(
  filters: OrdersApiSalesReportFiltersInterface,
): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: OrdersApiSalesReportSearchParamsInterface = {
    orders: filters.orderIds?.map((orderId) => orderId.toString()),
    statuses: filters.orderStatuses,
    start_date: filters.dateFrom,
    end_date: filters.dateTo,
    email: filters.email,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
