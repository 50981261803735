import isRTL from '../../../../common/helpers/is-lang-arabic';
import imgs from '../../../assets/imgs';
import { ActivationProcess, CommonQuestions, FeatureList, Requirements } from '../../../types/MarketingPage.interface';
import { PackagesEnum } from '../../../types/Packages.enum';
import { ZidpayCountriesHelpersBase } from '../../shared/helpers/helpers';

export class ZidpayCountriesHelpersSA extends ZidpayCountriesHelpersBase {
  static setCurrentPackage(packageId: string): PackagesEnum {
    let current: PackagesEnum = PackagesEnum.basic; // PackageId = 108, default
    switch (packageId) {
      case '109':
        current = PackagesEnum.growth;
        break;
      case '110':
        current = PackagesEnum.enterprise;
        break;
      case '111':
        current = PackagesEnum.professional;
        break;
      case '112':
        current = PackagesEnum.special;
        break;
      case '113':
        current = PackagesEnum.starter;
        break;
      case '114':
        current = PackagesEnum.ngo;
        break;
      default:
        break;
    }
    return current;
  }
  static getActivationProcess(): Array<ActivationProcess> {
    return [
      {
        icon: imgs.CameraHighlighted,
        number: '01',
        title: 'zidpay.marketing.activation.process.header.send.header',
        content: ['zidpay.marketing.activation.process.header.send.body'],
        arrow: imgs.ArrowHighlighted,
      },
      {
        icon: imgs.Nafath,
        number: '02',
        title: 'zidpay.marketing.activation.process.header.nafath.header',
        content: ['zidpay.marketing.activation.process.header.nafath.body'],
        arrow: imgs.Arrow,
      },
      {
        icon: imgs.Payments,
        number: '03',
        title: 'zidpay.marketing.activation.process.header.payments.header',
        content: ['zidpay.marketing.activation.process.header.payments.body'],
        arrow: imgs.Arrow,
      },
      {
        icon: imgs.Transactions,
        number: '04',
        title: 'zidpay.marketing.activation.process.header.transactions.header',
        content: ['zidpay.marketing.activation.process.header.transactions.body'],
      },
    ];
  }
  static getFeatureList(): Array<FeatureList> {
    return [
      {
        icon: imgs.DoubleCheck,
        title: 'zidpay.marketing.features.activate',
        content: [
          'zidpay.marketing.features.activate.click',
          'zidpay.marketing.features.activate.instant',
          'zidpay.marketing.features.activate.networks',
        ],
      },
      {
        icon: imgs.Dollar,
        title: 'zidpay.marketing.features.prices',
        content: [
          'zidpay.marketing.features.prices.no.fees',
          'zidpay.marketing.features.prices.no.fees.monthly',
          'zidpay.marketing.features.prices.no.fees.refund',
        ],
      },
      {
        icon: imgs.PaymentLink,
        title: 'zidpay.marketing.features.paymentlinks',
        content: [
          'zidpay.marketing.features.paymentlinks.share',
          'zidpay.marketing.features.paymentlinks.send',
          'zidpay.marketing.features.paymentlinks.follow',
        ],
      },
      {
        icon: imgs.Monitor,
        title: 'zidpay.marketing.features.dashboard',
        content: [
          'zidpay.marketing.features.dashboard.follow',
          'zidpay.marketing.features.dashboard.balance',
          'zidpay.marketing.features.dashboard.orders',
        ],
      },
      {
        icon: imgs.Phone,
        title: 'zidpay.marketing.features.collect',
        content: [
          'zidpay.marketing.features.collect.interval',
          'zidpay.marketing.features.collect.min',
          'zidpay.marketing.features.collect.fees',
        ],
      },
      {
        icon: imgs.Card,
        title: 'zidpay.marketing.features.networks',
        content: [
          'zidpay.marketing.features.networks.mada',
          'zidpay.marketing.features.networks.visa.mastercard',
          'zidpay.marketing.features.networks.applepay',
          'zidpay.marketing.features.networks.american',
        ],
      },
    ];
  }
  static getRequirements(): Array<Requirements> {
    return [
      {
        icon: imgs.Individuals,
        title: 'zidpay.marketing.requirements.individual',
        content: [
          'zidpay.marketing.requirements.individual.id',
          'zidpay.marketing.requirements.individual.freelance',
          'zidpay.marketing.requirements.individual.address',
          'zidpay.marketing.requirements.individual.bank',
        ],
      },
      {
        icon: imgs.Company,
        title: 'zidpay.marketing.requirements.company',
        content: [
          'zidpay.marketing.requirements.company.id',
          'zidpay.marketing.requirements.company.only',
          'zidpay.marketing.requirements.company.cr',
          'zidpay.marketing.requirements.company.bank',
          'zidpay.marketing.requirements.company.cert',
        ],
      },
      {
        icon: imgs.Charity,
        title: 'zidpay.marketing.requirements.charity',
        content: [
          'zidpay.marketing.requirements.charity.id',
          'zidpay.marketing.requirements.charity.cert',
          'zidpay.marketing.requirements.charity.main',
          'zidpay.marketing.requirements.charity.board',
          'zidpay.marketing.requirements.charity.bank',
        ],
      },
    ];
  }
  static getCommonQuestions(): Array<CommonQuestions> {
    return [
      { title: 'zidpay.marketing.questions.sa.one', answer: 'zidpay.marketing.questions.sa.one.answer' },
      { title: 'zidpay.marketing.questions.sa.two', answer: 'zidpay.marketing.questions.sa.two.answer' },
      { title: 'zidpay.marketing.questions.sa.three', answer: 'zidpay.marketing.questions.sa.three.answer' },
      { title: 'zidpay.marketing.questions.sa.four', answer: 'zidpay.marketing.questions.sa.four.answer' },
      {
        title: 'zidpay.marketing.questions.sa.five',
        answer: 'zidpay.marketing.questions.sa.five.answer',
        link: 'https://help.zid.sa/bank-certificate/',
      },
      {
        title: 'zidpay.marketing.questions.sa.six',
        answer: 'zidpay.marketing.questions.sa.six.answer',
        link: 'https://help.zid.sa/commercial-activity/',
      },
      {
        title: 'zidpay.marketing.questions.sa.seven',
        answer: 'zidpay.marketing.questions.sa.seven.answer',
        link: 'https://help.zid.sa/freelance-certificate/ ',
      },
    ];
  }
  static getHeaderImg(): string {
    return isRTL() ? imgs.WomanAR : imgs.WomanEN;
  }
}
