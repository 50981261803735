import ApiClient from '../../api/client';
import { DashboardApiDurationEnum } from './duration.enum';
/* import { ApiBaseResponseType } from '../../api/types'; */
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { dashboardApiUrls } from './urls';
import { ApiModelDashboardSummaryInterface } from '../../api/model/dashboard/summary.interface';
import { ApiModelDashboardProductInterface } from './../../api/model/dashboard/product.interface';
import { ApiModelDashboardRatingsInterface } from './../../api/model/dashboard/ratings.interface';
import { DashboardProductReportTypeEnum } from '../../api/model/dashboard/product_report_type.enum';
import ApiModelIndustryInterface from '../../api/model/industry.interface';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';

export class DashboardApiService {
  constructor(private readonly apiClient: ApiClient) {}

  async getSummary(
    duration: DashboardApiDurationEnum,
    isOnboarding?: boolean,
  ): Promise<ApiResponseType<ApiModelDashboardSummaryInterface>> {
    let url: string = dashboardApiUrls.summary.replace('{duration}', duration);

    url = url.replace('{is_onboarding}', isOnboarding ? '1' : '0');

    const response = await this.apiClient.request<ApiModelDashboardSummaryInterface>(ApiHttpMethodEnum.get, url, {
      cacheable: true,
    });

    return response;
  }

  async getRatings(): Promise<ApiModelDashboardRatingsInterface> {
    const url = dashboardApiUrls.ratings;

    const response = await this.apiClient.request<ApiModelDashboardRatingsInterface>(ApiHttpMethodEnum.get, url);

    return response.data;
  }

  public async getIndustries(): Promise<ApiBaseDataResponseObjectType<Array<ApiModelIndustryInterface>>> {
    const url = dashboardApiUrls.industries;

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<Array<ApiModelIndustryInterface>>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }

  public async confirmStoreActivity(
    // eslint-disable-next-line @typescript-eslint/camelcase
    store_industry_id: number | undefined,
    // eslint-disable-next-line @typescript-eslint/camelcase
    store_category_id: number | undefined,
    // eslint-disable-next-line @typescript-eslint/camelcase
    store_category_other: string,
  ): Promise<any> {
    const url = dashboardApiUrls.confirmStoreActivity;

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        store_industry_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        store_category_id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        store_category_other,
      },
    });
    return response.data;
  }

  async getProducts(
    duration: DashboardApiDurationEnum,
    type: DashboardProductReportTypeEnum,
  ): Promise<Array<ApiModelDashboardProductInterface>> {
    const url = dashboardApiUrls.products.replace('{type}', type).replace('{duration}', duration);

    const response = await this.apiClient.request<Array<ApiModelDashboardProductInterface>>(ApiHttpMethodEnum.get, url);

    return response.data;
  }
}
