import { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { EmbeddedCreditCardMixin } from './emdedded-credit-card-mixin';

import styles from './EmbeddedCreditCard.scss';

@Component
export class SubscriptionComponentsEmbeddedCreditCardComponent extends EmbeddedCreditCardMixin {
  render(h: CreateElement): VNode {
    return (
      <div class='payment-methods-empty other-box-content px-15' style='tapFormStyle'>
        <slot name='header'></slot>
        <form id='form-container' class={styles.embeddedcreditcard__form}>
          <div id='root'></div>
          {this.creditCardError ? (
            <div id='error-handler' role='alert' class={styles.embeddedcreditcard__errorhanlder}>
              {this.creditCardError}
            </div>
          ) : null}
        </form>
        <div class={['payment-methods-form']}>
          <slot></slot>
          <p id='msg'></p>
        </div>
      </div>
    );
  }
}
