// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ae69c5a0{--zid-modal--title--text-align: start;--zid-modal--title--color: var(--zid-colors-primary-600)}.ae69c5a0 main{padding:10px}.da194ba3{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}.bbe186a3 img{margin-bottom:10px;padding-right:10px;padding-left:0}html[dir=rtl] .bbe186a3 img{padding-right:0;padding-left:10px}.bbe186a3 p{font-size:var(--zid-font-size--sm)}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/WalletStatus/WalletStatus.scss","webpack://./src/common/styles/mixins/_rtl.scss"],"names":[],"mappings":"AAEA,UACE,qCAAA,CACA,wDAAA,CAEA,eACE,YAAA,CAEF,UACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAEE,cACE,kBAAA,CACA,kBAAA,CACA,cAAA,CClBN,4BDoBQ,eAAA,CACA,iBAAA,CAGJ,YACE,kCAAA","sourcesContent":["@import '../../../common/styles/mixins/_rtl';\n\n.wallet-status-modal {\n  --zid-modal--title--text-align: start;\n  --zid-modal--title--color: var(--zid-colors-primary-600);\n\n  main {\n    padding: 10px;\n  }\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    &__info {\n      img {\n        margin-bottom: 10px;\n        padding-right: 10px;\n        padding-left: 0;\n        @include rtl {\n          padding-right: 0;\n          padding-left: 10px;\n        }\n      }\n      p {\n        font-size: var(--zid-font-size--sm);\n      }\n    }\n  }\n}\n","@mixin rtl {\n  html[dir='rtl'] & {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wallet-status-modal": "ae69c5a0",
	"wallet-status-modal__container": "da194ba3",
	"wallet-status-modal__container__info": "bbe186a3"
};
export default ___CSS_LOADER_EXPORT___;
