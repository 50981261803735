import { WalletInputValues } from '../types/Wallet/input-values';

export const WALLET_SUGGESTED_VALUES: Array<WalletInputValues> = [
  {
    value: '100',
    selected: false,
  },
  {
    value: '500',
    selected: false,
  },
  {
    value: '1000',
    selected: false,
  },
  {
    value: '5000',
    selected: false,
  },
];

export const WALLET_WITHDRAWAL_FEE_THRESHOLD = 20000;
