import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Provide, Watch } from 'vue-property-decorator';
import { findVueNodeByComponent } from '../../../helpers/find-vue-node-by-component';
import { ClickOutsideHandlerComponent } from '../../ClickOutsideHandler/ClickOutsideHandler';
import { DropdownBaseBodyComponent } from './Body/DropdownBaseBody';
import { DropdownBaseHeaderComponent } from './Header/DropdownBaseHeader';
import { dropdownBaseStateSymbol } from './state-symbol';
import { DropdownBaseStateInterface } from './state.interface';
import { dropdownBaseToggleExpandedSymbol } from './toggle-expanded-symbol';
import { DropdownBaseChangeEventInterface } from './change-event.interface';

import styles from './DropdownBase.scss';

@Component
export class DropdownBaseComponent extends Vue {
  @Prop({
    default: false,
  })
  private readonly isExpanded!: boolean;

  @Prop({
    default: false,
  })
  private readonly isDisabled!: boolean;

  @Prop({
    default: true,
  })
  private readonly isExpandable!: boolean;

  @Prop({
    default: false,
  })
  private readonly allowClickOutside!: boolean;

  @Provide(dropdownBaseStateSymbol)
  public readonly state: DropdownBaseStateInterface = {
    isExpanded: this.isExpanded,
    isDisabled: this.isDisabled,
  };

  render(h: CreateElement): VNode {
    return (
      <ClickOutsideHandlerComponent onClickOutside={this.onClickOutside}>
        <div class={styles.dropdownbase}>
          {this.renderHeader()}
          {this.renderBody()}
        </div>
      </ClickOutsideHandlerComponent>
    );
  }

  private renderHeader(): VNode | null {
    if (!this.$slots.default) {
      return null;
    }

    return findVueNodeByComponent(this.$slots.default, DropdownBaseHeaderComponent);
  }

  private renderBody(): VNode | null {
    if (!this.$slots.default) {
      return null;
    }

    return findVueNodeByComponent(this.$slots.default, DropdownBaseBodyComponent);
  }

  @Watch('isExpanded')
  protected onChangeIsExpaded(value: boolean) {
    this.state.isExpanded = value;
  }

  @Watch('disabled')
  protected onChangeIsDisabled(value: boolean) {
    this.state.isDisabled = value;
    this.state.isExpanded = false;
  }

  @Provide(dropdownBaseToggleExpandedSymbol)
  protected onToggleExpanded(): void {
    if (this.isDisabled || !this.isExpandable) {
      return;
    }

    this.state.isExpanded = !this.state.isExpanded;
    this.onChange();
  }

  private onClickOutside(event: MouseEvent): void {
    if (this.allowClickOutside) return;
    if ((event.target as Element).classList.contains('zid-combo-item')) return;
    this.state.isExpanded = false;
    this.onChange();
  }

  private onChange(): void {
    const event: DropdownBaseChangeEventInterface = {
      state: this.state,
    };

    this.$emit('change', event);
  }
}
