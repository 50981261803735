import {
  ZidCheckbox,
  ZidCol,
  ZidContainer,
  ZidFileSelector,
  ZidHeading,
  ZidIcon,
  ZidInput,
  ZidInputGroup,
  ZidInputLeading,
  ZidInputTrailing,
  ZidRow,
  ZidText,
} from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import styles from './CorporateSection.module.scss';
import { InputCheckboxChangeEventInterface } from '../../../../common/components/Input/Checkbox/change-event.interface';
import { InputTextChangeEventInterface } from '../../../../common/components/Input';
import { ZidWalletStoreModule } from '../../../store/wallet.module';
import { VerifyWalletComponentsCountryKeySelectComponent } from '../../CountryKeySelect/CountryKeySelect';
import COUNTRY_CODES from '../../../api/country_codes';
import { ZidWalletComponentsFileSelectComponent } from '../../Shared/FileSelect/ZidWalletFileSelect';

@Component
export class VerifyWalletComponentsCorporateSectionComponent extends Vue {
  @Prop()
  private readonly isLoading!: boolean;
  @Prop()
  private readonly submitClicked!: boolean;

  private get commercialRegisterId(): string {
    return !this.isLoading && ZidWalletStoreModule.verificationData?.businessInfo?.commercialRegistrationNumber
      ? ZidWalletStoreModule.verificationData.businessInfo.commercialRegistrationNumber
      : '';
  }

  private get managerPhoneNumber(): string {
    return ZidWalletStoreModule.data?.managerPhoneNumber ? ZidWalletStoreModule.data.managerPhoneNumber : '';
  }

  private get managerMobileWithoutKey(): string | undefined {
    let value: string | undefined;
    const countryKeysToCheck = ['+20', '+33', '+40'];
    if (countryKeysToCheck.some((code) => this.managerMobile?.startsWith(code))) {
      value = this.managerMobile?.substring(3);
    } else if (this.managerMobile?.startsWith('+1')) {
      value = this.managerMobile?.substring(2);
    } else {
      value = this.managerMobile?.substring(4);
    }

    return value;
  }

  private get commercialRegisterPhoto(): File[] {
    return ZidWalletStoreModule.verificationData?.businessInfo?.commercialRegistrationPhoto
      ? [
          new File([], ZidWalletStoreModule.verificationData.businessInfo?.commercialRegistrationPhoto, {
            type: 'image/*',
          }),
        ]
      : [];
  }

  private managerMobile = this.managerPhoneNumber;

  private get isManagerStoreOwner(): boolean {
    return ZidWalletStoreModule.isManagerOwner;
  }

  private get countryKeys(): Array<string> {
    return Array.from(new Set(COUNTRY_CODES.sort((a: any, b: any) => a.key - b.key).map((country) => country.key)));
  }

  private countryKey = this.countryKeys[2];

  private fileName = '';

  render(h: CreateElement): VNode {
    return (
      <ZidContainer class={styles['verify-corporate']}>
        <ZidRow cols-lg={2} class={styles['verify-corporate--row']}>
          <ZidCol
            class={{
              [styles['verify-corporate__file-select']]: true,
              [styles['verify-corporate__file-select--selected']]: this.commercialRegisterPhoto,
            }}
          >
            <ZidHeading id={'cr-photo-heading'} level={6}>
              {this.$t(I18nMessages['wallet.commercial_register_certification'])}
            </ZidHeading>
            {this.commercialRegisterPhoto.length ? (
              <ZidWalletComponentsFileSelectComponent
                file={this.commercialRegisterPhoto}
                fileName={this.fileName}
                defaultFileName={I18nMessages['wallet.common.cr_photo.file']}
                onHandleImageUpload={this.handleImageUpload}
                onRemoveFiles={this.onRemoveFilesFieldChange}
              />
            ) : (
              <div>
                <div class={styles['verify-corporate__file-select--placeholder']}>
                  <ZidText>{this.$t(I18nMessages['wallet.id_photo.select.placeholder'])}</ZidText>
                </div>
                <ZidFileSelector
                  data-identifier={'cr_photo_file_select'}
                  accept={'image/*'}
                  onChange={this.handleImageUpload}
                  onRemove={this.onRemoveFilesFieldChange}
                  class={{
                    [styles['verify-corporate__file-select--empty']]:
                      this.submitClicked && !this.commercialRegisterPhoto?.length,
                  }}
                />
              </div>
            )}
          </ZidCol>
          <ZidCol>
            <ZidHeading id={'cr-number-heading'} level={6}>
              {this.$t(I18nMessages['wallet.commercial_number'])}
            </ZidHeading>
            <ZidInputGroup>
              <ZidInputLeading isInner>
                <ZidIcon icon={'subscription'} size={'xxs'} />
              </ZidInputLeading>
              <ZidInput
                data-identifier={'cr_number_input'}
                validation={'minLength:8|maxLength:12|required'}
                value={this.commercialRegisterId}
                onChange={(event: InputTextChangeEventInterface): void => this.onChangeCommercialId(event.value)}
                placeholder={this.$t(I18nMessages['wallet.verify.cr_number.placeholder'])}
              />
            </ZidInputGroup>
          </ZidCol>
        </ZidRow>
        <ZidRow cols={2}>
          <ZidCol class={styles['verify-corporate__manager-mobile--row']}>
            <div style={this.isManagerStoreOwner ? 'display: none' : undefined}>
              <ZidHeading id={'manager-mobile-heading'} level={6} class={styles['verify-corporate__heading']}>
                {this.$t(I18nMessages['wallet.zid_wallet_manager.mobile'])}
              </ZidHeading>
              <div class={styles['verify-corporate__manager-mobile']}>
                <ZidInputGroup>
                  <ZidInputLeading isInner>
                    <ZidIcon icon={'phone'} size={'xxs'} />
                  </ZidInputLeading>
                  <ZidInput
                    data-identifier={'manager_mobile_input'}
                    type={'number'}
                    validation={'maxLength:13|required'}
                    value={this.managerMobile ? this.managerMobileWithoutKey : ''}
                    onChange={(event: InputTextChangeEventInterface): void => this.onManagerMobileInput(event.value)}
                    placeholder={this.$t(I18nMessages['account.register.mobile.placeholder'])}
                  />
                  <ZidInputTrailing>
                    <VerifyWalletComponentsCountryKeySelectComponent
                      userMobile={this.managerPhoneNumber}
                      onCountryKeyChange={this.onCountryKeyChange}
                    />
                  </ZidInputTrailing>
                </ZidInputGroup>
              </div>
            </div>
            <ZidCheckbox
              data-identifier={'is_manager_owner_checkbox'}
              isChecked={this.isManagerStoreOwner}
              class={styles['verify-corporate__manager-checkbox']}
              onChange={(event: InputCheckboxChangeEventInterface): void => this.onManagerIsOwnerCheck(event.isChecked)}
            >
              <ZidText id={'is-manager-owner-text'} size={'sm'} weight={'semibold'}>
                {this.$t(I18nMessages['wallet.zid_wallet_manager.is_owner'])}
              </ZidText>
            </ZidCheckbox>
          </ZidCol>
        </ZidRow>
      </ZidContainer>
    );
  }

  private onCountryKeyChange(value: string): void {
    this.countryKey = value;
    if (this.managerMobileWithoutKey) {
      this.managerMobile = `${this.countryKey}${this.managerMobileWithoutKey}`;
    }
    this.$emit('setManagerMobile', this.managerMobile);
  }

  private async handleImageUpload(file: File[]): Promise<void> {
    const reader = new FileReader();
    if (file && file.length) {
      this.fileName = file[0].name;
      if (this.fileName.length > 20) {
        this.fileName = this.fileName.substring(20);
      }

      reader.readAsDataURL(file[0]);
      reader.onload = (): void => {
        ZidWalletStoreModule.setBusinessData({
          ...ZidWalletStoreModule.verificationData.businessInfo,
          commercialRegistrationPhoto: reader.result as string,
        });
      };
    }
  }

  private onChangeCommercialId(value: string): void {
    ZidWalletStoreModule.setBusinessData({
      ...ZidWalletStoreModule.verificationData.businessInfo,
      commercialRegistrationNumber: value,
    });
  }

  private onRemoveFilesFieldChange(): void {
    ZidWalletStoreModule.setBusinessData({
      ...ZidWalletStoreModule.verificationData.businessInfo,
      commercialRegistrationPhoto: '',
    });
  }

  private onManagerIsOwnerCheck(value: boolean): void {
    ZidWalletStoreModule.setManagerOwnerBool(value);
    this.managerMobile = '';
    this.$emit('setManagerMobile', '');
  }

  private onManagerMobileInput(value: string): void {
    this.managerMobile = `${this.countryKey}${value}`;
    this.$emit('setManagerMobile', this.managerMobile);
  }
}
