export const enum ApiModelProductClassTypeEnum {
  crowdfunding = 'crowdfunding_project',
  donationItem = 'donation_item',
  voucherItem = 'voucher',
  defaultItem = 'default',
  groupedProduct = 'grouped_product',
  downloadable = 'downloadable',
}

export const enum ApiModelProductTypeEnum {
  physical = 'physical',
  digital = 'digital',
  grouped = 'grouped',
}
