// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bd03040c{display:flex;flex-direction:column;align-items:center;text-align:center;--zid-modal-max-width: 650px;--zid-modal--title--text-align: start;--zid-modal--title--color: var(--zid-colors-primary-600)}.bd03040c main{padding:var(--zid-spacing-20)}.c25c1fb8 img{margin-bottom:10px}.c25c1fb8 p{font-size:var(--zid-font-size--sm)}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/AddFundsModal/LoaderModal/LoaderModal.module.scss"],"names":[],"mappings":"AAAA,UACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,4BAAA,CACA,qCAAA,CACA,wDAAA,CACA,eACI,6BAAA,CAIA,cACI,kBAAA,CAGJ,YACI,kCAAA","sourcesContent":[".loader-modal {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    --zid-modal-max-width: 650px;\n    --zid-modal--title--text-align: start;\n    --zid-modal--title--color: var(--zid-colors-primary-600);\n    main {\n        padding: var(--zid-spacing-20);\n    }\n\n    &--content {\n        img {\n            margin-bottom: 10px;\n        }\n        \n        p {\n            font-size: var(--zid-font-size--sm);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-modal": "bd03040c",
	"loader-modal--content": "c25c1fb8"
};
export default ___CSS_LOADER_EXPORT___;
