import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import ApiModelUserInterface from '../../api/model/user.interface';
import {
  ApiBaseDataResponseObjectType,
  ApiBaseDataStatusMessageResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiResponseType,
  ApiStatusResponseObjectType,
} from '../../api/types';
import { urlQueryFormat } from '../../common/helpers/url/query/format';
import {
  ApiAuthLoginDataInterface,
  ApiLoginDataInterface,
  ApiSubmitOtpDataInterface,
  ApiValidateEmailAuthInterface,
  loginResponseData,
} from './model/login-data.interface';
import {
  ApiRegisterDataInterface,
  ApiRegisterWizardInterface,
  BusinessTypeInterface,
} from './types/register_data.interface';
import { accountAuthUrls } from './urls';
import { ApiNewPasswordDataInterface } from './types/new-password-data.interface';
import { NewMerchantStepsListSettingsInterface } from '../../home/types/wizard-rules-interface';
import { LoginActionTypeEnum } from './enums/login-data.enum';

export class AccountAuthApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async validateAuthByEmail({
    data,
    extra,
  }: {
    data: ApiValidateEmailAuthInterface;
    extra?: any;
  }): Promise<ApiBaseDataResponseObjectType<ApiAuthLoginDataInterface> & { message: string }> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiAuthLoginDataInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        accountAuthUrls.validateAuthByEmail,
        {
          data: { ...data, ...extra },
        },
      )
      .then((response) => {
        return response.data;
      });
  }
  public async validateLogin(
    data: ApiLoginDataInterface,
  ): Promise<ApiBaseDataStatusMessageResponseObjectType<loginResponseData>> {
    return this.apiClient
      .request<ApiBaseDataStatusMessageResponseObjectType<loginResponseData>>(
        ApiHttpMethodEnum.post,
        accountAuthUrls.validateLogin,
        {
          data,
        },
      )
      .then((response) => {
        return response.data;
      });
  }

  public async validateResetEmail(form: { email: string }): Promise<ApiStatusResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiLoginDataInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        accountAuthUrls.reset,
        {
          data: {
            email: form.email,
          },
        },
      )
      .then((response) => {
        return response.data;
      });
  }

  public async getRegistered(registerId: string): Promise<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>> {
    const url = accountAuthUrls.registerDetails.replace('{registerId}', registerId.toString());
    const params = urlQueryFormat({ registerId });
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>>(ApiHttpMethodEnum.get, url, {
        params,
      })
      .then((response) => response.data);
  }

  public async getWizardId(wizardId: string): Promise<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>> {
    const url = accountAuthUrls.registerDetails.replace('{wizardId}', wizardId.toString());
    const params = urlQueryFormat({ wizardId });
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>>(ApiHttpMethodEnum.get, url, {
        params,
      })
      .then((response) => response.data);
  }

  public async submitOtp({
    data,
    extra,
  }: {
    data: ApiSubmitOtpDataInterface;
    extra: any;
  }): Promise<ApiStatusResponseObjectType> {
    const url = data.action === LoginActionTypeEnum.Register ? accountAuthUrls.registerOtp : accountAuthUrls.loginOtp;
    return this.apiClient
      .request<ApiStatusResponseObjectType>(ApiHttpMethodEnum.post, url, {
        data: { ...data, ...extra },
      })
      .then((result) => result.data);
  }

  public async submitRegisterWizardBusinessData(
    data: ApiRegisterWizardInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiStatusResponseObjectType>> {
    const url = accountAuthUrls.registerWizardBusiness;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.post, url, {
        data: data,
      })
      .then((response) => response.data);
  }

  public async sendOTP(phone_number: string): Promise<ApiStatusResponseObjectType> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiRegisterDataInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        accountAuthUrls.resend,
        { data: { phone_number } },
      )
      .then((result) => result.data);
  }

  // public async updateMobileNumber():

  public async updatePersonalData(
    id: string,
    data: ApiRegisterDataInterface,
  ): Promise<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>> {
    const url = accountAuthUrls.registerUpdatePersonalData.replace('{registerId}', id.toString());
    const params = urlQueryFormat({ ...data });
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiRegisterDataInterface>>(ApiHttpMethodEnum.put, url, {
        params,
      })
      .then((response) => response.data);
  }

  public async updatePhoneNumber(mobile: string): Promise<ApiBaseDataResponseObjectType<ApiStatusResponseObjectType>> {
    const url = accountAuthUrls.updateMobile;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.post, url, {
        data: {
          mobile,
        },
      })
      .then((response) => response.data);
  }

  public async getUser(): Promise<ApiBaseDataResponseObjectType<ApiModelUserInterface>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.get, accountAuthUrls.user)
      .then((response) => response.data);
  }

  public async getStoreEcommerceExperience(): Promise<ApiBaseDataResponseObjectType<any>> {
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<any>>(ApiHttpMethodEnum.get, accountAuthUrls.ecommerceExperience)
      .then((response) => response.data);
  }

  public async newPassword(data: ApiNewPasswordDataInterface): Promise<ApiStatusResponseObjectType> {
    const url = accountAuthUrls.resetNewPassword;
    return this.apiClient
      .request<ApiBaseDataResponseObjectType<ApiLoginDataInterface> & { message: string }>(
        ApiHttpMethodEnum.post,
        url,
        {
          data,
        },
      )
      .then((response) => response.data);
  }

  public async createRecommendedStepsList(): Promise<ApiResponseType<NewMerchantStepsListSettingsInterface>> {
    const response = await this.apiClient.request<NewMerchantStepsListSettingsInterface>(
      ApiHttpMethodEnum.post,
      accountAuthUrls.createRecommendedStepsList,
    );

    return response;
  }

  public async validateMobileNumber(mobile: string): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      accountAuthUrls.validateMobileNumber,
      {
        data: { mobile },
      },
    );

    return response.data;
  }

  public async verifyMobileOtp(mobile: string, code: string): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      accountAuthUrls.verifyMobileOtp,
      {
        data: { mobile, code },
      },
    );

    return response.data;
  }

  public async getBusinessType(): Promise<{ status: string; business_types: BusinessTypeInterface[] }> {
    return this.apiClient
      .request<{ status: string; business_types: BusinessTypeInterface[] }>(
        ApiHttpMethodEnum.get,
        accountAuthUrls.getBusinessType,
      )
      .then((response) => response.data);
  }
}
