import ApiModelStoreCategoryEnum from '../../../api/model/store/store-category.enum';
import UserStoreModule from '../../../user/store/module';

export default function isCharitableDonationStore(): boolean {
  const slug = UserStoreModule?.data?.store?.category?.slug;
  return (
    slug === ApiModelStoreCategoryEnum.charitableOrgDonationInitiative ||
    slug === ApiModelStoreCategoryEnum.charitableOrg
  );
}
