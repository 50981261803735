import { CommonHelpersTriggerGTMEventsNamesEnum } from './events-names.enums';

export default function helpersTriggerGTMEventAction(
  eventName: CommonHelpersTriggerGTMEventsNamesEnum,
  eventBody = {},
): void {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventBody,
    [CommonHelpersTriggerGTMEventsNamesEnum.timeStamp]: new Date().toUTCString(),
  });
}
