// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f9313e66 span{border-radius:var(--zid-border-radius-4)}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/AddFundsModal/CreditSection/CreditSection.module.scss"],"names":[],"mappings":"AAEI,eACE,wCAAA","sourcesContent":[".credit-section {\n  &__checkbox {\n    span {\n      border-radius: var(--zid-border-radius-4);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"credit-section__checkbox": "f9313e66"
};
export default ___CSS_LOADER_EXPORT___;
