export interface ZidWalletInterfaceWalletInterface {
  //NOTE: gRPC turns grpc backend's snake_case into camelCase
  id: string;
  storeId: string;
  storeName: string;
  storeUrl: string;
  storeAddress: ZidWalletTypesAddressType;
  phoneNumber: string;
  email: string;
  isEnabled: boolean;
  isVerified: boolean;
  bankAccountNumber: string;
  bankBic: string;
  virtualBankAccountNumber: string;
  balance: number; //balance without credits
  credits: number;
  authorizedAmount: number;
  authorizedCredits: number;
  overdraftLimit: number;
  currency: string;
  alertLowBalance: boolean;
  lowBalanceThreshold: number;
  businessInfo: ZidWalletTypesBusinessInfoType;
  verificationStatus: ZidWalletVerificationStatus;
  verificationRejectionReason: string;
  managerPhoneNumber: string;
  createdAt: string;
  updatedAt: string;
  bankAccountHolderName: string;
  totalBalance: number; // balance + credits
}

export type ZidWalletTypesAddressType = {
  line1: string;
  line2: string;
  line3?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

export interface ZidWalletTypesBusinessInfoType {
  corporateName?: string;
  vatNumber?: string;
  address?: string;
  nationalId?: string;
  commercialRegistrationNumber?: string; // Commercial Registration number of the business
  nationalIdPhoto?: string; // URL for photo of the national ID
  accountType?: string; // Account type of the business -> Individual or Business
  commercialRegistrationPhoto?: string; // URL for photo of the commercial registration
}

export enum ZidWalletVerificationStatus {
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED', // Placeholder status to avoid gRPC default
  REVIEW_PENDING = 'REVIEW_PENDING', // Wallet's verification is under review
  VERIFIED = 'VERIFIED', // Wallet is verified
  UNVERIFIED = 'UNVERIFIED', // Wallet is yet to be verified
  VERIFICATION_REJECTED = 'VERIFICATION_REJECTED', // Wallet's verification request was rejected
}
