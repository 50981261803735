export const enum ApiModelOrderPaymentMethodCodeEnum {
  cod = 'zid_cod',
  cop = 'zid_cop',
  bankTransfer = 'zid_bank_transfer',
  creditCard = 'zid_credit_card',
  paymentLink = 'payment_link.zidpay',
  posPaymentLink = 'POS-PAYMENT-LINK',
  tamara = 'tamara',
  zidpay = 'zidpay',
  tabby = 'tabby',
  misPay = 'mispay',
  zidInstallment = 'zid_installment',
  zidposCash = 'POS-CASH',
  zidposCreditCard = 'POS-CC',
  zidposSplit = 'POS-SPLIT',
  zidposTamara = 'POS-TAMARA',
  zidposTabby = 'POS-TABBY',
  tap = 'tap',
  hyperpay = 'hyperpay',
  payfort = 'payfort',
  mazeed = 'prepaid_mazeed',
  moyasar = 'moyasar',
}
