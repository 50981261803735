import { ZidpayStoreModule } from '../../../store/module';
import { CountryCode, CountryCodeSource } from '../../shared/CountryValidation/CountryValidation.enum';
import { ZidpayCountriesLocaleBase } from '../../shared/locale/locale';
import { ZidpayCountriesLocaleKW } from '../../KW/locale/locale';
import { ZidpayCountriesLocaleSA } from '../../SA/locale/locale';
import { ZidpayStoreSettingsModule } from '../../../store/settings-module';

export class ZidpayCountriesLocale {
  static getTitle(key: string, source: CountryCodeSource = CountryCodeSource.zidpayProfile): string {
    let countryCode: CountryCode | undefined;
    if (source === CountryCodeSource.zidpayProfile) {
      countryCode = ZidpayStoreModule.merchantProfile.headquarter?.countryCode;
    } else if (source === CountryCodeSource.zidpayDetails) {
      countryCode = ZidpayStoreSettingsModule.data?.headquarter?.countryCode;
    }
    if (!countryCode) return '';

    const countryCodeData: Partial<Record<CountryCode, typeof ZidpayCountriesLocaleBase>> = {
      [CountryCode.SA]: ZidpayCountriesLocaleSA,
      [CountryCode.KW]: ZidpayCountriesLocaleKW,
    };
    if (!countryCodeData[countryCode]) return '';

    const country = countryCodeData[countryCode] as typeof ZidpayCountriesLocaleBase;

    return country.getTitle(key);
  }
}
