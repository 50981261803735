import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ButtonComponent, ButtonSizeEnum, ButtonTypeEnum } from '../../Button';
import { ZidIconComponent, ZidIconTypeEnum } from '../../ZidIcon';
import { FontAwesomeIconComponent, FontAwesomeIconTypeEnum } from '../../FontAwesomeIcon';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ModalComponent } from '../Modal';
import { I18nMessages } from '../../../../i18n/messages';
import { ModalConfirmationTypeEnum } from './type.enum';

import styles from './Confirmation.scss';

@Component
export class ModalConfirmationComponent extends Vue {
  @Prop({
    required: true,
  })
  private readonly isOpen!: boolean;

  @Prop({
    default: ModalConfirmationTypeEnum.success,
  })
  private readonly type!: ModalConfirmationTypeEnum;

  @Prop()
  private readonly icon!: ZidIconTypeEnum | IconDefinition;

  @Prop()
  private readonly iconClass!: string;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly description!: string;

  @Prop({
    default: false,
  })
  private readonly isLoading!: boolean;

  @Prop({
    default: true,
  })
  private readonly showCancel!: boolean;

  @Prop({
    required: false,
  })
  private readonly confirmText!: string;

  @Prop({
    required: false,
  })
  private readonly cancelText!: string;

  private readonly typeToClassMap: Record<ModalConfirmationTypeEnum, string> = {
    [ModalConfirmationTypeEnum.success]: styles['modalconfirmation__content--type-success'],
    [ModalConfirmationTypeEnum.danger]: styles['modalconfirmation__content--type-danger'],
  };

  private readonly typeToConfirmationButtonTypeMap: Record<ModalConfirmationTypeEnum, ButtonTypeEnum> = {
    [ModalConfirmationTypeEnum.success]: ButtonTypeEnum.success,
    [ModalConfirmationTypeEnum.danger]: ButtonTypeEnum.danger,
  };

  render(h: CreateElement): VNode {
    return (
      <ModalComponent
        isOpen={this.isOpen}
        onClose={this.onCloseModal}
        contentClass={[styles.modalconfirmation__content, this.typeToClassMap[this.type]]}
      >
        {this.icon && this.renderIcon(h)}
        {this.title && <h2 class={styles.modalconfirmation__title}>{this.title}</h2>}
        {this.description && <h4 class={styles.modalconfirmation__description}>{this.description}</h4>}
        {this.$slots.default}
        <footer class={styles.modalconfirmation__footer}>
          <ButtonComponent
            size={ButtonSizeEnum.small}
            type={this.typeToConfirmationButtonTypeMap[this.type]}
            onClick={this.onClickButtonConfirm}
            class={styles.modalconfirmation__button}
            isLoading={this.isLoading}
            disabled={this.isLoading}
          >
            {this.confirmText ?? this.$t(I18nMessages['common.confirm'])}
          </ButtonComponent>
          {this.showCancel && (
            <ButtonComponent
              size={ButtonSizeEnum.small}
              type={ButtonTypeEnum.light}
              onClick={this.onClickButtonCancel}
              class={styles.modalconfirmation__button}
            >
              {this.cancelText ?? this.$t(I18nMessages['common.cancel'])}
            </ButtonComponent>
          )}
        </footer>
      </ModalComponent>
    );
  }

  private renderIcon(h: CreateElement): VNode {
    const classes = [styles.modalconfirmation__icon, this.iconClass];

    if (typeof this.icon === 'object') {
      return <FontAwesomeIconComponent icon={this.icon} type={FontAwesomeIconTypeEnum.solid} class={classes} />;
    }

    return <ZidIconComponent type={this.icon} class={classes} />;
  }

  private onClickButtonConfirm(): void {
    this.$emit('confirm');
  }

  private onClickButtonCancel(): void {
    this.$emit('cancel');
    this.$emit('close');
  }

  private onCloseModal(): void {
    this.$emit('close');
  }
}
