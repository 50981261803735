export const accountAuthUrls = {
  validateAuthByEmail: '/auth/v1/validate_auth_email',
  validateLogin: '/auth/v1/validate_login',
  reset: '/reset-password',
  registerDetails: '{registerId}',
  registerUpdatePersonalData: '/register/{registerId}/info',
  registerWizard: '/setup',
  registerWizardBusiness: '/setup/business',
  user: '/auth/v1/user',
  otp: '/otp',
  loginOtp: '/login/otp',
  registerOtp: '/register/otp',
  resend: '/resend-otp',
  updateMobile: '/change-mobile',
  ecommerceExperience: '/ecommerce-experience',
  resetNewPassword: '/reset-new-password',
  createRecommendedStepsList: '/account/settings/store/activate-recommended-steps',
  validateMobileNumber: '/auth/v1/validate-mobile',
  verifyMobileOtp: '/auth/v1/verify-mobile-otp',
  getBusinessType: '/api/v1/managers/store/business-type',
};
