import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ModalConfirmationComponent, ModalConfirmationTypeEnum } from '../../../common/components/Modal';
import { I18nMessages } from '../../../i18n/messages';
import styles from './ActivitySelection.scss';
import {
  DropdownListComponent,
  DropdownListOptionInterface,
  DropdownListTypeEnum,
} from '../../../common/components/Dropdown/List';
import { NotificationsStoreModule } from '../../../notifications/store/module';
import NotificationTypeEnum from '../../../notifications/components/type.enum';
import {
  InputSelectOptionInterface,
  InputTextComponent,
  InputTextChangeEventInterface,
} from '../../../common/components/Input';
import { DropdownListChangeEventInterface } from '../../../common/components/Dropdown/List/change-event.interface';
import { RenderableValueType } from '../../../common/helpers/types';
import ApiModelIndustryInterface from '../../../api/model/industry.interface';
import { ApiModelStoreCategoryInterface } from '../../../api/model/store-category.interface';
import { DashboardStoreIndustriesModule } from '../../../dashboard/store/industries-module';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';

@Component
export class ActivitySelectionComponent extends Vue {
  @Prop()
  private readonly isOpen!: boolean;

  @Prop()
  private readonly isDisabled!: boolean;

  private selectedIndustry: ApiModelIndustryInterface | undefined;
  private selectedCategory: ApiModelStoreCategoryInterface | undefined;
  private storeCategoryOther!: string | '';

  private get industries(): Array<ApiModelIndustryInterface> {
    return DashboardStoreIndustriesModule.data || [];
  }

  private get isLoading(): boolean {
    return [AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(
      DashboardStoreIndustriesModule.loadingState,
    );
  }

  private checkOtherCategory(
    selectedIndustry: ApiModelIndustryInterface | undefined,
    selectedCategory: ApiModelStoreCategoryInterface | undefined,
  ): boolean {
    if (
      selectedIndustry?.name === this.$t(I18nMessages['home.confirm_activity_modal.category.other']).toString() ||
      selectedCategory?.name === this.$t(I18nMessages['home.confirm_activity_modal.category.other']).toString()
    )
      return true;
    else return false;
  }

  async created(): Promise<void> {
    await DashboardStoreIndustriesModule.fetch();
  }

  render(h: CreateElement): VNode {
    return (
      <div>
        <ModalConfirmationComponent
          type={ModalConfirmationTypeEnum.success}
          isOpen={this.isOpen}
          onClose={this.onCloseModal}
          onConfirm={() => {
            if (this.selectedIndustry) {
              this.confirmStoreActivity(this.selectedIndustry, this.selectedCategory, this.storeCategoryOther);
            }
          }}
          title={this.$t(I18nMessages['home.confirm_activity_modal.title'])}
          description={this.$t(I18nMessages['home.confirm_activity_modal.description'])}
        >
          <div class={styles.content}>
            <label>{this.$t(I18nMessages['home.category_activity_modal_industry.title'])}</label>
            <DropdownListComponent
              type={DropdownListTypeEnum.primary}
              options={this.getIndustriesSelectOptions()}
              value={null}
              isDisabled={this.isDisabled}
              isLoading={this.isLoading}
              headerValueRenderer={this.renderIndustriesDropdownHeaderValue}
              onChange={this.onChangeIndustry}
            />
            {this.selectedIndustry &&
            this.selectedIndustry.name !=
              this.$t(I18nMessages['home.confirm_activity_modal.category.other']).toString() ? (
              <div>
                <label>{this.$t(I18nMessages['home.category_activity_modal_category.title'])}</label>
                <DropdownListComponent
                  type={DropdownListTypeEnum.primary}
                  options={this.getCategoriesOptions()}
                  value={null}
                  isDisabled={this.isDisabled}
                  isLoading={this.isLoading}
                  headerValueRenderer={this.renderCategoriesDropdownHeaderValue}
                  onChange={this.onChangeCategory}
                />
              </div>
            ) : null}

            {this.checkOtherCategory(this.selectedIndustry, this.selectedCategory) ? (
              <div>
                <label>{this.$t(I18nMessages['home.category_activity_modal_category.other'])}</label>
                <InputTextComponent
                  class={styles.products_and_customer__currencies__selection}
                  value={this.storeCategoryOther}
                  onChange={this.onChangestoreCategoryOther}
                />
              </div>
            ) : null}
          </div>
        </ModalConfirmationComponent>
      </div>
    );
  }

  private onChangeIndustry(event: DropdownListChangeEventInterface): void {
    this.selectedIndustry = this.industries.find(
      (industry) => industry.id.toString() === event.option.value.toString(),
    );
    this.selectedCategory = undefined;

    this.$forceUpdate();
  }

  private onChangeCategory(event: DropdownListChangeEventInterface): void {
    this.selectedCategory = this.selectedIndustry?.store_categories.find(
      (category) => category.id.toString() === event.option.value.toString(),
    );
    this.$forceUpdate();
  }

  private onChangestoreCategoryOther(event: InputTextChangeEventInterface) {
    this.storeCategoryOther = event.value;
  }

  private renderIndustriesDropdownHeaderValue(h: CreateElement): RenderableValueType {
    return this.selectedIndustry
      ? this.getIndustriesOptions().find(
          (option) => this.selectedIndustry && option.value === this.selectedIndustry.id.toString(),
        )?.label
      : this.$t(I18nMessages['common.forms.choose']).toString();
  }

  private getIndustriesSelectOptions(): Array<InputSelectOptionInterface<number | 'default'>> {
    if (!DashboardStoreIndustriesModule.data) return [];

    return [
      { label: this.$t(I18nMessages['common.forms.choose']).toString(), value: 'default' },
      ...DashboardStoreIndustriesModule.data.map((industry) => ({
        label: industry.name,
        value: industry.id,
      })),
    ];
  }

  private renderCategoriesDropdownHeaderValue(h: CreateElement): RenderableValueType {
    return this.selectedCategory ? this.selectedCategory.name : this.$t(I18nMessages['common.forms.choose']).toString();
  }

  private getIndustriesOptions(): Array<DropdownListOptionInterface> {
    return this.industries.map((industry) => ({
      label: industry.name,
      value: industry.id.toString(),
    }));
  }

  private getCategoriesOptions(): Array<DropdownListOptionInterface> {
    if (!this.selectedIndustry) return [];
    return this.selectedIndustry.store_categories.map((category) => ({
      label: category.name,
      value: category.id.toString(),
    }));
  }

  private onCloseModal(): void {
    this.$emit('close');
  }

  private async confirmStoreActivity(
    selectedIndustry: ApiModelIndustryInterface | undefined,
    selectedCategory: ApiModelStoreCategoryInterface | undefined,
    storeCategoryOther: string,
  ) {
    const industryId = selectedIndustry?.id;
    let categoryId = selectedCategory?.id;

    if (selectedIndustry?.name === this.$t(I18nMessages['home.confirm_activity_modal.category.other']).toString()) {
      categoryId = selectedIndustry?.store_categories[0].id;
    }

    const response = await DashboardStoreIndustriesModule.confirmStoreActivity({
      industryId,
      categoryId,
      storeCategoryOther,
    });

    if (response && response.status && response.message) {
      NotificationsStoreModule.notify({
        id: '',
        text: response.message,
        type: response.status === 'error' ? NotificationTypeEnum.danger : NotificationTypeEnum.success,
        showBell: false,
        autoDismiss: true,
      });
    }

    if (response.status != 'error') {
      this.onCloseModal();

      setTimeout(function () {
        window.location.reload();
      }, 1300);
    }
  }
}
