import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidpayApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { MerchantDetails } from '../types/MerchantDetails.interface';
import {
  changeNetworkStatusApiResponseType,
  changeZidpayStatusApiResponseType,
  Network,
  NetworksData,
  Package,
} from '../types/Networks.interface';
import { PackagesEnum } from '../types/Packages.enum';
import { ZidpayStoreUpdateMerchantModule } from './update-merchant-module';
import { ApiBaseDataResponseObjectType } from '../../api/types';
import { DownloadAttachment } from '../types/MerchantDetailsAttachment';
import Catch from '../../common/decorators/catch-error';
import { revalidate } from '../../common/helpers/revalidate/revalidate';
import { ZidpayCountriesHelpers } from '../countries/exports/helpers/helpers';
import { CountryCodeSource } from '../countries/shared/CountryValidation/CountryValidation.enum';

const zidpayService = ZidpayApiServiceFactory();

@Module({
  dynamic: true,
  name: 'zidpay-settings',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidpaySettingsModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public loadingNetworksState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: MerchantDetails | null = null;
  public merchantAttachments: Blob | null = null;
  public networks: Network[] | null = null;
  public isEnabledZidpay = false;
  public isNetworkLoading = false;
  public isPackageLoading = AppStoreReadyStateEnum.pending;
  public packages: Package | null = null;

  public error: Error | null = null;

  public currentPackage: PackagesEnum = PackagesEnum.basic;

  @Mutation
  private CHANGE_CURRENT_PACKAGE(): void {
    this.currentPackage = ZidpayCountriesHelpers.getHelpers(CountryCodeSource.zidpayDetails).setCurrentPackage(
      this.data?.package?.id as string,
    );
  }

  @Mutation
  private CHANGE_NETWORK_STATUS_SUCCESS(): void {
    this.error = null;
    this.isNetworkLoading = false;
  }

  @Mutation
  private CHANGE_NETWORK_STATUS_ERROR(name: string, error: Error): void {
    const network = this.networks?.find((n) => n.name == name);
    if (network) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      network.is_enabled = !network.is_enabled;
    }
    this.error = error;
    this.isNetworkLoading = false;
  }

  @Mutation
  private CHANGE_NETWORK_STATUS(name: string): void {
    const network = this.networks?.find((n) => n.name == name);
    if (network) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      network.is_enabled = !network.is_enabled;
    }
    this.error = null;
  }

  @Mutation
  private CHANGE_ZIDPAY_STATUS_SUCCESS(): void {
    this.error = null;
    this.isNetworkLoading = false;
  }

  @Mutation
  private CHANGE_ZIDPAY_STATUS_ERROR(error: Error): void {
    this.isEnabledZidpay = !this.isEnabledZidpay;
    this.error = error;
    this.isNetworkLoading = false;
  }

  @Mutation
  private CHANGE_ZIDPAY_STATUS(): void {
    this.isEnabledZidpay = !this.isEnabledZidpay;
    this.error = null;
  }

  @Mutation
  private FETCH_MERCHANT_DETAILS_SUCCESS(data: MerchantDetails): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
    ZidpayStoreUpdateMerchantModule.setSmsName(this.data.smsName || '');
    this.error = null;
  }

  @Mutation
  private FETCH_MERCHANT_ATTACHMENTS_SUCCESS(data: Blob): void {
    this.merchantAttachments = data;
  }

  @Mutation
  private FETCH_MERCHANT_ATTACHMENTS(): void {
    this.merchantAttachments = null;
    this.error = null;
  }

  @Mutation
  private FETCH_NETWORK_LOADING_STATUS(): void {
    this.isNetworkLoading = true;
  }

  @Mutation
  private FETCH_MERCHANT_DETAILS_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private FETCH_MERCHANT_DETAILS(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_NETWORKS_SUCCESS(data: NetworksData): void {
    this.loadingNetworksState = AppStoreReadyStateEnum.loaded;
    this.isEnabledZidpay = data.is_enabled;
    this.networks = data.payment_options;
    this.error = null;
  }

  @Mutation
  protected FETCH_NETWORKS_ERROR(error: Error): void {
    this.loadingNetworksState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private FETCH_NETWORKS(): void {
    this.loadingNetworksState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private SWITCH_ZIDPAY(): void {
    this.isEnabledZidpay = !this.isEnabledZidpay;
  }

  @Mutation
  private FETCH(): void {
    this.isPackageLoading = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_PACKAGES_SUCCESS(data: Package): void {
    this.isPackageLoading = AppStoreReadyStateEnum.loaded;
    this.packages = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.isPackageLoading = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async fetchMerchantDetails(pooling = false): Promise<void> {
    if (!pooling) {
      this.FETCH_MERCHANT_DETAILS();
    }
    try {
      const res = await zidpayService.getMerchantDetails();
      this.FETCH_MERCHANT_DETAILS_SUCCESS(res.data);
      this.CHANGE_CURRENT_PACKAGE();
    } catch (error) {
      this.FETCH_MERCHANT_DETAILS_ERROR(error as Error);
    }
  }

  @Action
  public async fetchMerchantAttachments(data: DownloadAttachment): Promise<void> {
    this.FETCH_MERCHANT_ATTACHMENTS();
    try {
      const res = await zidpayService.getMerchantAttachments(data);
      this.FETCH_MERCHANT_ATTACHMENTS_SUCCESS(res);
    } catch (error) {
      this.FETCH_MERCHANT_DETAILS_ERROR(error as Error);
    }
  }

  @Action
  @Catch({
    onError(error, ctx) {
      ctx.FETCH_NETWORKS_ERROR(error);
    },
  })
  public async fetchNetworks(): Promise<void> {
    this.FETCH_NETWORKS();
    const response = await zidpayService.getNetworks();
    this.FETCH_NETWORKS_SUCCESS(response.data.data);

    revalidate(response, (fresh: typeof response.data) => this.FETCH_NETWORKS_SUCCESS(fresh.data));
  }

  @Action
  public async changeNetworkStatus(network: Network): Promise<void> {
    this.FETCH_NETWORK_LOADING_STATUS();
    let service: changeNetworkStatusApiResponseType = (network: string) => zidpayService.enableNetwork(network);
    if (network.is_enabled) {
      service = (network: string): Promise<ApiBaseDataResponseObjectType<{}>> => zidpayService.disableNetwork(network);
    }
    this.CHANGE_NETWORK_STATUS(network.name);
    try {
      await service(network.name);
      this.CHANGE_NETWORK_STATUS_SUCCESS();
    } catch (error) {
      this.CHANGE_NETWORK_STATUS_ERROR(network.name, error as Error);
    }
  }

  @Action
  public async changeZidpayStatus(): Promise<void> {
    this.FETCH_NETWORK_LOADING_STATUS();
    let service: changeZidpayStatusApiResponseType = () => zidpayService.enableZidpay();
    if (this.isEnabledZidpay) {
      service = (): Promise<ApiBaseDataResponseObjectType<{}>> => zidpayService.disableZidpay();
    }
    this.CHANGE_ZIDPAY_STATUS();
    try {
      await service();
      this.CHANGE_ZIDPAY_STATUS_SUCCESS();
    } catch (error) {
      this.CHANGE_ZIDPAY_STATUS_ERROR(error as Error);
    }
  }

  @Action
  public async switchZidpayStatus(): Promise<void> {
    this.FETCH_NETWORK_LOADING_STATUS();
    this.SWITCH_ZIDPAY();
    this.CHANGE_ZIDPAY_STATUS_SUCCESS();
  }

  @Action
  public async fetchMerchantPackages(): Promise<void> {
    this.FETCH();
    try {
      const response = await zidpayService.getMerchantPackages();
      this.FETCH_PACKAGES_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }
}

export const ZidpayStoreSettingsModule = getModule(ZidpaySettingsModule);
