import Vue, { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidBox, ZidButton } from '@zidsa/ui';
import styles from './BankTransferSection.module.scss';
import { WalletComponentsAccountBankInfoComponent } from '../../AccountInfo/BankInfo/WalletBankInfo';
import { WalletComponentsVirtualWalletInfoComponent } from '../../AccountInfo/VirtualWalletInfo/VirtualWalletInfo';
import { ZidWalletStoreModule } from '../../../store/wallet.module';

@Component
export class ZidWalletComponentsBankTransferSectionComponent extends Vue {
  private isModal = true;

  private get isWalletVerified(): boolean {
    return ZidWalletStoreModule.data.isVerified;
  }

  private renderBankInfoBoxes(h: CreateElement): VNode {
    return (
      <div class={styles['transfer__section--bank-table']}>
        {this.renderVirtualBankInfo(h)}
        <hr></hr>
        {this.renderBankAccountInfo(h)}
      </div>
    );
  }

  private renderVirtualBankInfo(h: CreateElement): VNode {
    return (
      <div>
        <WalletComponentsVirtualWalletInfoComponent isModal={this.isModal} />
      </div>
    );
  }

  private renderBankAccountInfo(h: CreateElement): VNode {
    return (
      <div>
        <WalletComponentsAccountBankInfoComponent isModal={this.isModal} />
      </div>
    );
  }

  private renderUnverifiedWalletNotice(h: CreateElement): VNode {
    return (
      <div class={styles['transfer__section--unverified']}>
        <ZidBox class={styles['transfer__section--unverified--verify']}>
          <span>{this.$t(I18nMessages['wallet.please_verify.pre_link'])}</span>
          <span>
            <ZidButton link style={'display: inline-block;'} onClick={this.onToggleVerifyWalletModal}>
              {this.$t(I18nMessages['wallet.verification'])}
            </ZidButton>
          </span>
          <span>{this.$t(I18nMessages['wallet.please_verify.post_link'])}</span>
        </ZidBox>

        {this.renderVirtualBankInfo(h)}
      </div>
    );
  }

  render(h: CreateElement): VNode {
    return <div>{this.isWalletVerified ? this.renderBankInfoBoxes(h) : this.renderUnverifiedWalletNotice(h)}</div>;
  }

  private onToggleVerifyWalletModal(): void {
    this.$emit('toggleVerifyWalletModal');
  }
}
