<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1046 782"
    :width="width"
    :height="height"
  >
    <defs></defs>
    <path
      id="Layer"
      class="s0"
      d="m466.7 779.1l-103.7-81q24.6-18.3 41.1-38.5 16.6-20.3 25.4-38.2 8.8-17.9 13.1-46.8 4.2-29 5-49.7 0.7-20.6 0.8-61.2v-27.1l129-196.8 0.1 215.2c0 99.6-7.5 169.1-20.8 208.5q-20 59.2-90 115.6z"
    />
    <path
      id="Layer"
      class="s0"
      d="m181.1 444.2l1.4 130-118.8 0.4c-18.8 0.2-33.1-5.6-42.9-17.3-9.7-11.7-14.8-28.4-15-49.8q-0.1-16.1 2.9-32.3c2-10.7 6.1-21.5 9.8-30.7l162.6-0.2z"
    />
    <path
      id="Layer"
      class="s0"
      d="m169.6 574.3v-130.1h92.3v-69.7q0-58.9-44.6-74-44.6-15.1-143.7 12v-124.6q113.3-31 182.9-18.6 69.5 12.4 107 59.6 39.2 49.6 39.2 147.9v90.1c0 59.3-48.1 107.4-107.4 107.4h-125.7z"
    />
    <path
      id="Layer"
      class="s0"
      d="m466.3 116.2c-12.2-13.1-18.3-28.8-18.3-47 0.1-18.7 6.3-34.5 18.7-47.2 12.3-12.7 27.9-19.2 46.6-19.1 19.2 0.1 34.9 6.6 47.2 19.4 12.3 12.9 18.4 28.7 18.3 47.4-0.1 18.2-6.2 33.8-18.6 46.8-12.4 13-28.1 19.5-47.4 19.4-18.7 0-34.2-6.5-46.5-19.7zm110.7 181.9l-129 285.8 1.2-351.8c0.1-18.6 6-32.8 17.8-42.4 11.8-9.5 28.4-14.3 49.8-14.2 10.6 0 21.3 1.2 32 3.3 10.7 2.2 20.5 5.1 29.6 8.9l-1.4 110.4z"
    />
    <path
      id="Layer"
      fill-rule="evenodd"
      class="s0"
      d="m990.3 329.1c32.9 1 50 27 50 52.1-0.1 25.1-17.5 51-50.4 51.8-38.5 0.9-75.8-8.9-108.4-28.5-4 3.3-8 6.8-11.8 10.6-3.8 3.8-7.4 7.7-10.7 11.7 19.4 32.7 29 70.2 27.8 108.6-1.1 32.9-26.9 50-52 50h-0.2c-25.1-0.1-50.9-17.4-51.8-50.3-0.9-38.5 8.9-75.8 28.6-108.4-3.3-4.1-6.9-8-10.7-11.8-3.7-3.8-7.6-7.4-11.7-10.8-32.7 19.5-70.3 29-108.6 27.8-32.9-1-50-27-49.9-52.1 0-25.1 17.4-51 50.3-51.8 38.5-1 75.8 8.9 108.4 28.5 4.1-3.3 8-6.8 11.8-10.6 3.8-3.8 7.4-7.7 10.7-11.7-19.4-32.7-29-70.2-27.8-108.6 1.1-32.9 26.9-50 52-50h0.2c25.1 0.1 51 17.4 51.8 50.3 0.9 38.5-8.9 75.9-28.6 108.5 3.4 4.1 6.9 8 10.7 11.7 3.7 3.8 7.6 7.4 11.7 10.8 32.7-19.4 70.2-28.8 108.6-27.8zm-1.4 64.9c11-0.3 12.2-9.1 12.2-12.9 0.1-3.8-1.1-12.6-12.1-12.9-23.4-0.9-46.9 3.6-68.3 12.6 21.4 9.2 44.7 13.9 68.2 13.2zm-167 140.1c0.3 11 9.1 12.2 12.8 12.2h0.1c3.8 0 12.6-1.2 12.9-12.1 0.7-23.5-3.6-46.9-12.7-68.3-9.2 21.3-13.7 44.7-13.1 68.2zm13.4-139.5q0 0 0 0 0 0 0 0zm0 0q3.3-3.7 6.8-7.2 3.6-3.5 7.3-6.8-3.7-3.4-7.2-6.9-3.5-3.5-6.8-7.2-3.4 3.6-6.9 7.1-3.6 3.6-7.2 6.9 3.6 3.3 7.1 6.9 3.6 3.5 6.9 7.2zm13.5-167.7c-0.3-11-9.1-12.2-12.9-12.2h-0.1c-3.8 0-12.5 1.2-12.8 12.1-0.8 23.6 3.6 47 12.6 68.3 9.2-21.3 13.7-44.6 13.2-68.2zm-167.2 166c23.7 0.8 47-3.6 68.4-12.7-20-8.7-41.3-13.1-63.6-13.2h-1.1q-1.8 0-3.5 0.1c-11 0.3-12.3 9.1-12.3 12.9 0 3.7 1.2 12.5 12.1 12.9z"
    />
  </svg>
</template>
<script>
import { defineComponent, toRefs } from 'vue';
export default defineComponent({
  props: {
    width: {
      default: '1046',
      type: String,
    },
    height: {
      default: '782',
      type: String,
    },
  },

  setup(props) {
    const { width, height } = toRefs(props);

    return { width, height };
  },
});
</script>
<style lang="scss" scoped>
path {
  fill: #7462f4;
  stroke-width: 0px;
}
</style>
