<template>
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1661 667" :height="height" :width="width">
    <path
      id="Layer"
      class="s0"
      d="m1014.5 133.9c-13.8-14.7-20.8-32.3-20.7-52.8 0.1-21.2 7.1-38.9 21-53.3 14-14.3 31.5-21.6 52.6-21.5 21.7 0.1 39.4 7.4 53.2 21.9 13.8 14.4 20.6 32.3 20.6 53.4-0.1 20.5-7 38-21 52.7-13.9 14.7-31.7 22-53.3 21.9-21.1-0.1-38.6-7.4-52.4-22.3zm124.7 529.5l-145.4-0.5 1.4-395.1c0-21 6.8-37 20-47.8 13.3-10.7 32-16.1 56.1-16.1 12 0.1 24 1.4 36.1 3.8 12 2.4 23.2 5.8 33.4 10.1z"
    />
    <path
      id="Layer"
      fill-rule="evenodd"
      class="s0"
      d="m466.5 435.6c-0.1 28.3-19.7 57.5-56.8 58.4-43.4 1-85.4-10-122.1-32.2-4.6 3.8-9 7.8-13.3 12-4.3 4.3-8.3 8.7-12.1 13.2 21.9 36.9 32.7 79.1 31.3 122.4-1.2 37-30.3 56.3-58.5 56.3h-0.2c-28.4-0.1-57.5-19.6-58.4-56.7-1.1-43.3 10-85.4 32.1-122.2-3.7-4.5-7.7-9-11.9-13.3-4.3-4.2-8.7-8.3-13.2-12-37 21.9-79.3 32.6-122.4 31.3-37.1-1.2-56.4-30.5-56.3-58.8 0.1-28.3 19.6-57.4 56.7-58.4 43.3-1.1 85.4 10.1 122.1 32.2 4.6-3.7 9.1-7.7 13.3-12 4.3-4.2 8.3-8.7 12.1-13.2-21.9-36.8-32.7-79.1-31.3-122.4 1.2-37 30.3-56.3 58.5-56.3h0.3c28.3 0.1 57.4 19.6 58.3 56.7 1.1 43.3-10 85.5-32.1 122.2 3.8 4.6 7.8 9.1 12 13.3 4.1 4.2 8.6 8.2 13.2 12.1 36.8-21.9 79.1-32.4 122.3-31.3 37.1 1.1 56.5 30.4 56.4 58.7zm-134.7-0.4c24 10.4 50.4 15.6 76.8 14.8 12.4-0.3 13.8-10.3 13.8-14.5 0-4.3-1.3-14.2-13.6-14.6-26.5-1-52.9 4-77 14.3zm-111.4 172.7c0.3 12.4 10.3 13.7 14.5 13.8h0.1c4.2 0 14.1-1.4 14.5-13.7 0.8-26.5-4.1-52.9-14.3-77-10.3 24-15.5 50.3-14.8 76.9zm22.8-165.3q4-4 8.2-7.7-4.1-3.8-8.1-7.8-3.9-4-7.7-8.1-3.7 4.1-7.7 8.1-4 3.9-8.2 7.7 4.2 3.7 8.1 7.7 4 4 7.7 8.1 3.8-4.1 7.7-8zm7.5-180.9c-0.3-12.4-10.2-13.7-14.5-13.7h-0.1c-4.3 0-14.1 1.3-14.5 13.6-0.8 26.6 4.1 52.9 14.3 77 10.4-24 15.5-50.3 14.8-76.9zm-188.3 187c26.6 1 52.8-4 77-14.2-22.5-9.8-46.6-14.8-71.6-14.9h-1.3q-2 0-4 0.1c-12.4 0.3-13.8 10.2-13.8 14.5 0 4.2 1.3 14.1 13.7 14.5z"
    />
    <path
      id="Layer"
      class="s0"
      d="m945.8 665.1h-449.6l0.4-114.4c14.4-12.7 31.2-27.2 50.1-43.7 19.1-16.4 38.8-33.6 59-51.3 20.2-17.6 39.5-34.6 58-50.7 18.3-16.2 34.3-30.4 47.9-42.3 13.6-12.1 23.4-20.7 29.5-26.1l-168.8-0.6c-27.2-0.1-46.4-5.1-57.8-15.1-11.4-10-17.1-25.8-17-47.5 0.1-15.6 2.5-30.2 7.4-43.7 4.9-13.6 8.5-22.5 10.9-26.7l357.5 1.3c21.1 0 38.4 6 51.8 17.8 13.6 11.8 20.3 29.2 20.2 52 0 15.1-3.4 28.4-10 40.2-6.7 11.7-17.3 24.7-31.8 39.1-4.2 4.3-13.1 12.4-26.6 24.7-13.7 12.4-29.4 26.4-47.1 42.4-17.9 15.8-36 32-54.5 48.4-18.4 16.6-35.1 31.3-50.2 44.1-15.1 12.9-26.5 22.4-34.4 28.3l255.5 1-0.4 122.8z"
    />
    <path
      id="Layer"
      fill-rule="evenodd"
      class="s0"
      d="m1656.1 421.8c-0.1 51.5-16.2 95.8-35.5 132.7-19.3 36.9-46.7 65.1-81.9 84.4-35.4 19.4-77.1 29-125.3 28.8-48.7-0.1-91-10.2-127-30.2-36.2-19.8-64-47.3-83.6-82.3-19.7-35-29.5-74.9-29.3-119.6 0.1-44.8 9.7-84.4 28.8-119 19-34.7 44.6-61.9 76.8-81.9 32.2-19.9 68.1-29.8 107.6-29.7 25.8 0.1 49.5 4.4 71.3 12.7 21.8 8.5 39.7 20.1 54.1 35.1l0.7-217.5c9.8-4 20.5-7.1 32.3-9.5 11.8-2.1 23.1-3.3 34-3.2 22.9 0.1 40.7 5.3 53.2 15.6 12.6 10.4 25.1 25.6 25 45.7l-1.2 337.9zm-312.9-62.5c-18.5 19.1-27.7 44.4-27.9 75.9q-0.1 47.3 27.3 76.2c18.2 19.3 42 28.9 71.3 29.1 29.2 0.1 53-9.4 71.4-28.6 18.4-19.2 27.7-44.5 27.7-76 0.2-31.5-8.9-56.9-27.1-76.1-18.3-19.4-42.1-29-71.3-29.1-29.3-0.2-53.1 9.3-71.4 28.6z"
    />
  </svg>
</template>
<script>
import { defineComponent, toRefs } from 'vue';
export default defineComponent({
  props: {
    width: {
      default: '1672',
      type: String,
    },
    height: {
      default: '671',
      type: String,
    },
  },

  setup(props) {
    const { width, height } = toRefs(props);

    return { width, height };
  },
});
</script>
<style lang="scss" scoped>
path {
  fill: #7462f4;
  stroke-width: 0px;
}
</style>
