export const enum PaymentMethodCodeEnum {
  bankTransfer = 'bank_transfer',
  creditCard = 'credit_card',
  promotion = 'promotion',
}

export const enum PurchaseStatusCodeEnum {
  approved = 'approved',
  rejected = 'rejected',
  pending = 'pending',
  initiated = 'initiated',
}

export interface PurchaseHistoryApiFiltersInterface {
  page?: number;
  per_page?: number;
  status_code?: PurchaseStatusCodeEnum;
  payment_method_code?: PaymentMethodCodeEnum;
  purchase_type?: string;
}
