import Vue, { CreateElement, VNode } from 'vue';
import { ZidIcon } from '@zidsa/ui';
import { Component, Prop } from 'vue-property-decorator';
import styles from './OfferLabel.scss';
import { I18nMessages } from '../../../../i18n/messages';

@Component
export class OfferLabelNationalDay extends Vue {
  @Prop()
  private label!: string;

  render(h: CreateElement): VNode {
    return (
      <div class={styles.labelSection}>
        <div class={styles.labelSection__labelwrapper}>
          <div class={[styles.labelSection__labelbox, styles.labelSection__labelbox__discountLabel]}>
            <div class={[styles.labelSection__labeltext, styles.labelSection__labeltext__discountLabel]}>
              <ZidIcon icon='tag' size='xxs' />
              <p class={styles.labelSection__labeltext_LineClamp}>
                {this.label ? this.label : this.$t(I18nMessages['app_market.home.badges.national.day'])}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
