import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { SettingsApiLocationCitiesFiltersInterface } from '../api/location/cities/filters.interface';
import { SettingsApiLocationServiceFactory } from '../api/location/service-factory';
import { ApiModelCityInterface } from '../../api/model/city.interface';
import { SettingsApiLocationCitiesResponseInterface } from '../api/location/cities/response.interface';

const service = SettingsApiLocationServiceFactory();

@Module({
  dynamic: true,
  name: 'settingsCities',
  store: appStoreFactory(),
  namespaced: true,
})
class CitiesModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public filters: SettingsApiLocationCitiesFiltersInterface | null = null;
  public data: Array<ApiModelCityInterface> | null = null;
  public error: Error | null = null;

  @Mutation
  private FETCH(filters: SettingsApiLocationCitiesFiltersInterface): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.filters = filters;
    this.data = null;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: Array<ApiModelCityInterface>): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async fetch(filters: SettingsApiLocationCitiesFiltersInterface): Promise<void> {
    this.FETCH(filters);

    try {
      const response = await service.getCities(filters);

      this.FETCH_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetchCitiesByIds(citiesIds: number[]): Promise<SettingsApiLocationCitiesResponseInterface> {
    const response = await service.getCities({ cities: citiesIds });

    return response;
  }
}

export const SettingsStoreCitiesModule = getModule(CitiesModule);
