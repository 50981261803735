import {
  ZidButton,
  ZidContainer,
  ZidHeading,
  ZidIcon,
  ZidInput,
  ZidInputGroup,
  ZidInputLeading,
  ZidLoader,
  ZidModal,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidText,
} from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';
import { I18nMessages } from '../../../i18n/messages';
import { BankAccount } from '../../../zidpay/types/BankAccount.interface';
import { BanksTransferStoreModule } from '../../../payment-options/store/banks-transfer-module';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import { LoaderSizeEnum } from '../../../common/components/Loader';
import { RoutingRouteEnum } from '../../../routing/route.enum';
import { businessTypeEnum } from '../../../settings/api/store/business-type.enum';
import { VerifyWalletComponentsIndividualSectionComponent } from './IndividualSection/IndividualSection';
import { VerifyWalletComponentsCorporateSectionComponent } from './CorporateSection/CorporateSection';
import { ZidWalletStoreModule } from '../../store/wallet.module';

//Styles & Icons
import styles from './VerifyWalletModal.module.scss';
import isRTL from '../../../common/helpers/is-lang-arabic';
import arrowRight from '../../../account/assets/arrow_right.svg';
import arrowLeft from '../../../account/assets/arrow_left.svg';
import helpIcon from '../../assets/icons/help-icon.svg';

import { VerifyWalletComponentsVerifyWalletOtpModalComponent } from './VerifyWalletOtpModal/VerifyWalletOtpModal';
import showNotification from '../../../common/helpers/show-notification/show-notification';
import NotificationTypeEnum from '../../../notifications/components/type.enum';
import { InputTextChangeEventInterface } from '../../../common/components/Input';
import { ZidWalletVerificationStoreModule } from '../../store/wallet-verification.module';
import { VerifyWalletComponentsUserInfoComponent } from './UserInfo/UserInfo';
import { ZidWalletTypesBusinessInfoType } from '../../types/Wallet/wallet';
import { ZidWalletUpdateWalletRequestInterface } from '../../types/Wallet/update-wallet.interface';
import { ZidWalletComponentsVerifyWalletComponentsBusinessTypeSelectComponent } from './BusinessTypeSelect/BusinessTypeSelect';
import { ZidWalletModalsStoreModule } from '../../store/modal-booleans.module';

@Component
export class VerifyWalletModalComponent extends Vue {
  private selectedBank: BankAccount | null = null;

  private showOTPModal = false;

  private get bankAccounts(): Array<BankAccount> {
    return BanksTransferStoreModule.data?.data.banksAccounts || [];
  }

  private get isBanksLoading(): boolean {
    return [AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(
      BanksTransferStoreModule.loadingState,
    );
  }

  private get isLoading(): boolean {
    return [AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(ZidWalletStoreModule.loadingState);
  }

  private get accountData(): ZidWalletUpdateWalletRequestInterface {
    return !this.isLoading && ZidWalletStoreModule.verificationData
      ? ZidWalletStoreModule.verificationData
      : ({} as ZidWalletUpdateWalletRequestInterface);
  }

  private get accountBusinessData(): ZidWalletTypesBusinessInfoType {
    return !this.isLoading && ZidWalletStoreModule.verificationData.businessInfo
      ? ZidWalletStoreModule.verificationData.businessInfo
      : ({} as ZidWalletTypesBusinessInfoType);
  }

  private get isVerificationLoading(): boolean {
    return ZidWalletVerificationStoreModule.loadingState === AppStoreReadyStateEnum.loading;
  }

  private get isIndividual(): boolean {
    return this.accountBusinessData.accountType === businessTypeEnum.individual;
  }

  private get isNationalIdInvalid(): boolean {
    //core (and thus grpc) returns it as 0 if empty
    return (
      !this.accountBusinessData.nationalId ||
      this.accountBusinessData.nationalId.length !== 10 ||
      this.accountBusinessData.nationalId === '0'
    );
  }

  private get isCorporateInvalid(): boolean {
    const crMinLength = 8;
    const crNumberMaxLength = 12;
    return (
      !this.accountBusinessData.commercialRegistrationNumber ||
      this.accountBusinessData.commercialRegistrationNumber.length < crMinLength ||
      this.accountBusinessData.commercialRegistrationNumber.length > crNumberMaxLength ||
      !this.accountBusinessData.commercialRegistrationPhoto ||
      (!ZidWalletStoreModule.isManagerOwner &&
        (!this.accountData.managerPhoneNumber || this.accountData.managerPhoneNumber?.length < crMinLength))
    );
  }

  private get isMainInvalid(): boolean {
    const { managerPhoneNumber, bankAccountNumber, ...otherFields } = this.accountData;
    return Object.values(otherFields).some((field) => !field);
  }

  private get isAllInvalid(): boolean {
    return (
      this.isMainInvalid ||
      this.isNationalIdInvalid ||
      (!this.isIndividual && this.isCorporateInvalid) ||
      (this.isIndividual && !this.accountBusinessData.nationalIdPhoto)
    );
  }

  private verificationId = '';

  private submitClicked = false;

  async created(): Promise<void> {
    await BanksTransferStoreModule.fetch();
    ZidWalletStoreModule.initializeVerificationData();
  }

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidModal
          visible={ZidWalletModalsStoreModule.showVerificationModal}
          onClose={this.toggleVerificationModal}
          class={styles.verify__modal}
          title={this.$t(I18nMessages['wallet.verification'])}
        >
          <form onKeydown={(event: KeyboardEvent): void => this.onEnter(event)} data-identifier={'verify_form'}>
            <ZidContainer>
              <ZidHeading id={'civil-id-heading'} level={6}>
                {this.$t(I18nMessages['zidpay.activation.data.basic.idNumber'])}
              </ZidHeading>
              <div class={styles.verify__input}>
                <ZidInputGroup>
                  <ZidInputLeading isInner>
                    <ZidIcon icon={'account_circle_outline'} size={'xs'} />
                  </ZidInputLeading>
                  <ZidInput
                    type={'number'}
                    validation={'minLength:10|maxLength:10|required'}
                    data-identifier={'civil_id_input'}
                    nativeOnKeydown={(event: KeyboardEvent): void => this.validateInput(event)}
                    value={this.accountBusinessData.nationalId}
                    onChange={this.onChangeCivilId}
                    placeholder={this.$t(I18nMessages['wallet.verify.id.placeholder'])}
                  />
                </ZidInputGroup>
              </div>
            </ZidContainer>

            <ZidWalletComponentsVerifyWalletComponentsBusinessTypeSelectComponent
              selected={this.accountBusinessData.accountType}
            />
            <VerifyWalletComponentsUserInfoComponent
              id={'user-info-component'}
              onMobileChanged={this.onMobileChanged}
              onEmailChanged={this.onEmailChanged}
              isLoading={this.isLoading}
            />
            <div>
              {/* INDIVIDUAL OR CORPORATE SECTION */}
              {this.isIndividual ? (
                <VerifyWalletComponentsIndividualSectionComponent
                  id={'individual-component'}
                  isLoading={this.isLoading}
                  submitClicked={this.submitClicked}
                />
              ) : (
                <VerifyWalletComponentsCorporateSectionComponent
                  id={'corporate-component'}
                  onSetManagerMobile={this.onSetManagerMobile}
                  isLoading={this.isLoading}
                  submitClicked={this.submitClicked}
                />
              )}
            </div>
            <ZidContainer class={styles['verify__bank-select--col']}>
              <ZidHeading id={'bank-select-heading'} level={6}>
                {this.$t(I18nMessages['wallet.verification.main_bank.title'])}
              </ZidHeading>
              <div class={styles['verify__bank-select']}>
                <div>
                  {this.isBanksLoading ? (
                    <ZidLoader id={'zid-loader'} size={LoaderSizeEnum.small} class={styles.verify__loader} />
                  ) : (
                    <div>
                      <ZidSelect
                        class={{
                          [styles['verify__bank-dropdown']]: true,
                          [styles['verify__bank-dropdown--empty']]:
                            this.submitClicked && !this.accountData.bankAccountNumber,
                        }}
                        data-identifier={'bank_select'}
                        value={this.selectedBank ? { ...this.selectedBank, value: this.selectedBank.id } : null}
                        onChange={[this.setBankAccount]}
                        outlined
                      >
                        <ZidSelectHeader
                          class={{
                            [styles['verify__bank-select--header']]: !this.accountData.bankBic,
                            [styles['verify__bank-select--header--selected']]: this.accountData.bankBic,
                          }}
                          data-identifier={'bank_select_header'}
                        >
                          {this.selectedBank ? (
                            <span>
                              {this.$t(
                                I18nMessages[`wallet.bic_bank.${this.selectedBank.swift}` as keyof typeof I18nMessages],
                              )}{' '}
                              - {this.selectedBank.iban}
                            </span>
                          ) : (
                            this.$t(I18nMessages['common.choose'])
                          )}
                        </ZidSelectHeader>
                        <ZidSelectBody
                          data-identifier={'bank_select_body'}
                          class={styles['verify__bank-dropdown--body']}
                        >
                          {this.bankAccounts &&
                            this.bankAccounts?.map((bank) => (
                              <ZidSelectOption
                                data-identifier={'bank_select_option'}
                                value={{ ...bank, label: bank.bank, value: bank.id }}
                              >
                                <ZidText size={'sm'}>
                                  {bank.account_number} -
                                  {this.$t(I18nMessages[`wallet.bic_bank.${bank.swift}` as keyof typeof I18nMessages])}
                                </ZidText>
                              </ZidSelectOption>
                            ))}
                        </ZidSelectBody>
                      </ZidSelect>
                    </div>
                  )}
                </div>
                <div>
                  <ZidButton
                    data-identifier={'bank_add_button'}
                    inverse
                    onClick={this.goToBankPage}
                    link
                    class={styles['verify__add-bank']}
                  >
                    {this.$t(I18nMessages['payment_options.bank_transfer.add.cta'])}
                    <ZidIcon icon={'plus'} color={'light'} size={'xs'} />
                  </ZidButton>
                </div>
              </div>
              <div class={styles['verify__tip']}>
                <img src={helpIcon} /> &nbsp;
                <ZidText type={'neutral'}>{this.$t(I18nMessages['wallet.verification.bank_tip'])}</ZidText>
              </div>
            </ZidContainer>

            <div class={styles.verify__btns}>
              <ZidButton link onClick={this.toggleVerificationModal}>
                <img src={isRTL() ? arrowRight : arrowLeft} />
                {this.$t(I18nMessages['wallet.common.later'])}
              </ZidButton>
              <ZidButton isLoading={this.isVerificationLoading} onClick={this.submit}>
                {this.$t(I18nMessages['wallet.confirm_info'])}
              </ZidButton>
            </div>
          </form>
        </ZidModal>
        <VerifyWalletComponentsVerifyWalletOtpModalComponent
          id={'otp-modal-component'}
          verificationId={this.verificationId}
          showOTPModal={this.showOTPModal}
          onToggleOtpModal={this.onToggleOtpModal}
        />
      </div>
    );
  }

  private onToggleOtpModal(): void {
    this.showOTPModal = !this.showOTPModal;
    if (this.showOTPModal) {
      this.toggleVerificationModal();
    }
  }

  private onEnter(event: KeyboardEvent): void {
    if (['Enter'].includes(event.key)) {
      this.submit();
    }
  }

  private async submit(): Promise<void> {
    //Wallet API needs IBAN (bankAccountNumber) but core settings allow merchant to add bank with only account # instead.
    //Therefore, separate error validation for IBAN for clarification until backend does mapping to account #
    if (this.accountData.bankBic && !this.accountData.bankAccountNumber) {
      showNotification(String(this.$t(I18nMessages['wallet.choose_iban.error'])), NotificationTypeEnum.danger);
    } else if (this.isAllInvalid) {
      this.submitClicked = true;
      showNotification(String(this.$t(I18nMessages['wallet.general_error'])), NotificationTypeEnum.danger);
    } else {
      try {
        this.onUpdateWallet()
          .then(async (res) => {
            if (res.status !== 200) {
              showNotification(this.extractErrorMessage(res.data.message), NotificationTypeEnum.danger);
              return res;
            }
            this.initiateVerification()
              .then(async (res) => {
                if (res.status !== 200) {
                  showNotification(this.extractErrorMessage(res.data.message), NotificationTypeEnum.danger);
                  return res;
                } else {
                  this.verificationId = res.data.id as string;
                  this.onToggleOtpModal();
                  return res;
                }
              })
              .catch((err) => {
                showNotification(String(err.response), NotificationTypeEnum.danger);
                return err;
              });
          })
          .catch((err) => {
            showNotification(String(err.response), NotificationTypeEnum.danger);
            return err;
          });
      } catch (error) {
        showNotification(String(this.$t(I18nMessages['wallet.verify.error_occured'])), NotificationTypeEnum.danger);
      }
    }
  }

  private extractErrorMessage(message: any): string {
    const inputString = message;
    const regex = /string='([^']*)'/;

    const match = inputString.match(regex);

    return match ? match[1] : inputString;
  }

  private goToBankPage(): void {
    this.toggleVerificationModal();
    this.$router.push({ name: RoutingRouteEnum.paymentOptions });
  }

  private async initiateVerification(): Promise<any> {
    return await ZidWalletVerificationStoreModule.initiateWalletVerificationRequest();
  }

  private onUpdateWallet(): Promise<any> {
    return ZidWalletStoreModule.updateWallet();
  }

  private onMobileChanged(value: string): void {
    ZidWalletStoreModule.setWalletData({ ...ZidWalletStoreModule.verificationData, phoneNumber: value });
  }

  private onEmailChanged(value: string): void {
    ZidWalletStoreModule.setWalletData({ ...ZidWalletStoreModule.verificationData, email: value });
  }

  private validateInput(event: KeyboardEvent): void {
    if (['e', '-'].includes(event.key)) {
      event.preventDefault();
      return;
    }
  }

  private async onChangeCivilId(event: InputTextChangeEventInterface): Promise<void> {
    ZidWalletStoreModule.setBusinessData({
      ...ZidWalletStoreModule.verificationData.businessInfo,
      nationalId: event.value,
    });
  }

  private onSetManagerMobile(value: string): void {
    if (!ZidWalletStoreModule.isManagerOwner) {
      ZidWalletStoreModule.setWalletData({ ...ZidWalletStoreModule.verificationData, managerPhoneNumber: value });
    } else {
      ZidWalletStoreModule.setWalletData({ ...ZidWalletStoreModule.verificationData, managerPhoneNumber: '' });
    }
  }

  private async setBankAccount(selectedBank: BankAccount | null): Promise<void> {
    this.selectedBank = selectedBank;

    if (selectedBank) {
      ZidWalletStoreModule.setWalletData({
        ...ZidWalletStoreModule.verificationData,
        bankAccountHolderName: selectedBank.beneficiary_name,
        bankAccountNumber: selectedBank.iban,
        bankBic: selectedBank.swift,
      });
    }
  }

  private toggleVerificationModal(): void {
    ZidWalletModalsStoreModule.toggleVerificationModal();
  }
}
