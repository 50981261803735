import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import UserStoreModule from '../../../../user/store/module';
import {
  DropdownBaseBodyComponent,
  DropdownBaseChangeEventInterface,
  DropdownBaseComponent,
  DropdownBaseHeaderComponent,
  DropdownBaseToggleExpandedComponent,
} from '../../../../common/components/Dropdown/Base';
import BaseIcon from '../../../../app-market/components/icon/BaseIcon.vue';
import AppsIcon from '../../../../app-market/assets/imgs/EmbeddedApp/AppsIcon.vue';
import { PinnedAppComponent } from './PinnedAppComponent/PinnedAppComponent';
import { PinnedAppAppInterface } from '../../../types/pinned-app.interface';
import { ZidCol, ZidContainer, ZidIcon, ZidRow, ZidTooltip } from '@zidsa/ui';
import { RoutingRouteEnum } from '../../../../routing/route.enum';

import styles from './PinnedApps.scss';

@Component
export default class PinnedApps extends Vue {
  private isExpanded = false;

  render(h: CreateElement): VNode {
    return (
      <DropdownBaseComponent
        id='pinned-apps-dropdown'
        isExpanded={this.isExpanded}
        onChange={this.onChangeDropdown}
        class={styles.pinnedApps}
      >
        <DropdownBaseHeaderComponent expandedClass={styles['pinnedApps__dropdown__header--expanded']}>
          <DropdownBaseToggleExpandedComponent class={styles.pinnedApps__dropdown__button_wrapper}>
            <ZidTooltip>
              <button class={styles.pinnedApps__dropdown__button}>
                <BaseIcon width='16' height='16' viewBox='0 0 16 16'>
                  <AppsIcon />
                </BaseIcon>
              </button>
              <template slot='tooltip'>{this.$t(I18nMessages['statusbar.myApps'])}</template>
            </ZidTooltip>
          </DropdownBaseToggleExpandedComponent>
        </DropdownBaseHeaderComponent>
        <DropdownBaseBodyComponent class={styles.pinnedApps__dropdown__body}>
          <ZidContainer class={styles.pinnedApps__dropdown__body_container}>
            {this.myPinnedApps && this.myPinnedApps?.length > 0 && (
              <ZidRow class={styles.pinnedApps__dropdown__body_container_row}>
                {this.myPinnedApps?.map((app) => (
                  <ZidCol class={styles.pinnedApps__dropdown__body_container_row_col} key={app.id} cols={4}>
                    <div
                      id='pinned-app'
                      class={styles.pinnedApps__dropdown__body_container_row_col_app}
                      onClick={(): void => this.onOpenEmbeddedApp(app.id)}
                    >
                      <PinnedAppComponent pinnedApp={app} />
                    </div>
                  </ZidCol>
                ))}
              </ZidRow>
            )}
            {(!this.myPinnedApps || this.myPinnedApps?.length === 0) && (
              <div class={styles.pinnedApps__dropdown__body_container_empty}>
                <span class={styles.pinnedApps__dropdown__body_container_empty_text}>
                  {this.$t(I18nMessages['statusbar.myApps.empty'])}
                </span>
              </div>
            )}
          </ZidContainer>
          <div class={styles.pinnedApps__dropdown__body_bottom}>
            <div
              id='browse-app-market-btn'
              class={styles.pinnedApps__dropdown__body_bottom_redirect_btn}
              onClick={(): void => this.onAppsManagement()}
            >
              <span class={styles.pinnedApps__dropdown__body_bottom_redirect_btn_text}>
                {' '}
                {this.$t(I18nMessages['statusbar.myApps.redirect.zam'])}{' '}
              </span>
              <ZidIcon
                class={styles.pinnedApps__dropdown__body_bottom_redirect_btn_icon}
                icon='arrow_right'
                size='xxs'
              />
            </div>
          </div>
        </DropdownBaseBodyComponent>
      </DropdownBaseComponent>
    );
  }

  private get myPinnedApps(): PinnedAppAppInterface[] | undefined {
    return UserStoreModule?.data?.store.pinned_apps;
  }

  private onChangeDropdown(event: DropdownBaseChangeEventInterface): void {
    this.isExpanded = event.state.isExpanded;
  }

  private onCloseDropdown(): void {
    this.isExpanded = false;
  }

  private onOpenEmbeddedApp(appID: number) {
    this.onCloseDropdown();
    this.$router.push({ name: RoutingRouteEnum.appMarketEmbedded, params: { id: `${appID}` } });
  }

  private onAppsManagement(): void {
    this.onCloseDropdown();
    if (this.$route.name != RoutingRouteEnum.appMarketMyApps)
      this.$router.push({ name: RoutingRouteEnum.appMarketMyApps });
  }
}
