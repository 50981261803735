export const ordersApiUrls = {
  orders: '/api/v1/orders',
  orderDetails: '/api/v1/orders/{orderId}',
  returnOrder: '/api/v1/reverse-order/{orderId}',
  reverseOrderReasons: '/api/v1/reverse-order/reasons',
  returnOrderRequest: '/api/v1/reverse-order',
  requestReverseWaybill: '/api/v1/reverse-orders/waybill',
  orderChangeStatus: '/api/v1/orders/{orderId}/change-status',
  editableOrder: '/orders/{orderId}/edit',
  exportOrders: '/api/v1/orders/export',
  airwayBills: '/api/v1/orders/waybills',
  salesReport: '/api/v1/orders/sales-report',
  downloadOrders: '/orders/download',
  prepareOrders: '/api/v1/orders/prepare',
  bulkAction: '/api/v1/orders/bulk-action',
  bulkActionStatus: '/api/v1/orders/bulk-action/{actionId}',
  orderCreateDraft: '/api/v1/orders/draft/id',
  orderDraftSession: '/api/v1/orders/draft/{sessionId}',
  orderDraftCurrency: '/api/v1/orders/draft/{sessionId}/currency',
  uploadImageOption: '/api/v1/orders/draft/{sessionId}/upload-image',
  uploadFileOption: '/api/v1/orders/draft/{sessionId}/upload-file',
  orderDraftProducts: '/api/v1/orders/draft/{sessionId}/products',
  orderDraftCustomer: '/api/v1/orders/draft/{sessionId}/customer',
  orderDraftCustomerAddresses: '/api/v1/orders/draft/{sessionId}/customer/addresses',
  orderDraftCustomerAddress: '/api/v1/orders/draft/{sessionId}/customer/address',
  orderDraftDeliveryOptions: '/api/v1/orders/draft/{sessionId}/delivery',
  orderDraftPaymentMethods: '/api/v1/orders/draft/{sessionId}/payment',
  orderDraftVerification: '/api/v1/orders/draft/{sessionId}/verify',
  orderDraftCoupon: '/api/v1/orders/draft/{sessionId}/coupon',
  orderDraftConfirmation: '/api/v1/orders/draft/{sessionId}/confirm',
  orderDrafts: '/api/v1/orders/drafts',
  orderPickupOptions: '/api/v1/orders/{orderId}/pickup-options',
  confirmBankTransfer: '/api/v1/orders/{orderId}/confirm-bank-transfer',
  orderDraftUpdateProducts: '/api/v1/orders/draft/{sessionId}/products/update',
  orderDraftDeleteProduct: '/api/v1/orders/draft/{sessionId}/products/delete',
  ordersInvoices: '/api/v1/orders/invoices',
  ordersSummaries: '/api/v1/orders/summaries',
  deleteOrderTag: '/api/v1/order/{orderId}/tags/{tagId}/delete',
  addOrderTag: '/api/v1/order/{orderId}/tags',
  getOrdersTags: '/api/v1/orders/tags',
  updateOrderPaymentStatus: '/api/v1/orders/{orderId}/payment-status',
  donationsReport: '/api/v1/products/ngo-sales-report',
  giftImageBase64: '/api/v1/orders/gift-image-base64',
  addOrderComment: '/api/v1/orders/{orderId}/add-order-comment',
  setOrderShippingMethod: '/api/v1/orders/set-shipping-method',
  getOrderPII: '/api/v1/orders/get-marketplace-pii',
};
