import ApplePay from './networks/apple-pay-logo.svg';
import AppleVisa from './networks/apple-visa.svg';
import AppleMada from './networks/apple-mada.svg';
import Visa from './networks/visa-master.svg';
import Mada from './networks/mada.svg';
import Net from './networks/knet.svg';
import Amrican from './networks/american-express.svg';
import Sadad from './networks/sadad.svg';
import Discover from './networks/Discover-02.svg';
import Aman from './networks/OmanNet.svg';
import Faster from './networks/Faster-Payments-01.svg';
import Direct from './networks/Debit.svg';
import Penfet from './networks/Benefit.svg';
import Benefit from './networks/Benefit.svg';
import Stc from './stc.png';
import Logo from './logo.png';
export { default as PaymentLinksIcon } from './payment-links-icon.vue';
export { default as PaymentLinksIconSelected } from './payment-links-icon-selected.vue';
import PaymentLinksNotification from './paymentlink-notification.png';
import IconOne from './iconOne.png';
import IconTwo from './iconTwo.png';
import IconThree from './iconThree.png';
import CurrentOne from './balance.svg';
import CurrentTwo from './deposit.svg';
import CurrentThree from './deposit.svg';
import LandingPageIconOne from './landingPageIconOne.png';
import LandingPageIconTwo from './landingPageIconTwo.png';
import LandingPageIconThree from './landingPageIconThree.png';
import VisaOnly from './visaOnly.png';
import Master from './master.png';
import BankIcon from './bankIcon.png';
import Photoreview from './photoreview.png';
import AttachPhoto from './attach.svg';
import Flag from './saudi flag.svg';
import KWFlag from './kuwait flag.svg';
import Sucess from './sucess.png';
import ZidPayLogo from './zidpayLogo.svg';
import ZidPayActivationLogo from './zidpayactivationlogo.png';
import NotifyMe from './NotifyMe.svg';
import Search from './search.svg';
import clockTimer from './clockTimer.svg';
import Delete from './delete.png';
import WhatsApp from './whatsApp.png';
import pdfFile from './pdf-file-icon.svg';
import xlsFile from './xls-file-icon.svg';
import docxFile from './docx-file-icon.svg';
import checkCircle from './checkCircle.svg';
import eye from './eye.svg';
import Pdf from './pdf-file-icon.svg';
import Preview from './preview.svg';
import nationalAddressSample from './national-address-sample.svg';
import DoubleCheck from '../icons/double-check.svg';
import Dollar from '../icons/dollar.svg';
import PaymentLink from '../icons/payment-link.svg';
import Monitor from '../icons/monitor.svg';
import Phone from '../icons/phone.svg';
import Card from '../icons/card.svg';
import Check from '../icons/check.svg';
import GreenCheck from '../icons/green-check.svg';
import RocketPurple from '../icons/rocket-purple.svg';
import Rocket from '../icons/rocket.svg';
import Welcome from '../icons/welcome.svg';
import WomanAR from './woman-ar.svg';
import WomanKWAR from './woman-ar-kw.svg';
import WomanEN from './woman-en.svg';
import WomanKWEN from './woman-en-kw.svg';
import ZidpayAR from './zidpay-rebranded-ar.svg';
import ZidpayEN from './zidpay-rebranded-en.svg';
import ZidpayARColored from './Zid Pay colored(Arabic).svg';
import Individuals from '../icons/individuals.svg';
import Company from '../icons/company.svg';
import Charity from '../icons/charity.svg';
import ArrowHighlighted from '../icons/arrow-highlighted.svg';
import Arrow from '../icons/arrow.svg';
import CameraHighlighted from '../icons/camera-highlighted.svg';
import Nafath from '../icons/nafath.svg';
import Payments from '../icons/payments.svg';
import Transactions from '../icons/transactions.svg';
import ExternalLink from '../icons/external-link.svg';
import Hello from '../icons/hello.svg';
import Balance from '../icons/balance.svg';
import Held from '../icons/held.svg';
import Withdrawal from '../icons/withdrawal.svg';
import LoadingSkeleton from '../icons/loading-skeleton.svg';
import EmptyChart from '../icons/empty-chart.svg';
import Receipt from '../icons/receipt.svg';
import ReceiptWhite from '../icons/white-receipt.svg';
import ArrowLeft from '../icons/arrow-left.svg';
import SearchIcon from '../icons/search.svg';
import CheckInversed from '../icons/check-inversed.svg';
import ExportIcon from '../icons/export.svg';
import FilterIcon from '../icons/filter.svg';
import CopyIcon from '../icons/copy.svg';
import User from '../icons/u_user.svg';
import Telephone from '../icons/u_phone.svg';
import ATM from '../icons/u_card-atm.svg';
import Mail from '../icons/u_envelope-alt.svg';
import Country from '../icons/u_location-point.svg';
import ZidPayRebrand from '../imgs/zidpay-rebrand.svg';
import Edit from '../icons/edit.svg';
import EditWhite from '../icons/edit-white.svg';
import TV from '../icons/tv.svg';
import Award from '../icons/award.svg';
import HeldBalance from '../icons/held-balance.svg';
import File from '../icons/file.svg';
import WhiteFilter from '../icons/white-filter.svg';
import Download from '../icons/download.svg';
import Bank from '../icons/bank.svg';

export default {
  NotifyMe,
  Search,
  ApplePay,
  AppleVisa,
  AppleMada,
  Visa,
  Mada,
  Stc,
  Sadad,
  Net,
  Discover,
  Aman,
  Amrican,
  Faster,
  Direct,
  Penfet,
  Logo,
  PaymentLinksNotification,
  IconOne,
  IconTwo,
  IconThree,
  CurrentOne,
  CurrentTwo,
  CurrentThree,
  LandingPageIconOne,
  LandingPageIconTwo,
  LandingPageIconThree,
  VisaOnly,
  Master,
  BankIcon,
  Photoreview,
  AttachPhoto,
  Flag,
  KWFlag,
  Sucess,
  ZidPayLogo,
  Benefit,
  ZidPayActivationLogo,
  clockTimer,
  Delete,
  pdfFile,
  xlsFile,
  docxFile,
  WhatsApp,
  checkCircle,
  eye,
  Pdf,
  Preview,
  nationalAddressSample,
  DoubleCheck,
  Dollar,
  PaymentLink,
  Monitor,
  Phone,
  Card,
  Check,
  GreenCheck,
  RocketPurple,
  Rocket,
  Welcome,
  WomanAR,
  WomanKWAR,
  WomanEN,
  WomanKWEN,
  ZidpayAR,
  ZidpayEN,
  ZidpayARColored,
  Individuals,
  Company,
  Charity,
  ArrowHighlighted,
  Arrow,
  CameraHighlighted,
  Nafath,
  Payments,
  Transactions,
  ExternalLink,
  Hello,
  Balance,
  Held,
  Withdrawal,
  LoadingSkeleton,
  EmptyChart,
  Receipt,
  ReceiptWhite,
  ArrowLeft,
  SearchIcon,
  CheckInversed,
  ExportIcon,
  FilterIcon,
  CopyIcon,
  User,
  Telephone,
  ATM,
  Mail,
  Country,
  ZidPayRebrand,
  Edit,
  EditWhite,
  TV,
  Award,
  HeldBalance,
  File,
  WhiteFilter,
  Download,
  Bank,
};
