// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ab2c2fe6{display:flex;gap:5px;margin-top:var(--zid-spacing-20)}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/VerifyWallet/BusinessTypeSelect/BusinessTypeSelect.module.scss"],"names":[],"mappings":"AAAA,UACI,YAAA,CACA,OAAA,CACA,gCAAA","sourcesContent":[".business__type {\n    display: flex;\n    gap: 5px;\n    margin-top: var(--zid-spacing-20);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"business__type": "ab2c2fe6"
};
export default ___CSS_LOADER_EXPORT___;
