// https://github.com/zidsa/zid_api/blob/master/app/Foundation/Services/Subscriptions/Packages/PackageFactory.php#L12
export const enum ApiModelSubscriptionPackageCodeGroupEnum {
  business = 'business',
  ngo = 'ngo',
  trial = 'trial',
  starter = 'starter',
  growth = 'growth',
  enterprise = 'enterprise',
  professional = 'professional',
  newProfessional = 'new_professional',
  zidLite = 'zid_lite',
  newGrowth = 'new_growth',
}

export const enum ApiModelSubscriptionPackageCodeEnum {
  freeTrial = 'trial.trial',
  sales = 'sales',
  delivery = 'delivery',
  freeCredits = 'freeCredits',
  zidCredits15 = 'zid_credits_15',
  zidCredits50 = 'zid_credits_50',
  zidCredits100 = 'zid_credits_100',
  zidCreditsMonthlyUnlimited = 'zid_credits_monthly_unlimited',
  zidCreditsYearlyUnlimited = 'zid_credits_yearly_unlimited',
  zidRevenueShareStarter = 'zid_revenue_share_starter',
  zidRevenueShareAdvanced = 'zid_revenue_share_advanced',
  zidRevenueShareProfessional = 'zid_revenue_share_professional',
  free = 'free',
  ventureMonthly = 'venture_monthly',
  ventureYearly = 'venture_yearly',
  businessMonthly = 'business_monthly',
  businessYearly = 'business_yearly',
  growthMonthly = 'growth_monthly',
  growthYearly = 'growth_yearly',
  enterprise = 'enterprise',
  ngoYearly = 'ngo.yearly',
}
