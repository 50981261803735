// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".abe0eddb{height:30px;padding:10px;border:1px solid gray;cursor:pointer}.abe0eddb:focus{border:1px solid red}", "",{"version":3,"sources":["webpack://./src/common/components/Input/Draggable/Draggable.scss"],"names":[],"mappings":"AACE,UACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,cAAA,CACA,gBACE,oBAAA","sourcesContent":[".draggable {\n  &__item {\n    height: 30px;\n    padding: 10px;\n    border: 1px solid grey;\n    cursor: pointer;\n    &:focus {\n      border: 1px solid red;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draggable__item": "abe0eddb"
};
export default ___CSS_LOADER_EXPORT___;
