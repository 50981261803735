import { ApiBaseDataResponseObjectType } from '../../api/types';
import { CountryCode } from '../countries/shared/CountryValidation/CountryValidation.enum';
import { Network } from './Networks.interface';

export enum AccountType {
  // left handside is zidpay convention and right side is core convention
  corporate = 'establishment',
  company = 'corporate',
  individual = 'individual',
  charity = 'ngo',
}

export interface KuwaitOwnersImgsUrl {
  ownerIdBackImgUrl: string;
  ownerIdFrontImgUrl: string;
}
export interface RequiredFields {
  EnteredFileds: EnteredFileds[];
  basic: MerchantProfileBasicEnum[];
  headquarter: MerchantProfileHeadquarterEnum[];
  commercial: MerchantProfileCommercialEnum[];
  bankCertificate?: MerchantProfileCommercialEnum[];
  package: MerchantProfilePackageEnum[];
}
export enum EnteredFileds {
  bankId = 'bank_id',
  expectedTransactionsPerMonth = 'expected_transactions_per_month',
  expectedTransactionsVolumePerMonth = 'expected_transactions_volume_per_month',
  englishAccountHolderName = 'english_account_holder_name',
  Iban = 'iban',
  Networks = 'networks',
}

export enum MerchantProfileCommercialEnum {
  organizationName = 'organizationName',
  commercialName = 'commercialName',
  accountType = 'accountType',
  commercialRegisterClassification = 'commercialRegisterClassification',
  categoryId = 'categoryId',
  vatNumber = 'vatNumber',
  commercialRegisterNumber = 'commercialRegisterNumber',
  vatRegistrationCertificateImgUrl = 'vatRegistrationCertificateImgUrl',
  commercialRegisterImgUrl = 'commercialRegisterImgUrl',
  ngoCertificateImgUrl = 'ngoCertificateImgUrl',
  regulationImgUrl = 'regulationImgUrl',
  directorsFormationImgUrl = 'directorsFormationImgUrl',
  directorsIdsImgsUrl = 'directorsIdsImgsUrl',
  articleOfAssociationImgUrl = 'articleOfAssociationImgUrl',
  freelanceImgUrl = 'freelanceImgUrl',
  bankCertificateImgUrl = 'bankCertificateImgUrl',
  commercialLicenseImgUrl = 'commercialLicenseImgUrl',
  activityLicenseImgUrl = 'activityLicenseImgUrl',
  signatureImgUrl = 'signatureImgUrl',
  kuwaitOwnersImgsUrl = 'kuwaitOwnersImgsUrl',
  saudiOwnersImgsUrl = 'saudiOwnersImgsUrl',
  ngoName = 'ngoName',
  commercialLicenseNumber = 'commercialLicenseNumber',
}

export enum MerchantProfileBasicEnum {
  name = 'name',
  ngoOwnerName = 'ngoOwnerName',
  idImgUrl = 'idImgUrl',
  idNumber = 'idNumber',
  phoneNumber = 'phoneNumber',
  email = 'email',
  idImg = 'idImg',
  idImgFrontUrl = 'idImgFrontUrl',
  idImgBackUrl = 'idImgBackUrl',
  companyKuwaitOwnerId = 'companyKuwaitOwnerId',
  companyKuwaitOwnerName = 'companyKuwaitOwnerName',
  companyKuwaitOwnerIdFrontImgUrl = 'companyKuwaitOwnerIdFrontImgUrl',
  companyKuwaitOwnerIdBackImgUrl = 'companyKuwaitOwnerIdBackImgUrl',
  companySaudiOwnerId = 'companySaudiOwnerId',
  companySaudiOwnerName = 'companySaudiOwnerName',
  companySaudiOwnerIdImgUrl = 'companySaudiOwnerIdImgUrl',
}

export enum MerchantProfileHeadquarterEnum {
  country = 'country',
  countryCode = 'countryCode',
  city = 'city',
  district = 'district',
  street = 'street',
  nationalAddressCertificateImgUrl = 'nationalAddressCertificateImgUrl',
}

export enum MerchantProfilePackageEnum {
  id = 'id',
  currentPackage = 'currentPackage',
}
export type MerchantProfileEnteredFields = Partial<
  Omit<Record<EnteredFileds, string>, EnteredFileds.Networks> & Record<EnteredFileds.Networks, Network[]>
>;
export type MerchantProfilePackage = Partial<Record<MerchantProfilePackageEnum, string>>;
export type MerchantProfileBasic = Partial<Record<MerchantProfileBasicEnum, string>>;
export type MerchantProfileHeadquarter = Partial<
  Record<MerchantProfileHeadquarterEnum, string> & Record<MerchantProfileHeadquarterEnum.countryCode, CountryCode>
>;
export type MerchantProfileCommercial = Partial<
  Omit<
    Record<MerchantProfileCommercialEnum, string>,
    | MerchantProfileCommercialEnum.kuwaitOwnersImgsUrl
    | MerchantProfileCommercialEnum.saudiOwnersImgsUrl
    | MerchantProfileCommercialEnum.directorsIdsImgsUrl
  > &
    Record<MerchantProfileCommercialEnum.kuwaitOwnersImgsUrl, KuwaitOwnersImgsUrl[]> &
    Record<MerchantProfileCommercialEnum.saudiOwnersImgsUrl, string[]> &
    Record<MerchantProfileCommercialEnum.directorsIdsImgsUrl, string[]> &
    Record<MerchantProfileCommercialEnum.accountType, AccountType>
>;

export interface MerchantProfile {
  basic?: MerchantProfileBasic;
  headquarter?: MerchantProfileHeadquarter;
  commercial?: MerchantProfileCommercial;
  package?: MerchantProfilePackage;
  activePaymentGateways?: string[];
  isTrial?: boolean;
  isZidpayEnabled?: boolean;
  storeAgeInMonths?: number;
  subscriptionRemainingInMonths?: number;
  annualSales?: number;
  numberOfBranches?: string;
  last3MonthsAvgSales?: number;
  last6MonthsAvgSales?: number;
  last9MonthsAvgSales?: number;
}

export type MerchantProfileApiResponseInterface = ApiBaseDataResponseObjectType<MerchantProfile>;
