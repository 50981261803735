import urls from './urls';
import AppApiHttpMethodEnum from '../../api/http-method.enum';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import AppApiClient from '../../api/client';
import { ApiBaseDataResponseObjectType, ApiBaseResponseType } from '../../api/types';
import ApiModelUserInterface from '../../api/model/user.interface';

export default class ApiUserService {
  private readonly apiClient: AppApiClient;

  constructor(apiClient: AppApiClient) {
    this.apiClient = apiClient;
  }

  async getUserDetails(): Promise<ApiBaseDataResponseObjectType<ApiModelUserInterface>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelUserInterface>>(
      AppApiHttpMethodEnum.get,
      urls.details,
      { cache: { ignoreCache: true } },
    );

    return response.data;
  }

  sendVerificationMerchantMailLink(): Promise<ApiBaseResponseType<{ message: string }>> {
    return this.apiClient.request(AppApiHttpMethodEnum.post, urls.confirmationEmailLink, {
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        response_json: true,
      },
    });
  }

  logout(): Promise<ApiBaseResponseType> {
    return this.apiClient.request(ApiHttpMethodEnum.post, urls.logout);
  }
}
