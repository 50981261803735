export enum CountryCode {
  SA = 'SA',
  KW = 'KW',
}

export enum CountryCodeSource {
  zidpayDetails,
  zidpayProfile,
}

export enum ZidpayPage {
  zidpayMarketing,
  zidpaySettings,
}
