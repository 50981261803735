import { Component, Prop } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { CreateElement } from 'vue/types/umd';
import styles from './LoaderModal.module.scss';
import { ZidHeading, ZidModal } from '@zidsa/ui';
import creditCard from '../../../assets/images/credit-card.svg';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidWalletStoreModule } from '../../../store/wallet.module';
import { ZidWalletVerificationStatus } from '../../../types/Wallet/wallet';

@Component
export class ZidWalletComponentsAddFundsLoaderModalComponent extends Vue {
  @Prop()
  private readonly showLoaderModal!: boolean;

  private get isWalletVerifying(): boolean {
    return ZidWalletStoreModule.data?.verificationStatus === ZidWalletVerificationStatus.REVIEW_PENDING;
  }

  private get isWalletRejected(): boolean {
    return ZidWalletStoreModule.data?.verificationStatus === ZidWalletVerificationStatus.VERIFICATION_REJECTED;
  }

  private get isWalletToBeVerified(): boolean {
    return ZidWalletStoreModule.data?.verificationStatus === ZidWalletVerificationStatus.UNVERIFIED;
  }

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidModal
          class={styles['loader-modal']}
          visible={this.showLoaderModal}
          title={this.$t(I18nMessages['wallet.wallet_status.header'])}
          onClose={this.onToggleLoaderModal}
        >
          <main>{this.renderPaymentStatus(h)}</main>
        </ZidModal>
      </div>
    );
  }

  private renderPaymentStatus(h: CreateElement): VNode {
    return (
      <div>
        <div class={styles['loader-modal--content']}>
          {/* Added animation for img is in the svg file */}
          <img src={creditCard} />
          <ZidHeading level={6} type={'primary'} weight={'semibold'}>
            {this.isWalletVerifying && this.$t(I18nMessages['wallet.payment_status.review'])}
            {this.isWalletRejected && this.$t(I18nMessages['wallet.rejected'])}
            {this.isWalletToBeVerified && this.$t(I18nMessages['wallet.to.be.verified'])}
          </ZidHeading>
          <p>
            {this.isWalletVerifying && this.$t(I18nMessages['wallet.payment_status.review.description'])}
            {this.isWalletRejected && this.$t(I18nMessages['wallet.rejected.description'])}
            {this.isWalletToBeVerified && this.$t(I18nMessages['wallet.to.be.verified.description'])}
          </p>
        </div>
      </div>
    );
  }

  private onToggleLoaderModal(): void {
    this.$emit('toggleLoaderModal');
  }
}
