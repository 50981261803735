import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import styles from './ZidWalletFileSelect.module.scss';
import { ZidText } from '@zidsa/ui';
import { I18nMessages } from '../../../../i18n/messages';
import removeIcon from '../../../assets/icons/remove-icon.svg';

@Component
export class ZidWalletComponentsFileSelectComponent extends Vue {
  @Prop()
  private readonly file!: File[] | undefined;

  @Prop()
  private readonly fileName!: string;

  @Prop()
  private readonly defaultFileName!: string;

  render(h: CreateElement): VNode {
    const { file, fileName, defaultFileName } = this;
    return (
      <div class={styles.upload}>
        <div class={styles.upload__content}>
          {file && file.length && (
            <div class={styles.upload__file}>
              {fileName.length > 0 ? (
                <ZidText>{fileName}</ZidText>
              ) : (
                <ZidText>{this.$t(I18nMessages[defaultFileName as keyof I18nMessages])}</ZidText>
              )}
              <img src={removeIcon} onClick={this.onRemoveFilesFieldChange} role={'button'} />
            </div>
          )}
        </div>
      </div>
    );
  }

  private onRemoveFilesFieldChange(): void {
    this.$emit('removeFiles');
  }
}
