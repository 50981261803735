import { computed, ref } from 'vue';
import {
  GlobalSearchCategoriesInterface,
  GlobalSearchResultsInterface,
  UseGlobalSearchInterface,
} from '../types/global-search.interface';
import { globalSearchModule } from '../store/GlobalSearch/module';
import { debounce } from 'lodash';
import { InputTextChangeEventInterface } from '../../common/components/Input';
import AppStoreReadyStateEnum from '../store/ready-state.enum';
import { MixpanelService } from '../../mixpanel/api/service';
import { MixpanelEvents } from '../../mixpanel/api/events.enum';

export function useGlobalSearch(): UseGlobalSearchInterface {
  const mixpanel = new MixpanelService();
  const searchQuery = computed((): string => globalSearchModule.searchQuery);
  const mimSearchLimit = ref<number>(3);
  const selectedCategory = computed(
    (): GlobalSearchCategoriesInterface | null => globalSearchModule.selectedSearchCategories,
  );
  const isSearchLoading = computed((): boolean =>
    [AppStoreReadyStateEnum.loading].includes(globalSearchModule.loadingState),
  );
  const searchResults = computed((): GlobalSearchResultsInterface => globalSearchModule.searchResult);
  const searchResultsCount = computed((): number =>
    Object.values(searchResults.value).reduce((sum, arr) => sum + arr.length, 0),
  );
  const isEmptySearch = computed((): boolean => {
    if (searchQuery.value.length >= mimSearchLimit.value) {
      return Object.values(searchResults.value).every((arr) => Array.isArray(arr) && arr.length === 0);
    }

    return false;
  });
  const hasNotSearched = computed((): boolean => globalSearchModule.searchQuery.length < mimSearchLimit.value);

  const resetSearchResultsStatus = (): void => {
    globalSearchModule.SET_CATEGORY_SEARCH_RESULT_STATUS(false);
    globalSearchModule.SET_GLOBAL_SEARCH_RESULT_STATUS(false);
  };

  const handleSearch = async (searchQuery: string): Promise<void> => {
    resetSearchResultsStatus();

    if (searchQuery.length < mimSearchLimit.value) {
      globalSearchModule.RESET_SEARCH_RESULTS();
      globalSearchModule.ABORT_BULK_FETCH();
      return;
    }

    const mixpanelProps = [
      {
        label: 'keyword',
        value: searchQuery,
      },
    ];
    if (selectedCategory.value)
      mixpanelProps.push({
        label: 'search category',
        value: selectedCategory.value.code,
      });
    mixpanel.track(MixpanelEvents.globalSearch, mixpanelProps);

    if (selectedCategory.value) {
      switch (selectedCategory.value.code) {
        case 'orders':
          await globalSearchModule.searchOrders(searchQuery);
          break;
        case 'products':
          await globalSearchModule.searchProducts(searchQuery);
          break;
        case 'customers':
          await globalSearchModule.searchCustomers(searchQuery);
          break;
        case 'appMarket':
          await globalSearchModule.searchAppMarket(searchQuery);
          break;
        case 'features':
          await globalSearchModule.searchFeatures({
            search: searchQuery,
            page: 1,
            perPage: 10,
          });
      }
    } else {
      await globalSearchModule.globalSearch(searchQuery);
    }
  };

  const performSearch = debounce((search: InputTextChangeEventInterface) => {
    globalSearchModule.SET_SEARCH_QUERY(search.value);
    handleSearch(search.value);
  }, 600);

  return {
    performSearch,
    selectedCategory,
    isSearchLoading,
    isEmptySearch,
    searchResults,
    hasNotSearched,
    handleSearch,
    searchQuery,
    searchResultsCount,
  };
}
