export const enum BadgeTypeEnum {
  light = 'light',
  dark = 'dark',
  danger = 'danger',
  warning = 'warning',
  info = 'info',
  success = 'success',
  secondary = 'secondary',
  primary = 'primary',
  successLight = 'success_light',
  neutral = 'neutral',
}

export const enum BadgeTypeMenuBased {
  new = 'new',
  update = 'update',
  soon = 'soon',
  message = 'message',
  live = 'live',
  nationalDay = 'nationalDay',
}
