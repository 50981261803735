export function toEnglishDigits(arabicNumber: string | number): number {
  // Arabic to English digit mapping
  const arabicToEnglishMap: { [key: string]: string } = {
    '٠': '0',
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
  };

  // Convert the Arabic number to a string if it's not already
  return Number(arabicNumber.toString().replace(/[٠-٩]/g, (char) => arabicToEnglishMap[char]));
}
