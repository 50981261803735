import { InputTagsChangeEventInterface } from './change-event.interface';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { InputTagsOptionInterface } from './option.interface';
import { I18nMessages } from '../../../../i18n/messages';
import styles from './Tags.scss';

@Component({
  components: {
    Multiselect,
  },
})
export default class Tags extends Vue {
  @Prop({
    required: true,
  })
  readonly options!: Array<InputTagsOptionInterface>;

  @Prop({
    required: true,
  })
  readonly value!: Array<any>;

  @Prop({
    default: false,
  })
  readonly isLoading!: boolean;

  @Prop({
    default: 'common.forms.choose',
  })
  readonly placeholder!: string;

  @Prop()
  readonly name!: string;

  @Prop({
    default: false,
  })
  readonly disabled!: boolean;

  @Prop({
    default: false,
  })
  readonly taggable!: boolean;

  get chooseString(): string {
    return this.$t(I18nMessages['common.forms.choose']).toString();
  }

  get styles() {
    return styles;
  }

  get activePlaceholder(): string {
    return this.value.length === 0
      ? this.$t(I18nMessages[this.placeholder as keyof typeof I18nMessages]).toString()
      : '';
  }

  onChange(tags: any): void {
    const changeEvent: InputTagsChangeEventInterface = {
      value: tags,
    };
    this.$emit('change', changeEvent);
  }

  translate(key: string): string {
    return this.$t(I18nMessages[key as keyof typeof I18nMessages]).toString();
  }
}
