import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ZidIcon } from '@zidsa/ui';
import { ZidIconTypeEnum } from '../../../../common/components/ZidIcon/type.enum';
import Collapsible from '../../../../common/components/Collapsible/Collapsible.vue';
import styles from './NavigationSection.scss';
import { RoutingRouteEnum } from '../../../../routing/route.enum';
import { NotificationsStoreModule } from '../../../../notifications/store/module';
import NotificationTypeEnum from '../../../../notifications/components/type.enum';
import { I18nMessages } from '../../../../i18n/messages';
import Sparkles from '../CustomIcons/sparkles.png';

@Component
export default class NavigationSection extends Vue {
  @Prop({
    required: true,
  })
  private label!: string | VNode;

  @Prop({
    required: true,
  })
  private icon!: ZidIconTypeEnum | null;

  @Prop({
    required: true,
  })
  private customIcon!: string | null;

  @Prop()
  private tag!: VNode;

  @Prop({
    required: true,
  })
  private customIconHighlighted!: string | null;

  @Prop()
  private route!: RoutingRouteEnum;

  @Prop()
  private href!: string;

  @Prop({
    default: false,
  })
  private permissionRequired!: boolean;

  @Prop()
  private subscriptionUpgradeRequired!: boolean;

  @Prop()
  private index!: number;

  @Prop()
  private activeMenu!: number;

  @Prop()
  private subRoutes!: Array<any>;

  @Prop({
    default: false,
  })
  private isSplitted!: boolean;

  @Prop()
  private isHeading!: boolean;

  @Prop()
  private isExpandedByDefault!: boolean;

  @Prop()
  private hasToggledMenuOnRouteChange!: boolean;

  @Prop({
    default: '_self',
  })
  private linkTarget!: string;

  @Prop()
  private iconCustomWidth!: string | null;

  @Prop()
  private iconCustomHeight!: string | null;

  private get hasChildren(): boolean {
    return !!this.$slots.default && this.$slots.default.length > 0;
  }

  private get isHighlighted(): boolean {
    if (this.subRoutes) {
      return (this.subRoutes.includes(this.$route.name) && this.activeMenu === -1) || this.activeMenu === this.index;
    }

    if (this.href) {
      return this.href === this.$route.fullPath;
    }

    return this.activeMenu === this.index || this.route === this.$route.name;
  }

  render(h: CreateElement): VNode | null {
    return (
      <li
        class={{
          [styles.sidebarnavigationsection]: true,
          [styles['sidebarnavigationsection--split']]: this.isSplitted,
          [styles['sidebarnavigationsection--link']]: !this.hasChildren && !this.isHeading,
        }}
      >
        {this.renderGroupHeader(h, this.index)}
        {this.hasChildren && (
          <Collapsible isCollapsed={!this.isHighlighted}>
            <ul
              class={{
                [styles.sidebarnavigationsection__items]: true,
                [styles['sidebarnavigationsection__items--open']]: this.isHighlighted,
              }}
            >
              {this.$slots.default}
            </ul>
          </Collapsible>
        )}
      </li>
    );
  }

  private renderGroupHeader(h: CreateElement, index: number): VNode | null {
    if (this.isHeading) {
      return this.renderSpanItem(h);
    }

    if (!this.href && !this.route && !this.hasChildren) {
      return this.renderSpanItem(h);
    }

    if (this.href || this.hasChildren) {
      return (
        <a
          href={this.href}
          class={{
            [styles.sidebarnavigationsection__link]: true,
            [styles['sidebarnavigationsection__link--open']]: this.isHighlighted && !this.hasChildren,
            [styles['sidebarnavigationsection__haschildren']]: this.hasChildren,
          }}
          onClick={($event: MouseEvent) => this.onClickLink($event, index)}
          target={this.linkTarget}
        >
          <span class={styles.sidebarnavigationsection__labelicon}>
            {this.icon && this.renderItemIcon(h, this.isHighlighted && !this.hasChildren)}
            {this.renderItemLabel(h, this.isHighlighted)}
          </span>
          {this.tag}
          {this.hasChildren && this.renderArrowIcon(h)}
        </a>
      );
    }

    return (
      <router-link
        to={{ name: this.route }}
        class={{
          [styles.sidebarnavigationsection__link]: true,
          [styles['sidebarnavigationsection__link--open']]: this.isHighlighted,
        }}
      >
        <span class={styles.sidebarnavigationsection__labelicon}>
          {this.icon && this.renderItemIcon(h, this.isHighlighted)}
          {this.customIcon && (
            <img
              src={this.isHighlighted ? (this.customIconHighlighted as string) : this.customIcon}
              class={{
                [styles['sidebarnavigationsection__custom-icon']]: true,
                [styles['sidebarnavigationsection__custom-icon--open']]: this.isHighlighted,
              }}
              alt={this.label as string}
            />
          )}
          {this.renderItemLabel(h, this.isHighlighted)}
        </span>
        {this.tag}
        {this.hasChildren && this.renderArrowIcon(h)}
      </router-link>
    );
  }

  private renderSpanItem(h: CreateElement): VNode {
    return (
      <span class={[styles.sidebarnavigationsection__span]}>
        <span class={styles.sidebarnavigationsection__labelicon}>
          {this.icon && this.renderItemIcon(h, false)}
          {this.renderItemLabel(h, this.isHeading)}
          {this.isHeading && <img src={Sparkles} class={styles.sidebarnavigationsection__sparkles} />}
        </span>
        {this.tag && <span class={styles.soonbadge}>{this.tag}</span>}
      </span>
    );
  }

  private renderItemIcon(h: CreateElement, isOpen: boolean): VNode {
    return (
      <ZidIcon
        icon={this.icon}
        color='light'
        size={'xxs'}
        class={{
          [styles.sidebarnavigationsection__icon]: true,
          [styles['sidebarnavigationsection__icon--open']]: isOpen,
        }}
        style={{ '--zid-icon-width': this.iconCustomWidth, '--zid-icon-height': this.iconCustomHeight }}
      />
    );
  }

  private renderItemLabel(h: CreateElement, isOpen: boolean): VNode {
    return (
      <span
        class={{
          [styles.sidebarnavigationsection__label]: true,
          [styles['sidebarnavigationsection__label--open']]: isOpen,
        }}
      >
        {this.label}
      </span>
    );
  }

  private renderArrowIcon(h: CreateElement): VNode {
    return (
      <ZidIcon
        icon={'arrow_down'}
        size={'xxs'}
        class={{
          [styles.sidebarnavigationsection__arrowicon]: true,
          [styles['sidebarnavigationsection__arrowicon--collapsed']]: !this.isHighlighted,
        }}
      />
    );
  }

  private onClickLink(event: MouseEvent, index: number): void {
    if (this.permissionRequired) {
      event.preventDefault();
      NotificationsStoreModule.notify({
        id: '',
        text: this.$t(I18nMessages['common.permission_required']).toString(),
        type: NotificationTypeEnum.danger,
        showBell: false,
        autoDismiss: true,
      });
    }

    if (this.subscriptionUpgradeRequired) {
      event.preventDefault();
      this.$emit('openUpgradeModal');
    }

    this.emitToggleMenuEvent(index);

    if (!this.hasChildren) {
      return;
    }

    event.preventDefault();
  }

  private emitToggleMenuEvent(menuIndex: number): void {
    this.$emit('toggleMenu', menuIndex);
  }

  /*
    We check that the '$route' watcher in the parent component has
    triggered before toggling the menu.

    For now only Zid Solutions will require this.
    If in the future we need more than one to be collapsed by default,
    then activeMenu should be a list instead of a number.

    Ref: CR-2124
  */
  @Watch('hasToggledMenuOnRouteChange')
  onToggleMenu(): void {
    if (this.hasToggledMenuOnRouteChange && this.isExpandedByDefault) {
      this.emitToggleMenuEvent(this.index);
    }
  }
}
