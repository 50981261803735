import { Route } from 'vue-router';
import UserStoreModule from '../../user/store/module';
import { RoutingRouteEnum } from '../route.enum';

export function inventoryAddressesGuard(to: Route, from: Route, next: Function) {
  if (
    UserStoreModule.data?.store.has_multi_product_inventory &&
    to.name == RoutingRouteEnum.settingsInventoryAddresses
  ) {
    next({
      name: RoutingRouteEnum.settingsInventoryAddressesV2,
    });
  } else {
    next();
  }
}
