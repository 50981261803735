import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { setupCache } from 'axios-cache-adapter';

import ApiHttpMethodEnum from './http-method.enum';
import { ApiRequestConfigType, ApiResponseType } from './types';
import { apiParamsSerializer } from './params-serializer';
import UserStoreModule from '../user/store/module';
import { generateUrlFromAxiosRequest } from '../common/helpers/url/generate-url-from-axios-request';

export const cache = setupCache({
  maxAge: 30 * 60 * 1000, // 30 minutes: Maximum time for storing each request in milliseconds
  limit: 30, // Maximum number of cached requests
  key: generateUrlFromAxiosRequest,
  exclude: {
    query: false,
    filter: ({ cacheable }: ApiRequestConfigType): boolean => !Boolean(cacheable),
  },
});

/**
 * API HTTP Client
 *
 * Provides an abstraction for making requests to future-proof the application
 */
export default class ApiClient {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      adapter: cache.adapter,
    });

    this.client.interceptors.response.use(
      (res: AxiosResponse) => {
        return res;
      },
      (error: AxiosError) => {
        if ((error?.response?.data as any)?.message?.code === 'LOGGED_OUT') {
          sessionStorage.removeItem('zid_can_user_see_category_selection_modal');
          window.location.replace('/login');
          return;
        }

        /**
         * Requested by Maciej Sobaczewski
         * Force the user to logout when he tries to hit some endpoint and get an error with message code of `MUST_LOGOUT`
         * JIRA: https://zid-dev.atlassian.net/browse/SHF-578
         */
        if ((error?.response?.data as any)?.message?.code === 'MUST_LOGOUT') {
          UserStoreModule.logout();
          return;
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * Make an API request
   */
  request<ResponseType = any>(
    method: ApiHttpMethodEnum,
    url: string,
    options: ApiRequestConfigType = {},
  ): Promise<ApiResponseType<ResponseType>> {
    return this.client.request<ResponseType>({
      method,
      url,
      paramsSerializer: apiParamsSerializer,
      ...options,
    });
  }
}
