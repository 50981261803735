// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ed48124e{--zid-modal-max-width: 580px}.ed48124e div div{display:flex;flex-direction:column;justify-content:center;align-items:center;gap:var(--zid-spacing-16)}.de3a70e8{margin-top:var(--zid-spacing-40)}.f1878c84{margin:var(--zid-spacing-12);--zid-button-border-radius: var(--zid-border-radius-8);--zid-button-padding: var(--zid-spacing-12)}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/SuccessModal/SuccessModal.module.scss"],"names":[],"mappings":"AAAA,UACE,4BAAA,CACA,kBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,yBAAA,CAGF,UACE,gCAAA,CAGF,UACE,4BAAA,CACA,sDAAA,CACA,2CAAA","sourcesContent":[".success__modal {\n  --zid-modal-max-width: 580px;\n  div div {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: var(--zid-spacing-16);\n  }\n\n  &--icon {\n    margin-top: var(--zid-spacing-40);\n  }\n\n  &--content {\n    margin: var(--zid-spacing-12);\n    --zid-button-border-radius: var(--zid-border-radius-8);\n    --zid-button-padding: var(--zid-spacing-12);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"success__modal": "ed48124e",
	"success__modal--icon": "de3a70e8",
	"success__modal--content": "f1878c84"
};
export default ___CSS_LOADER_EXPORT___;
