import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import { MazeedModule } from '../../mazeed/store/module';

export async function routingGuardsMazeedActivationGuard(to: Route, from: Route, next: Function): Promise<void> {
  await MazeedModule.getStoreSummary();
  if (MazeedModule.checkIfAllowToActivate && MazeedModule.checkStoreInSaudiArabia) {
    next();
  } else {
    next({
      name: RoutingRouteEnum.mazeed,
    });
  }
}
