import keysToValues from '../common/helpers/keys-to-values';
import arData from './data/ar.json';
import enData from './data/en.json';
import I18nLocaleEnum from './locale.enum';

export const I18nMessages = {
  ...keysToValues(arData),
  ...keysToValues(enData),
};

export type I18nMessages = typeof I18nMessages;

const I18nTranslations: Record<I18nLocaleEnum, Record<keyof typeof I18nMessages, string>> = {
  [I18nLocaleEnum.ar]: arData,
  [I18nLocaleEnum.en]: enData,
};

export default I18nTranslations;
