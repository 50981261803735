import { ZidCol, ZidContainer, ZidFileSelector, ZidHeading, ZidLoader, ZidRow, ZidText } from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import styles from './IndividualSection.module.scss';

import { ZidWalletComponentsFileSelectComponent } from '../../Shared/FileSelect/ZidWalletFileSelect';
import { ZidWalletStoreModule } from '../../../store/wallet.module';

@Component
export class VerifyWalletComponentsIndividualSectionComponent extends Vue {
  @Prop()
  private readonly isLoading!: boolean;
  @Prop()
  private readonly submitClicked!: boolean;

  private get civilIdPhoto(): File[] {
    return ZidWalletStoreModule.verificationData?.businessInfo?.nationalIdPhoto
      ? [new File([], ZidWalletStoreModule.verificationData.businessInfo.nationalIdPhoto, { type: 'image/*' })]
      : [];
  }

  private fileName = '';

  render(h: CreateElement): VNode {
    return (
      <ZidContainer class={styles['verify-individual']}>
        <ZidRow
          class={{
            [styles['verify-individual__file-select']]: true,
            [styles['verify-individual__file-select--selected']]: this.civilIdPhoto?.length,
          }}
        >
          <ZidCol class={styles['verify-individual__file-select--row']}>
            <ZidHeading id={'photo-id-heading'} level={6}>
              {this.$t(I18nMessages['wallet.photo_identification'])}
            </ZidHeading>
            {this.isLoading ? (
              <ZidLoader />
            ) : (
              <div>
                {this.civilIdPhoto?.length ? (
                  <ZidWalletComponentsFileSelectComponent
                    file={this.civilIdPhoto}
                    fileName={this.fileName}
                    defaultFileName={I18nMessages['wallet.common.id_card.file']}
                    onHandleImageUpload={this.handleImageUpload}
                    onRemoveFiles={this.onRemoveFilesFieldChange}
                  />
                ) : (
                  <div>
                    <div class={styles['verify-individual__file-select--placeholder']}>
                      <ZidText>{this.$t(I18nMessages['wallet.id_photo.select.placeholder'])}</ZidText>
                    </div>
                    <ZidFileSelector
                      data-identifier={'photo_id_uploader'}
                      accept={'image/*'}
                      loading={this.isLoading}
                      onChange={this.handleImageUpload}
                      class={{
                        [styles['verify-individual__file-select--empty']]:
                          this.submitClicked && !this.civilIdPhoto?.length,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </ZidCol>
        </ZidRow>
      </ZidContainer>
    );
  }

  private handleImageUpload(file: File[]): void {
    const reader = new FileReader();
    if (file && file.length) {
      this.fileName = file[0].name;
      if (this.fileName.length > 20) {
        this.fileName = this.fileName.substring(20);
      }

      reader.readAsDataURL(file[0]);
      reader.onload = (): void => {
        ZidWalletStoreModule.setBusinessData({
          ...ZidWalletStoreModule.verificationData.businessInfo,
          nationalIdPhoto: reader.result as string,
        });
      };
    }
  }

  private onRemoveFilesFieldChange(): void {
    ZidWalletStoreModule.setBusinessData({
      ...ZidWalletStoreModule.verificationData.businessInfo,
      nationalIdPhoto: '',
    });
  }
}
