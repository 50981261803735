import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import styles from './Checkbox.scss';
import { InputCheckboxChangeEventInterface } from './change-event.interface';

let currentId = 0;

@Component
export class InputCheckboxComponent extends Vue {
  private readonly id: number = currentId++;

  @Prop({
    required: true,
  })
  private readonly isChecked!: boolean;

  @Prop({
    default: false,
  })
  private readonly disabled!: boolean;

  @Prop({
    default: false,
  })
  private readonly revert!: boolean;

  render(h: CreateElement): VNode {
    const inputId = `checkbox-${this.id}`;
    const hasLabel = !!this.$slots.default;

    return (
      <div
        class={{
          [styles.checkbox]: true,
          [styles['checkbox--with-label']]: hasLabel,
          [styles['checkbox--disabled']]: this.disabled,
        }}
      >
        <input
          type='checkbox'
          checked={this.isChecked}
          class={styles.checkbox__input}
          id={inputId}
          onChange={this.onChange}
          disabled={this.disabled}
        />
        <label
          class={{
            [styles.checkbox__label]: true,
            [styles.checkbox__label__revert]: this.revert,
            [styles['checkbox__label--disabled']]: this.disabled,
          }}
          for={inputId}
        >
          {this.$slots.default}
        </label>
      </div>
    );
  }

  private onChange(): void {
    const event: InputCheckboxChangeEventInterface = {
      isChecked: !this.isChecked,
    };

    this.$emit('change', event);
  }
}
