// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ae88ec1d{margin-top:10px;margin-bottom:18px;--zid-chip-background-color: none;--zid-chip-border-color: var(--zid-colors-primary-500);--zid-chip-border-radius: 8px;--zid-chip-color: var(--zid-colors-primary-500);--zid-chip-padding: 10px 10px 10px}.ae88ec1d [class~=zid-chip--selected]{--zid-chip-background-color: var(--zid-colors-primary-500);--zid-chip-color: var(--zid-colors-neutrals-white)}@media(max-width: 488px){.ae88ec1d{justify-content:center}}@media(max-width: 397px){.ae88ec1d{--zid-chip-padding: 8px 8px 8px}}@media(max-width: 381px){.ae88ec1d{--zid-chip-padding: 5px 5px 5px}}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/AddFundsModal/Actions/SuggestedFundSelect/SuggestedValueChips.module.scss"],"names":[],"mappings":"AACI,UACI,eAAA,CACA,kBAAA,CACA,iCAAA,CACA,sDAAA,CACA,6BAAA,CACA,+CAAA,CACA,kCAAA,CAEA,sCACI,0DAAA,CACA,kDAAA,CAGJ,yBAdJ,UAeM,sBAAA,CAAA,CAGF,yBAlBJ,UAmBM,+BAAA,CAAA,CAGF,yBAtBJ,UAuBM,+BAAA,CAAA","sourcesContent":[".funds {\n    &__chips {\n        margin-top: 10px;\n        margin-bottom: 18px;\n        --zid-chip-background-color: none;\n        --zid-chip-border-color: var(--zid-colors-primary-500);\n        --zid-chip-border-radius: 8px;\n        --zid-chip-color: var(--zid-colors-primary-500);\n        --zid-chip-padding: 10px 10px 10px;\n        \n        [class~='zid-chip--selected'] {\n            --zid-chip-background-color: var(--zid-colors-primary-500);\n            --zid-chip-color: var(--zid-colors-neutrals-white);\n        }\n\n        @media (max-width: 488px) {\n          justify-content: center;\n        }\n\n        @media (max-width: 397px) {\n          --zid-chip-padding: 8px 8px 8px;\n        }\n\n        @media (max-width: 381px) {\n          --zid-chip-padding: 5px 5px 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"funds__chips": "ae88ec1d"
};
export default ___CSS_LOADER_EXPORT___;
