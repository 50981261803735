import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidWalletApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { ZidWalletInterfaceWalletInterface, ZidWalletTypesBusinessInfoType } from '../types/Wallet/wallet';
import ApiModelUserInterface from '../../api/model/user.interface';
import { ZidWalletUpdateWalletRequestInterface } from '../types/Wallet/update-wallet.interface';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';
const zidWalletService = ZidWalletApiServiceFactory();

@Module({
  dynamic: true,
  name: 'zidWallet',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidWalletModule extends VuexModule {
  public data: ZidWalletInterfaceWalletInterface = {} as ZidWalletInterfaceWalletInterface;
  public verificationData: ZidWalletUpdateWalletRequestInterface = {} as ZidWalletUpdateWalletRequestInterface;
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public userData: ApiModelUserInterface | null = null;
  public error: Error | null = null;
  public isManagerOwner = false;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: any): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Mutation
  private INITIALIZE_VERIFICATION_DATA(): void {
    const businessInfo = this.data.businessInfo || {};

    this.verificationData = {
      phoneNumber: this.data.phoneNumber ?? '',
      email: this.data.email ?? '',
      managerPhoneNumber: this.data.managerPhoneNumber ?? '',
      bankAccountHolderName: '',
      bankAccountNumber: '',
      bankBic: '',
      businessInfo: {
        nationalId: businessInfo.nationalId ?? '',
        nationalIdPhoto: businessInfo.nationalIdPhoto ?? '',
        commercialRegistrationNumber: businessInfo.commercialRegistrationNumber ?? '',
        commercialRegistrationPhoto: businessInfo.commercialRegistrationPhoto ?? '',
        accountType:
          businessInfo.accountType && businessInfo.accountType !== 'ngo' && businessInfo.accountType !== 'establishment'
            ? businessInfo.accountType
            : 'individual',
      },
    };
  }

  @Mutation
  private UPDATE_WALLET_DATA(data: ZidWalletUpdateWalletRequestInterface): void {
    this.verificationData = data;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private UPDATE_TOTAL_BALANCE(balance: number): void {
    this.data.totalBalance = balance;
  }

  @Mutation
  private UPDATE_BUSINESS_DATA(data: ZidWalletTypesBusinessInfoType): void {
    this.verificationData.businessInfo = data;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private SET_MANAGER_OWNER_BOOL(value: boolean): void {
    this.isManagerOwner = value;
  }

  @Action
  public setManagerOwnerBool(value: boolean): void {
    this.SET_MANAGER_OWNER_BOOL(value);
  }

  @Action
  public setWalletData(data: ZidWalletUpdateWalletRequestInterface): void {
    this.UPDATE_WALLET_DATA(data);
  }

  @Action
  public setBusinessData(data: ZidWalletTypesBusinessInfoType): void {
    this.UPDATE_BUSINESS_DATA(data);
  }

  @Action
  public updateTotalBalance(balance: number): void {
    this.UPDATE_TOTAL_BALANCE(balance);
  }

  @Action({ rawError: true })
  public initializeVerificationData(): void {
    this.INITIALIZE_VERIFICATION_DATA();
  }

  @Action({ rawError: true })
  public async updateWallet(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletInterfaceWalletInterface>>
  > {
    this.FETCH();

    return zidWalletService
      .updateWallet(this.verificationData)
      .then((res) => {
        this.FETCH_SUCCESS(res.data);
        return res;
      })
      .catch((err) => {
        this.FETCH_ERROR(err);

        return err.response;
      });
  }

  @Action({ rawError: true })
  public async fetchWallet(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletInterfaceWalletInterface>>
  > {
    this.FETCH();

    return zidWalletService
      .getWallet()
      .then((response) => {
        this.FETCH_SUCCESS(response.data);
        return response;
      })
      .catch((error) => {
        this.FETCH_ERROR(error);

        return error.response;
      });
  }
}

export const ZidWalletStoreModule = getModule(ZidWalletModule);
