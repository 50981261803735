import Vue from 'vue';
import VueI18n from 'vue-i18n';
import I18nLocaleEnum from '../../i18n/locale.enum';
import I18nTranslations from '../../i18n/messages';

export default function appI18nCreate(locale: I18nLocaleEnum) {
  Vue.use(VueI18n);

  const i18n = new VueI18n({
    locale,
    messages: I18nTranslations,
  });

  return i18n;
}
