import {
  ZidHeading,
  ZidIcon,
  ZidLoader,
  ZidTable,
  ZidTableCell,
  ZidTableRow,
  ZidTableRowGroup,
  ZidText,
  ZidTooltip,
} from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import styles from '../WalletInfoTables.module.scss';
import { ZidWalletStoreModule } from '../../../store/wallet.module';
import AppStoreReadyStateEnum from '../../../../app/store/ready-state.enum';
import { AuthUserStoreModule } from '../../../../account/store/user.module';
import { I18nMessages } from '../../../../i18n/messages';

@Component
export class WalletComponentsVirtualWalletInfoComponent extends Vue {
  @Prop()
  private readonly isModal!: boolean;

  private copiedText = '';

  private get isLoading(): boolean {
    const loadingStates = [AppStoreReadyStateEnum.pending, AppStoreReadyStateEnum.loading];

    return (
      loadingStates.includes(AuthUserStoreModule.loadingState) ||
      loadingStates.includes(ZidWalletStoreModule.loadingState)
    );
  }

  private get userName(): string | undefined {
    return ZidWalletStoreModule.data?.storeName;
  }

  private get virtualIban(): string | undefined {
    return ZidWalletStoreModule.data?.virtualBankAccountNumber;
  }

  created(): void {
    AuthUserStoreModule.fetch();
  }

  private renderCopyIcon(h: CreateElement, text: string): VNode {
    const copied = (
      <span class={styles.bank_transfer_modal__copied_text}>
        <ZidIcon icon={'check_mark'} size={'xxs'} color={'success'} />
      </span>
    );
    const copyIcon = <ZidIcon icon={'copy'} size={'xs'} onClick={(): void => this.onClickCopy(text)} />;
    return this.copiedText.includes(text) ? copied : copyIcon;
  }

  render(h: CreateElement): VNode {
    return <div class={styles.wallet__parent}>{this.renderVirtualWallet(h)}</div>;
  }

  private renderVirtualWallet(h: CreateElement): VNode {
    return (
      <div class={styles['wallet__bank-table--column']}>
        <div class={styles['wallet__bank-table--virtual']}>
          <ZidHeading level={6} weight={'semibold'} class={styles['wallet__tooltip']}>
            {this.$t(I18nMessages['wallet.account_information'])}
            <ZidTooltip class={styles.inventory__container__info_sub_title_tooltip} contentWidth='200px'>
              <ZidIcon size={'xxs'} color={'var(--zid-colors-neutrals-400)'} icon='danger_circle_outline' />
              <template slot='tooltip'>{this.$t(I18nMessages['wallet.account_information.tooltip'])}</template>
            </ZidTooltip>
          </ZidHeading>
          {this.isModal && <ZidText>{this.$t(I18nMessages['wallet.topup.virtual_account.tip'])}</ZidText>}
        </div>
        <ZidTable wrapper={false}>
          <ZidTableRowGroup>
            <ZidTableRow>
              <ZidTableCell>
                <ZidText>{this.$t(I18nMessages['zidship.wallet.account_holder_name'])}</ZidText>
              </ZidTableCell>
              <ZidTableCell class={{ [styles['wallet__bank-table--loader']]: this.isLoading }}>
                <ZidText weight={'semibold'}>{this.isLoading ? <ZidLoader size={'small'} /> : this.userName}</ZidText>
              </ZidTableCell>
            </ZidTableRow>
          </ZidTableRowGroup>
          <ZidTableRowGroup>
            <ZidTableCell>
              <ZidText>{this.$t(I18nMessages['zidship.wallet.bank_name'])}</ZidText>
            </ZidTableCell>
            <ZidTableCell>
              <ZidText weight={'semibold'}>{this.$t(I18nMessages['wallet.bank.arabic'])}</ZidText>
            </ZidTableCell>
          </ZidTableRowGroup>
          <ZidTableRowGroup>
            <ZidTableCell>
              <ZidText>{this.$t(I18nMessages['zidship.wallet.iban'])}</ZidText>
            </ZidTableCell>
            <ZidTableCell>
              {this.virtualIban && (
                <div class={styles['wallet__bank-table--copy']}>
                  {this.renderCopyIcon(h, this.virtualIban)}
                  <ZidText type={'primary'} weight={'semibold'}>
                    {this.virtualIban}
                  </ZidText>
                </div>
              )}
            </ZidTableCell>
          </ZidTableRowGroup>
        </ZidTable>
      </div>
    );
  }

  private onClickCopy(text: string): void {
    navigator.clipboard.writeText(text);
    this.copiedText = text;
  }
}
