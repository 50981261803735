import {
  ZidCol,
  ZidContainer,
  ZidHeading,
  ZidIcon,
  ZidInput,
  ZidInputGroup,
  ZidInputLeading,
  ZidInputTrailing,
  ZidRow,
} from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import styles from './UserInfo.module.scss';
import { InputTextChangeEventInterface } from '../../../../common/components/Input';
import { ZidWalletStoreModule } from '../../../store/wallet.module';
import { ZidWalletUpdateWalletRequestInterface } from '../../../types/Wallet/update-wallet.interface';
import { VerifyWalletComponentsCountryKeySelectComponent } from '../../CountryKeySelect/CountryKeySelect';

@Component
export class VerifyWalletComponentsUserInfoComponent extends Vue {
  @Prop()
  private readonly isLoading!: boolean;

  private get userEmail(): string {
    return ZidWalletStoreModule.data?.email ? ZidWalletStoreModule.data?.email : '';
  }

  private get userMobile(): string {
    return !this.isLoading && ZidWalletStoreModule.data?.phoneNumber ? ZidWalletStoreModule.data?.phoneNumber : '';
  }

  private get userMobileWithoutKey(): string | undefined {
    let value: string | undefined;

    if (this.userMobile.startsWith('+20')) {
      value = this.userMobile.substring(3);
    } else {
      value = this.userMobile.substring(4);
    }

    return value;
  }

  private userForm: ZidWalletUpdateWalletRequestInterface = {
    email: '',
    phoneNumber: this.userMobile,
  } as ZidWalletUpdateWalletRequestInterface;

  private countryKey = '';

  render(h: CreateElement): VNode {
    return (
      <ZidContainer class={styles['verify-user']}>
        <ZidRow cols-lg={2} class={styles['verify-user--row']}>
          <ZidCol>
            <ZidHeading id={'mobile-heading'} level={6}>
              {this.$t(I18nMessages['common.phone_number'])}
            </ZidHeading>
            <div class={styles['verify-phone-container']}>
              <ZidInputGroup>
                <ZidInputLeading isInner>
                  <ZidIcon icon={'phone'} size={'xxs'} />
                </ZidInputLeading>
                <ZidInput
                  data-identifier={'mobile_input'}
                  dir={'ltr'}
                  type={'number'}
                  validation={'maxLength:13|required'}
                  value={this.userMobileWithoutKey}
                  onChange={(event: InputTextChangeEventInterface): void => this.onMobileChange(event.value)}
                  placeholder={this.$t(I18nMessages['account.register.mobile.placeholder'])}
                />
                <ZidInputTrailing>
                  <VerifyWalletComponentsCountryKeySelectComponent
                    onCountryKeyChange={this.onCountryKeyChange}
                    userMobile={this.userMobile}
                  />
                </ZidInputTrailing>
              </ZidInputGroup>
            </div>
          </ZidCol>
          <ZidCol>
            <ZidHeading id={'email-header'} level={6}>
              {this.$t(I18nMessages['common.email'])}
            </ZidHeading>
            <ZidInputGroup>
              <ZidInputLeading isInner>
                <ZidIcon icon={'email'} size={'xxs'} />
              </ZidInputLeading>
              <ZidInput
                data-identifier={'email_input'}
                validation={'email|required'}
                value={this.userEmail}
                onChange={(event: InputTextChangeEventInterface): void => this.onEmailChange(event.value)}
                placeholder={this.$t(I18nMessages['account.register.email.placeholder'])}
              />
            </ZidInputGroup>
          </ZidCol>
        </ZidRow>
      </ZidContainer>
    );
  }

  private onCountryKeyChange(value: string): void {
    this.countryKey = value;
    if (this.userMobileWithoutKey) {
      this.userForm.phoneNumber = `${this.countryKey}${this.userMobileWithoutKey}`;
    }
    this.$emit('mobileChanged', this.userForm.phoneNumber);
  }

  private onMobileChange(value: string): void {
    if (value && value.length) {
      this.userForm.phoneNumber = `${this.countryKey}${value}`;
      this.$emit('mobileChanged', this.userForm.phoneNumber);
    }
  }

  private onEmailChange(value: string): void {
    this.userForm.email = value;
    this.$emit('emailChanged', this.userForm.email);
  }
}
