import Vue, { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export class LiveIconComponent extends Vue {
  render(h: CreateElement): VNode {
    return (
      <svg width='15px' height='15px' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <style>{`circle {fill: #FFFFFF;stroke: #FFFFFF;stroke-width: 1px;stroke-opacity: 1;}.pulse {fill: #7F40E2;fill-opacity: 0;transform-origin: 50% 50%;animation-duration: 1.3s;animation-name: pulse;animation-iteration-count: infinite;}@keyframes pulse {from {stroke-width: 5px;stroke-opacity: 1;transform: scale(0.3);}to{stroke-width: 0;stroke-opacity: 0;transform: scale(2);}}`}</style>
        </defs>
        <circle cx='50%' cy='50%' r='4px' />
        <circle class='pulse' cx='50%' cy='50%' r='5px' />
      </svg>
    );
  }
}
