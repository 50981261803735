import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import styles from './Item.scss';
import { RoutingRouteEnum } from '../../../../../routing/route.enum';
import { NotificationsStoreModule } from '../../../../../notifications/store/module';
import { I18nMessages } from '../../../../../i18n/messages';
import NotificationTypeEnum from '../../../../../notifications/components/type.enum';
import { ZidIcon } from '@zidsa/ui';
import starIcon from '../../CustomIcons/star-icon.svg';
import ApiModelUserInterface from '../../../../../api/model/user.interface';
import UserStoreModule from '../../../../../user/store/module';
import { isOldFeatureAvailable, isUsingNewPackageSystem } from '../../../../../common/helpers/is-feature-available';

@Component
export default class AppSidebarNavigationSectionItem extends Vue {
  @Prop()
  href!: string;

  @Prop({
    required: true,
  })
  private label!: string;

  @Prop({
    required: true,
  })
  private icon!: string | null;

  @Prop()
  route!: RoutingRouteEnum;

  @Prop()
  permissionRequired!: boolean;

  @Prop()
  customIcon!: string;

  @Prop({
    default: '_self',
  })
  linkTarget!: string;

  @Prop()
  private readonly siblingRoutes!: RoutingRouteEnum | string;

  get user(): ApiModelUserInterface | null | any {
    return UserStoreModule.data;
  }

  render(h: CreateElement): VNode | null {
    return (
      <li
        class={{
          [styles.sidebarnavigationsectionitem]: true,
          [styles['sidebarnavigationsectionitem--open']]: this.isHighlighted(),
        }}
      >
        <a
          onClick={this.checkLinkPermission}
          class={{
            [styles.sidebarnavigationsectionitem__span]: !this.href && !this.route,
            [styles.sidebarnavigationsectionitem__link]: this.href || this.route,
            [styles['sidebarnavigationsectionitem__link--open']]: this.isHighlighted(),
            [styles['sidebarnavigationsectionitem__link--disabeld']]: this.isDisabeld(),
          }}
        >
          <span class={styles.sidebarnavigationsectionitem__labelicon}>
            {this.icon && (
              <ZidIcon
                icon={this.icon}
                color='light'
                size={'xxs'}
                class={{
                  [styles.sidebarnavigationsection__icon]: true,
                  [styles['sidebarnavigationsection__icon--open']]: this.isHighlighted(),
                }}
              />
            )}
            {this.customIcon && (
              <img src={this.customIcon} class={styles['sidebarnavigationsectionitem__custom-icon']} alt={this.label} />
            )}{' '}
            <span class={styles.sidebarnavigationsectionitem__link__label}>{this.label}</span>
          </span>
          <span>{this.$slots.default}</span>
          {!this.href && !this.route && <span class={styles.soonbadge}>{this.$t(I18nMessages['common.soon'])}</span>}
          {this.isDisabeld() && <img src={starIcon} />}
        </a>
      </li>
    );
  }

  /*
   * TO-DO:
   * - Move to router level when new pages are added
   */
  private checkLinkPermission(event: MouseEvent): void {
    if (this.permissionRequired) {
      event.preventDefault();
      NotificationsStoreModule.notify({
        id: '',
        text: this.$t(I18nMessages['common.permission_required']).toString(),
        type: NotificationTypeEnum.danger,
        showBell: false,
        autoDismiss: true,
      });
      return;
    }
    if (this.route && this.$route.name !== this.route) {
      this.$router
        .push({
          name: this.route,
        })
        .catch((error) => error);
    }
    if (this.href) window.open(this.href as string, this.linkTarget);
  }

  private isHighlighted(): boolean {
    if (this.siblingRoutes.length > 0) {
      return this.route === this.$route.name || this.siblingRoutes.includes(this.$route.name ?? '');
    }

    return this.route === this.$route.name;
  }
  private isDisabeld(): boolean {
    const featureRouteNames: Record<string, string> = {
      [RoutingRouteEnum.abandonedCarts]: 'abandoned_carts.abandoned_carts_details',
      [RoutingRouteEnum.bundleOffers]: 'product_bundles.creating_bundles',
      [RoutingRouteEnum.affiliateList]: 'affiliate_marketing.add_affiliate',
    };

    return Boolean(
      featureRouteNames[this.route] &&
        !isUsingNewPackageSystem() &&
        !isOldFeatureAvailable(featureRouteNames[this.route]),
    );
  }
}
