import { ApiSearchParamsType } from '../../../api/types';
import { objectFilter } from '../../../common/helpers/object/filter';
import { OrdersApiDraftsFiltersInterface } from './filters.interface';
import { OrdersApiDraftsSearchParamsInterface } from './search-params.interface';

export function ordersApiDraftsFiltersToSearchParams(filters: OrdersApiDraftsFiltersInterface): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: OrdersApiDraftsSearchParamsInterface = {
    limit: filters.limit !== undefined ? String(filters.limit) : undefined,
    page: filters.page !== undefined ? String(filters.page) : undefined,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
