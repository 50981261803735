import { Component, Prop } from 'vue-property-decorator';
import Vue, { CreateElement, VNode } from 'vue';
import { ZidButton, ZidHeading, ZidModal, ZidText } from '@zidsa/ui';
import { I18nMessages } from '../../../i18n/messages';

import successCircle from '../../assets/icons/success-circle.svg';
import styles from './SuccessModal.module.scss';

@Component
export class ZidWalletComponentsSuccessModalComponent extends Vue {
  @Prop()
  private readonly showًWithdrawSuccessModal!: boolean;

  @Prop()
  private readonly showOtpSuccessModal!: boolean;

  render(h: CreateElement): VNode {
    return (
      <ZidModal
        visible={this.showًWithdrawSuccessModal || this.showOtpSuccessModal}
        onClose={this.onToggleSuccessModal}
        class={styles.success__modal}
      >
        <img src={successCircle} class={styles['success__modal--icon']} />
        {this.showًWithdrawSuccessModal ? this.renderWithdrawSuccess(h) : this.renderOtpSuccess(h)}
      </ZidModal>
    );
  }

  private renderWithdrawSuccess(h: CreateElement): VNode {
    return (
      <div class={styles['success__modal--content']}>
        <ZidHeading level={4} type={'primary'}>
          {this.$t(I18nMessages['wallet.withdraw_success_modal.message'])}
        </ZidHeading>

        <ZidButton onClick={(): void => this.onBtnClick()}>{this.$t(I18nMessages['wallet.back_to_wallet'])}</ZidButton>
      </div>
    );
  }

  private renderOtpSuccess(h: CreateElement): VNode {
    return (
      <div class={styles['success__modal--content']}>
        <ZidHeading level={4} type={'primary'}>
          {this.$t(I18nMessages['wallet.verification_success.heading'])}
        </ZidHeading>
        <ZidText>{this.$t(I18nMessages['wallet.verification_success.desc'])}</ZidText>
        <ZidButton onClick={(): void => this.onBtnClick()}>{this.$t(I18nMessages['wallet.back_to_wallet'])}</ZidButton>
      </div>
    );
  }

  private onBtnClick(): void {
    window.location.href = '/zidwallet';
  }

  private onToggleSuccessModal(): void {
    this.$emit('toggleSuccessModal');
  }
}
