import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidIconTypeEnum } from './type.enum';
import styles from './ZidIcon.css';

@Component
export default class ZidIconComponent extends Vue {
  @Prop({
    required: true,
  })
  readonly type!: ZidIconTypeEnum;

  private iconTypeToClassNameMap: Record<ZidIconTypeEnum, string> = {
    [ZidIconTypeEnum.user]: styles['zidicon--icon-user'],
    [ZidIconTypeEnum.apps]: styles['zidicon--icon-apps'],
    [ZidIconTypeEnum.arrow]: styles['zidicon--icon-arrow'],
    [ZidIconTypeEnum.arrowLeft]: styles['zidicon--icon-arrow-left'],
    [ZidIconTypeEnum.arrowRight]: styles['zidicon--icon-arrow-right'],
    [ZidIconTypeEnum.arrowLine]: styles['zidicon--icon-arrow-line'],
    [ZidIconTypeEnum.basket]: styles['zidicon--icon-basket'],
    [ZidIconTypeEnum.bell]: styles['zidicon--icon-bell'],
    [ZidIconTypeEnum.calendar]: styles['zidicon--icon-calendar'],
    [ZidIconTypeEnum.chart]: styles['zidicon--icon-chart'],
    [ZidIconTypeEnum.clock]: styles['zidicon--icon-clock'],
    [ZidIconTypeEnum.close]: styles['zidicon--icon-close'],
    [ZidIconTypeEnum.cogwheel]: styles['zidicon--icon-cogwheel'],
    [ZidIconTypeEnum.coins]: styles['zidicon--icon-coins'],
    [ZidIconTypeEnum.dartBoard]: styles['zidicon--icon-dart-board'],
    [ZidIconTypeEnum.eCommerce]: styles['zidicon--icon-ecommerce'],
    [ZidIconTypeEnum.exit]: styles['zidicon--icon-exit'],
    [ZidIconTypeEnum.eye]: styles['zidicon--icon-eye'],
    [ZidIconTypeEnum.find]: styles['zidicon--icon-find'],
    [ZidIconTypeEnum.home]: styles['zidicon--icon-home'],
    [ZidIconTypeEnum.laptop]: styles['zidicon--icon-laptop'],
    [ZidIconTypeEnum.logo]: styles['zidicon--icon-logo'],
    [ZidIconTypeEnum.options]: styles['zidicon--icon-options'],
    [ZidIconTypeEnum.pen]: styles['zidicon--icon-pen'],
    [ZidIconTypeEnum.pieChart]: styles['zidicon--icon-pie-chart'],
    [ZidIconTypeEnum.plant]: styles['zidicon--icon-plant'],
    [ZidIconTypeEnum.plus]: styles['zidicon--icon-plus'],
    [ZidIconTypeEnum.print]: styles['zidicon--icon-print'],
    [ZidIconTypeEnum.settings]: styles['zidicon--icon-settings'],
    [ZidIconTypeEnum.tick]: styles['zidicon--icon-tick'],
    [ZidIconTypeEnum.truck]: styles['zidicon--icon-truck'],
    [ZidIconTypeEnum.upDown]: styles['zidicon--icon-up-down'],
    [ZidIconTypeEnum.upload]: styles['zidicon--icon-upload'],
    [ZidIconTypeEnum.user]: styles['zidicon--icon-user'],
    [ZidIconTypeEnum.users]: styles['zidicon--icon-users'],
    [ZidIconTypeEnum.viral]: styles['zidicon--icon-viral'],
    [ZidIconTypeEnum.writing]: styles['zidicon--icon-writing'],
    [ZidIconTypeEnum.transfer]: styles['zidicon--icon-transfer'],
    [ZidIconTypeEnum.delivery]: styles['zidicon--icon-delivery'],
    [ZidIconTypeEnum.circle]: styles['zidicon--icon-circle'],
    [ZidIconTypeEnum.account]: styles['zidicon--icon-account'],
    [ZidIconTypeEnum.marker]: styles['zidicon--icon-marker'],
  };

  render(h: CreateElement): VNode {
    return <span class={[styles.zidicon, this.iconTypeToClassNameMap[this.type]]} />;
  }
}
