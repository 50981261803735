import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import { SubscrbtionEnumPackagesCodeEnum } from '../../subscription/enums/packages-code.enum';

export function isPackageAvailableToCheckout(to: Route, from: Route, next: Function): void {
  const queryPackageCode = to.query['package_code']?.toString();

  if (queryPackageCode) {
    const validPrefixes = [
      SubscrbtionEnumPackagesCodeEnum.zidLite,
      SubscrbtionEnumPackagesCodeEnum.newGrowth,
      SubscrbtionEnumPackagesCodeEnum.newProfessional,
    ];
    const isPackageAvailable = validPrefixes.some((prefix) => queryPackageCode.startsWith(prefix));

    if (isPackageAvailable) {
      next();
      return;
    }
    next({ name: RoutingRouteEnum.subscriptionPackages });
  }

  next();
}
