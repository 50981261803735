import { ActivationProcess, CommonQuestions, FeatureList, Requirements } from '../../../types/MarketingPage.interface';
import { PackagesEnum } from '../../../types/Packages.enum';

export abstract class ZidpayCountriesHelpersBase {
  static setCurrentPackage(packageId: string): PackagesEnum {
    return PackagesEnum.basic;
  }
  static getActivationProcess(): Array<ActivationProcess> {
    return [];
  }
  static getFeatureList(): Array<FeatureList> {
    return [];
  }
  static getRequirements(): Array<Requirements> {
    return [];
  }
  static getCommonQuestions(): Array<CommonQuestions> {
    return [];
  }
  static getHeaderImg(): string {
    return '';
  }
}
