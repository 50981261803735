import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import { subscriptionManagementApiUrls } from './urls';
import { urlQueryFormat } from '../../../common/helpers/url/query/format';
import { purchaseHistoryApiFiltersToSearchParams } from './purchase-history/filters-to-search-params';
import type { ApiBaseDataResponseObjectType, ApiBaseMessageResponseObjectType } from '../../../api/types';
import type { PurchaseHistoryApiFiltersInterface } from './purchase-history/filters.interface';
import type { SubscriptionApiPurchaseHistoryResponseInterface } from './purchase-history/response';
import type { SubscriptionTypesSaveCardResponse } from '../../types';

export class SubscriptionManagementApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getPurchaseHistory(
    filters: PurchaseHistoryApiFiltersInterface,
  ): Promise<ApiBaseDataResponseObjectType<SubscriptionApiPurchaseHistoryResponseInterface>> {
    const params = urlQueryFormat(purchaseHistoryApiFiltersToSearchParams(filters));
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<SubscriptionApiPurchaseHistoryResponseInterface>
    >(ApiHttpMethodEnum.get, subscriptionManagementApiUrls.getPurchaseHistory, { params, cacheable: true });

    return response.data;
  }

  public async getPaymentCards(): Promise<any> {
    const response = await this.apiClient.request<any>(
      ApiHttpMethodEnum.get,
      subscriptionManagementApiUrls.getPaymentCards,
    );

    return response;
  }

  public async savePaymentCard(
    cardToken: string,
  ): Promise<ApiBaseDataResponseObjectType<SubscriptionTypesSaveCardResponse>> {
    const params = urlQueryFormat({
      cardToken,
    });

    return this.apiClient
      .request<ApiBaseDataResponseObjectType<SubscriptionTypesSaveCardResponse>>(
        ApiHttpMethodEnum.post,
        subscriptionManagementApiUrls.savePaymentCard,
        {
          params,
        },
      )
      .then((response) => response.data);
  }

  public async deletePaymentCard(cardId: string): Promise<ApiBaseMessageResponseObjectType> {
    const params = urlQueryFormat({
      cardId,
    });

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType>(
        ApiHttpMethodEnum.delete,
        subscriptionManagementApiUrls.deletePaymentCard,
        {
          params,
        },
      )
      .then((response) => response.data);
  }

  public async setDefaultPaymentCard(
    cardId: string,
  ): Promise<ApiBaseMessageResponseObjectType & { description: string }> {
    const params = urlQueryFormat({
      cardId,
    });

    return this.apiClient
      .request<ApiBaseMessageResponseObjectType & { description: string }>(
        ApiHttpMethodEnum.post,
        subscriptionManagementApiUrls.setDefaultPaymentCard,
        {
          params,
        },
      )
      .then((response) => response.data);
  }
}
