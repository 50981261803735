import Vue, { CreateElement, VNode } from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import { dropdownBaseStateSymbol } from '../state-symbol';
import { DropdownBaseStateInterface } from '../state.interface';
import styles from './DropdownBaseBody.scss';

@Component
export class DropdownBaseBodyComponent extends Vue {
  @Inject({
    default: {
      isExpanded: false,
    } as DropdownBaseStateInterface,
    from: dropdownBaseStateSymbol,
  })
  private state!: DropdownBaseStateInterface;

  render(h: CreateElement): VNode {
    return (
      <div
        class={{
          [styles.dropdownbasebody]: true,
          [styles['dropdownbasebody--expanded']]: this.state.isExpanded,
        }}
      >
        {this.$slots.default}
      </div>
    );
  }
}
