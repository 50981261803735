<template>
  <div :class="styles['input-dropdown']">
    <GlobalSearchComponentsSearchCategoriesComponent v-if="!isCategorySelected" />
    <GlobalSearchComponentsSearchBodyComponent />
    <GlobalSearchComponentsSearchFooterComponent />
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';

import GlobalSearchComponentsSearchBodyComponent from '../SearchBody/SearchBody.vue';
import GlobalSearchComponentsSearchFooterComponent from '../SearchFooter/SearchFooter.vue';
import GlobalSearchComponentsSearchCategoriesComponent from '../SearchCategories/SearchCategories.vue';
import { globalSearchModule } from '../../../../store/GlobalSearch/module';
export default defineComponent({
  components: {
    GlobalSearchComponentsSearchBodyComponent,
    GlobalSearchComponentsSearchFooterComponent,
    GlobalSearchComponentsSearchCategoriesComponent,
  },

  setup() {
    const isCategorySelected = computed((): boolean => Boolean(globalSearchModule.selectedSearchCategories));

    return { isCategorySelected };
  },
});
</script>
<style lang="scss" module="styles" src="../GloablSearch.module.scss"></style>
