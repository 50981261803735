<template>
  <div :class="styles['order-streak-notification']" v-if="isComponentVisible">
    <div
      :class="styles['order-streak-notification__content-wrapper']"
      @mouseover="showStreakNotification"
      @mouseleave="hideStreakNotification"
    >
      <span :class="[styles['order-streak-notification__icon'], styles[orderStreakIcon?.className]]">{{
        orderStreakIcon?.icon
      }}</span>
      <transition :name="styles['order-streak-notification-fade']">
        <div
          v-if="isNotificationVisible"
          :class="styles['order-streak-notification__card']"
          @mouseleave="hideStreakNotification"
        >
          <div :class="styles['order-streak-notification__card-first-part']">
            <span :class="[styles['order-streak-notification__icon'], styles[orderStreakIcon?.className]]">{{
              orderStreakIcon?.icon
            }}</span>
            <div :class="styles['order-streak-notification__notification-info']">
              <span :class="styles['order-streak-notification__notification-title']">{{
                streakData?.content?.title
              }}</span>
              <span :class="styles['order-streak-notification__notification-description']">{{
                streakData?.content?.desc
              }}</span>
            </div>
          </div>
          <ZidButton
            :class="styles['order-streak-notification__notification-button']"
            :inversed="orderStreakButton?.inversed"
            :type="orderStreakButton?.type"
            @click="handleOrderStreakButton"
          >
            <template #start-icon v-if="orderStreakButton?.icon">
              <span>{{ orderStreakButton?.icon }}</span>
            </template>
            {{ orderStreakButton?.title }}
          </ZidButton>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted, ref } from 'vue';
import { ZidButton } from '@zidsa/ui';
import { OrderNotificationsApiServiceFactory } from '../../api/order-notifcations/service-factory';
import type { ApiModelOrderNotificationStreakInterface } from '../../api/order-streak/response.interface';
import { OrderStreakTrackEnum } from '../../api/order-streak/order-streak.enum';
import { useRouter } from 'vue-router/composables';
import { RoutingRouteEnum } from '../../../routing/route.enum';
import useI18nMessages from '../../../common/composables/useI18nMessages';
import { getServerErrorMessage } from '../../../common/decorators/catch-error';
import { AxiosError } from 'axios';
import NotificationTypeEnum from '../../../notifications/components/type.enum';
import showNotification from '../../../common/helpers/show-notification/show-notification';

const orderService = OrderNotificationsApiServiceFactory();

const emit = defineEmits(['fetchCompleted']);
const isNotificationVisible = ref(false);

const router = useRouter();

const streakData = ref<ApiModelOrderNotificationStreakInterface | null>(null);

const vm = getCurrentInstance()?.proxy;

const I18nMessage = useI18nMessages();

const isComponentVisible = computed(() => {
  return !!(
    streakData.value &&
    streakData.value.track &&
    [OrderStreakTrackEnum.lose, OrderStreakTrackEnum.onGoing].includes(streakData.value.track)
  );
});

const orderStreakIcon = computed(() => {
  return streakData.value?.track === OrderStreakTrackEnum.onGoing
    ? { icon: '\uD83D\uDD25', className: 'order-streak-notification__icon--ongoing' }
    : streakData.value?.track === OrderStreakTrackEnum.lose
    ? { icon: '\uD83D\uDE22', className: 'order-streak-notification__icon--lose' }
    : null;
});

const orderStreakButton = computed(() => {
  return streakData.value?.track === OrderStreakTrackEnum.onGoing
    ? {
        icon: '𝕏',
        type: 'primary',
        inversed: true,
        title: vm?.$t(I18nMessage['common.shareit']),
        action: () => {
          postOrderStreakOnX();
        },
      }
    : streakData.value?.track === OrderStreakTrackEnum.lose
    ? {
        icon: '',
        type: 'secondary',
        inversed: true,
        title: vm?.$t(I18nMessage['sidebar.navigation_section.marketing.abandoned_cart']),
        action: () => goToAbandonedCart(),
      }
    : null;
});

const showStreakNotification = () => {
  isNotificationVisible.value = true;
};

const linkToShare = ref('');

const getImageToShare = async () => {
  try {
    const response = await orderService.getOrderStreakImageToShare();

    linkToShare.value = response.data ?? '';
  } catch (e) {
    showNotification(getServerErrorMessage(e as AxiosError), NotificationTypeEnum.danger);
  }
};
const postOrderStreakOnX = async () => {
  if (!!linkToShare.value) {
    const tweetUrl = `https://twitter.com/intent/tweet?` + `url=${encodeURIComponent(linkToShare.value)}`;
    window.open(tweetUrl, '_blank');
  }
};

const goToAbandonedCart = () => {
  router.push({ name: RoutingRouteEnum.abandonedCarts });
};

const hideStreakNotification = () => {
  isNotificationVisible.value = false;
};

const getStreakData = async () => {
  try {
    const response = await orderService.getOrderStreak();
    streakData.value = response.data ?? null;
    emit('fetchCompleted', {
      days_count: streakData.value?.days_count,
      track: streakData.value?.track,
    });
  } catch (e) {
    showNotification(getServerErrorMessage(e as AxiosError), NotificationTypeEnum.danger);
  }
};

const handleOrderStreakButton = () => {
  if (orderStreakButton.value?.action) {
    orderStreakButton.value.action();
  }
};

onMounted(() => {
  getStreakData();
  getImageToShare();
});
</script>

<style module="styles" lang="scss" src="./OrderStreakNotification.module.scss"></style>
