// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c7683c46{display:none;position:absolute;top:100%;left:0;z-index:11}html[dir=rtl] .c7683c46{right:0;left:initial;text-align:right}.a641de67{display:block}", "",{"version":3,"sources":["webpack://./src/common/components/Dropdown/Base/Body/DropdownBaseBody.scss","webpack://./src/common/styles/mixins/_rtl.scss"],"names":[],"mappings":"AAEA,UACE,YAAA,CACA,iBAAA,CACA,QAAA,CACA,MAAA,CACA,UAAA,CCNA,wBDSE,OAAA,CACA,YAAA,CACA,gBAAA,CAIJ,UACE,aAAA","sourcesContent":["@import '../../../../styles/mixins/rtl';\n\n.dropdownbasebody {\n  display: none;\n  position: absolute;\n  top: 100%;\n  left: 0;\n  z-index: 11;\n\n  @include rtl {\n    right: 0;\n    left: initial;\n    text-align: right;\n  }\n}\n\n.dropdownbasebody--expanded {\n  display: block;\n}\n","@mixin rtl {\n  html[dir='rtl'] & {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownbasebody": "c7683c46",
	"dropdownbasebody--expanded": "a641de67"
};
export default ___CSS_LOADER_EXPORT___;
