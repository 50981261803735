/* eslint-disable @typescript-eslint/camelcase */
import { EventProperty } from '../types/eventProperty';
import { MixpanelEvents } from './events.enum';
import mixpanel, { Dict } from 'mixpanel-browser';
import UserStoreModule from '../../user/store/module';

export class MixpanelService {
  private properties: Dict = {};
  public track(event: MixpanelEvents, props?: EventProperty[]): void {
    try {
      this.getStorePropertis();
    } catch (error) {
      //fail silently
    }
    props?.forEach((p) => {
      this.properties[p.label] = p.value;
    });
    mixpanel.track(event, this.properties);
  }

  getStorePropertis(): void {
    const data = UserStoreModule.data;
    if (!data) return;
    const { roles, store, email } = data;
    this.properties['Email'] = email;
    if (roles) {
      this.properties['User Type'] = roles.map((r) => r.name).join(', ');
    }

    if (store) {
      const {
        id: storeId,
        title: storeTitle,
        created_at: storeCreationDate,
        subscription,
        industry,
        category,
        store_business_data,
        email: storeEmail,
      } = store;
      mixpanel.identify(storeId.toString());
      mixpanel.people.set_once({ name: storeTitle, email: storeEmail });

      this.properties['Store ID'] = storeId;

      this.properties['Store Name'] = storeTitle;

      this.properties['Store Creation Date'] = storeCreationDate;

      this.properties['Store Email'] = storeEmail;

      if (subscription) {
        this.properties['Store Package'] = subscription.full_package_name?.en || 'N/A';
      }

      if (industry) {
        this.properties['Store Industry'] = industry.name || 'N/A';
      }

      if (category) {
        this.properties['Store Category'] = category.name || 'N/A';
      }

      if (store_business_data) {
        this.properties['Store Business Type'] = store_business_data.business_type || 'N/A';
      }

      this.properties['Store Age'] = this.getStoreAge(storeCreationDate);
    } else {
      mixpanel.reset();
    }
  }

  getStoreAge(createdAt: string): number {
    const creationDate = new Date(createdAt);
    const today = new Date();
    const millisecondsDiff = today.getTime() - creationDate.getTime();
    return Math.floor(millisecondsDiff / (1000 * 60 * 60 * 24));
  }
}
