import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';

@Module({
  dynamic: true,
  name: 'zidWalletModals',
  store: appStoreFactory(),
  namespaced: true,
})

//Using a store module because some modals are toggled from too many components and overlap.
// + emitting and passing from too many components becomes less readable
class ZidWalletModalsModule extends VuexModule {
  public showVerificationModal = false;
  public showFundsModal = false;

  @Mutation
  private TOGGLE_VERIFICATION_MODAL(): void {
    this.showVerificationModal = !this.showVerificationModal;
  }

  @Mutation
  private TOGGLE_FUNDS_MODAL(): void {
    this.showFundsModal = !this.showFundsModal;
  }

  @Action
  public toggleVerificationModal(): void {
    this.TOGGLE_VERIFICATION_MODAL();
  }

  @Action
  public toggleFundsModal(): void {
    this.TOGGLE_FUNDS_MODAL();
  }
}

export const ZidWalletModalsStoreModule = getModule(ZidWalletModalsModule);
