export const mazeedApiUrls = {
  mazeedSettings: '/store/mazeed/settings',
  mazeedAnnouncement: '/store/mazeed/announcement',
  mazeedCheckActivationRequirements: '/store/mazeed/check-activation-requirements',
  mazeedStoreProfile: '/store/mazeed/store-profile',
  mazeedStoreSummary: '/store/mazeed/store-summary',
  mazeedAllIndustries: '/store/mazeed/industries',
  mazeedSaudiBanks: '/store/mazeed/saudi-banks',
  mazeedAvailableBankAccounts: '/api/v1/account/settings/payment-options/bank-transfer',
  mazeedUploadStoreLogo: '/store/mazeed/upload-store-logo',
  mazeedAgreements: '/store/mazeed/agreements',
  mazeedProducts: '/store/mazeed/products',
  mazeedProduct: '/store/mazeed/products/{productId}',
  mazeedSubmitForReview: '/store/mazeed/submit-for-review',
  mazeedAddProducts: '/store/mazeed/add-products',
  mazeedAddNewBankAccount: '/api/v1/account/settings/payment-options/bank-transfer/add',
  mazeedCheckIban: '/store/mazeed/check-iban',
  mazeedAcitavteProducts: '/store/mazeed/activate-products',
  mazeedDisableProducts: '/store/mazeed/disable-products',
  mazeedAllResellerSettlements: '/store/mazeed/resellers-settlements/all',
  mazeedAnalysisResellerSettlements: '/store/mazeed/resellers-settlements/analysis',
  mazeedResellerSettlementInvoice: '/store/mazeed/resellers-settlements/invoice/{id}',
  mazeedResellerSettlementDownload: '/store/mazeed/resellers-settlements/download',
};
