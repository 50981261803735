import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import styles from './BusinessTypeSelect.module.scss';
import { businessTypeEnum } from '../../../../settings/api/store/business-type.enum';
import { VerifyBusinessType } from '../../../types/Verification/verification-type';
import { I18nMessages } from '../../../../i18n/messages';
import { ZidContainer, ZidRadio, ZidText } from '@zidsa/ui';
import { ZidWalletStoreModule } from '../../../store/wallet.module';

@Component
export class ZidWalletComponentsVerifyWalletComponentsBusinessTypeSelectComponent extends Vue {
  @Prop()
  private readonly selected!: string;

  private businessType: Array<VerifyBusinessType> = [
    {
      name: businessTypeEnum.individual,
      content: I18nMessages['common.business_details.type_of_business.individual'],
      selected: true,
    },
    {
      name: businessTypeEnum.corporate,
      content: I18nMessages['common.business_details.type_of_business.company'],
      selected: false,
    },
  ];

  render(h: CreateElement): VNode {
    return (
      <ZidContainer class={styles.business__type}>
        {this.businessType &&
          this.businessType.map((type, index) => (
            <ZidRadio
              name={'businessType'}
              data-identifier={`business_${type.name}`}
              value={type.name}
              onClick={(): void => this.onBusinessTypeSelected(index)}
              checked={this.selected === type.name}
            >
              <ZidText>{this.$t(type.content).toString()}</ZidText>
            </ZidRadio>
          ))}
      </ZidContainer>
    );
  }

  private onBusinessTypeSelected(index: number): void {
    ZidWalletStoreModule.setBusinessData({
      ...ZidWalletStoreModule.verificationData.businessInfo,
      accountType: this.businessType[index].name,
    });
  }
}
