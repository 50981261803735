import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { PinnedAppAppInterface } from '../../../../types/pinned-app.interface';

import styles from './PinnedAppComponent.scss';

@Component
export class PinnedAppComponent extends Vue {
  @Prop()
  private readonly pinnedApp!: PinnedAppAppInterface;

  render(h: CreateElement): VNode {
    return (
      <div class={styles.pinned_app}>
        <img class={styles.pinned_app_icon} src={this.pinnedApp.icon || ''} alt='' />
        <span class={styles.pinned_app_name}> {this.pinnedApp.name} </span>
      </div>
    );
  }
}
