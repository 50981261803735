import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { LoaderSizeEnum } from './size.enum';
import styles from './Loader.css';

@Component
export class LoaderComponent extends Vue {
  @Prop({
    default: LoaderSizeEnum.medium,
  })
  private readonly size!: LoaderSizeEnum;

  private readonly sizeToClassMap: Record<LoaderSizeEnum, string> = {
    [LoaderSizeEnum.small]: styles['loader--size-small'],
    [LoaderSizeEnum.medium]: styles['loader--size-medium'],
    [LoaderSizeEnum.large]: styles['loader--size-large'],
  };

  render(h: CreateElement): VNode {
    return <div class={[styles.loader, this.sizeToClassMap[this.size]]} />;
  }
}
