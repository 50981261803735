import Vue, { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export class UploadIconComponent extends Vue {
  render(h: CreateElement): VNode {
    return (
      <svg xmlns='http://www.w3.org/2000/svg' width='147.883' height='114.323' viewBox='0 0 147.883 114.323'>
        <defs></defs>
        <g transform='translate(0 0)'>
          <path
            fill='#908c96'
            d='M114.122,71.022a40.326,40.326,0,0,0-80.475.015,38.485,38.485,0,0,0,4.86,76.664h58.56a3.016,3.016,0,0,0,0-6.033H38.507a32.451,32.451,0,0,1-1.764-64.856A3.016,3.016,0,0,0,39.6,73.8c0-.06,0-.119-.005-.178a34.292,34.292,0,0,1,68.584.007c0,.058,0,.12,0,.165a3.016,3.016,0,0,0,2.864,3.012A32.444,32.444,0,0,1,129.853,134.4a3.016,3.016,0,1,0,3.807,4.679,38.477,38.477,0,0,0-19.537-68.057Z'
            transform='translate(0 -33.378)'
          />
          <path
            fill='#635972'
            d='M140.966,138.094v38.465a3.016,3.016,0,1,0,6.033,0V138.094a3.016,3.016,0,0,0-6.033,0Z'
            transform='translate(-70.097 -83.099)'
          />
        </g>
        <path
          fill='#635972'
          d='M144.171,129.173a3.016,3.016,0,0,0,2.133-5.149l-17.252-17.252a3.016,3.016,0,0,0-4.266,0l-17.252,17.252a3.016,3.016,0,0,0,4.266,4.266l15.119-15.119,15.119,15.119A3.009,3.009,0,0,0,144.171,129.173Z'
          transform='translate(-53.033 -68.584)'
        />
      </svg>
    );
  }
}
