<template>
  <div :class="styles['subscription-package']">
    <ZidAlert
      type="success"
      size="small"
      :class="styles['subscription-package__badge']"
      :slot="false"
      :dismissible="false"
    >
      <template slot="icon">
        <ZidIcon icon="tag" color="success" size="var(--zid-font-size--xs)" class="alert__icon" />
      </template>
      {{ subscriptionPackage }}
    </ZidAlert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZidAlert, ZidIcon } from '@zidsa/ui';
import { AppStoreModule } from '../../../app/store/module';
import UserStoreModule from '../../../user/store/module';
export default defineComponent({
  components: {
    ZidAlert,
    ZidIcon,
  },
  setup() {
    const subscriptionPackage = UserStoreModule.data?.store?.subscription?.full_package_name[AppStoreModule.locale];
    return { subscriptionPackage };
  },
});
</script>

<style module="styles" lang="scss">
.subscription-package {
  display: flex;
  &__badge {
    --zid-alert-border-radius: 50px;
    background-color: var(--zid-colors-green-500);
    padding: 6px var(--zid-spacing-12) !important;
    align-items: center;
    color: var(--zid-colors-neutrals-white);
    white-space: nowrap;
    margin-inline-end: var(--zid-spacing-12);
    --zid-alert-background-color: var(--zid-colors-green-100) !important;
    border: 0 !important;
    margin-top: var(--zid-spacing-4);

    div {
      font-size: var(--zid-font-size--xs) !important;
      font-weight: var(--zid-font-weight-regular) !important;
    }

    span {
      --zid-icon-color: var(--zid-colors-green-800) !important;
    }

    [class~='alert'] {
      flex-direction: row !important;
      padding: var(--zid-spacing-8) !important;
      margin-bottom: var(--zid-spacing-4);
      --zid-alert-border: none;
    }

    [class~='alert__content'] {
      color: var(--zid-colors-green-700) !important;
    }

    [class~='alert__icon'] {
      font-size: var(--zid-font-size--base);
      font-weight: lighter;
    }
  }
}
</style>
