interface Country {
  code: string;
  name: string;
  arName: string;
  key: string;
  flagFile: string;
}

//Note: zid wallet's API requires the '+' sign in the key
const COUNTRY_CODES: Country[] = [
  {
    code: 'SA',
    name: 'Kingdom of Saudi Arabia',
    arName: 'المملكة العربية السعودية',
    key: '+966',
    flagFile: 'ksa_flag.svg',
  },
  { code: 'KW', name: 'Kuwait', arName: 'الكويت', key: '+965', flagFile: 'kuwait_flag.svg' },
  { code: 'QA', name: 'Qatar', arName: 'قطر', key: '+974', flagFile: 'qatar_flag.svg' },
  { code: 'BH', name: 'Bahrain', arName: 'البحرين', key: '+973', flagFile: 'bahrain_flag.svg' },
  { code: 'OM', name: 'Oman', arName: 'عمان', key: '+968', flagFile: 'oman_flag.svg' },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    arName: 'الامارات العربية المتحدة',
    key: '+971',
    flagFile: 'uae_flag.svg',
  },
  { code: 'EG', name: 'Egypt', key: '+20', arName: 'مصر', flagFile: 'egypt_flag.svg' },
];

export default COUNTRY_CODES;
