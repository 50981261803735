import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidWalletApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import Catch from '../../common/decorators/catch-error';
import { ZidWalletVerificationRequestResponseInterface } from '../types/Verification/wallet-verification.interface';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';
const zidWalletService = ZidWalletApiServiceFactory();

@Module({
  dynamic: true,
  name: 'zidWalletVerification',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidWalletVerificationModule extends VuexModule {
  public data: ZidWalletVerificationRequestResponseInterface = {} as ZidWalletVerificationRequestResponseInterface;
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public error: Error | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_SUCCESS(data: any): void {
    this.data = data;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action({ rawError: true })
  public async initiateWalletVerificationRequest(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>
  > {
    this.FETCH();

    return zidWalletService
      .initiateWalletVerificationRequest()
      .then((res) => {
        this.FETCH_SUCCESS(res.data);
        return res;
      })
      .catch((err) => {
        this.FETCH_ERROR(err);

        return err.response;
      });
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_SUCCESS() })
  public async validateWalletVerificationOTPRequest({
    id,
    otp,
  }: {
    id: string;
    otp: string;
  }): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>> {
    this.FETCH();

    const response = await zidWalletService.validateWalletVerificationOTPRequest(id, otp);

    this.FETCH_SUCCESS(response.data);

    return response;
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_SUCCESS() })
  public async resendWalletVerificationOtpRequest(
    id: string,
  ): Promise<ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletVerificationRequestResponseInterface>>> {
    this.FETCH();

    const response = await zidWalletService.resendWalletVerificationOTPRequest(id);

    this.FETCH_SUCCESS(response.data);

    return response;
  }
}

export const ZidWalletVerificationStoreModule = getModule(ZidWalletVerificationModule);
