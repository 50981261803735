import { ApiSearchParamsType } from '../../api/types';
import { objectFilter } from '../../common/helpers/object/filter';
import { ZidWalletTransactionFiltersRequestInterface } from '../types/Transactions/transactions.interface';

export function transactionsApiFiltersToSearchParams(
  filters: ZidWalletTransactionFiltersRequestInterface,
): ApiSearchParamsType {
  const params: ZidWalletTransactionFiltersRequestInterface = {
    pageSize: filters.pageSize,
    pageNumber: filters.pageNumber,
  };

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
