import Vue, { CreateElement, VNode } from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export class WalletIcon extends Vue {
  render(h: CreateElement): VNode {
    return (
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13.1667 4.66667H12.5V4C12.5 3.46957 12.2893 2.96086 11.9142 2.58579C11.5392 2.21071 11.0304 2 10.5 2H3.83334C3.30291 2 2.7942 2.21071 2.41913 2.58579C2.04406 2.96086 1.83334 3.46957 1.83334 4V12C1.83334 12.5304 2.04406 13.0391 2.41913 13.4142C2.7942 13.7893 3.30291 14 3.83334 14H13.1667C13.6971 14 14.2058 13.7893 14.5809 13.4142C14.956 13.0391 15.1667 12.5304 15.1667 12V6.66667C15.1667 6.13623 14.956 5.62753 14.5809 5.25245C14.2058 4.87738 13.6971 4.66667 13.1667 4.66667ZM3.83334 3.33333H10.5C10.6768 3.33333 10.8464 3.40357 10.9714 3.5286C11.0964 3.65362 11.1667 3.82319 11.1667 4V4.66667H3.83334C3.65653 4.66667 3.48696 4.59643 3.36194 4.4714C3.23691 4.34638 3.16668 4.17681 3.16668 4C3.16668 3.82319 3.23691 3.65362 3.36194 3.5286C3.48696 3.40357 3.65653 3.33333 3.83334 3.33333ZM13.8333 10H13.1667C12.9899 10 12.8203 9.92976 12.6953 9.80474C12.5702 9.67971 12.5 9.51014 12.5 9.33333C12.5 9.15652 12.5702 8.98695 12.6953 8.86193C12.8203 8.73691 12.9899 8.66667 13.1667 8.66667H13.8333V10ZM13.8333 7.33333H13.1667C12.6362 7.33333 12.1275 7.54405 11.7525 7.91912C11.3774 8.29419 11.1667 8.8029 11.1667 9.33333C11.1667 9.86377 11.3774 10.3725 11.7525 10.7475C12.1275 11.1226 12.6362 11.3333 13.1667 11.3333H13.8333V12C13.8333 12.1768 13.7631 12.3464 13.6381 12.4714C13.5131 12.5964 13.3435 12.6667 13.1667 12.6667H3.83334C3.65653 12.6667 3.48696 12.5964 3.36194 12.4714C3.23691 12.3464 3.16668 12.1768 3.16668 12V5.88667C3.38086 5.96201 3.6063 6.00034 3.83334 6H13.1667C13.3435 6 13.5131 6.07024 13.6381 6.19526C13.7631 6.32029 13.8333 6.48986 13.8333 6.66667V7.33333Z'
          fill='#9081F6'
        />
      </svg>
    );
  }
}
