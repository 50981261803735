import { PackagesEnum } from '../../zidpay/types/Packages.enum';
import UserStoreModule from '../../user/store/module';

const RELEASE_DATE = '2024-03-10';

export default function isFreemiumSubscription(): boolean {
  const subscription = UserStoreModule.data?.store?.subscription;
  const isStarter = subscription?.package_key === PackagesEnum.starter;

  return isStarter && subscription?.is_expired && new Date(RELEASE_DATE) <= new Date(subscription?.subscribed_at);
}
