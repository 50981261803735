import { render, staticRenderFns } from "./OrderStreakNotification.vue?vue&type=template&id=3ff69140"
import script from "./OrderStreakNotification.vue?vue&type=script&setup=true&lang=ts"
export * from "./OrderStreakNotification.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OrderStreakNotification.module.scss?vue&type=style&index=0&prod&module=styles&lang=scss&external"




function injectStyles (context) {
  
  this["styles"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports