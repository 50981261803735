import { ZidBox, ZidText } from '@zidsa/ui';

//Styles & Assets
import styles from './PaymentSelect.module.scss';

import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { WalletPaymentMethods } from '../../../../types/Wallet/input-values';
import { I18nMessages } from '../../../../../i18n/messages';
import { SubscriptionTypesPaymentMethodEnum } from '../../../../../subscription/types/payment-methods.enum';

@Component
export class WalletComponentsAddFundsActionsPaymentSelectComponent extends Vue {
  @Prop()
  private readonly isUserInput!: boolean;

  private paymentMethods: Array<WalletPaymentMethods> = [
    {
      name: I18nMessages['common.payment_method.credit_card'],
      selected: false,
      img: 'credit-cards.svg',
      alt: 'visa, mastercard, mada',
      value: SubscriptionTypesPaymentMethodEnum.embeddedCreditCard,
    },
    {
      name: I18nMessages['wallet.apple_pay'],
      selected: false,
      img: 'apple-pay.svg',
      alt: 'apple pay',
      value: SubscriptionTypesPaymentMethodEnum.creditCard,
    },
    {
      name: I18nMessages['wallet.wire_transfer'],
      selected: false,
      img: 'wire-transfer.svg',
      alt: 'transfer',
      value: SubscriptionTypesPaymentMethodEnum.bankTransfer,
    },
  ];

  render(h: CreateElement): VNode {
    return (
      <div class={styles.funds__row}>
        {this.paymentMethods.map((payment, index) => (
          <ZidBox
            class={[styles['funds__box'], this.paymentMethods[index].selected ? styles['funds__box--clicked'] : '']}
            nativeOnClick={(): void => this.toggleMoneySelect(index, payment.value)}
            position='start'
            name='suggested_money_values'
            value={payment.name}
            selected={this.paymentMethods[index].selected}
          >
            <ZidText>{this.$t(payment.name).toString()}</ZidText>
            <img src={require(`../../../../assets/images/${payment.img}`)} alt={payment.alt} />
          </ZidBox>
        ))}
      </div>
    );
  }

  private toggleMoneySelect(index: number, value: SubscriptionTypesPaymentMethodEnum): void {
    for (let i = 0; i < this.paymentMethods.length; i++) {
      if (i === index) {
        this.paymentMethods[i].selected = !this.paymentMethods[i].selected;
      } else {
        this.paymentMethods[i].selected = false;
      }
    }
    this.$emit('paymentSelect', value);
  }

  @Watch('isUserInput')
  async onMoneyInput(): Promise<void> {
    if (this.isUserInput)
      for (let i = 0; i < this.paymentMethods.length; i++) {
        this.paymentMethods[i].selected = false;
      }
  }
}
