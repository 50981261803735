import { ZidBadge, ZidButton, ZidCheckbox, ZidHeading, ZidInput, ZidLoader, ZidModal, ZidText } from '@zidsa/ui';

//Styles & Assets
import styles from './AddFundsModal.module.scss';
import exclamationSvg from '../../assets/icons/exclamation-circle.svg';
import insertMoney from '../../assets/icons/money-insert.svg';
import insertMoneyGreen from '../../assets/icons/insert-money-green.svg';
import appleLogoSvg from '../../assets/images/apple-logo.svg';
import checkSvg from '../../assets/icons/checkmark.svg';
import isRTL from '../../../common/helpers/is-lang-arabic';
import arrowRight from '../../../account/assets/arrow_right.svg';
import arrowLeft from '../../../account/assets/arrow_left.svg';
import topupIcon from '../../assets/icons/topup-yellow.svg';

import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../i18n/messages';
import { ZidWalletComponentsBankTransferSectionComponent } from './BankTransferSection/BankTransferSection';
import { ZidWalletComponentsAddFundsBankTransferSectionComponent } from './CreditSection/CreditSection';
import { ZidWalletStoreModule } from '../../store/wallet.module';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import { InputTextChangeEventInterface } from '../../../common/components/Input';
import NotificationTypeEnum from '../../../notifications/components/type.enum';
import { PurchaseStoreModule } from '../../../subscription/store/subscription-checkout/purchase/purchase-module';
import Catch from '../../../common/decorators/catch-error';
import { PurchasablesApiServiceFactory } from '../../../purchasables/api/service-factory';
import { SubscriptionTypesPaymentMethodEnum } from '../../../subscription/types/payment-methods.enum';
import { ZidWalletComponentsBalanceCardsComponent } from '../Balances/Balances';
import { InputCheckboxChangeEventInterface } from '../../../common/components/Input/Checkbox/change-event.interface';
import { SubscriptionManagementPaymentCardsStoreModule } from '../../../subscription/store/subscription-managment/payment-cards-module';
import { ZidWalletConfigurationsStoreModule } from '../../store/configurations.module';
import { WalletComponentsAddFundsActionsMoneyChipSelectComponent } from './Actions/SuggestedFundSelect/SuggestedValueChips';
import { WalletComponentsAddFundsActionsPaymentSelectComponent } from './Actions/PaymentSelect/PaymentSelect';
import { ZidWalletModalsStoreModule } from '../../store/modal-booleans.module';
import { ZidWalletApiServiceFactory } from '../../api/service-factory';
import { loadScriptDynamically } from '../../../common/helpers/load-script';
import { toEnglishDigits } from '../../../common/helpers/to-english-digits';

const PurchasablesService = PurchasablesApiServiceFactory();
const walletService = ZidWalletApiServiceFactory();

@Component
export class WalletComponentAddFundsModal extends Vue {
  @Prop()
  private readonly showVerifyWallet!: boolean;

  private moneyValue: string | number = '';

  private isUserInput = false;

  private walletBalanceCredits = 50;

  private isNotIntgerNumber = false;

  private isSavedBankCardData = false;

  private chooseOptionError = false;

  private disableSubmitEmbeddedCreditCard = true;

  private removeUntill = false;

  private submitLoading = false;

  private submitCreditCard = false;

  private paymentMethod = '';

  private get showFundsModal(): boolean {
    return ZidWalletModalsStoreModule.showFundsModal;
  }

  private get balance(): number {
    return ZidWalletStoreModule.data.totalBalance ?? 0;
  }

  private get newBalance(): number {
    return this.balance + Number(this.moneyValue);
  }

  private get isConfigurationsLoading(): boolean {
    return [AppStoreReadyStateEnum.loading, AppStoreReadyStateEnum.pending].includes(
      ZidWalletConfigurationsStoreModule.loadingState,
    );
  }

  private get maxLimit(): number {
    const defaultMax = 5000;
    if (!this.isConfigurationsLoading && ZidWalletConfigurationsStoreModule.data.configurations) {
      const topupName = 'WALLET_TOPUP_ONLINE_PAYMENT_AMOUNT_LIMIT';
      const configuration = ZidWalletConfigurationsStoreModule.data.configurations.find(
        (config) => config.name === topupName,
      );
      if (configuration) return configuration.numericValue;
    }
    return defaultMax;
    //alternatively, use index as follows in commented line. however, in case backend adds more, it's best to use name
    // return !this.isConfigsLoading ? ZidWalletConfigurationsStoreModule.data.configurations[3].numericValue : 5000;
  }

  private get isOverLimit(): boolean {
    return !this.isTransferSection && Number(this.moneyValue) > this.maxLimit;
  }

  private get paymentCards(): any {
    return SubscriptionManagementPaymentCardsStoreModule.data;
  }

  private get hasError(): boolean {
    return this.isOverLimit || this.isNotIntgerNumber;
  }

  private fetchPaymentMethods(): void {
    SubscriptionManagementPaymentCardsStoreModule.fetch();
  }

  async created(): Promise<void> {
    this.fetchPaymentMethods();
    ZidWalletConfigurationsStoreModule.fetch();
  }

  private get isInvalid(): boolean {
    return !Number(this.moneyValue) || this.hasError;
  }

  private get isApplePaySection(): boolean {
    return this.paymentMethod === SubscriptionTypesPaymentMethodEnum.creditCard;
  }

  private get isCreditSection(): boolean {
    return this.paymentMethod === SubscriptionTypesPaymentMethodEnum.embeddedCreditCard;
  }

  private get isTransferSection(): boolean {
    return this.paymentMethod === SubscriptionTypesPaymentMethodEnum.bankTransfer;
  }

  private get noOptionChosen(): boolean {
    return (!this.isApplePaySection && !this.isCreditSection) || this.isTransferSection;
  }

  mounted() {
    loadScriptDynamically('https://goSellJSLib.b-cdn.net/v1.4.1/js/gosell.js', {
      defer: true,
    });
  }

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidModal
          class={styles['funds__modal']}
          visible={this.showFundsModal}
          onClose={this.onToggleFundsModal}
          title={this.$t(I18nMessages['wallet.charge_funds'])}
        >
          <ZidWalletComponentsBalanceCardsComponent
            newBalance={this.newBalance}
            isTransferSection={this.isTransferSection}
            isInvalid={this.isInvalid}
            balance={this.balance}
          />
          <ZidHeading weight={'semibold'} level={6}>
            {this.$t(I18nMessages['wallet.charge_funds.method'])}
          </ZidHeading>
          {this.chooseOptionError && (
            <p class={styles['funds__modal__error-message']}>
              <img src={exclamationSvg} />
              <span>{this.$t(I18nMessages['wallet.wallet_modal.amount_errorchoose_error'])}</span>
            </p>
          )}
          <WalletComponentsAddFundsActionsPaymentSelectComponent onPaymentSelect={this.onPaymentSelect} />

          {this.isCreditSection
            ? this.renderCreditSection(h)
            : this.isApplePaySection
            ? this.renderMoneyInput(h)
            : this.isTransferSection
            ? this.renderTransferSection(h)
            : ''}
          <div class={styles.funds__btns}>
            <ZidButton link onClick={this.onToggleFundsModal}>
              <img src={isRTL() ? arrowRight : arrowLeft} />
              <ZidText>
                {this.$t(I18nMessages['finance.marketing.notification.defaulted.merchant.modal.later'])}
              </ZidText>
            </ZidButton>
            <ZidButton
              type={!this.isApplePaySection ? 'primary' : null}
              class={{ [styles.funds__apple]: this.isApplePaySection }}
              onClick={this.clickHandler}
              disabled={this.noOptionChosen ? false : this.isInvalid || this.submitLoading}
            >
              {this.isApplePaySection ? (
                <div class={[styles['funds__box__confirm-btn']]}>
                  {this.submitLoading ? (
                    <ZidLoader />
                  ) : (
                    <div>
                      <img src={appleLogoSvg} />
                      {this.$t(I18nMessages['wallet.pay_with_apple'])}
                    </div>
                  )}
                </div>
              ) : (
                <div class={[styles['funds__box__confirm-btn']]}>
                  {this.submitLoading ? (
                    <ZidLoader />
                  ) : (
                    <div>
                      <img src={checkSvg} />
                      {this.$t(I18nMessages['wallet.confirm_charge'])}
                    </div>
                  )}
                </div>
              )}
            </ZidButton>
          </div>
        </ZidModal>
      </div>
    );
  }

  private renderMoneyInput(h: CreateElement): VNode {
    //this is used as a PART of Credit section, but is the entirety of Apple pay section
    return (
      <div class={styles['funds__money-input']}>
        <ZidHeading level={6}>{this.$t(I18nMessages['zidship.wallet.online_payment_label'])}</ZidHeading>
        <div class={styles['funds__money-input__amount-input']}>
          <img
            class={styles['funds__money-input__amount-input__icon']}
            src={this.moneyValue.toString.length && !this.hasError ? insertMoneyGreen : insertMoney}
          />
          <ZidInput
            placeholder={this.$t(I18nMessages['wallet.charge_input.placeholder'])}
            type={'tel'}
            min={0}
            nativeOnKeydown={(event: KeyboardEvent): void => this.validateInput(event)}
            value={this.moneyValue}
            onChange={(event: InputTextChangeEventInterface): Promise<void> => this.setMoneyValue(event.value)}
          />
        </div>

        {this.hasError && (
          <div>
            {this.isNotIntgerNumber ? (
              <p class={styles['funds__modal__error-message']}>
                <img src={exclamationSvg} />
                <span>{this.$t(I18nMessages['wallet.wallet_modal.amount_error'])}</span>
              </p>
            ) : (
              <ZidBadge class={styles['funds__max-limit']}>
                <ZidText>
                  <img src={topupIcon} /> &nbsp;
                  {this.$t(I18nMessages['wallet.topup.max_limit.error'], {
                    limit: this.maxLimit,
                  })}
                </ZidText>
              </ZidBadge>
            )}
          </div>
        )}

        <WalletComponentsAddFundsActionsMoneyChipSelectComponent
          isUserInput={this.isUserInput}
          onMoneyChipSelect={this.onMoneyChipSelect}
        />
      </div>
    );
  }

  private renderCreditSection(h: CreateElement): VNode {
    return (
      <div>
        {this.renderMoneyInput(h)}
        <div>
          <ZidHeading level={6} weight={'semibold'}>
            {this.$t(I18nMessages['wallet.credit_info'])}
          </ZidHeading>

          {this.removeUntill &&
            this.paymentCards.map((card: any) => (
              <div class={styles.paymentmethods__card}>
                <ZidButton nativeOnClick={(): Promise<void> => this.sendWithSavedCard(card)}>
                  send data with saved
                </ZidButton>
                <div class={styles['paymentmethods__card-icon']}>
                  <img src={`/images/${this.getStringToLowerCase(card.scheme)}_logo.png`} alt={card.scheme} />
                </div>
                <div class={styles['paymentmethods__card-info']}>
                  <p>{card.cardholder_name}</p>
                  <p class={styles['paymentmethods__card-digits']}>{this.getCardNumber(card.last_four)}</p>
                </div>
              </div>
            ))}

          <ZidWalletComponentsAddFundsBankTransferSectionComponent
            showVerifyWallet={this.showVerifyWallet}
            onDisableSubmitEmbeddedCreditCard={this.onDisableSubmitEmbeddedCreditCard}
            submitCreditCard={this.submitCreditCard}
            onSubmitWithEmbeddedCreditCard={this.submitWithEmbeddedCreditCard}
          />
          {this.removeUntill && (
            <ZidCheckbox
              isChecked={this.isSavedBankCardData}
              class={styles['credit-section__checkbox']}
              onChange={(event: InputCheckboxChangeEventInterface): void => {
                this.isSavedBankCardData = event.isChecked;
              }}
            >
              <ZidText size={'sm'} weight={'semibold'}>
                {this.$t(I18nMessages['wallet.save_credit_info'])}
              </ZidText>
            </ZidCheckbox>
          )}
        </div>
      </div>
    );
  }
  private renderTransferSection(h: CreateElement): VNode {
    return (
      <ZidWalletComponentsBankTransferSectionComponent onToggleVerifyWalletModal={this.onToggleVerifyWalletModal} />
    );
  }

  private onToggleFundsModal(): void {
    ZidWalletModalsStoreModule.toggleFundsModal();
  }

  private onToggleVerifyWalletModal(): void {
    ZidWalletModalsStoreModule.toggleVerificationModal();
    this.onToggleFundsModal();
  }

  private onPaymentSelect(value: SubscriptionTypesPaymentMethodEnum): void {
    this.chooseOptionError = false;
    this.paymentMethod = value;
  }

  private async setMoneyValue(value: string): Promise<void> {
    this.moneyValue = toEnglishDigits(value);
    if (value.length) this.isUserInput = true;
    else this.isUserInput = false;

    setTimeout(() => {
      !Number.isInteger(Number(this.moneyValue) / this.walletBalanceCredits)
        ? (this.isNotIntgerNumber = true)
        : (this.isNotIntgerNumber = false);
    }, 1000);
  }

  private async onMoneyChipSelect(value: string): Promise<void> {
    //to display it as the input text value:
    this.moneyValue = value;
  }

  //payment cards
  private getStringToLowerCase(text: string): string {
    return text.toLocaleLowerCase();
  }

  private getCardNumber(lastFourDigits: string): string {
    return `**** **** **** ${lastFourDigits}`;
  }
  //start send transactions

  private onDisableSubmitEmbeddedCreditCard(event: boolean): void {
    this.disableSubmitEmbeddedCreditCard = event;
  }

  private clickHandler(): void {
    if (!this.disableSubmitEmbeddedCreditCard) {
      this.submitLoading = true;
    }
    if (this.isTransferSection) {
      this.onToggleFundsModal();
    } else if (this.noOptionChosen) {
      this.chooseOptionError = true;
    } else if (this.isApplePaySection) {
      this.submitWitCreditCard();
    } else {
      this.chargeTheWallet();
    }
  }

  private async chargeTheWallet(): Promise<void> {
    if (this.isCreditSection) {
      this.submitCreditCard = !this.submitCreditCard;
    } else {
      this.submitCreditCard = false;
    }
  }

  private async initializeStartingStep(): Promise<void> {
    PurchaseStoreModule.WALLET_SELECTED(true);
    const packageCode = await this.getPackageId('wallet_balance_50_credits');
    const defaultQuantity = 1;
    if (packageCode) {
      await this.initializePurchase(
        [packageCode],
        Number(this.moneyValue) ? Number(this.moneyValue) / this.walletBalanceCredits : defaultQuantity,
        window.location.href,
      );
    } else {
      PurchaseStoreModule.setInitailzeLoadingState(AppStoreReadyStateEnum.loaded);
    }
  }

  private async initializePurchase(purchasables: Array<string>, quantity: number, callbackUrl: string): Promise<void> {
    await PurchaseStoreModule.initializeWalletPurchase({
      purchasables: purchasables,
      quantity: quantity,
      callbackUrl: callbackUrl,
    });
  }

  @Catch({
    onError: (_, ctx) => {
      ctx.isLoading = false;
      window.history.back();
    },
  })
  private async getPackageId(packageCode: string): Promise<string> {
    const response = await PurchasablesService.getPurchasableByCode(packageCode);
    const packageId: string = response.data.id as string;
    PurchaseStoreModule.setSelectedPackage(packageId);
    return packageId as string;
  }

  private get purchaseId(): string | null {
    return PurchaseStoreModule.purchaseId;
  }

  private validateInput(event: KeyboardEvent): void {
    if (['e', '-'].includes(event.key)) {
      event.preventDefault();
      return;
    }
  }

  private async sendWithSavedCard(event: any): Promise<void> {
    this.submitLoading = true;
    await this.initializeStartingStep();

    if (this.isCreditSection) {
      await PurchaseStoreModule.setSelectedPaymentMethod(SubscriptionTypesPaymentMethodEnum.embeddedCreditCard);
    }

    const response = await this.purchaseCheckout(event.user_id);

    if (response?.status === 'success') {
      PurchaseStoreModule.WALLET_SELECTED(false);
      const transactionUrl = response.data.embedded_credit_card.reference_id;
      setTimeout(() => (window.location.href = transactionUrl), 300);
      this.submitLoading = false;
    } else {
      this.$toaster.show({
        message: this.$t(I18nMessages['app_market.application.purchasePlan.failed']),
        type: NotificationTypeEnum.warning,
      });
    }
  }

  //send with credit card

  private async submitWithEmbeddedCreditCard(event: any): Promise<void> {
    this.submitLoading = true;
    await this.initializeStartingStep();

    if (this.isCreditSection) {
      await PurchaseStoreModule.setSelectedPaymentMethod(SubscriptionTypesPaymentMethodEnum.embeddedCreditCard);
    }

    const response = await this.purchaseCheckout(event.id);

    if (response?.status === 'success') {
      PurchaseStoreModule.WALLET_SELECTED(false);
      const transactionUrl = response.data.embedded_credit_card.transaction_url;
      setTimeout(() => {
        window.location.href = transactionUrl;
        this.submitLoading = false;
      }, 300);
    } else {
      this.$toaster.show({
        message: this.$t(I18nMessages['wallet.wallet_modal.failed_confirmation']),
        type: NotificationTypeEnum.warning,
      });
    }
  }

  @Catch({ onError: (_, ctx) => (ctx.isLoading = false) })
  private async purchaseCheckout(cardSource = null): Promise<any> {
    const walletURL = window.location.origin + window.location.pathname;

    if (this.purchaseId) {
      return await walletService.purchaseWalletCheckout({
        ['purchase_id']: this.purchaseId,
        ['card_source']: cardSource,
        ['callbackUrl']: walletURL,
      });
    }
  }

  //send with apple pay
  @Catch({ onError: (_, ctx) => (ctx.isLoading = false) })
  private async submitWitCreditCard(): Promise<void> {
    this.submitLoading = true;
    await this.initializeStartingStep();

    if (this.isApplePaySection) {
      await PurchaseStoreModule.setSelectedPaymentMethod(SubscriptionTypesPaymentMethodEnum.creditCard);
    }

    const response = await this.purchaseCheckout();

    if (response?.status === 'success') {
      await window.goSell.config(response.data.credit_card.configs);
      setTimeout(async () => {
        await window.goSell.openPaymentPage();
        this.submitLoading = false;
      }, 3000);
    } else {
      this.$toaster.show({
        message: this.$t(I18nMessages['wallet.wallet_modal.failed_confirmation']),
        type: NotificationTypeEnum.warning,
      });
    }
  }
}
