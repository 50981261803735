// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d2cb0d71{background:var(--color-white);font-size:14px;padding:10px;border-radius:5px;border:1px solid var(--color-light-alt8);color:var(--zid-colors-primary-500);transition:box-shadow .3s ease,color .3s ease,border-color .3s ease}.d2cb0d71:focus{outline:none;color:var(--color-secondary-alt7);border-color:var(--zid-colors-primary-400);box-shadow:0 0 0 3px var(--zid-colors-primary-200)}.d2cb0d71::placeholder{opacity:.5}", "",{"version":3,"sources":["webpack://./src/common/components/Input/Text/Text.scss"],"names":[],"mappings":"AAAA,UACE,6BAAA,CACA,cAAA,CACA,YAAA,CACA,iBAAA,CACA,wCAAA,CACA,mCAAA,CACA,mEAAA,CAEA,gBACE,YAAA,CACA,iCAAA,CACA,0CAAA,CACA,kDAAA,CAGF,uBACE,UAAA","sourcesContent":[".inputtext {\n  background: var(--color-white);\n  font-size: 14px;\n  padding: 10px;\n  border-radius: 5px;\n  border: 1px solid var(--color-light-alt8);\n  color: var(--zid-colors-primary-500);\n  transition: box-shadow 0.3s ease, color 0.3s ease, border-color 0.3s ease;\n\n  &:focus {\n    outline: none;\n    color: var(--color-secondary-alt7);\n    border-color: var(--zid-colors-primary-400);\n    box-shadow: 0 0 0 3px var(--zid-colors-primary-200);\n  }\n\n  &::placeholder {\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputtext": "d2cb0d71"
};
export default ___CSS_LOADER_EXPORT___;
