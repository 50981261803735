import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import { ZidWalletApiServiceFactory } from '../api/service-factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { ApiBaseDataResponseObjectType, ApiResponseType } from '../../api/types';
import { ZidWalletConfigurationRequestResponseInterface } from '../types/Configurations/configurations-response.interface';
const zidWalletService = ZidWalletApiServiceFactory();

@Module({
  dynamic: true,
  name: 'zidWalletConfigurations',
  store: appStoreFactory(),
  namespaced: true,
})
class ZidWalletConfigurationsModule extends VuexModule {
  public data: ZidWalletConfigurationRequestResponseInterface = {} as ZidWalletConfigurationRequestResponseInterface;
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public error: Error | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private FETCH_SUCCESS(data: any): void {
    this.data = data;
    this.loadingState = AppStoreReadyStateEnum.loaded;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action({ rawError: true })
  public async fetch(): Promise<
    ApiResponseType<ApiBaseDataResponseObjectType<ZidWalletConfigurationRequestResponseInterface>>
  > {
    this.FETCH();

    return zidWalletService
      .getConfigurations()
      .then((res) => {
        this.FETCH_SUCCESS(res.data);
        return res;
      })
      .catch((err) => {
        this.FETCH_ERROR(err);

        return err.response;
      });
  }
}

export const ZidWalletConfigurationsStoreModule = getModule(ZidWalletConfigurationsModule);
