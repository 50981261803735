<template>
  <div :class="[styles.statusbarmobile, {[styles.statusbarmobile__disabled]: isSuspendedSubscription()}] ">
    <div :class="styles.statusbarmobile__wrapper">
      <div :class="[styles.statusbarmobile__wrapper__item, styles.menu_item, { [styles.collapsed]: !isCollapsed }]">
        <button @click="onToggleNavigation">
          <MenuNavigationIcon :class="styles.statusbarmobile__wrapper__item__menutoggle" />
          <ZidArLogo v-if="isArLocal" width="27" height="16" />
          <ZidEnLogo v-else width="27" height="16" />
        </button>
      </div>
      <div :class="[styles.statusbarmobile__wrapper__item, { [styles.active]: isActive('home') }]">
        <button @click="() => redirectToPage('home')">
          <ZidIcon icon="home_outline" size="xxs" />
          <ZidText size="sm">{{ $t(I18nMessages['common.page_header.main']) }}</ZidText>
        </button>
      </div>
      <div :class="[styles.statusbarmobile__wrapper__item, { [styles.active]: isActive('orders') }]">
        <button @click="() => redirectToPage('orders')">
          <ZidIcon icon="basket_outline" size="xxs" />
          <ZidText size="sm">{{ $t(I18nMessages['sidebar.navigation_section.orders.title']) }}</ZidText>
        </button>
      </div>
      <div :class="[styles.statusbarmobile__wrapper__item, { [styles.active]: isActive('products') }]">
        <button @click="() => redirectToPage('products')">
          <ZidIcon icon="tag" size="xxs" />
          <ZidText size="sm">{{ $t(I18nMessages['sidebar.navigation_section.products.title']) }}</ZidText>
        </button>
      </div>
      <div :class="styles.statusbarmobile__wrapper__item">
        <button @click="openSearchView">
          <ZidIcon icon="search" size="xxs" />
          <ZidText size="sm">{{
            $t(I18nMessages['order_creation.products_and_customer.product_selection.search'])
          }}</ZidText>
        </button>
        <GlobalSearch isSearchMobileView ref="globalSearchComponent" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ZidIcon, ZidText } from '@zidsa/ui';
import { defineComponent, computed, toRefs, ref, onBeforeMount } from 'vue';
import MenuNavigationIcon from '../../../../common/assets/icons/MenuNavigationIcon.vue';
import ZidArLogo from '../../../../common/assets/icons/ZidArLogo.vue';
import ZidEnLogo from '../../../../common/assets/icons/ZidEnLogo.vue';
import { AppStoreModule } from '../../../store/module';
import I18nLocaleEnum from '../../../../i18n/locale.enum';
import { useRouter, useRoute } from 'vue-router/composables';
import GlobalSearch from '../GlobalSearch/GlobalSearch.vue';
import { GlobalSearchComponentInterface } from '../../../types/global-search.interface';
import useI18nMessages from '../../../../common/composables/useI18nMessages';
import { AccountSettingsStoreModule } from '../../../../settings/store/account-settings/module';
import isSuspendedSubscription from "../../../../routing/guards/is-suspended-subscription";

export default defineComponent({
  components: { ZidIcon, ZidText, MenuNavigationIcon, ZidArLogo, ZidEnLogo, GlobalSearch },
  props: {
    isCollapsed: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const { isCollapsed } = toRefs(props);
    const globalSearchComponent = ref<GlobalSearchComponentInterface | null>(null);
    const isArLocal = computed((): boolean => AppStoreModule.locale === I18nLocaleEnum.ar);
    const I18nMessages = useI18nMessages();

    onBeforeMount(async () => {
      await AccountSettingsStoreModule.getFeatureFlagInfo();
    });

    const onToggleNavigation = (event: MouseEvent): void => {
      event.stopPropagation();
      emit('toggleSidebar');
    };

    const redirectToPage = (route: string): void => {
      router.push({ name: route });
    };

    const isActive = (name: string) => {
      return route.name === name;
    };

    const openSearchView = () => {
      if (!globalSearchComponent.value) return;
      globalSearchComponent.value.openSearch();
    };

    return {
      isArLocal,
      onToggleNavigation,
      redirectToPage,
      isActive,
      isCollapsed,
      globalSearchComponent,
      openSearchView,
      I18nMessages,
      isSuspendedSubscription
    };
  },
});
</script>

<style lang="scss" module="styles" src="./StatusBarMobile.module.scss"></style>
