export function objectFilter<TObject extends Record<string, any>>(
  obj: TObject,
  predicate: <TKey extends keyof TObject>(key: TKey, value: TObject[TKey]) => boolean,
): Partial<TObject> {
  return Object.keys(obj).reduce((acc, key: keyof TObject) => {
    const value = obj[key];

    if (predicate(key, value)) {
      acc[key] = value;
    }

    return acc;
  }, {} as Partial<TObject>);
}
