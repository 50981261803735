import { urlQueryFormat } from '../common/helpers/url/query/format';

export function apiParamsSerializer(input: any): string {
  if (typeof input === 'string') {
    return input;
  }

  if (typeof input === 'object') {
    return urlQueryFormat(input);
  }

  return '';
}
