import SettingsTypesAccountSettingsInterface from '../../types/account-settings.interface';
import UserStoreModule from '../../../user/store/module';
import { GenderEnum } from '../../types/gender.enum';

const userProfile = UserStoreModule.data;
/* eslint-disable @typescript-eslint/camelcase */
const data: SettingsTypesAccountSettingsInterface = {
  name: userProfile?.name ?? '',
  email: userProfile?.email ?? '',
  mobile: userProfile?.mobile ?? '',
  mobile_pending_confirmation: userProfile?.mobile_pending_confirmation ?? '',
  email_pending_confirmation: userProfile?.mobile_pending_confirmation ?? '',
  birth_date: userProfile?.user_profile_data.birth_date ?? '',
  gender: userProfile?.gender ?? GenderEnum.male,
  country_id: userProfile?.user_profile_data?.country_id,
  city_id: userProfile?.user_profile_data?.city_id,
  is_organization_employee: userProfile?.user_profile_data.is_organization_employee ?? false,
  working_organization_name: userProfile?.user_profile_data.working_organization_name ?? '',
};
/* eslint-enable @typescript-eslint/camelcase */

export default data;
