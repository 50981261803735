import { SettingsApiLocationCitiesFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../../api/types';
import { SettingsApiLocationCitiesSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../../common/helpers/object/filter';

export function settingsApiLocationCitiesFiltersToSearchParams(
  filters: SettingsApiLocationCitiesFiltersInterface,
): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: SettingsApiLocationCitiesSearchParamsInterface = {
    search: filters.search,
    country_id: filters.countryId !== undefined ? String(filters.countryId) : undefined,
    cities_ids: filters.cities,
  };
  /* eslint-enable @typescript-eslint/camelcase */
  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
