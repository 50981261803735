import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import styles from './Footer.scss';

@Component
export default class AppFooter extends Vue {
  render(h: CreateElement): VNode {
    return (
      <footer class={styles.footer}>
        Copyright&nbsp;<strong>Zid App</strong>&nbsp;&copy; 2016-{this.year}
      </footer>
    );
  }

  private get year(): string {
    return new Date().getFullYear().toString();
  }
}
