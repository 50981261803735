import ApiClient from '../../api/client';
import ApiHttpMethodEnum from '../../api/http-method.enum';
import { ApiModelPurchasableInterface } from '../../api/model/purchasable.interface';
import { ApiBaseDataResponseObjectType } from '../../api/types';
import { urlFormatParams } from '../../common/helpers/url/format-params';
import { purchasablesApiUrls } from './urls';

export class PurchasablesApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getPurchasables(type: string): Promise<ApiBaseDataResponseObjectType<ApiModelPurchasableInterface[]>> {
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelPurchasableInterface[]>>(
      ApiHttpMethodEnum.get,
      purchasablesApiUrls.purchasables,
      {
        params: {
          type,
        },
      },
    );

    return response.data;
  }

  public async getPurchasableByCode(
    code: string,
  ): Promise<ApiBaseDataResponseObjectType<ApiModelPurchasableInterface>> {
    const url = urlFormatParams(purchasablesApiUrls.getPurchasableByCode, {
      code,
    });

    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<ApiModelPurchasableInterface>>(
      ApiHttpMethodEnum.get,
      url,
    );

    return response.data;
  }
}
