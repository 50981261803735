import { MazeedEnumsStoreFormKeysEnum } from '../enums/store-form-keys.enum';
import { MazeedTypesActivationStoreProfileData } from '../types/Activation/store_profile_data.type';

export type MazeedHelpersStoreFormObjectType = {
  [key: string]: number | string | boolean;
};

export const MazeedHelpersActivationStoreFormObjectCheck = (
  object: MazeedHelpersStoreFormObjectType,
): Array<string> => {
  const errorArr = [];
  for (const key in object) {
    const value = object[key];
    switch (key) {
      case MazeedEnumsStoreFormKeysEnum.vatNumber:
        break;
      case MazeedEnumsStoreFormKeysEnum.ordersCount:
        break;
      case MazeedEnumsStoreFormKeysEnum.ordersTotal:
        break;
      case MazeedEnumsStoreFormKeysEnum.bankId:
        break;
      case MazeedEnumsStoreFormKeysEnum.mobile:
        if (value.toString().length < 4) errorArr.push(key);
        break;
      default:
        if (!Boolean(value)) errorArr.push(key);
        break;
    }
  }
  return errorArr;
};

export const ValidateStoreProfileDataHandler = (
  storeProfileData: MazeedTypesActivationStoreProfileData,
): Array<string> => {
  let checkStatusArray: Array<string> = [];

  if (storeProfileData) {
    for (const key in storeProfileData) {
      const value = storeProfileData[key as keyof MazeedTypesActivationStoreProfileData];
      let errorArray: Array<string> = [];

      switch (key) {
        case MazeedEnumsStoreFormKeysEnum.contactOwner:
          errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          break;

        case MazeedEnumsStoreFormKeysEnum.contactSupport:
          errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          break;

        case MazeedEnumsStoreFormKeysEnum.contactOperations:
          if (!storeProfileData.isContactOperations) {
            errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          }
          break;

        default:
          break;
      }

      checkStatusArray = [...errorArray, ...checkStatusArray];
    }
  }
  return checkStatusArray;
};

export const ValidateStoreInformationDataHandler = (
  storeProfileData: MazeedTypesActivationStoreProfileData,
): Array<string> => {
  let checkStatusArray: Array<string> = [];

  if (storeProfileData) {
    for (const key in storeProfileData) {
      const value = storeProfileData[key as keyof MazeedTypesActivationStoreProfileData];
      let errorArray: Array<string> = [];

      switch (key) {
        case MazeedEnumsStoreFormKeysEnum.contactOwner:
          errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          break;

        case MazeedEnumsStoreFormKeysEnum.bankAccount:
          errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          break;

        case MazeedEnumsStoreFormKeysEnum.storeInfo:
          errorArray = MazeedHelpersActivationStoreFormObjectCheck(value as MazeedHelpersStoreFormObjectType);
          break;

        default:
          break;
      }

      checkStatusArray = [...errorArray, ...checkStatusArray];
    }
    if (!Boolean(storeProfileData.mazeedIndustryId)) {
      checkStatusArray.push(MazeedEnumsStoreFormKeysEnum.mazeedIndustryId);
    }
    if (!storeProfileData.store_info.freelance_certificate) {
      checkStatusArray.push(MazeedEnumsStoreFormKeysEnum.freelanceCertificate);
    }
    checkStatusArray = checkStatusArray.filter((e) =>
      storeProfileData.store_info.business_type === MazeedEnumsStoreFormKeysEnum.establishment
        ? e !== MazeedEnumsStoreFormKeysEnum.freelanceCertificate
        : e !== MazeedEnumsStoreFormKeysEnum.crNumber,
    );
  }
  return checkStatusArray;
};

export const updateTheValuesOFStoreProfileObject = (
  storeProfile: MazeedTypesActivationStoreProfileData,
): MazeedTypesActivationStoreProfileData => {
  const storeProfileKeys = Object.keys(storeProfile);
  const newStoreProfile = { ...storeProfile };

  storeProfileKeys.forEach((key: string) => {
    const storeProfileValue = storeProfile[key as keyof MazeedTypesActivationStoreProfileData];
    switch (key) {
      case MazeedEnumsStoreFormKeysEnum.bankAccount:
        if (!Boolean(storeProfileValue)) newStoreProfile[MazeedEnumsStoreFormKeysEnum.bankAccount] = {};
        break;
      case MazeedEnumsStoreFormKeysEnum.contactOwner:
        if (!Boolean(storeProfileValue)) newStoreProfile[MazeedEnumsStoreFormKeysEnum.contactOwner] = {};
        break;
      case MazeedEnumsStoreFormKeysEnum.contactOperations:
        if (!Boolean(storeProfileValue)) newStoreProfile[MazeedEnumsStoreFormKeysEnum.contactOperations] = {};
        break;
      case MazeedEnumsStoreFormKeysEnum.contactSupport:
        if (!Boolean(storeProfileValue)) newStoreProfile[MazeedEnumsStoreFormKeysEnum.contactSupport] = {};
        break;
    }
  });
  return newStoreProfile;
};

export const MazeedHelpersCheckIsNumber = function (evt: any): number | boolean | void {
  evt = evt ? evt : window.event;
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    evt.preventDefault();
  } else {
    return true;
  }
};
