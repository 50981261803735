import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import AppStoreRootStateInterface from './root-state.interface';

export default function appStoreCreate(isProduction: boolean): Store<AppStoreRootStateInterface> {
  Vue.use(Vuex);

  return new Vuex.Store({
    strict: !isProduction,
  });
}
