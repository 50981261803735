import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { ZidButton, ZidIcon } from '@zidsa/ui';
import { I18nMessages } from '../../../i18n/messages';
import ApiModelUserInterface from '../../../api/model/user.interface';
import UserStoreModule from '../../../user/store/module';
import isRTL from '../../../common/helpers/is-lang-arabic';

import styles from './StoreDemoBar.module.scss';

@Component
export default class AppComponentsStoreDemoBarComponent extends Vue {
  private get user(): ApiModelUserInterface | null {
    return UserStoreModule?.data;
  }

  private get checkDemoStore(): boolean {
    const demoStoreId = 127604;
    return this.user?.store.id == demoStoreId;
  }

  render(h: CreateElement): VNode | void {
    if (this.checkDemoStore) {
      return (
        <nav class={styles.store_demo_bar}>
          <div>
            <h3 class={styles.store_demo_bar__title}>{this.$t(I18nMessages['demo_bar.title'])}</h3>
            <p class={styles.store_demo_bar__desc}>
              <span>{this.$t(I18nMessages['demo_bar.desc'])}</span>
              <span> &#128076; </span>
            </p>
          </div>
          <ZidButton class={styles.store_demo_bar__btn} onClick={this.redirectToRegister}>
            <span>{this.$t(I18nMessages['demo_bar.btnText'])}</span>
            <ZidIcon
              class={styles.store_demo_bar__arrow_icon}
              icon={isRTL() ? 'arrow_left' : 'arrow_right'}
              size='xs'
              color='light'
            />
          </ZidButton>
        </nav>
      );
    }
  }

  private redirectToRegister(): void {
    const redirectUrl = 'https://zid.click/3IwPb4I';
    UserStoreModule.logout();
    location.replace(redirectUrl);
  }
}
