import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Collapsible extends Vue {
  @Prop({
    required: true,
  })
  public isCollapsed!: boolean;
}
