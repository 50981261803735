import { CustomersApiFiltersInterface } from './filters.interface';
import { CustomersApiSearchParamsInterface } from './search-params.interface';
import { ApiSearchParamsType } from '../../api/types';
import { objectFilter } from '../../common/helpers/object/filter';

export function customersApiFiltersToSearchParams(filters: CustomersApiFiltersInterface): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: CustomersApiSearchParamsInterface = {
    page: filters.page?.toString(),
    limit: filters.limit?.toString(),
    search: filters.search,
    order_by: filters.orderBy,
    sort_by: filters.sortBy,
    cities_ids: filters.cities,
    orders_count_compare: filters.ordersCountCompare,
    orders_count_value: filters.ordersCount,
    status: filters.status,
    gender: filters.gender,
    birth_month: filters.birthMonth,
    countries_ids: filters.countries,
    is_newsletter_subscriber: filters.newsletterStatus,
    type: filters.type,
    customer_channels: filters.customerChannels,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
