import { getModule, Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import AppNotificationInterface from './notification.interface';
import { v4 as uuidv4 } from 'uuid';

@Module({
  dynamic: true,
  name: 'notifications',
  store: appStoreFactory(),
  namespaced: true,
})
class NotificationsModule extends VuexModule {
  public notifications: Array<AppNotificationInterface> = [];

  @Mutation
  private NOTIFY(notification: AppNotificationInterface): void {
    this.notifications = this.notifications.concat(notification);
  }

  @Mutation
  private DISMISS_NOTIFICATION(notificationID: string): void {
    this.notifications = this.notifications.filter((notification) => notification.id !== notificationID);
  }

  @Action
  public notify(notification: AppNotificationInterface): void {
    const transformedNotification: AppNotificationInterface = { ...notification, id: uuidv4() };
    this.NOTIFY(transformedNotification);
    if (notification.autoDismiss) {
      setTimeout(() => this.DISMISS_NOTIFICATION(transformedNotification.id), 7000);
    }
  }

  @Action
  public dismiss(id: string): void {
    this.DISMISS_NOTIFICATION(id);
  }
}

export const NotificationsStoreModule = getModule(NotificationsModule);
