// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".f1784c29{display:flex;gap:10px}.f1784c29 div{margin:10px}@media(max-width: 414px){.f1784c29{flex-wrap:wrap;flex-direction:column;align-content:center}}.f1784c29 span{align-self:center}.d6b45a5d{display:flex;flex-direction:row;align-items:baseline;gap:5px;width:100%}.de3a3cba{width:18px}", "",{"version":3,"sources":["webpack://./src/zidwallet/components/Balances/Balances.module.scss"],"names":[],"mappings":"AAAA,UACE,YAAA,CACA,QAAA,CACA,cACE,WAAA,CAGF,yBAPF,UAQI,cAAA,CACA,qBAAA,CACA,oBAAA,CAAA,CAGF,eACE,iBAAA,CAGF,UACE,YAAA,CACA,kBAAA,CACA,oBAAA,CACA,OAAA,CACA,UAAA,CAGJ,UACE,UAAA","sourcesContent":[".balances {\n  display: flex;\n  gap: 10px;\n  div {\n    margin: 10px;\n  }\n\n  @media (max-width: 414px) {\n    flex-wrap: wrap;\n    flex-direction: column;\n    align-content: center;\n  }\n\n  span {\n    align-self: center;\n  }\n\n  &-text {\n    display: flex;\n    flex-direction: row;\n    align-items: baseline;\n    gap: 5px;\n    width: 100%;\n  }\n}\n.balances-money-icon {\n  width: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balances": "f1784c29",
	"balances-text": "d6b45a5d",
	"balances-money-icon": "de3a3cba"
};
export default ___CSS_LOADER_EXPORT___;
