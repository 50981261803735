import UserStoreModule from '../../user/store/module';
import isRTL from './is-lang-arabic';

export function isUsingNewPackageSystem(): boolean {
  return UserStoreModule?.data?.store?.subscription?.is_using_new_package_system ?? false;
}

export default function isFeatureAvailable(featureKey: string): boolean | undefined {
  const feature = UserStoreModule?.data?.store.subscription.policies_v2[featureKey];
  if (isUsingNewPackageSystem()) {
    return feature?.enabled ?? false;
  } else return true;
}

export function isOldFeatureAvailable(featureKey: string): boolean {
  const feature = UserStoreModule?.data?.store.subscription.policies_v2[featureKey];
  return feature?.enabled ?? false;
}

export function getFeaturePackage(featureKey: string): string {
  const feature = UserStoreModule?.data?.store.subscription.policies_v2[featureKey];
  return isRTL() ? feature?.available_in.ar ?? '' : feature?.available_in.en ?? '';
}

export function getFeatureConfig(featureKey: string): any | null {
  if (isUsingNewPackageSystem()) {
    const feature = UserStoreModule?.data?.store.subscription.policies_v2[featureKey];
    return feature?.config;
  }
  return null;
}

export function getFeaturePackageKey(featureKey: string): string {
  const feature = UserStoreModule?.data?.store.subscription.policies_v2[featureKey];
  return feature?.available_in.package_key || '';
}

export function getNextPackageCode(featureKey: string): string {
  const featurePackageKey = getFeaturePackageKey(featureKey);

  const subscibtion = UserStoreModule?.data?.store.subscription;

  const targetPackageVariants = subscibtion?.available_variants[featurePackageKey];

  const currentVariant = subscibtion?.package_code.split('.')[1] || '';

  const activeVariant = targetPackageVariants?.variants.includes(currentVariant)
    ? currentVariant
    : targetPackageVariants?.default_variant || '';

  return `${featurePackageKey}.${activeVariant}`;
}
