// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ca9204b9{width:500px;max-width:100%;padding:30px;text-align:center}.f88990e0{--zid-icon-size: 44px !important;margin-bottom:16px}.c735eba7{display:flex;justify-content:center}.d0ed2177 .f88990e0{color:var(--zid-colors-green-500)}.dfa2eba5 .f88990e0{color:var(--color-danger)}.c9dddeb7{font-size:26px;font-weight:400;color:var(--color-dark);margin:0 0 24px}.bd9c264e{margin:0;font-size:16px;font-weight:400;white-space:pre;color:var(--color-dark-alt4)}.b1de0421{margin-top:16px;display:flex;flex-direction:row;justify-content:center}.d012d127{margin:0 3px}", "",{"version":3,"sources":["webpack://./src/common/components/Modal/Upgrade/Upgrade.scss"],"names":[],"mappings":"AAAA,UACE,WAAA,CACA,cAAA,CACA,YAAA,CACA,iBAAA,CAGF,UACE,gCAAA,CACA,kBAAA,CAEA,UACE,YAAA,CACA,sBAAA,CAIJ,oBACE,iCAAA,CAGF,oBACE,yBAAA,CAGF,UACE,cAAA,CACA,eAAA,CACA,uBAAA,CACA,eAAA,CAGF,UACE,QAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,4BAAA,CAGF,UACE,eAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,UACE,YAAA","sourcesContent":[".modalupgrade__content {\n  width: 500px;\n  max-width: 100%;\n  padding: 30px;\n  text-align: center;\n}\n\n.modalupgrade__icon {\n  --zid-icon-size: 44px !important;\n  margin-bottom: 16px;\n\n  &__container {\n    display: flex;\n    justify-content: center;\n  }\n}\n\n.modalupgrade__content--type-success .modalupgrade__icon {\n  color: var(--zid-colors-green-500);\n}\n\n.modalupgrade__content--type-danger .modalupgrade__icon {\n  color: var(--color-danger);\n}\n\n.modalupgrade__title {\n  font-size: 26px;\n  font-weight: 400;\n  color: var(--color-dark);\n  margin: 0 0 24px;\n}\n\n.modalupgrade__description {\n  margin: 0;\n  font-size: 16px;\n  font-weight: 400;\n  white-space: pre;\n  color: var(--color-dark-alt4);\n}\n\n.modalupgrade__footer {\n  margin-top: 16px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n\n.modalupgrade__button {\n  margin: 0 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalupgrade__content": "ca9204b9",
	"modalupgrade__icon": "f88990e0",
	"modalupgrade__icon__container": "c735eba7",
	"modalupgrade__content--type-success": "d0ed2177",
	"modalupgrade__content--type-danger": "dfa2eba5",
	"modalupgrade__title": "c9dddeb7",
	"modalupgrade__description": "bd9c264e",
	"modalupgrade__footer": "b1de0421",
	"modalupgrade__button": "d012d127"
};
export default ___CSS_LOADER_EXPORT___;
