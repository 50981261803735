import { ApiSearchParamsType } from '../../../api/types';
import { objectFilter } from '../../../common/helpers/object/filter';
import ZidpayApiFiltersTransactions, { ZidpayApiFiltersPaymentLink } from '../filters/transactions.interface';

export function transformTransactionsFiltersToRequestParams(
  filters: ZidpayApiFiltersTransactions | ZidpayApiFiltersPaymentLink,
): ApiSearchParamsType {
  if (!filters) return {};
  return objectFilter(filters, (key, value) => Boolean(value)) as ApiSearchParamsType;
}
