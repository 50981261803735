import { I18nMessages } from '../../../../i18n/messages';
import { ZidpayCountriesLocaleBase } from '../../shared/locale/locale';

export class ZidpayCountriesLocaleSA extends ZidpayCountriesLocaleBase {
  static titles: Record<string, string> = {
    individualBankCertificateTitle: I18nMessages['zidpay.activation.account.banckAccount.choose.detailed.individuals'],
    minIbanChar: '24',
    invoiceBtn: I18nMessages['common.vat.invoice'],
    bulkExportBtn: I18nMessages['zidpay.account.statement.deposits.bulk.export.invoice'],
    activationProcessHeader: I18nMessages['zidpay.marketing.activation.process.header'],
  };
}
