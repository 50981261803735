import { Component, Vue, Prop } from 'vue-property-decorator';
import styles from './Draggable.scss';
import VueDraggable from 'vuedraggable';
@Component({
  components: {
    VueDraggable,
  },
  data: () => ({
    styles,
  }),
})
export default class InputDraggableComponent extends Vue {
  @Prop()
  readonly list!: Array<any>;
  @Prop()
  readonly move!: any;
  @Prop()
  readonly noTransitionOnDrag!: boolean;
  @Prop()
  readonly tag?: string | null | undefined;
  @Prop()
  readonly value!: Array<any>;

  onChange(event: any): void {
    this.$emit('change', event);
  }
}
