import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import UserStoreModule from '../../user/store/module';

export function subscriptionWebManagerGuard(to: Route, from: Route, next: Function): void {
  const user = UserStoreModule.data;
  const isWebSubscription = user?.store.subscription.manager?.web ?? true;

  if (isWebSubscription) {
    next();
  } else {
    next({ name: RoutingRouteEnum.subscriptionsIndex });
  }
}
