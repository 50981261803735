import { SettingsApiLocationCountriesFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../../api/types';
import { SettingsApiLocationCountriesSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../../common/helpers/object/filter';

export function settingsApiLocationCountriesFiltersToSearchParams(
  filters?: SettingsApiLocationCountriesFiltersInterface,
): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: SettingsApiLocationCountriesSearchParamsInterface = {
    search: filters?.search,
    countries_ids: filters?.countries,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
