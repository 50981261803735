const enum ApiHttpMethodEnum {
  get = 'GET',
  delete = 'DELETE',
  head = 'HEAD',
  options = 'OPTIONS',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  link = 'LINK',
  unlink = 'UNLINK',
}

export default ApiHttpMethodEnum;
