import { OrdersApiDonationsReportFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../api/types';
import { OrdersApiDonationsReportSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../common/helpers/object/filter';

export function ordersApiDonationsReportFiltersToSearchParams(
  filters: OrdersApiDonationsReportFiltersInterface,
): ApiSearchParamsType {
  const params: OrdersApiDonationsReportSearchParamsInterface = {
    orders: filters.orderIds?.map((orderId) => orderId.toString()),
    period: filters.period,
    ['start_date']: filters.reportStartDate,
    ['end_date']: filters.reportEndDate,
    email: filters.email,
  };

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
