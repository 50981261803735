import { Component, Prop } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { CreateElement } from 'vue/types/umd';
import { ZidHeading, ZidIcon, ZidLoader, ZidText } from '@zidsa/ui';
import moneyIcon from '../../assets/images/money-icon.png';

import styles from './Balances.module.scss';
import { I18nMessages } from '../../../i18n/messages';
import { ZidWalletStoreModule } from '../../store/wallet.module';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import isRTL from '../../../common/helpers/is-lang-arabic';

@Component
export class ZidWalletComponentsBalanceCardsComponent extends Vue {
  @Prop()
  private readonly newBalance!: number;

  @Prop()
  private readonly balance!: number;

  @Prop()
  private readonly isInvalid!: boolean;

  @Prop()
  private readonly hasError!: boolean;

  @Prop()
  private readonly isTransferSection!: boolean;

  private get isLoading(): boolean {
    return ZidWalletStoreModule.loadingState === AppStoreReadyStateEnum.loading;
  }

  render(h: CreateElement): VNode {
    return (
      <div class={styles.balances}>
        {this.renderCurrentBalance(h)}
        {this.isMobileView() ? (
          <ZidIcon icon={'arrow_down'} size='xs' color='secondary' />
        ) : (
          <ZidIcon icon={isRTL() ? 'arrow_left' : 'arrow_right'} size='xs' color='secondary' />
        )}
        {this.renderNewBalance(h)}
      </div>
    );
  }

  private renderCurrentBalance(h: CreateElement): VNode {
    return (
      <div>
        <ZidHeading level={6} weight={'bold'}>
          <img src={moneyIcon} class={styles['balances-money-icon']} />{' '}
          {this.$t(I18nMessages['zidship.wallet.current_balance'])}
        </ZidHeading>
        <div class={styles['balances-text']}>
          {this.isLoading ? (
            <ZidLoader />
          ) : (
            <ZidHeading weight={'semibold'} level={3}>
              {this.balance.toLocaleString()}
            </ZidHeading>
          )}
          <ZidText size={'sm'}>{this.$t(I18nMessages['common.saudi.riyal'])}</ZidText>
        </div>
      </div>
    );
  }

  private renderNewBalance(h: CreateElement): VNode {
    return (
      <div>
        <ZidHeading level={5} weight={'bold'}>
          <img src={moneyIcon} class={styles['balances-money-icon']} />{' '}
          {this.$t(I18nMessages['zidship.wallet.online_payment_modal_new_balance'])}
        </ZidHeading>
        <div class={styles['balances-text']}>
          {this.isLoading ? (
            <ZidLoader />
          ) : (
            <ZidHeading weight={'semibold'} level={3} type={this.isInvalid || this.hasError ? 'neutral' : null}>
              {this.isTransferSection ? (
                <span> {this.$t(I18nMessages['wallet.wallet_modal.transfer_new_balance'])} </span>
              ) : this.isInvalid || this.hasError ? (
                this.balance.toLocaleString()
              ) : (
                this.newBalance.toLocaleString(undefined, { maximumFractionDigits: 2 })
              )}
            </ZidHeading>
          )}
          <ZidText size={'sm'} style={this.isTransferSection ? 'display:none' : ''}>
            {this.$t(I18nMessages['common.saudi.riyal'])}
          </ZidText>
        </div>
      </div>
    );
  }

  private isMobileView(): boolean {
    return window.matchMedia('(max-width: 414px)').matches;
  }
}
