import Vue, { CreateElement, VNode } from 'vue';
import { Component, Inject } from 'vue-property-decorator';
import { dropdownBaseToggleExpandedSymbol } from '../toggle-expanded-symbol';

@Component
export class DropdownBaseToggleExpandedComponent extends Vue {
  @Inject({
    from: dropdownBaseToggleExpandedSymbol,
    default: () => {
      /* noop */
    },
  })
  private toggleExpanded!: () => void;

  render(h: CreateElement): VNode {
    return (
      <button type='button' onClick={this.toggleExpanded}>
        {this.$slots.default}
      </button>
    );
  }
}
