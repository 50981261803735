import { InputTinyMCEChangeEventInterface } from './change-event.interface';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { TINY_MCE_API_KEY } from './api-key.const';
import I18nLocaleEnum from '../../../../i18n/locale.enum';
import styles from './TinyMCE.scss';

@Component({
  name: 'TinyMCE',
  components: {
    editor: Editor,
  },
})
export default class TinyMCEComponent extends Vue {
  @Prop({
    default: I18nLocaleEnum.ar,
  })
  private readonly lang!: I18nLocaleEnum;

  @Prop({
    required: true,
  })
  readonly value!: string;

  @Prop({
    default: (): Array<string> => [],
  })
  private readonly tools!: Array<string>;

  @Prop({
    default: (): Array<string> => [],
  })
  private readonly plugins!: Array<string>;

  get TINY_MCE_API_KEY(): string {
    return TINY_MCE_API_KEY;
  }
  get config(): Record<string, any> {
    /* eslint-disable @typescript-eslint/camelcase */
    return {
      height: 300,
      menubar: false,
      plugins: this.editorPlugins,
      toolbar: this.editorToolbar,
      directionality: this.editorDirection,
      language: this.editorLanguage,
      resize: false,
      statusbar: false,
      content_style: styles,
    };
    /* eslint-enable @typescript-eslint/camelcase */
  }
  get editorPlugins(): string {
    const defaultPlugins: Array<string> = ['link', 'media', 'image', 'table', 'lists', 'fullscreen', 'code'];

    return [...defaultPlugins, this.plugins].join(' ');
  }
  get editorToolbar(): string {
    const defaultTools = [
      'styleselect',
      'bold underline italic removeformat',
      'fontselect',
      'forecolor backcolor',
      'bullist numlist',
      'indent outdent',
      'alignright aligncenter alignleft alignjustify',
      'table',
      'link image media',
      'fullscreen code help',
    ];
    return [...defaultTools, ...this.tools].join(' | ');
  }
  get editorDirection(): string {
    return this.lang === I18nLocaleEnum.ar ? 'rtl' : 'ltr';
  }
  get editorLanguage(): string {
    return this.lang === I18nLocaleEnum.en ? 'en_US' : this.lang;
  }
  onChange(event: any): void {
    const value = event.target.getContent();

    const changeEvent: InputTinyMCEChangeEventInterface = {
      value,
    };

    this.$emit('change', changeEvent);
  }
}
