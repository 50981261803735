import NotificationTypeEnum from '../../../notifications/components/type.enum';
import { NotificationsStoreModule } from '../../../notifications/store/module';

/**
 *
 * @param {string} text - Notification content
 * @param {string} type - 'success' or 'danger'
 * @param {string} identifier - Used by QA Automated Testing to identify HTML elements.
 * @param {string} title - Notification title
 */
export default function showNotification(
  text: string,
  type: NotificationTypeEnum,
  identifier?: string,
  title?: string,
): void {
  NotificationsStoreModule.notify({
    id: '',
    text,
    type,
    showBell: false,
    autoDismiss: true,
    identifier,
    title,
  });
}
