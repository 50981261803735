import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import UserStoreModule from '../../user/store/module';

export function marketplaceConnectGuard(to: Route, from: Route, next: Function): void {
  const isMarketplaceConnectEnabled = UserStoreModule.data?.store?.marketplace_connect_enabled;

  if (isMarketplaceConnectEnabled) {
    next();
  } else {
    next({
      name: RoutingRouteEnum.appMarket,
    });
  }
}
