import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InputSelectChangeEventInterface } from './change-event.interface';
import { InputSelectOptionInterface } from './option.interface';
import { InputSelectOptionGroupInterface } from './option-group.interface';
import { InputSelectOptionType, InputSelectOptionsType, InputSelectValueType } from './types';
import styles from './Select.scss';
import { I18nMessages } from '../../../../i18n/messages';

@Component
export class InputSelectComponent<TValue extends InputSelectValueType = string> extends Vue {
  @Prop({
    required: true,
  })
  private readonly options!: InputSelectOptionsType<TValue>;

  @Prop()
  private readonly identifier!: string | undefined;

  @Prop()
  private readonly value!: TValue;

  @Prop({
    default: false,
  })
  private readonly disabled!: boolean;

  render(h: CreateElement): VNode {
    return (
      <select
        disabled={this.disabled}
        data-identifier={this.identifier}
        class={styles.inputselect}
        onChange={this.onChange}
        value={this.value}
      >
        {this.options.map((item, index) => this.renderItem(h, item, index))}
      </select>
    );
  }

  renderItem(h: CreateElement, item: InputSelectOptionType<TValue>, index: number): VNode {
    if (item.hasOwnProperty('options')) {
      return this.renderOptionGroup(h, item as InputSelectOptionGroupInterface<TValue>);
    }

    return this.renderOption(h, item as InputSelectOptionInterface<TValue>, index);
  }

  renderOptionGroup(h: CreateElement, optionGroup: InputSelectOptionGroupInterface<TValue>): VNode {
    return (
      <optgroup
        data-identifier={this.identifier + '_option' + optionGroup.value}
        key={optionGroup.value}
        label={optionGroup.label}
      >
        {optionGroup.options.map((option, index) => this.renderOption(h, option, index))}
      </optgroup>
    );
  }

  renderOption(h: CreateElement, option: InputSelectOptionInterface<TValue>, index: number): VNode {
    return (
      <option
        key={`${index}-${option.value}`}
        value={option.value ? option.value.toString() : undefined}
        data-selected={option.value?.toString() === this.value?.toString()}
        data-identifier={`select-option-${
          option.value?.toString() ?? this.$t(I18nMessages['common.forms.choose']).toString()
        }`}
      >
        {option.label}
      </option>
    );
  }

  private onChange(event: Event) {
    const value = (event.target as HTMLSelectElement).value;

    let option: InputSelectOptionInterface<TValue> | undefined;
    let group: InputSelectOptionGroupInterface<TValue> | undefined;

    for (let item of this.options) {
      if (item.hasOwnProperty('options')) {
        item = item as InputSelectOptionGroupInterface<TValue>;

        option = item.options.find((option) => option.value && option.value.toString() === value);

        if (option) {
          group = item;
        }
      } else {
        item = item as InputSelectOptionInterface<TValue>;

        if (item.value && item.value.toString() === value) {
          option = item;
        }
      }

      if (option) {
        break;
      }
    }

    // Will happen only if there's a race condition which should be never
    if (!option) {
      //Optional event to notify the parent component
      //of being deselected
      this.$emit('deselect');
      return;
    }

    const changeEvent: InputSelectChangeEventInterface<TValue> = {
      group,
      option,
    };

    this.$emit('change', changeEvent);
  }
}
