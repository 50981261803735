import { ZidButton, ZidCol, ZidHeading, ZidModal, ZidText } from '@zidsa/ui';
import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { I18nMessages } from '../../../../i18n/messages';
import styles from './VerifyWalletOtpModal.module.scss';
import { InputTextChangeEventInterface, InputTextComponent } from '../../../../common/components/Input';
import { ZidWalletVerificationStoreModule } from '../../../store/wallet-verification.module';
import showNotification from '../../../../common/helpers/show-notification/show-notification';
import NotificationTypeEnum from '../../../../notifications/components/type.enum';
import { ZidWalletComponentsSuccessModalComponent } from '../../SuccessModal/SuccessModal';
import { ZidWalletVerificationStatusEnum } from '../../../types/Verification/verification-status.enum';
import { ZidWalletStoreModule } from '../../../store/wallet.module';

@Component
export class VerifyWalletComponentsVerifyWalletOtpModalComponent extends Vue {
  @Prop()
  private readonly showOTPModal!: boolean;

  @Prop()
  private readonly verificationId!: string;

  @Prop({
    default: 4,
  })
  private codeLength!: number;

  private codeFields = Array<string>(this.codeLength).fill('');

  @Prop({
    default: 60,
  })
  private countDownDuration!: number;

  private get userMobile(): string {
    return ZidWalletStoreModule.data?.phoneNumber;
  }

  private get hiddenNumber(): string {
    let lastFourDigits;
    let mobile = '';
    if (this.userMobile && this.userMobile.length >= 8) {
      lastFourDigits = this.userMobile.substring(this.userMobile.length - 4, this.userMobile.length);
      mobile = `**${lastFourDigits}`;
    }
    return mobile;
  }

  private isResent = false;

  private countDown = this.countDownDuration;

  private showOtpSuccessModal = false;

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidModal class={styles['wallet__otp']} visible={this.showOTPModal} onClose={this.onToggleOtpModal}>
          <div class={styles['wallet__otp--top-content']}>
            <ZidHeading type={'primary'} weight={'semibold'} level={5}>
              {this.$t(I18nMessages['wallet.otp.title'])}
            </ZidHeading>
            <ZidText dir={'ltr'}>
              {this.$t(I18nMessages['account.login.otp.card.description'], {
                ['phone_number']: this.hiddenNumber,
              })}
            </ZidText>
          </div>
          <form data-identifier={'otp_form'}>
            <ZidCol class={styles['wallet__otp--fields']}>
              {this.codeFields.map((field, index) => (
                <InputTextComponent
                  onChange={(event: InputTextChangeEventInterface): void => this.handleSMSFill(event)}
                  type='text'
                  id={`field_${index}`}
                  min={0}
                  max={9}
                  maxlength={1}
                  value={field}
                  nativeOnKeydown={(event: KeyboardEvent): void => this.validateInput(event, index)}
                  nativeOnKeypress={(event: KeyboardEvent): void => this.handleInput(event, index)}
                  inputmode='numeric'
                  autocomplete='one-time-code'
                />
              ))}
            </ZidCol>
            {/* Resend Code */}
            <div>{this.renderTimer(h)}</div>

            <div class={styles['wallet__otp--btns']}>
              <ZidButton link onClick={this.onToggleOtpModal}>
                {this.$t(I18nMessages['common.cancel'])}
              </ZidButton>
              <ZidButton type={'primary'} onClick={this.submit}>
                {this.$t(I18nMessages['wallet.confirm_info'])}
              </ZidButton>
            </div>
          </form>
        </ZidModal>

        <ZidWalletComponentsSuccessModalComponent
          showOtpSuccessModal={this.showOtpSuccessModal}
          onToggleSuccessModal={this.onToggleSuccessModal}
        />
      </div>
    );
  }

  private renderResend(h: CreateElement): VNode {
    return (
      <div class={styles['wallet__otp--input-col']}>
        <ZidText size={'sm'}>{this.$t(I18nMessages['account.otp.resend.message'])}</ZidText>
        <ZidButton data-identifier={'resend_otp_btn'} link size={'small'} onClick={this.resendOtp}>
          {this.$t(I18nMessages['account.login.otp.card.resend'])}
        </ZidButton>
      </div>
    );
  }

  private renderTimer(h: CreateElement): VNode {
    if (this.isResent)
      return (
        <div class={styles['wallet__otp--input-col']}>
          <ZidText size={'sm'}>{this.$t(I18nMessages['account.otp.resend.message'])}</ZidText>
          <ZidButton data-identifier={'timer_btn'} link size={'small'}>
            {this.$t(I18nMessages['account.login.otp.card.resend'])}
          </ZidButton>
          <ZidText size={'sm'}>
            ({this.countDown} {this.$t(I18nMessages['common.second'])})
          </ZidText>
        </div>
      );

    return <div>{this.renderResend(h)}</div>;
  }

  private handleInput(event: KeyboardEvent, index: number): void {
    if (this.isSingleDigitNumber(event.key)) {
      this.$set(this.codeFields, index, event.key);
      this.shiftFocus(index + 1);
    }
  }

  private validateInput(event: KeyboardEvent, index: number): void {
    if (!this.isSingleDigitNumber(event.key) && !['Enter', 'Tab', 'Delete', 'Backspace'].includes(event.key)) {
      event.preventDefault();
      return;
    }

    if (event.key === 'Backspace') {
      this.shiftFocusBack(index);
    }

    if (event.key === 'Enter') {
      this.submit();
    }
  }

  private handleSMSFill(event: InputTextChangeEventInterface): void {
    const lastFour = 4;
    if (event.value.length === lastFour) {
      this.codeFields[0] = event.value;
      this.codeFields = this.codeFields[0].split('');
    }
  }

  private isSingleDigitNumber(value: string | number): boolean {
    return RegExp(/^\d+$/).test(value.toString());
  }

  private shiftFocus(index: number): void {
    const nextElement = document.getElementById(`field_${index}`);
    if (!nextElement) {
      return;
    }

    nextElement?.focus();
  }

  private shiftFocusBack(index: number): void {
    const prevElement = document.getElementById(`field_${index - 1}`);
    const lastElement = document.getElementById(`field_3`) as HTMLInputElement;

    if (lastElement) {
      lastElement.value = '';
    }
    if (!prevElement) {
      return;
    }

    prevElement?.focus();
  }

  private onToggleOtpModal(): void {
    this.$emit('toggleOtpModal');
  }

  private async submit(): Promise<any> {
    const otpCode = this.codeFields.join('');
    const info = {
      id: this.verificationId,
      otp: otpCode,
    };

    if (otpCode.length === 4) {
      await ZidWalletVerificationStoreModule.validateWalletVerificationOTPRequest(info).then((response: any) => {
        //OTP API response status will still be success if OTP is 4 digits but is incorrect.
        //therefore, need to check if verification status has turned to pending in order to know if OTP is correct
        // + Enums in gRPC are read oddly -- first enum being 0 int and review_pending being string enum
        if (
          response.status &&
          response.status === 200 &&
          String(ZidWalletVerificationStoreModule.data.status) ===
            ZidWalletVerificationStatusEnum[ZidWalletVerificationStatusEnum.REVIEW_PENDING]
        ) {
          this.onToggleSuccessModal();
          this.onToggleOtpModal();
        } else {
          showNotification(String(response.data.errorMessage), NotificationTypeEnum.danger);
          showNotification(String(this.$t(I18nMessages['otp.default_error'])), NotificationTypeEnum.danger);
        }
      });
    } else {
      showNotification(String(this.$t(I18nMessages['otp.default_error'])), NotificationTypeEnum.danger);
    }
  }

  private async resendOtp(): Promise<void> {
    if (this.countDown > 0 && !this.isResent) {
      this.isResent = true;
      this.startTimer();
      await ZidWalletVerificationStoreModule.resendWalletVerificationOtpRequest(this.verificationId).then(
        (response: any) => {
          if (response.status === 200) {
            showNotification(String(this.$t(I18nMessages['wallet.otp_resent'])), NotificationTypeEnum.success);
          } else {
            showNotification(response.data.errorMessage, NotificationTypeEnum.danger);
          }
        },
      );
    }
  }

  private startTimer(): void {
    const timer = setInterval(() => {
      if (this.countDown <= 0) {
        clearInterval(timer);
        this.isResent = false;
        this.countDown = this.countDownDuration;
      } else {
        this.countDown--;
      }
    }, 1000);
  }

  private onToggleSuccessModal(): void {
    this.showOtpSuccessModal = !this.showOtpSuccessModal;
  }
}
