import { getModule, Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../app/store/factory';
import AppStoreReadyStateEnum from '../../app/store/ready-state.enum';
import { DashboardApiServiceFactory } from '../api/service-factory';
import ApiModelIndustryInterface from '../../api/model/industry.interface';

const dashboardService = DashboardApiServiceFactory();

@Module({
  dynamic: true,
  name: 'dashboardIndustries',
  store: appStoreFactory(),
  namespaced: true,
})
class IndustriesModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: Array<ApiModelIndustryInterface> | null = null;
  public error: Error | null = null;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.error = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: Array<ApiModelIndustryInterface>): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data;
  }

  @Mutation
  private FETCH_ERROR(error: Error): void {
    this.loadingState = AppStoreReadyStateEnum.error;
    this.error = error;
  }

  @Action
  public async confirmStoreActivity({
    industryId,
    categoryId,
    storeCategoryOther,
  }: {
    industryId: number | undefined;
    categoryId: number | undefined;
    storeCategoryOther: string;
  }) {
    try {
      const response = await dashboardService.confirmStoreActivity(industryId, categoryId, storeCategoryOther);

      return response;
      // this.FETCH_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }

  @Action
  public async fetch(): Promise<void> {
    this.FETCH();

    try {
      const response = await dashboardService.getIndustries();

      this.FETCH_SUCCESS(response.data);
    } catch (error) {
      this.FETCH_ERROR(error as Error);
    }
  }
}

export const DashboardStoreIndustriesModule = getModule(IndustriesModule);
