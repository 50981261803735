import { OrdersApiExportAllFiltersInterface } from './filters.interface';
import { ApiSearchParamsType } from '../../../api/types';
import { OrdersApiExportAllSearchParamsInterface } from './search-params.interface';
import { objectFilter } from '../../../common/helpers/object/filter';

export function ordersApiExportAllFiltersToSearchParams(
  filters: OrdersApiExportAllFiltersInterface,
): ApiSearchParamsType {
  // Some of the filters search params use camel case that's not allowed
  // inside the application. Disable the rule in order to prepare the search params object
  /* eslint-disable @typescript-eslint/camelcase */
  const params: OrdersApiExportAllSearchParamsInterface = {
    order_id: filters.orderId?.toString(),
    customer_id: filters.customerId?.toString(),
    date_from: filters.dateFrom,
    date_to: filters.dateTo,
    shipping_method: filters.shippingMethod?.toString(),
    ['shipping_country']: filters.shippingCountry,
    ['shipping_city']: filters.shippingCity,
    payment_method: filters.paymentMethod?.toString(),
    search: filters.search,
    status: filters.orderStatus?.toString(),
    order_by: filters.orderBy,
    sort_by: filters.sortBy,
    email: filters.email,
    source: filters.source,
    ['date_attribute']: filters.dateAttribute,
    ['transaction_reference']: filters.transactionReference,
    tags: filters.tags,
    ['payment_status']: filters.orderPaymentStatus,
    ['customer_type']: filters['customer_type'],
    sku: filters.sku,
    cashier: filters.cashier,
    ['is_gift_order']: filters.isGiftOrder,
  };
  /* eslint-enable @typescript-eslint/camelcase */

  return objectFilter(params, (key, value) => value !== undefined) as ApiSearchParamsType;
}
