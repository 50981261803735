<template>
  <div :class="styles['search-results']">
    <button :class="styles['link']" @click="redirectToFeaturePage(url)">
      <div :class="styles['result-box-wrapper']">
        <div :class="styles['category-icon']">
          <component v-if="customIcon" :is="customIcon"></component>
          <ZidIcon v-else :icon="icon" size="14px" />
        </div>
        <div v-if="img" :class="styles['img-box']">
          <img :src="img" alt="result-img" />
        </div>
        <div :class="styles['content-box']">
          <ZidText :weight="handleFeatureResultFontWeight" size="sm">{{ result }}</ZidText>
          <ZidText :class="styles['sub-result']" size="sm">{{ subResult }}</ZidText>
        </div>
      </div>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue';
import { ZidIcon, ZidText } from '@zidsa/ui';
import { useRouter } from 'vue-router/composables';
import { globalSearchModule } from '../../../../../store/GlobalSearch/module';
import { GlobalSearchCategoriesEnum } from '../../../../../types/global-search.enum';

export default defineComponent({
  props: ['img', 'result', 'subResult', 'icon', 'url', 'customIcon', 'source'],
  components: { ZidIcon, ZidText },
  setup(props) {
    const { img, result, subResult, icon, url, customIcon, source } = toRefs(props);
    const router = useRouter();
    const handleFeatureResultFontWeight = computed((): string => {
      if (!source.value || globalSearchModule.selectedSearchCategories) return 'regular';
      return source.value === GlobalSearchCategoriesEnum.features ? 'semibold' : 'regular';
    });

    const redirectToFeaturePage = (url: string | object): void => {
      if (typeof url === 'string') {
        window.location.href = url;
        return;
      }

      router.push(url);
      globalSearchModule.TOGGLE_SEARCH_VIEW(false);
    };

    return {
      img,
      result,
      subResult,
      icon,
      url,
      customIcon,
      redirectToFeaturePage,
      handleFeatureResultFontWeight,
      GlobalSearchCategoriesEnum,
    };
  },
});
</script>
<style lang="scss" module="styles" src="./SearchResult.module.scss"></style>
