import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { LoaderComponent, LoaderSizeEnum } from '../Loader';
import { ButtonTypeEnum } from './type.enum';
import { ButtonSizeEnum } from './size.enum';
import { CreateElement, VNode } from 'vue/types/umd';
import styles from './Button.scss';
import { ZidIconComponent } from '../ZidIcon';
import { FontAwesomeIconComponent } from '../FontAwesomeIcon';

@Component
export class ButtonComponent extends Vue {
  private readonly typeToClassNameMap: Record<ButtonTypeEnum, string> = {
    [ButtonTypeEnum.primary]: styles['button--type-primary'],
    [ButtonTypeEnum.secondary]: styles['button--type-secondary'],
    [ButtonTypeEnum.success]: styles['button--type-success'],
    [ButtonTypeEnum.warning]: styles['button--type-warning'],
    [ButtonTypeEnum.danger]: styles['button--type-danger'],
    [ButtonTypeEnum.info]: styles['button--type-info'],
    [ButtonTypeEnum.link]: styles['button--type-link'],
    [ButtonTypeEnum.light]: styles['button--type-light'],
    [ButtonTypeEnum.neutral]: styles['button--type-neutral'],
  };

  private readonly sizeToClassNameEnum: Record<ButtonSizeEnum, string> = {
    [ButtonSizeEnum.small]: styles['button--size-small'],
    [ButtonSizeEnum.default]: styles['button--size-default'],
    [ButtonSizeEnum.large]: styles['button--size-large'],
  };

  @Prop({
    default: ButtonTypeEnum.primary,
  })
  private readonly type!: ButtonTypeEnum;

  @Prop({
    default: ButtonSizeEnum.default,
  })
  private readonly size!: ButtonSizeEnum;

  @Prop({
    default: false,
  })
  private readonly outlined!: boolean;

  @Prop({
    default: false,
  })
  private readonly isLoading!: boolean;

  @Prop()
  readonly htmlType!: 'button' | 'submit' | 'reset';

  render(h: CreateElement): VNode {
    return (
      <button type={this.htmlType} class={this.classes} onClick={this.onClick}>
        <span class={styles.button__content}>{this.$slots.default}</span>
        {this.isLoading && <LoaderComponent size={LoaderSizeEnum.small} class={styles.button__loader} />}
      </button>
    );
  }

  get classes(): Record<string, boolean> {
    return {
      [styles.button]: true,
      [styles['button--outlined']]: this.outlined,
      [styles['button--loading']]: this.isLoading,
      [styles['button--icon-only']]: this.isIconOnly(),
      [this.typeToClassNameMap[this.type]]: !!this.type,
      [this.sizeToClassNameEnum[this.size]]: !!this.size,
    };
  }

  private isIconOnly(): boolean {
    if (!Array.isArray(this.$slots.default) || this.$slots.default.length !== 1) {
      return false;
    }

    const componentClass = this.$slots.default[0].componentOptions?.Ctor;
    return componentClass === ZidIconComponent || componentClass === FontAwesomeIconComponent;
  }

  private onClick(event: MouseEvent): void {
    // We dont generally want the click propagation
    event.stopPropagation();
    this.$emit('click', event);
  }
}
