import Vue, { VNode, CreateElement } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export class ClickOutsideHandlerComponent extends Vue {
  private readonly documentElement: HTMLElement = document.body;

  @Prop({
    default: true,
  })
  private readonly isListening!: boolean;

  private listening = false;

  mounted(): void {
    if (this.isListening) {
      this.listen();
    }
  }

  beforeDestroy(): void {
    this.endListening();
  }

  render(h: CreateElement): VNode {
    return <div>{this.$slots.default}</div>;
  }

  @Watch('isListening')
  protected onChangeIsListening(value: boolean, oldValue: boolean): void {
    if (value === oldValue) {
      return;
    }

    if (value) {
      this.listen();
    } else {
      this.endListening();
    }
  }

  private onClickDocumentElement(event: MouseEvent): void {
    const element = this.$el;
    const path = event.composedPath ? event.composedPath() : [];

    const isInside = path.indexOf(element) !== -1 || (event.target && element.contains(event.target as Node));

    if (!isInside) {
      this.$emit('clickOutside', event);
      this.listening = false;
    }
  }

  private listen(): void {
    if (this.listening) {
      return;
    }

    this.documentElement.addEventListener('click', this.onClickDocumentElement);
    this.listening = true;
  }

  private endListening(): void {
    if (!this.listening) {
      return;
    }

    this.documentElement.removeEventListener('click', this.onClickDocumentElement);
    this.listening = true;
  }
}
