import { Route } from 'vue-router';
import { RoutingRouteEnum } from '../route.enum';
import { UserPermissionSlugEnum } from '../../api/model/user/permission-slug.enum';
import { userStoreHasPermission } from '../../user/store/has-permission';

function isRouteAllowed(to: Route): boolean {
  function hasWalletSectionPermissions(): boolean {
    return userStoreHasPermission(UserPermissionSlugEnum.walletViewBalance);
  }
  function hasPaymentOptionsPermissions(): boolean {
    return userStoreHasPermission(UserPermissionSlugEnum.paymentOptions);
  }

  return [
    RoutingRouteEnum.subscriptionsSuspended,
    RoutingRouteEnum.home,
    RoutingRouteEnum.subscriptionsCheckout,
    RoutingRouteEnum.completeStoreInfo,
    RoutingRouteEnum.subscriptionsCheckoutThank,
    hasWalletSectionPermissions() ? RoutingRouteEnum.zidWallet : null,
    hasPaymentOptionsPermissions() ? RoutingRouteEnum.paymentOptions : null,
  ].includes(to.name as RoutingRouteEnum);
}

export function suspendedGuard(to: Route, next: Function) {
  if (!isRouteAllowed(to)) next({ name: RoutingRouteEnum.home });
  else next();
}
