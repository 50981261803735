import Vue, { CreateElement, VNode } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ZidIcon } from '@zidsa/ui';

import { ButtonComponent, ButtonSizeEnum, ButtonTypeEnum } from '../../Button';
import { ModalComponent } from '../Modal';
import { I18nMessages } from '../../../../i18n/messages';

import styles from './Upgrade.scss';

@Component
export class ModalUpgradeComponent extends Vue {
  @Prop({
    required: true,
  })
  private readonly isOpen!: boolean;

  @Prop()
  private readonly icon!: string;

  @Prop()
  private readonly iconClass!: string;

  @Prop()
  private readonly title!: string;

  render(h: CreateElement): VNode {
    return (
      <ModalComponent isOpen={this.isOpen} onClose={this.onCloseModal} contentClass={[styles.modalupgrade__content]}>
        {this.icon && this.renderIcon(h)}
        {this.title && <h2 class={styles.modalupgrade__title}>{this.title}</h2>}
        {this.$t(I18nMessages['subscriptions.upgrade.package'])}
        <footer class={styles.modalupgrade__footer}>
          <ButtonComponent
            size={ButtonSizeEnum.small}
            type={ButtonTypeEnum.primary}
            onClick={this.onClickButtonConfirm}
            class={styles.modalupgrade__button}
          >
            {this.$t(I18nMessages['subscriptions.visit.subscription'])}
          </ButtonComponent>
          <ButtonComponent
            size={ButtonSizeEnum.small}
            type={ButtonTypeEnum.light}
            onClick={this.onClickButtonCancel}
            class={styles.modalupgrade__button}
          >
            {this.$t(I18nMessages['common.cancel'])}
          </ButtonComponent>
        </footer>
      </ModalComponent>
    );
  }

  private renderIcon(h: CreateElement): VNode {
    return (
      <div class={styles.modalupgrade__icon__container}>
        <ZidIcon icon={this.icon} color='primary' class={[styles.modalupgrade__icon, this.iconClass]} />
      </div>
    );
  }

  private onClickButtonConfirm(): void {
    this.$emit('confirm');
  }

  private onClickButtonCancel(): void {
    this.$emit('close');
  }

  private onCloseModal(): void {
    this.$emit('close');
  }
}
