import ApiClient from '../../../api/client';
import ApiHttpMethodEnum from '../../../api/http-method.enum';
import type {
  ApiBaseDataResponseObjectType,
  ApiBaseMessageResponseObjectType,
  ApiBaseResponseObjectType,
  ApiResponseType,
  ApiStatusResponseObjectType,
} from '../../../api/types';
import { urlFormatParams } from '../../../common/helpers/url/format-params';
import type { SubscriptionTypesSubscriptionCheckoutCouponRequestInterface } from '../../types/subscription-checkout-coupon-request.interface';
import type { SubscriptionTypesSubscriptionCheckoutRecurringRequestInterface } from '../../types/subscription-checkout-is-recurring-request.interface';
import type { SubscriptionTypesSubscriptionCheckoutPaymentMethodRequestInterface } from '../../types/subscription-checkout-payment-method-request.interface';
import type { SubscriptionTypesSubscriptionPurchasesCheckoutRequestInterface } from '../../types/subscription-checkout-purchases-checkout-request.interface';
import type { SubscriptionTypesSubscriptionPurchasableRequestInterface } from '../../types/subscription-purchasable.interface';
import type { SubscriptionApiCoreProductsResponseInterface } from './core-products/response.interface';
import type { SubscriptionApiCorePackagesResponseInterface } from './core-packages/response.interface';
import type { SubscriptionApiPurchaseResponseInterface } from './purchase/response.interface';
import type { SubscriptionApiPurchasedResponseInterface } from './purchased/response.interface';
import { subscriptionCheckoutApiUrls } from './urls';
import type { SupscriptionTypesConfirmCheckoutResponseInterface } from '../../types/confirm-checkout-response.interface';
import type { SubscriptionTypesSubscriptionCheckoutApplePayConfigType } from '../../types/apple-pay-config.interface';

export class SubscriptionCheckoutApiService {
  constructor(private readonly apiClient: ApiClient) {}

  public async getCorePackages(): Promise<ApiResponseType<SubscriptionApiCorePackagesResponseInterface>> {
    const response = await this.apiClient.request<SubscriptionApiCorePackagesResponseInterface>(
      ApiHttpMethodEnum.get,
      subscriptionCheckoutApiUrls.getCorePackages,
      { cacheable: true },
    );

    return response;
  }

  public async getCoreProducts(
    visibility?: string,
  ): Promise<ApiResponseType<SubscriptionApiCoreProductsResponseInterface>> {
    const params = visibility ? { visibility } : {};
    const response = await this.apiClient.request<SubscriptionApiCoreProductsResponseInterface>(
      ApiHttpMethodEnum.get,
      subscriptionCheckoutApiUrls.getCoreProducts,
      { cacheable: true, params },
    );

    return response;
  }

  public async createPurchase(
    data: Array<SubscriptionTypesSubscriptionPurchasableRequestInterface>,
  ): Promise<SubscriptionApiPurchaseResponseInterface> {
    const response = await this.apiClient.request<SubscriptionApiPurchaseResponseInterface>(
      ApiHttpMethodEnum.post,
      subscriptionCheckoutApiUrls.createPurchases,
      { data },
    );
    return response.data;
  }

  public async addPurchaseItems(
    data: { purchasable_id: string; quantity: number },
    purchasesId: string,
  ): Promise<ApiBaseDataResponseObjectType<Array<string>>> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.addPurchasesItems, {
      purchasesId,
    });
    const response = await this.apiClient.request<ApiBaseDataResponseObjectType<Array<string>>>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );
    return response.data;
  }

  public async viewPurchase(purchasesId: string): Promise<SubscriptionApiPurchaseResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.viewPurchase, {
      purchasesId,
    });
    const response = await this.apiClient.request<SubscriptionApiPurchaseResponseInterface>(ApiHttpMethodEnum.get, url);
    return response.data;
  }

  public async setPaymentMethod(
    data: SubscriptionTypesSubscriptionCheckoutPaymentMethodRequestInterface,
    purchasesId: string,
  ): Promise<ApiBaseDataResponseObjectType<SubscriptionTypesSubscriptionCheckoutApplePayConfigType>> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.setPaymentMethod, {
      purchasesId,
    });
    const response = await this.apiClient.request<
      ApiBaseDataResponseObjectType<SubscriptionTypesSubscriptionCheckoutApplePayConfigType>
    >(ApiHttpMethodEnum.post, url, { data });
    return response.data;
  }

  public async setIsRecurring(
    data: SubscriptionTypesSubscriptionCheckoutRecurringRequestInterface,
    purchasesId: string,
  ): Promise<any> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.setIsRecurring, {
      purchasesId,
    });
    const response = await this.apiClient.request<any>(ApiHttpMethodEnum.post, url, { data });
    return response.data;
  }

  public async deactivateAutoRenewSubscription(): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      subscriptionCheckoutApiUrls.deactivateAutoRenew,
    );
    return response.data;
  }

  public async activateAutoRenewSubscription(): Promise<ApiBaseMessageResponseObjectType> {
    const response = await this.apiClient.request<ApiBaseMessageResponseObjectType>(
      ApiHttpMethodEnum.post,
      subscriptionCheckoutApiUrls.activateAutoRenew,
    );
    return response.data;
  }

  public async deletePurchaseItem(
    data: { purchasable_id: string },
    purchasesId: string,
  ): Promise<SubscriptionApiPurchaseResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.deletePurchasesItems, {
      purchasesId,
    });
    const response = await this.apiClient.request<SubscriptionApiPurchaseResponseInterface>(
      ApiHttpMethodEnum.delete,
      url,
      { data },
    );
    return response.data;
  }

  public async purchaseCheckout(data: SubscriptionTypesSubscriptionPurchasesCheckoutRequestInterface): Promise<any> {
    const response = await this.apiClient.request<any>(
      ApiHttpMethodEnum.post,
      subscriptionCheckoutApiUrls.purchasesCheckout,
      { data },
    );
    return response.data;
  }

  public async purchaseBankTransfer(data: FormData): Promise<any> {
    const response = await this.apiClient.request<any>(
      ApiHttpMethodEnum.post,
      subscriptionCheckoutApiUrls.purchasesBankTransfer,
      { data },
    );
    return response.data;
  }

  public async confirmZidWalletPurchase(
    purchaseId: string,
  ): Promise<SupscriptionTypesConfirmCheckoutResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.confirmZidWallet, {
      purchaseId,
    });
    const response = await this.apiClient.request<SupscriptionTypesConfirmCheckoutResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: { purchaseId },
      },
    );
    return response.data;
  }

  public async confirmWalletTopup(
    purchaseId: string,
    tapId: string,
  ): Promise<SupscriptionTypesConfirmCheckoutResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.confirmWalletTopup, {
      purchaseId,
    });
    const response = await this.apiClient.request<SupscriptionTypesConfirmCheckoutResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
      {
        data: { ['tap_id']: tapId },
      },
    );
    return response.data;
  }

  public async setCoupon(
    data: SubscriptionTypesSubscriptionCheckoutCouponRequestInterface,
    purchasesId: string,
  ): Promise<SubscriptionApiPurchaseResponseInterface | any> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.setCoupon, {
      purchasesId,
    });
    const response = await this.apiClient.request<SubscriptionApiPurchaseResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
      { data },
    );

    return response.data;
  }

  public async deleteCoupon(purchasesId: string): Promise<SubscriptionApiPurchaseResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.deleteCoupon, {
      purchasesId,
    });
    const response = await this.apiClient.request<SubscriptionApiPurchaseResponseInterface>(
      ApiHttpMethodEnum.delete,
      url,
    );
    return response.data;
  }

  public async checkPurchasedStatus(
    purchasesId: string,
  ): Promise<SubscriptionApiPurchasedResponseInterface | ApiStatusResponseObjectType> {
    const response = await this.apiClient.request<
      SubscriptionApiPurchasedResponseInterface | ApiStatusResponseObjectType
    >(ApiHttpMethodEnum.post, subscriptionCheckoutApiUrls.checkPurchasedStatus, {
      data: { id: purchasesId },
    });
    return response.data;
  }

  public async handleApplePayPurchase(purchaseId: string, tapTokenId: string): Promise<ApiBaseResponseObjectType> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.handleApplePayPurchase, {
      purchaseId,
    });
    const response = await this.apiClient.request<ApiBaseResponseObjectType>(ApiHttpMethodEnum.post, url, {
      data: {
        ['tap_token_id']: tapTokenId,
      },
    });
    return response.data;
  }

  public async confirm(purchaseId: string): Promise<SupscriptionTypesConfirmCheckoutResponseInterface> {
    const url = urlFormatParams(subscriptionCheckoutApiUrls.confirm, {
      purchaseId,
    });
    const response = await this.apiClient.request<SupscriptionTypesConfirmCheckoutResponseInterface>(
      ApiHttpMethodEnum.post,
      url,
    );
    return response.data;
  }
}
