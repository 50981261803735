export const zidWalletApiUrls = {
  wallet: '/api/v1/wallet',
  transactions: '/api/v1/wallet/transactions',
  bankTransfer: '/api/v1/wallet-bank-transfer',
  updateWallet: '/api/v1/wallet/update',
  verify: '/api/v1/wallet/verify',
  verifyOtp: '/api/v1/wallet/verify/otp',
  resendOtp: '/api/v1/wallet/verify/otp-resend',
  withdraw: '/api/v1/wallet/withdraw',
  exportTransactions: '/api/v1/wallet/transactions/export',
  configurations: '/api/v1/wallet/configurations',
  walletCheckout: '/api/v1/purchases/wallet-checkout',
};
