import { Component, Prop } from 'vue-property-decorator';
import Vue, { VNode } from 'vue';
import { CreateElement } from 'vue/types/umd';
import styles from './WalletStatus.scss';
import { ZidButton, ZidHeading, ZidModal } from '@zidsa/ui';
import approvePay from '../../assets/images/approved-pay.svg';
import rejectPay from '../../assets/images/reject-pay.svg';
import { ButtonSizeEnum } from '../../../common/components/Button/size.enum';
import { I18nMessages } from '../../../i18n/messages';
import { RoutingRouteEnum } from '../../../routing/route.enum';
import { PurchaseStatusCodeEnum } from '../../../subscription/api/subscription-mangmanet/purchase-history/filters.interface';

@Component
export class WalletStatus extends Vue {
  @Prop()
  private readonly showResponseModal!: boolean;

  @Prop()
  private readonly purchaseStatus!: string;

  render(h: CreateElement): VNode {
    return (
      <div>
        <ZidModal
          class={styles['wallet-status-modal']}
          visible={this.showResponseModal}
          onClose={this.onToggleResponseModal}
          title={this.$t(I18nMessages['wallet.wallet_status.header'])}
        >
          <main>
            {this.purchaseStatus === PurchaseStatusCodeEnum.approved ||
            this.purchaseStatus === PurchaseStatusCodeEnum.initiated
              ? this.renderApprovedStatus(h)
              : this.renderRejectStatus(h)}
          </main>
        </ZidModal>
      </div>
    );
  }

  private onToggleResponseModal(): void {
    this.$emit('toggleResponseModal');
  }

  private renderApprovedStatus(h: CreateElement): VNode {
    return (
      <div class={styles['wallet-status-modal__container']}>
        <div class={styles['wallet-status-modal__container__info']}>
          <img src={approvePay} />
          <ZidHeading level={6} type={'primary'} weight={'semibold'}>
            {this.$t(I18nMessages['wallet.wallet_status.accepted'])}
          </ZidHeading>
          <p> {this.$t(I18nMessages['wallet.wallet_status.accepted_caption'])}</p>
        </div>
        <footer>
          <ZidButton
            size={ButtonSizeEnum.large}
            type={'primary'}
            onClick={(): void => {
              this.$emit('toggleResponseModal');
              this.$router.push({ name: RoutingRouteEnum.zidWallet });
            }}
          >
            {this.$t(I18nMessages['wallet.wallet_status.back_to_wallet'])}
          </ZidButton>
        </footer>
      </div>
    );
  }

  private renderRejectStatus(h: CreateElement): VNode {
    return (
      <div class={styles['wallet-status-modal__container']}>
        <div class={styles['wallet-status-modal__container__info']}>
          <img src={rejectPay} />
          <ZidHeading level={6} type={'primary'} weight={'semibold'}>
            {this.$t(I18nMessages['wallet.wallet_status.rejected'])}
          </ZidHeading>
          <p>{this.$t(I18nMessages['wallet.wallet_status.rejected_caption'])}</p>
        </div>
        <footer>
          <ZidButton
            type={'primary'}
            size={ButtonSizeEnum.large}
            onClick={(): void => {
              this.$emit('toggleFundsModal');
              this.$emit('toggleResponseModal');
            }}
          >
            {this.$t(I18nMessages['wallet.wallet_status.back_to_payment'])}
          </ZidButton>
        </footer>
      </div>
    );
  }
}
