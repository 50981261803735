import Vue, { VNode, CreateElement } from 'vue';
import { Component } from 'vue-property-decorator';
import { NotificationsStoreModule } from '../store/module';
import AppNotificationInterface from '../store/notification.interface';
import Notification from './Notification';

@Component
export default class AppNotifications extends Vue {
  get notifications(): Array<AppNotificationInterface> {
    return NotificationsStoreModule.notifications;
  }
  render(h: CreateElement): VNode | null {
    return this.notifications.length > 0 ? (
      <div>
        {this.notifications.map((notification: AppNotificationInterface) => (
          <Notification data-identifier={notification.identifier} notification={notification} />
        ))}
      </div>
    ) : null;
  }
}
