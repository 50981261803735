import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';
import '../common/styles/variables.global.css';
import '../common/styles/fonts.global.css';
import '../common/styles/scrollbar.css';
import '../common/styles/bootstrap.global.scss';
import App from './views/App/App';
import appApiStoreFactory from './store/factory';
import { appStoreHydrate } from './store/hydrate';
import appI18nFactory from './i18n/factory';
import { appRouterFactory } from '../routing/factory';
import {
  GOOGLE_MAPS_DEV_KEY,
  GOOGLE_MAPS_PRODUCTION_KEY,
  MIXPANEL_TESTING_TOKEN,
  MIXPANEL_PROD_TOKEN,
} from '../common/constants';
import isProduction from '../common/helpers/is-production';
import { ZidToasterPlugin } from '@zidsa/ui';
import VueApexCharts from 'vue-apexcharts';
import * as Sentry from '@sentry/vue';
import mixpanel from 'mixpanel-browser';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuelidate);

Vue.use(VueGoogleMaps, {
  load: {
    key: isProduction() ? GOOGLE_MAPS_PRODUCTION_KEY : GOOGLE_MAPS_DEV_KEY,
    libraries: 'places',
  },
});

Vue.use(ZidToasterPlugin);

mixpanel.init(isProduction() ? MIXPANEL_PROD_TOKEN : MIXPANEL_TESTING_TOKEN, {
  // eslint-disable-next-line @typescript-eslint/camelcase
  record_sessions_percent: 1,
  // eslint-disable-next-line @typescript-eslint/camelcase
  record_idle_timeout_ms: 60000,
  // eslint-disable-next-line @typescript-eslint/camelcase
  record_mask_text_class: '',
  // eslint-disable-next-line @typescript-eslint/camelcase
  record_mask_text_selector: '',
  // eslint-disable-next-line @typescript-eslint/camelcase
  record_max_ms: 900000,
});

export function appCreate(rootElement: HTMLElement): void {
  const router = appRouterFactory();

  const store = appApiStoreFactory();

  appStoreHydrate();

  const i18n = appI18nFactory();

  /**
   * IS_PRODUCTION and VUE_APP_SENTRY_DSN are defined in webpack.config.js
   * Global variables injected into the app at build time
   * By webpack.DefinePlugin
   */
  if (IS_PRODUCTION && VUE_APP_SENTRY_DSN) {
    Sentry.init({
      Vue,
      dsn: VUE_APP_SENTRY_DSN,
      tracePropagationTargets: ['web.zid.sa/orders', 'web.zid.sa/products'],
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['web.zid.sa/orders', 'web.zid.sa/products'],
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 0.25, //  Capture 25% of the transactions
      denyUrls: [
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Block beamer to solve this issue: https://zid.sentry.io/issues/4794169041/?project=1274216
        /app\.getbeamer\.com\/js\/beamer-embed\.js/i,
        // Block snapchat pixel to solve this issue: https://zid.sentry.io/issues/4794172429/?project=1274216
        /cdn\.speakol\.com\/pixel\/js\/sppixel\.min\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
      ],
    });
  }

  const instance = new Vue({
    router,
    store,
    i18n,
    render: (createElement) => createElement(App),
  });

  //This will register vue-router hooks to the class Components
  Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

  Vue.use(VueApexCharts);

  Vue.component('apexchart', VueApexCharts);

  instance.$mount(rootElement);
}
