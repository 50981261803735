const ZIDSHIP_API = 'https://api.zidship.com';

export const zidShipApiUrls = {
  serviceLevels: '/account/settings/delivery-options/zidship/service-levels?city_id={city_id}',
  serviceLevelDetails: '/account/settings/delivery-options/zidship/service-level-details/{code}',
  serviceLevelCutomization: '/account/settings/delivery-options/zidship/service-level-customization/{code}',
  walletBalance: '/account/settings/delivery-options/zidship/wallet-balance',
  walletTransactions: '/account/settings/delivery-options/zidship/wallet-transactions',
  packagesTransactions: '/account/settings/delivery-options/zidship/packages-transactions',
  printTransactionsAttachment:
    '/account/settings/delivery-options/zidship/print-transactions-attachment?transaction_numbers={transaction_numbers}',
  activateServiceLevels: '/account/settings/delivery-options/zidship/service-activate',
  serviceActivationFormData: '/account/settings/delivery-options/zidship/get-merchant-account-data',
  disableServiceLevels: '/account/settings/delivery-options/zidship/deactivate-service-level',
  configurations: '/account/settings/delivery-options/zidship/service-levels/{code}/configurations',
  updateZidshipInventoryAddress: '/account/settings/delivery-options/zidship/update-zidship-inventory-addresses',
  purchasables: '/api/v1/purchasables?type=zidship_balance',
  payOnline: '/purchases/zidship?response_json=true',
  bankTransfer: '/account/settings/delivery-options/zidship/wallet-bank-transfer',
  journeyPages: `${ZIDSHIP_API}/v1/pages/zidship-journey/`,
  googleMapsSettings: '/market/integrations/google_maps',
  merchantReports: '/account/settings/delivery-options/zidship/get-merchant-reports',
  shipmentsSummary: '/account/settings/delivery-options/zidship/get-shipments-summary',
  verifyIBAN: '/account/settings/delivery-options/zidship/verify-iban',
  sendOTP: '/account/settings/delivery-options/zidship/send-otp',
  verifyOTP: '/account/settings/delivery-options/zidship/verify-otp',
  zidshipStatus: '/account/settings/delivery-options/zidship/status',
  returnShipment: '/account/settings/delivery-options/zidship/return-shipment',
  zidshipNeedPickup:
    '/account/settings/delivery-options/zidship/first-mile/need-pickup?reference_numbers={reference_numbers}',
  zidshipAssignPickupRequest: '/account/settings/delivery-options/zidship/first-mile/assign-pickup-request',
  checkIfHasPickupFeature: '/account/settings/delivery-options/zidship/first-mile/has-pickup-feature',
  maxBoxesNumber:
    '/account/settings/delivery-options/zidship/service-levels/{code}/max-pieces?origin_city={origin_city}&origin_country_id={origin_country_id}&destination_city={destination_city}&destination_country_id={destination_country_id}',
  checkPurchasedStatus: '/api/v1/account/settings/check-purchased-status',
  zidshipShipments: '/api/v1/account/settings/delivery-options/zidship/shipments',
  zidshipShipmentsExport: '/api/v1/account/settings/delivery-options/zidship/shipments/export',
  zidshipShipmentsFilters: '/api/v1/account/settings/delivery-options/zidship/shipments/filters',
  serviceLevelCourierDetails:
    '/account/settings/delivery-options/zidship/service-levels/{service_level}/couriers/{courier_code}',
  zidshipServiceLevelsPackages:
    '/account/settings/delivery-options/zidship/get-zidship-service-levels-packages?service_level_code={service_level_code}',
};
