import { StringifyOptions } from 'query-string';
import queryString from 'query-string';
import { UrlQueryTypesFormatObjectType } from './types';

const defaultOptions: StringifyOptions = {
  arrayFormat: 'bracket',
  encode: true,
  skipNull: true,
  skipEmptyString: false,
};

export function urlQueryFormat(input: UrlQueryTypesFormatObjectType, options: Partial<StringifyOptions> = {}): string {
  const finalOptions = Object.assign({}, defaultOptions, options);

  return queryString.stringify(input, finalOptions);
}
