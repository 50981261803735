import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import appStoreFactory from '../../../app/store/factory';
import AppStoreReadyStateEnum from '../../../app/store/ready-state.enum';
import Catch from '../../../common/decorators/catch-error';
import { SubscriptionManagementApiServiceFactory } from '../../api/subscription-mangmanet/service-factory';

const SubscriptionManagementService = SubscriptionManagementApiServiceFactory();

@Module({
  dynamic: true,
  name: 'PaymentCards',
  store: appStoreFactory(),
  namespaced: true,
})
class SubscriptionManagementPaymentCardsModule extends VuexModule {
  public loadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;
  public data: any | null = null;
  public error: Error | null = null;
  public deleteCardLoadingState: AppStoreReadyStateEnum = AppStoreReadyStateEnum.pending;

  @Mutation
  private FETCH(): void {
    this.loadingState = AppStoreReadyStateEnum.loading;
    this.data = null;
  }

  @Mutation
  private FETCH_SUCCESS(data: any): void {
    this.loadingState = AppStoreReadyStateEnum.loaded;
    this.data = data.data;
  }

  @Mutation
  FETCH_ERROR(error: Error | null): void {
    this.error = error;
    this.loadingState = AppStoreReadyStateEnum.error;
  }

  @Mutation
  private FETCH_CARD_SUCCESS(data: any): void {
    window.location.href = data.response.redirect.url;
  }

  @Mutation
  private DELETE_CARD_LOADING(): void {
    this.deleteCardLoadingState = AppStoreReadyStateEnum.loading;
  }

  @Mutation
  private DELETE_CARD_SUCCESS(): void {
    this.deleteCardLoadingState = AppStoreReadyStateEnum.loaded;
  }

  @Action({ rawError: true })
  @Catch({ onError: (_, ctx) => ctx.FETCH_ERROR(null) })
  public async fetch(): Promise<void> {
    this.FETCH();
    const response = await SubscriptionManagementService.getPaymentCards();
    this.FETCH_SUCCESS(response.data);
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async savePaymentCard(cardToken: string): Promise<any> {
    const response = await SubscriptionManagementService.savePaymentCard(cardToken);
    this.FETCH_CARD_SUCCESS(response.data);
    return response;
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async deletePaymentCard(cardId: string): Promise<any> {
    this.DELETE_CARD_LOADING();
    const response = await SubscriptionManagementService.deletePaymentCard(cardId);
    this.DELETE_CARD_SUCCESS();
    return response;
  }

  @Action({ rawError: true })
  @Catch({ onError: (error: any, ctx) => ctx.FETCH_ERROR(error) })
  public async setDefaultPaymentCard(cardId: string): Promise<any> {
    const response = await SubscriptionManagementService.setDefaultPaymentCard(cardId);
    await this.fetch();
    return response;
  }
}

export const SubscriptionManagementPaymentCardsStoreModule = getModule(SubscriptionManagementPaymentCardsModule);
