export enum MazeedEnumsStoreFormKeysEnum {
  mazeedIndustryId = 'mazeedIndustryId',
  description = 'description',
  isContactOperations = 'isContactOperations',
  bankId = 'bankId',
  existingBankAccount = 'existingBankAccount',
  businessType = 'business_type',
  crNumber = 'cr_number',
  vatNumber = 'vat_number',
  name = 'name',
  establishment = 'establishment',
  corporate = 'corporate',
  individual = 'individual',
  beneficiary = 'beneficiary',
  iban = 'iban',
  accountNumber = 'accountNumber',
  mobile = 'mobile',
  email = 'email',
  contactOwner = 'contactOwner',
  bankAccount = 'bankAccount',
  storeInfo = 'store_info',
  mazeedProductsIdLocalStorageKey = 'mazeedProductIds',
  contactOperations = 'contactOperations',
  contactSupport = 'contactSupport',
  ordersCount = 'orders_count',
  ordersTotal = 'orders_total',
  freelanceCertificate = 'freelance_certificate',
  commercialName = 'commercial_name',
}
